import styled from 'styled-components'

const CreateWorkspaceBox = styled.div`
  .content-box {
    height: 100%;
  }
  .content-scroll {
    height: calc(96% - 72px);
    overflow-y: auto;
    // margin-top: 100px;
  }
  .-pointer {
    cursor: pointer;
  }
  .btn {
    margin: 0.125em;
  }
  .boxSetting {
    right: 1%;
    width: 200px;
    text-align: left;
    border: 1px solid #0000000f;
    box-shadow: 6px 7px 20px 0 rgb(0 0 0 / 11%);
  }
  .dropdown-item-setting {
    cursor: pointer;
    padding: 10px;
  }
  .dropdown-item-setting:hover {
    background: #c1c1c129;
  }
  .badge {
    margin-left: 0px;
    min-width: 55px;
    text-align: left;
  }
  .badge-default {
    background-color: #f2f4f7;
    color: #344054;
  }
  .badge-green {
    background-color: #ecfdf3;
    color: #037a48;
  }
  .badge-blue {
    background-color: #0047ff;
    color: #fff;
  }
  .badge-yellow {
    background-color: #fec84b;
    color: #344054;
  }
  .badge-white {
    border: solid 1px #d0d5dd;
    background-color: #fff;
    color: #344054;
  }
  .table {
    svg {
      color: #98a2b3;
      cursor: pointer;
    }
  }

  .-br-5 {
    border-radius: 5px;
  }
`
const ModalBox = styled.div`
  .w-table {
    max-width: 345px !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
export { CreateWorkspaceBox, ModalBox }
