import styled from 'styled-components'

const TagBox = styled.div`
  // input[type="checkbox"]:checked::after {
  //   content: "";
  //   display: block;
  //   width: 6px;
  //   height: 11px;
  //   border-style: solid;
  //   border-color: rgb(255, 255, 255);
  //   border-image: initial;
  //   border-width: 0px 2px 2px 0px;
  //   transform: rotate(45deg);
  //   position: absolute;
  //   top: 1px;
  //   left: 5px;
  // }
  // input[type="checkbox"]:checked::before {
  //   content: "";
  //   display: block;
  //   position: absolute;
  //   width: 16px;
  //   height: 16px;
  //   border: 1px solid rgb(0, 71, 255);
  //   background-color: rgb(0, 71, 255);
  // }

  .check-box-16 {
    height: 16px;
    width: 16px;
  }
  .d-baseline {
    display: flex;
    flex-flow: row;
    place-content: flex-start;
    -webkit-box-align: baseline;
    -webkit-box-pack: start;
    align-items: center;
  }
  .btn-drop-disabled {
    background-color: #f5f5f5 !important;
    color: #d6dded !important;
    border-color: #d6dded !important;
  }
  .w-table {
    max-width: 250px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .w-280px {
    width: 280px;
  }
  .email-status {
    background: #ecfdf3;
    padding: 15px;
    border-radius: 50px;
    color: #667085;
  }
  .content-menu {
    width: 100%;
    padding-left: 256px;
  }
  .content-box {
    height: 100%;
  }
  .content-scroll {
    // margin-top: 100px;
    overflow: auto;
  }
  .-pointer {
    cursor: pointer;
  }
  .btn {
    margin: 0.125em;
  }
  .boxSetting {
    right: 1%;
    width: 200px;
    text-align: left;
    border: 1px solid #0000000f;
    box-shadow: 6px 7px 20px 0 rgb(0 0 0 / 11%);
  }
  .dropdown-item-setting {
    cursor: pointer;
    padding: 10px;
  }
  .dropdown-item-setting:hover {
    background: #c1c1c129;
  }
  .badge {
    margin-left: 0px;
    min-width: 55px;
    text-align: center;
    border-radius: 16px;
    font-weight: 600;
    font-size: 12px;
    padding: 2px 8px 2px 6px;
  }
  .badge-default {
    background-color: #f2f4f7;
    color: #344054;
  }
  .badge-green {
    background: #ecfdf3;
    mix-blend-mode: multiply;
    color: #12b76a;
  }
  .badge-blue {
    background-color: #0047ff;
    color: #fff;
  }
  .badge-primary {
    background-color: #eaf0ff;
    color: #0047ff;
  }
  .badge-yellow {
    background-color: #fec84b;
    color: #344054;
  }
  .badge-white {
    border: solid 1px #d0d5dd;
    background-color: #fff;
    color: #344054;
  }
  .table {
    svg {
      color: #98a2b3;
      cursor: pointer;
    }
  }

  .close:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit;
  }
  button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
  }
  .close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
  }
  .alert-box {
    position: absolute;
    width: 30%;
    z-index: 999999;
    padding: 0px;
    top: 45px;
    right: 20px;
  }
  .input-group-text {
    padding: 10px 0.75rem;
    line-height: 1.5;
    background-color: #fff;
    display: flex;
    align-items: center;
    border: 1px solid #ced4da;
    border-right: 0;
    background: #e9ecef;
    border-radius: 0 !important;
  }
  .boxsearch {
    right: 16%;
    width: 32%;
    text-align: left;
    border: 1px solid #0000000f;
    box-shadow: 6px 7px 20px 0 rgb(0 0 0 / 11%);
    z-index: 9;
  }
  .text-gray {
    color: #d0d5dd;
  }
  .card {
    border: none;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1);
    border-radius: 10px;
  }
  .card-body {
    padding: 21px;
    border-radius: 10px;
  }
  .integrate-name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    margin: 0;
  }
  .integrate-name2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    margin: 0;
  }
  .integrate-desc {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }
  .settings-but {
    border: 1px solid #d0d5dd;
    border-radius: 5px;
    padding: 5px 20px;
    background: none;
    font-size: 14px;
  }
  .ph-gray::placeholder {
    color: #d0d5dd !important;
    font-size: 14px !important;
  }
  .fixed-conversion-id {
    padding-left: 54px;
  }
  .fixed-position {
    position: absolute;
    padding: 7px 13px;
  }
  table {
    width: 60%;
  }
  table tr {
    height: 50px;
  }
  table th {
    padding-bottom: 15px;
  }
  table > tbody > tr {
    border-top: 1px solid rgb(234, 236, 240);
  }
  table .form-check-input {
    float: none;
  }
  .cm-box {
    height: 0px;
  }
  .fix-tr {
    padding: 18px 0px 16px;
    font-size: 14px;
    font-weight: 600;
    color: #667085;
  }
  .switch {
    width: 38px;
    height: 19px;
  }
  .slider-disabled:before {
    background-color: #ffffff !important;
  }
  .slider-disabled.round {
    background: #96b3ff;
    border: 1px solid #f5f5f5;
  }
  .slider-disabled-fix {
    background: #f5f5f5 !important;
  }
  .slider-md:before {
    background-color: #ffffff !important;
  }
  .slider {
    background: #d0d5dd;
  }
  .slider: before {
    height: 15px;
    width: 15px;
    left: 1px;
    bottom: 1px;
  }
  input:checked + .slider:before {
    transform: translateX(19px);
  }
  .force-disable {
    background: #d9d9d9 !important;
  }
  .form-control {
    font-size: 14px;
  }
`

export { TagBox }
