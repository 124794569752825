import styled from 'styled-components'

const VerifyBox = styled.div`
  .content-box {
    height: 100%;
    overflow-y: hidden;
    min-height: 660px;
  }
  .content {
    border-radius: 0px;
    box-shadow: 4px 10px 30px 0 rgb(0 0 0 / 5%);
    background-color: #fff;
    padding: 30px;
  }
  .otp-input {
    width: 48px !important;
    padding: 13px;
    height: 3.5rem;
    margin: 0 0.2rem;
    font-size: 2rem;
    border-radius: 4px;
    border: 1px solid #d0d5dd !important;
  }
  .otp-input-validate {
    width: 48px !important;
    padding: 13px;
    height: 3.5rem;
    margin: 0 0.2rem;
    font-size: 2rem;
    border-radius: 4px;
    border: 1px solid #dc3545;
  }
  .content {
    min-height: 80vh;
    max-height: 100vh;
  }
`
const ModalBox = styled.div`
  .w-table {
    max-width: 345px !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
export { VerifyBox, ModalBox }
