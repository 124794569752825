import React, { Fragment, useState, useEffect, useRef } from 'react'
import { Link, useParams } from 'react-router-dom'
import { BsThreeDotsVertical } from '@react-icons/all-files/bs/BsThreeDotsVertical'
import Modal from 'react-modal'
import { MdError, MdWarning, MdClose, MdNavigateNext } from 'react-icons/md'
import { roleByAccountId, roleByWorkspaceId } from '../../apis/account/api'
import { MetaBox } from './style'
import FooterComponent from '../layout/footer'
import MenuSettingComponent from '../layout/menusetting'
import TopbarSettingsComponent from '../layout/topbarworkspacesettings'
import AlertComponent from '../layout/alert'
import TopbarNotiComponent from '../layout/topbarNotification'
import { MdExpandMore } from 'react-icons/md'
import { FaFilter } from '@react-icons/all-files/fa/FaFilter'
import { FaAngleDown } from '@react-icons/all-files/fa/FaAngleDown'
import DataTable, { createTheme } from 'react-data-table-component'
import Dropdown from 'react-bootstrap/Dropdown'

import { redirectLogin } from '../../helper/helper'

import { allMetadataList, deleteMatedata } from '../../apis/metadata/api'
import LoadingComponent from '../layout/loading'
import TopbarFreeNotiComponent from '../layout/topbarFreeNotification'
import { planDetail } from '../../apis/plan/api'
var _tempTag = []

const MetaComponent = () => {
  const { accountId, workspaceId } = useParams()

  const { id } = useParams()
  const [showDropdownFilter, setShowDropdownFilter] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [filterType, setFilterType] = useState('')
  const [metadataName, setMetadataName] = useState('')
  const [filterCategory, setFilterCategory] = useState('')
  const [activeWorkspaceRole, setActiveWorkspaceRole] = useState('')
  const [activeAccountRole, setActiveAccountRole] = useState('')
  const [paginationReset, setPaginationReset] = useState(false)
  const [isLastItem, setIsLastItem] = useState(false)
  const [allTagsList, setAllTagsList] = useState([])

  const [deleteMetaId, setDeleteMetaId] = useState('')
  const [showPopupDeleteMeta, setShowPopupDeleteMeta] = useState(false)
  const [metadataList, setMetadataList] = useState('')
  const [metadataListMaster, setMetadataListMaster] = useState('')
  const [accountDetail, setAccountDetail] = useState('')
  const [selectFilterType, setSelectFilterType] = useState('Select All')
  const [selectFilterCategory, setSelectFilterCategory] = useState('Select All')
  const [isLoading, setIsLoading] = useState(false)

  const accessToken = localStorage.getItem('token')
  const [isLoadingPage, setIsLoadingPage] = useState(true)
  const [planInfo, setPlanInfo] = useState('')
  const ref = useRef()
  const [showAlert, setShowAlert] = useState({
    msg: 'Account Role has been updated successfully.',
    status: true,
    isShow: false,
  })
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '436px',
    },
  }

  const getRoleByWorkspaceId = async () => {
    const { data, status } = await roleByWorkspaceId(accessToken, workspaceId)
    if (status === 200) {
      setActiveWorkspaceRole(data.data.role)
    }
  }
  const getRoleByAccountId = async () => {
    const { data, status } = await roleByAccountId(accessToken, accountId)
    if (status === 200) {
      setActiveAccountRole(data.data.role)
      if (data.data.role !== 'None') {
        getPlanInfo()
      }
    }
  }

  const handleClickOutside = (event) => {
    try {
      if (!event?.target?.className?.includes('btn-filter')) {
        if (ref.current && ref.current.contains(event.target)) {
          return
        } else {
          setShowDropdownFilter(false)
        }
      }
    } catch (error) {
      setShowDropdownFilter(false)
    }
  }

  const clearFilter = () => {
    setIsLoading(true)
    setSelectFilterType('Select All')
    setSelectFilterCategory('Select All')
    setShowFilter(false)
    setShowDropdownFilter(false)
    getMetadata()
    setPaginationReset(!paginationReset)
    setTimeout(() => {
      setIsLoading(false)
    }, 700)
  }

  const startFilter = (dataMaster = '') => {
    setIsLoading(true)
    let listFilter = dataMaster || metadataListMaster
    setShowFilter(true)
    setShowDropdownFilter(false)
    let res = []
    if (
      selectFilterType === 'Select All' &&
      selectFilterCategory !== 'Select All'
    ) {
      let _tempData = {}
      let filterCate = listFilter.filter(
        (item) => item.category === selectFilterCategory,
      )
      for (let i = 0; i < filterCate.length; i++) {
        _tempData = {
          name: filterCate[i].name,

          type: <span>{filterCate[i].type}</span>,
          category: <span>{filterCate[i].category}</span>,
          channel: (
            <div className="text-14 text-center">
              {filterCate[i]?.channel_type ? (
                <span className="text-14 ">
                  <img
                    src={`/assets/img/${filterCate[i].channel_type}.png`}
                    style={{ height: '18px' }}
                    className="mx-1"
                  />
                </span>
              ) : (
                <Fragment />
              )}
            </div>
          ),
          option: (
            <Fragment>
              {filterCate[i].type === 'Custom' && (
                <Dropdown className="m-0 cursor" style={{ height: 15 }}>
                  <Dropdown.Toggle className="dropdown-dot p-0 m-0">
                    <BsThreeDotsVertical
                      className="-pointer"
                      style={{ marginTop: -8 }}
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="position-fixed boxSetting-dot">
                    <Dropdown.Item
                      onClick={() => handlePopupDeleteMeta(filterCate[i])}
                      className="dropdown-item-setting-dot text-14 text-red"
                    >
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </Fragment>
          ),
        }
        res.push(_tempData)
      }
      setMetadataList(res)
    } else if (
      selectFilterCategory === 'Select All' &&
      selectFilterType !== 'Select All'
    ) {
      let _tempData = {}
      let filterAllCate = listFilter.filter(
        (item) => item.type === selectFilterType,
      )
      for (let i = 0; i < filterAllCate.length; i++) {
        _tempData = {
          name: filterAllCate[i].name,

          type: <span>{filterAllCate[i].type}</span>,
          category: <span>{filterAllCate[i].category}</span>,
          channel: (
            <div className="text-14 text-center">
              {filterAllCate[i]?.channel_type ? (
                <span className="text-14 ">
                  <img
                    src={`/assets/img/${
                      filterAllCate[i].channel_type === 'ga4'
                        ? 'ga4'
                        : filterAllCate[i].channel_type
                    }.png`}
                    style={{ height: '18px' }}
                    className="mx-1"
                  />
                </span>
              ) : (
                <Fragment />
              )}
            </div>
          ),
          option: (
            <Fragment>
              {filterAllCate[i].type === 'Custom' && (
                <Dropdown className="m-0 cursor" style={{ height: 15 }}>
                  <Dropdown.Toggle className="dropdown-dot p-0 m-0">
                    <BsThreeDotsVertical
                      className="-pointer"
                      style={{ marginTop: -8 }}
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="position-fixed boxSetting-dot">
                    <Dropdown.Item
                      onClick={() => handlePopupDeleteMeta(filterAllCate[i])}
                      className="dropdown-item-setting-dot text-14 text-red"
                    >
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </Fragment>
          ),
        }
        res.push(_tempData)
      }
      setMetadataList(res)
      // setMetadataList(
      //   metadataListMaster.filter((item) => item.type === selectFilterType)
      // );
    } else if (
      selectFilterType === 'Select All' &&
      selectFilterCategory === 'Select All'
    ) {
      let _tempData = {}
      let filterAll = listFilter
      for (let i = 0; i < filterAll.length; i++) {
        _tempData = {
          name: filterAll[i].name,

          type: <span>{filterAll[i].type}</span>,
          category: <span>{filterAll[i].category}</span>,
          channel: (
            <div className="text-14 text-center">
              {filterAll[i]?.channel_type ? (
                <span className="text-14 ">
                  <img
                    src={`/assets/img/${
                      filterAll[i].channel_type === 'ga4'
                        ? 'ga4'
                        : filterAll[i].channel_type
                    }.png`}
                    style={{ height: '18px' }}
                    className="mx-1"
                  />
                </span>
              ) : (
                <Fragment />
              )}
            </div>
          ),
          option: (
            <Fragment>
              {filterAll[i].type === 'Custom' && (
                <Dropdown className="m-0 cursor" style={{ height: 15 }}>
                  <Dropdown.Toggle className="dropdown-dot p-0 m-0">
                    <BsThreeDotsVertical
                      className="-pointer"
                      style={{ marginTop: -8 }}
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="position-fixed boxSetting-dot">
                    <Dropdown.Item
                      onClick={() => handlePopupDeleteMeta(filterAll[i])}
                      className="dropdown-item-setting-dot text-14 text-red"
                    >
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </Fragment>
          ),
        }
        res.push(_tempData)
      }
      setMetadataList(res)

      // setMetadataList(metadataListMaster);
    } else {
      let _tempData = {}
      let filterAll = metadataListMaster.filter(
        (item) =>
          item.type === selectFilterType &&
          item.category === selectFilterCategory,
      )
      for (let i = 0; i < filterAll.length; i++) {
        _tempData = {
          name: filterAll[i].name,

          type: <span>{filterAll[i].type}</span>,
          category: <span>{filterAll[i].category}</span>,
          channel: (
            <div className="text-14 text-center">
              {filterAll[i]?.channel_type ? (
                <span className="text-14 ">
                  <img
                    src={`/assets/img/${
                      filterAll[i].channel_type === 'ga4'
                        ? 'ga4'
                        : filterAll[i].channel_type
                    }.png`}
                    style={{ height: '18px' }}
                    className="mx-1"
                  />
                </span>
              ) : (
                <Fragment />
              )}
            </div>
          ),
          option: (
            <Fragment>
              {filterAll[i].type === 'Custom' && (
                <Dropdown className="m-0 cursor" style={{ height: 15 }}>
                  <Dropdown.Toggle className="dropdown-dot p-0 m-0">
                    <BsThreeDotsVertical
                      className="-pointer"
                      style={{ marginTop: -8 }}
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="position-fixed boxSetting-dot">
                    <Dropdown.Item
                      onClick={() => handlePopupDeleteMeta(filterAll[i])}
                      className="dropdown-item-setting-dot text-14 text-red"
                    >
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </Fragment>
          ),
        }
        res.push(_tempData)
      }
      setMetadataList(res)
      // setMetadataList(
      //   metadataListMaster.filter(
      //     (item) =>
      //       item.type === selectFilterType &&
      //       item.category === selectFilterCategory
      //   )
      // );
    }
    setTimeout(() => {
      setIsLoading(false)
    }, 700)
  }

  useEffect(() => {
    document.title = 'Metadata | Tag Turbo'
    setIsLoadingPage(true)
    getMetadata()
    getRoleByAccountId()
    getRoleByWorkspaceId()
  }, [])

  useEffect(() => {
    window.addEventListener('click', handleClickOutside)
    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  }, [handleClickOutside])

  const getPlanInfo = async () => {
    const { data, status } = await planDetail(accessToken, accountId)
    if (status === 200) {
      setPlanInfo(data.plan_of_account_detail)
    }
  }

  const getMetadata = async (option = '') => {
    const { data, status } = await allMetadataList(accessToken, workspaceId)
    let res = []
    if (data && data.data && data.data.length > 0 && status === 200) {
      let _tempData = {}
      for (let i = 0; i < data.data.length; i++) {
        _tempData = {
          name: data.data[i].name,

          type: <span>{data.data[i].type}</span>,
          category: (
            <span>
              {data.data[i].category === 'Event parameter'
                ? data.data[i].category + ' name'
                : data.data[i].category}
            </span>
          ),
          channel: (
            <div className="text-14 text-center">
              {data.data[i]?.channel_type ? (
                <span className="text-14 ">
                  <img
                    src={`/assets/img/${
                      data.data[i].channel_type === 'ga4'
                        ? 'ga4'
                        : data.data[i].channel_type
                    }.png`}
                    style={{ height: '18px' }}
                    className="mx-1"
                  />
                </span>
              ) : (
                <Fragment />
              )}
            </div>
          ),
          option: (
            <Fragment>
              {data.data[i].type === 'Custom' && (
                <Dropdown className="m-0 cursor" style={{ height: 15 }}>
                  <Dropdown.Toggle className="dropdown-dot p-0 m-0">
                    <BsThreeDotsVertical
                      className="-pointer"
                      style={{ marginTop: -8 }}
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="position-fixed boxSetting-dot">
                    <Dropdown.Item
                      onClick={() => handlePopupDeleteMeta(data.data[i])}
                      className="dropdown-item-setting-dot text-14 text-red"
                    >
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </Fragment>
          ),
        }
        res.push(_tempData)
      }
      if (option === 'reload') {
        setMetadataListMaster(data.data)
        setTimeout(() => {
          startFilter(data.data)
        }, 1000)
      } else {
        setMetadataList(res)
        setMetadataListMaster(data.data)
      }

      setIsLoadingPage(false)
      _tempTag.push(...data.data)
      setAllTagsList(_tempTag.map((item) => item))
      setFilterType(removeDuplicates(data.data.map((item) => item.type)))
      setFilterCategory(
        removeDuplicates(data.data.map((item) => item.category)),
      )
    } else {
      setIsLoadingPage(false)
    }
  }

  const handlePopupDeleteMeta = (meta) => {
    setShowPopupDeleteMeta(true)
    setDeleteMetaId(meta)
    setMetadataName(meta.name)
  }

  const deleteMeta = async (meta) => {
    let obj = {
      meta_id: meta.id,
      meta_detail: {
        category: meta.category,
      },
    }
    if (meta?.category === 'Event name') {
      obj.meta_detail.meta_tag_type = meta?.tag_type
    }
    if (meta?.category === 'Event parameter value') {
      obj.meta_detail.event_params_value = meta?.event_parameter_value
    }

    const { data, status } = await deleteMatedata(accessToken, workspaceId, obj)
    if (status === 200) {
      getMetadata('reload')
      setShowPopupDeleteMeta(false)
      handleAlert('Metadata has been deleated successfully.', true)
    } else {
      handleAlert('Failed to delete your metadata. Please try again.', false)
      setShowPopupDeleteMeta(false)
    }
  }

  function removeDuplicates(array) {
    return [...new Set(array)]
  }

  const caseInsensitiveSort = (rowA, rowB) => {
    const a = rowA.name.toLowerCase()
    const b = rowB.name.toLowerCase()

    if (a > b) {
      return 1
    }

    if (b > a) {
      return -1
    }

    return 0
  }
  const columns = [
    {
      name: 'Metadata',
      selector: (row) => row.name,
      sortFunction: caseInsensitiveSort,
      sortable: true,
      width: '30%',
    },
    {
      name: 'Type',
      selector: (row) => row.type,
      sortable: false,
      width: '20%',
    },
    {
      name: 'Category',
      selector: (row) => row.category,
      sortable: false,
      width: '20%',
    },
    {
      name: 'Tracking Channel',
      selector: (row) => row.channel,
      sortable: false,
      width: '20%',
      center: true,
    },
    {
      name: '',
      selector: (row) => row.option,
      sortable: false,
    },
  ]
  const handleAlert = (msg, status) => {
    setShowAlert({
      msg,
      status,
      isShow: true,
    })

    setTimeout(() => {
      setShowAlert({
        msg,
        status,
        isShow: false,
      })
    }, 10000)
  }
  return (
    <MetaBox>
      <div className="page pull-page-bg-secondary ">
        <div className="content-box">
          <TopbarSettingsComponent accountDetailFn={setAccountDetail} />
          {isLoadingPage ? (
            <LoadingComponent />
          ) : (
            <Fragment>
              <TopbarFreeNotiComponent planInfo={planInfo} />
              <TopbarNotiComponent />

              <MenuSettingComponent
                accountId={accountId}
                id={workspaceId}
                activeWorkspaceRole={activeWorkspaceRole}
                activeAccountRole={activeAccountRole}
              />
              {showAlert.isShow && (
                <AlertComponent
                  messageAlert={showAlert.msg}
                  status={showAlert.status}
                  alertType={showAlert.status ? 'alert-success' : 'alert-error'}
                />
              )}
              <div className="content-menu content-scroll ">
                <div className="container-md animate fadeIn one p-4 min-h-82">
                  <div className="content">
                    <div className="d-flex justify-content-between item-center mb-4">
                      <div className="col-6">
                        <h4 className="m-0 text-20 text-semibold">Metadata </h4>
                      </div>
                      <div className="col-6 text-right">
                        <button
                          className="btn-outline btn-blue btn-filter"
                          onClick={() =>
                            setShowDropdownFilter(!showDropdownFilter)
                          }
                        >
                          <FaFilter className="mx-2 fixed-icon" />
                          Filter
                        </button>
                      </div>
                    </div>
                    {showDropdownFilter && (
                      <div
                        className="card w-25 p-3 dropdown-filter innerFilter"
                        ref={ref}
                      >
                        <p className="mb-1">Type</p>
                        <Dropdown className="mb-2 cursor ">
                          <Dropdown.Toggle className="dropdown-full dropdown-white d-flex justify-content-between">
                            {selectFilterType}
                            <MdExpandMore className="mt-1" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="boxSetting">
                            <Dropdown.Item
                              onClick={() => setSelectFilterType('Select All')}
                              className="dropdown-item-setting text-14"
                            >
                              Select All
                            </Dropdown.Item>
                            {filterType?.map((item, i) => {
                              return (
                                <Dropdown.Item
                                  key={i}
                                  onClick={() => setSelectFilterType(item)}
                                  className="dropdown-item-setting text-14"
                                >
                                  {item}
                                </Dropdown.Item>
                              )
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                        <p className="mb-1">Category</p>
                        <Dropdown className="mb-3 cursor ">
                          <Dropdown.Toggle className="dropdown-full dropdown-white d-flex justify-content-between">
                            {selectFilterCategory}
                            <MdExpandMore className="mt-1" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="boxSetting">
                            <Dropdown.Item
                              onClick={() =>
                                setSelectFilterCategory('Select All')
                              }
                              className="dropdown-item-setting text-14"
                            >
                              Select All
                            </Dropdown.Item>
                            {filterCategory?.map((item, i) => {
                              return (
                                <Dropdown.Item
                                  key={i}
                                  onClick={() => setSelectFilterCategory(item)}
                                  className="dropdown-item-setting text-14"
                                >
                                  {item}
                                </Dropdown.Item>
                              )
                            })}
                          </Dropdown.Menu>
                        </Dropdown>

                        <div className="d-flex justify-content-end">
                          <span
                            className="p-2 -cursor clear-btn"
                            onClick={() => clearFilter()}
                          >
                            Clear All
                          </span>
                          <button
                            className="btn badge-blue px-3"
                            onClick={() => startFilter()}
                          >
                            <b>Apply</b>
                          </button>
                        </div>
                      </div>
                    )}
                    {showFilter && (
                      <div className="mb-3">
                        <b className="mx-2 text-12">Filter By</b>
                        <span className="badge badge-grey mx-2">
                          Type: {selectFilterType}
                        </span>
                        <span className="badge badge-grey mx-2">
                          Category: {selectFilterCategory}
                        </span>
                      </div>
                    )}
                    {isLoading ? (
                      <LoadingComponent />
                    ) : metadataList.length > 0 ? (
                      <div className="border rounded bg-white mt-2 box-shadow-center">
                        <div className="item-center table-border-radius">
                          <DataTable
                            pagination={true}
                            className="-fadeIn mt-0 none-overflow"
                            center={true}
                            columns={columns}
                            data={metadataList}
                            paginationResetDefaultPage={paginationReset}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="p-2 mt-5">
                        <div className="card-body my-4 box-card">
                          <div className="d-flex justify-content-center item-center text-center">
                            <div>
                              <img
                                src="/assets/img/nodata.png"
                                alt="tag turbo"
                              />
                              <h4 className="text-gray">No data found</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* {} */}
                  </div>
                  <div className="my-3">
                    <FooterComponent />
                  </div>
                </div>
              </div>
            </Fragment>
          )}

          <Modal
            shouldCloseOnOverlayClick={false}
            closeTimeoutMS={200}
            ariaHideApp={false}
            isOpen={showPopupDeleteMeta}
            contentLabel="modal"
            style={customStyles}
            onRequestClose={() => setShowPopupDeleteMeta(false)}
            className={'modal-Publish-box animate-5 fadeIn two'}
          >
            <div className="modal-dialog modal-md ">
              <div className="modal-content">
                <div className="modal-header bg-danger">
                  <div className="p-1 text-white">
                    <p className="py-0 mb-0 text-20"></p>
                  </div>
                </div>
                <div className="d-flex justify-content-end px-2">
                  <div>
                    <MdClose
                      className="cursor"
                      onClick={() => setShowPopupDeleteMeta(false)}
                    />
                  </div>
                </div>
                <div className="modal-body p-4 mt-3 text-center">
                  <img className="mb-2" src="/assets/img/warning.png" />

                  <p className="text-20 text-danger">Delete Metadata</p>
                  <p className="text-14">
                    Are you sure you want to delete {metadataName} from the{' '}
                    <br /> metadata? Please note that this action cannot be
                    undone.
                  </p>
                </div>
                <div className="modal-footer justify-content-center px-5 pb-5">
                  <div
                    className="btn cursor w-50"
                    onClick={() => setShowPopupDeleteMeta(false)}
                  >
                    Cancel
                  </div>
                  <div
                    className="btn btn-danger w-50"
                    onClick={() => deleteMeta(deleteMetaId)}
                  >
                    Delete
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </MetaBox>
  )
}

export default MetaComponent
