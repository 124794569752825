import styled from 'styled-components'

const Home = styled.div`
  .step2 {
    z-index: 999999;
  }
  .progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: var(--bs-progress-bar-color);
    text-align: center;
    white-space: nowrap;
    background-color: #6490fe;
    transition: var(--bs-progress-bar-transition);
  }
  .h-menu {
    padding: 10px 30px !important;
  }
  .content-box {
    height: 100%;
  }
  .content-scroll {
    height: calc(100% - 72px);
    overflow-y: auto;
    // margin-top: 100px;
  }
  .-pointer {
    cursor: pointer;
  }
  .-pointer:hover {
    color: #0047ff;
  }
  .btn {
    margin: 0.125em;
  }
  .boxSetting {
    right: 1%;
    width: 200px;
    text-align: left;
    border: 1px solid #0000000f;
    box-shadow: 6px 7px 20px 0 rgb(0 0 0 / 11%);
  }

  // .dropdown-item-setting:first-child {
  //   cursor: pointer;
  //   padding: 15px;

  // }
  .dropdown-item-setting {
    cursor: pointer;
    padding: 15px;
  }
  .dropdown-item-setting:hover {
    background: #c1c1c129;
  }
  .badge {
    margin-left: 0px;
    min-width: 55px;
    text-align: left;
  }
  .badge-default {
    color: #ffffff;
    background-color: #96b3ff;
  }
  .badge-draft {
    color: #6490fe;
    background-color: #eaf0ff;
  }
  .badge-inactive {
    color: #344054;
    background-color: #f2f4f7;
  }
  .badge-green {
    background-color: #ecfdf3;
    color: #037a48;
  }
  .badge-blue {
    background-color: #0047ff;
    color: #fff;
  }
  .badge-yellow {
    background-color: #fec84b;
    color: #344054;
  }
  .badge-white {
    border: solid 1px #d0d5dd;
    background-color: #fff;
    color: #344054;
  }
  .table {
    svg {
      color: #98a2b3;
      cursor: pointer;
    }
  }
  .alert-box {
    position: absolute;
    width: 30%;
    z-index: 999999;
    padding: 0px;
    top: 45px;
    right: 20px;
  }
  .table > :not(caption) > * > * {
    padding: 1rem 1.7rem;
  }

  #currency {
    padding: 4px;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
  }

  .-flag {
    width: 25px;
  }
  .select-flag {
    font-size: 14px;
    font-weight: 600;
    color: #475467;
  }

  .checkmark {
    display: inline-block;
    width: 20px;
    height: 19px;
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    transform: rotate(45deg);
  }

  .checkmark_stem {
    position: absolute;
    width: 2px;
    height: 15px;
    background-color: #0047ff;
    left: 11px;
    top: 6px;
  }

  .checkmark_kick {
    position: absolute;
    width: 5px;
    height: 2px;
    background-color: #0047ff;
    left: 6px;
    top: 19px;
  }

  .space-between {
    justify-content: space-between;
  }

  .badge-saving {
    border: 1px solid #0047ff;
    border-radius: 4px;
    color: #0047ff;
    font-weight: 600;
    font-size: 12px;
    padding: 4px 8px;
  }
  .badge {
    border-radius: 4px;
    font-weight: 600;
    font-size: 12px;
    padding: 4px 8px;
    text-align: center;
  }

  .badge-default {
    color: #ffffff;
    background-color: #96b3ff;
  }
  .badge-blue {
    color: #fff;
    background-color: #0047ff;
  }
  .badge-purple {
    color: #fff;
    background-color: #002fa8;
  }
  .badge-gray {
    color: #fff;
    background-color: #b9a884;
  }
  .badge-danger {
    color: #fff;
    background-color: #f04438;
  }
  .badge-outline-blue {
    color: #0047ff;
    background-color: #fff;
    border: 1px solid #0047ff;
  }
  .mt-plan-box {
    margin-top: 34px;
  }
  .body-plan-box-gray {
    // border-top: 5px solid rgb(208, 213, 221);
    border-radius: 5px;
  }
  .plan-box {
    // background: #ffffff;
    // border: 1.5px solid #d0d5dd;
    // border-radius: 8px;
    // padding: 30px;
    // border-top: 8px solid #d0d5dd;
    // line-height: 2;
    // width: 24%;
    cursor: pointer;
  }
  .plan-box:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  }
  .plan-box-footer {
    text-align: center;

    color: #0047ff;
    height: 140px;
    position: relative;
    span {
      cursor: pointer;
    }
  }
  .foot-btn {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
  }

  .plan-active {
    border: 1.5px solid #0047ff;
    border-top: 8px solid #004fff;
  }
`
const Payment = styled.div`
  .input-group-text {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef00;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
  }
  .input-group-text.is-invalid {
    border: 1px solid #dc3545;
  }
  .nav {
    margin: 1rem 0;
  }
`
const CreateBox = styled.div`
  .h-menu {
    padding: 10px 30px !important;
  }
  .content-box {
    height: 100%;
  }
  .content-scroll {
    height: calc(100% - 72px);
    overflow-y: auto;
    // margin-top: 100px;
  }
  .-pointer {
    cursor: pointer;
  }
  .-pointer:hover {
    color: #0047ff;
  }
  .btn {
    margin: 0.125em;
  }
  .boxSetting {
    right: 1%;
    width: 200px;
    text-align: left;
    border: 1px solid #0000000f;
    box-shadow: 6px 7px 20px 0 rgb(0 0 0 / 11%);
  }

  // .dropdown-item-setting:first-child {
  //   cursor: pointer;
  //   padding: 15px;

  // }
  .dropdown-item-setting {
    cursor: pointer;
    padding: 15px;
  }
  .dropdown-item-setting:hover {
    background: #c1c1c129;
  }
  .badge {
    margin-left: 0px;
    min-width: 55px;
    text-align: left;
  }
  .badge-default {
    color: #ffffff;
    background-color: #96b3ff;
  }
  .badge-green {
    background-color: #ecfdf3;
    color: #037a48;
  }
  .badge-blue {
    background-color: #0047ff;
    color: #fff;
  }
  .badge-yellow {
    background-color: #fec84b;
    color: #344054;
  }
  .badge-white {
    border: solid 1px #d0d5dd;
    background-color: #fff;
    color: #344054;
  }
  .table {
    svg {
      color: #98a2b3;
      cursor: pointer;
    }
  }
  .alert-box {
    position: absolute;
    width: 30%;
    z-index: 999999;
    padding: 0px;
    top: 45px;
    right: 20px;
  }
  .table > :not(caption) > * > * {
    padding: 1rem 1.7rem;
  }

  #currency {
    padding: 4px;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
  }

  .-flag {
    width: 25px;
  }
  .select-flag {
    font-size: 14px;
    font-weight: 600;
    color: #475467;
  }

  .checkmark {
    display: inline-block;
    width: 20px;
    height: 19px;
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    transform: rotate(45deg);
  }

  .checkmark_stem {
    position: absolute;
    width: 2px;
    height: 15px;
    background-color: #0047ff;
    left: 11px;
    top: 6px;
  }

  .checkmark_kick {
    position: absolute;
    width: 5px;
    height: 2px;
    background-color: #0047ff;
    left: 6px;
    top: 19px;
  }

  .space-between {
    justify-content: space-between;
  }

  .badge-saving {
    border: 1px solid #0047ff;
    border-radius: 4px;
    color: #0047ff;
    font-weight: 600;
    font-size: 12px;
    padding: 4px 8px;
  }
  .badge {
    border-radius: 4px;
    font-weight: 600;
    font-size: 12px;
    padding: 4px 8px;
    text-align: center;
  }

  .badge-default {
    color: #ffffff;
    background-color: #96b3ff;
  }
  .badge-blue {
    color: #fff;
    background-color: #0047ff;
  }
  .badge-purple {
    color: #fff;
    background-color: #002fa8;
  }
  .badge-gray {
    color: #fff;
    background-color: #b9a884;
  }
  .badge-danger {
    color: #fff;
    background-color: #f04438;
  }
  .badge-outline-blue {
    color: #0047ff;
    background-color: #fff;
    border: 1px solid #0047ff;
  }
  .mt-plan-box {
    margin-top: 34px;
  }
  .body-plan-box-gray {
    // border-top: 5px solid rgb(208, 213, 221);
    border-radius: 5px;
  }
  .plan-box {
    // background: #ffffff;
    // border: 1.5px solid #d0d5dd;
    // border-radius: 8px;
    // padding: 30px;
    // border-top: 8px solid #d0d5dd;
    // line-height: 2;
    // width: 24%;
    cursor: pointer;
  }
  .plan-box:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  }
  .plan-box-footer {
    text-align: center;

    color: #0047ff;
    height: 140px;
    position: relative;
    span {
      cursor: pointer;
    }
  }
  .foot-btn {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
  }

  .plan-active {
    border: 1.5px solid #0047ff;
    border-top: 8px solid #004fff;
  }
`
const Checkout = styled.div`
  .h-menu {
    padding: 10px 30px !important;
  }
  .content-box {
    height: 100%;
  }
  .content-scroll {
    height: 95%;
    overflow-y: auto;
    // margin-top: 100px;
  }
  .-pointer {
    cursor: pointer;
  }
  .-pointer:hover {
    color: #0047ff;
  }
  .btn {
    margin: 0.125em;
  }
  .boxSetting {
    right: 1%;
    width: 200px;
    text-align: left;
    border: 1px solid #0000000f;
    box-shadow: 6px 7px 20px 0 rgb(0 0 0 / 11%);
  }

  // .dropdown-item-setting:first-child {
  //   cursor: pointer;
  //   padding: 15px;

  // }
  .dropdown-item-setting {
    cursor: pointer;
    padding: 15px;
  }
  .dropdown-item-setting:hover {
    background: #c1c1c129;
  }
  .badge {
    margin-left: 0px;
    min-width: 55px;
    text-align: left;
  }
  .badge-default {
    color: #ffffff;
    background-color: #96b3ff;
  }
  .badge-green {
    background-color: #ecfdf3;
    color: #037a48;
  }
  .badge-blue {
    background-color: #0047ff;
    color: #fff;
  }
  .badge-yellow {
    background-color: #fec84b;
    color: #344054;
  }
  .badge-white {
    border: solid 1px #d0d5dd;
    background-color: #fff;
    color: #344054;
  }
  .table {
    svg {
      color: #98a2b3;
      cursor: pointer;
    }
  }
  .alert-box {
    position: absolute;
    width: 30%;
    z-index: 999999;
    padding: 0px;
    top: 45px;
    right: 20px;
  }
  .table > :not(caption) > * > * {
    padding: 1rem 1.7rem;
  }

  #currency {
    padding: 4px;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
  }

  .-flag {
    width: 25px;
  }
  .select-flag {
    font-size: 14px;
    font-weight: 600;
    color: #475467;
  }

  .checkmark {
    display: inline-block;
    width: 20px;
    height: 19px;
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    transform: rotate(45deg);
  }

  .checkmark_stem {
    position: absolute;
    width: 2px;
    height: 15px;
    background-color: #0047ff;
    left: 11px;
    top: 6px;
  }

  .checkmark_kick {
    position: absolute;
    width: 5px;
    height: 2px;
    background-color: #0047ff;
    left: 6px;
    top: 19px;
  }

  .space-between {
    justify-content: space-between;
  }

  .badge-saving {
    border: 1px solid #0047ff;
    border-radius: 4px;
    color: #0047ff;
    font-weight: 600;
    font-size: 12px;
    padding: 4px 8px;
  }
  .badge {
    border-radius: 4px;
    font-weight: 600;
    font-size: 12px;
    padding: 4px 8px;
    text-align: center;
  }

  .badge-default {
    color: #ffffff;
    background-color: #96b3ff;
  }
  .badge-blue {
    color: #fff;
    background-color: #0047ff;
  }
  .badge-purple {
    color: #fff;
    background-color: #002fa8;
  }
  .badge-gray {
    color: #fff;
    background-color: #b9a884;
  }
  .badge-danger {
    color: #fff;
    background-color: #f04438;
  }
  .badge-outline-blue {
    color: #0047ff;
    background-color: #fff;
    border: 1px solid #0047ff;
  }
  .mt-plan-box {
    margin-top: 34px;
  }
  .body-plan-box-gray {
    // border-top: 5px solid rgb(208, 213, 221);
    border-radius: 5px;
  }
  .plan-box {
    // background: #ffffff;
    // border: 1.5px solid #d0d5dd;
    // border-radius: 8px;
    // padding: 30px;
    // border-top: 8px solid #d0d5dd;
    // line-height: 2;
    // width: 24%;
    cursor: pointer;
  }
  .plan-box:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  }
  .plan-box-footer {
    text-align: center;

    color: #0047ff;
    height: 140px;
    position: relative;
    span {
      cursor: pointer;
    }
  }
  .foot-btn {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
  }

  .plan-active {
    border: 1.5px solid #0047ff;
    border-top: 8px solid #004fff;
  }
`
const ModalBox = styled.div`
  .w-table {
    max-width: 345px !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
export { Home, Payment, CreateBox, Checkout, ModalBox }
