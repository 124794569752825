import styled from 'styled-components'

const SubscriptionBox = styled.div`
  .flag-box {
    border: 1px solid #d0d5dd;
    padding: 3px 13px;
    border-radius: 5px;
    background: #f5f5f5;
  }
  .-flag {
    width: 25px;
  }
  .select-flag {
    font-size: 14px;
    font-weight: 600;
    color: #475467;
  }
  .foot-btn {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
  }

  .checkmark {
    display: inline-block;
    width: 20px;
    height: 19px;
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    transform: rotate(45deg);
  }

  .checkmark_stem {
    position: absolute;
    width: 2px;
    height: 15px;
    background-color: #0047ff;
    left: 11px;
    top: 6px;
  }

  .checkmark_kick {
    position: absolute;
    width: 5px;
    height: 2px;
    background-color: #0047ff;
    left: 6px;
    top: 19px;
  }
  .badge {
    margin-left: 0px;
    min-width: 55px;
    // text-align: left;
  }
  .badge-default {
    background-color: #f2f4f7;
    color: #344054;
  }
  .badge-green {
    background-color: #ecfdf3;
    color: #037a48;
  }
  .badge-blue {
    background-color: #0047ff;
    color: #fff;
  }
  .badge-yellow {
    background-color: #fec84b;
    color: #344054;
  }
  .badge-white {
    border: solid 1px #d0d5dd;
    background-color: #fff;
    color: #344054;
  }
  .badge-default {
    color: #ffffff;
    background-color: #96b3ff;
  }
  .badge-blue {
    color: #fff;
    background-color: #0047ff;
  }
  .badge-purple {
    color: #fff;
    background-color: #002fa8;
  }
  .badge-gray {
    color: #fff;
    background-color: #b9a884;
  }
  .badge-danger {
    color: #fff;
    background-color: #f04438;
  }
  .badge-saving {
    border: 1px solid #0047ff;
    border-radius: 4px;
    color: #0047ff;
    font-weight: 600;
    font-size: 12px;
    padding: 4px 8px;
  }
  .change-plan {
    position: absolute;
    left: calc(100% - 71%);
  }
  .space-between {
    justify-content: space-between;
  }
  #currency {
    padding: 4px;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
  }
  .body-plan-box-bule {
    border-top: 5px solid #0d6efd;
    border-radius: 5px;
  }
  .body-plan-box-gray {
    // border-top: 5px solid #d0d5dd;
    border-radius: 5px;
  }
  .plan-box {
    cursor: pointer;
  }
  .plan-box:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  }
  .plan-box-footer {
    text-align: center;

    color: #0047ff;
    height: 100px;
    position: relative;
    span {
      cursor: pointer;
    }
  }
  .slider-disabled:before {
    background-color: #ffffff !important;
  }
  .slider-disabled.round {
    background: #d0d5dd !important;
    border: 1px solid #f5f5f5;
  }
  .slider-md:before {
    background-color: #ffffff !important;
  }
  .slider {
    background: #d0d5dd;
  }

  .table > :not(caption) > * > * {
    padding-left: 20px;
  }
  .content-menu {
    width: 100%;
    padding-left: 256px;
  }
  .content-box {
    height: 100%;
  }
  .content-scroll {
    // margin-top: 100px;
    height: 87vh;
    overflow-y: auto;
  }
  .-pointer {
    cursor: pointer;
  }

  .alert-box {
    position: absolute;
    width: 30%;
    z-index: 999999;
    padding: 0px;
    top: 45px;
    right: 20px;
  }

  .text-gray {
    color: #d0d5dd;
  }
  .text-green {
    color: #039855;
  }
  .text-blue {
    color: #6490fe;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;
  }
  .slider:before {
    position: absolute;
    content: '';
    height: 15px;
    width: 15px;
    left: 4px;
    bottom: 4px;
    background-color: #0047ff;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(15px);
    -ms-transform: translateX(15px);
    transform: translateX(15px);
    background-color: #fff;
  }

  .-input-credit {
    width: 384px;
    height: 40px;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    margin: 10px;
    padding: 8px 15px;
  }

  .-align-right {
    text-align: right;
  }
  .-float-right {
    float: right;
  }
  .alert-primary {
    --bs-alert-color: #ffffff;
    --bs-alert-bg: #0047ff;
    --bs-alert-border-color: #0047ff;
  }
`
const Payment = styled.div`
  .border-right-red {
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
  }
  .input-group-text {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef00;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
  }
  .input-group-text.is-invalid {
    border: 1px solid #dc3545;
  }
  .nav {
    margin: 1rem 0;
  }
`
const ModalToggle = styled.div`
  .slider {
    background: transparent;
  }
`
export { SubscriptionBox, Payment, ModalToggle }
