import styled from 'styled-components'

const TagBox = styled.div`
  .content-menu {
    width: 100%;
    padding-left: 256px;
  }
  .content-box {
    height: 100%;
  }
  .content-scroll {
    // margin-top: 100px;
    overflow: auto;
  }
  .-pointer {
    cursor: pointer;
  }
  .btn {
    margin: 0.125em;
  }
  .boxSetting {
    right: 1%;
    width: 200px;
    text-align: left;
    border: 1px solid #0000000f;
    box-shadow: 6px 7px 20px 0 rgb(0 0 0 / 11%);
  }
  .dropdown-item-setting {
    cursor: pointer;
    padding: 10px;
  }
  .dropdown-item-setting:hover {
    background: #c1c1c129;
  }
  .badge {
    margin-left: 0px;
    min-width: 55px;
    text-align: center;
    border-radius: 16px;
    font-weight: 600;
    font-size: 12px;
    padding: 2px 8px 2px 6px;
  }
  .badge-default {
    background-color: #f2f4f7;
    color: #344054;
  }
  .badge-green {
    background: #ECFDF3;
    mix-blend-mode: multiply;
    color: #12B76A;
  }
  .badge-blue {
    background-color: #0047ff;
    color: #fff;
  }
  .badge-primary {
    background-color: #eaf0ff;
    color: #0047ff;
  }
  .badge-yellow {
    background-color: #fec84b;
    color: #344054;
  }
  .badge-white {
    border: solid 1px #d0d5dd;
    background-color: #fff;
    color: #344054;
  }
  .table {
    svg {
      color: #98a2b3;
      cursor: pointer;
    }
  }

  .close:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit;
  }
  button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
  }
  .close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
  }
  .alert-box {
    position: absolute;
    width: 30%;
    z-index: 999999;
    padding: 0px;
    top: 45px;
    right: 20px;
  }
  .input-group-text {
    padding: 10px 0.75rem;
    line-height: 1.5;
    background-color: #fff;
    display: flex;
    align-items: center;
    border: 1px solid #ced4da;
    border-right: 0;
    background: #e9ecef;
    border-radius: 0 !important;
  }
  .boxsearch {
    right: 16%;
    width: 32%;
    text-align: left;
    border: 1px solid #0000000f;
    box-shadow: 6px 7px 20px 0 rgb(0 0 0 / 11%);
    z-index: 9;
  }
  .text-gray {
    color: #d0d5dd;
  }
  .card{
    border: none;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1);
    border-radius: 10px;
  }
  .card-body {
    padding: 21px;
    border-radius: 10px;
  }
  .integrate-name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    margin: 0;
  }
  .integrate-name2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    margin: 0;
  }
  .integrate-desc {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }
  .settings-but {
    border: 1px solid #D0D5DD;
    border-radius: 5px;
    padding: 5px 20px;
    background: none;
    font-size: 14px;
  }
  .
`
const DashboardBox = styled.div`
  .-table {
    width: 100%;
  }
  .ellipsis-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 178px;
    display: block;
    padding-top: 4px;
  }
  //   .ellipsis-text:hover {
  //     text-overflow: clip;
  //     white-space: normal;
  //     word-break: break-all;
  // }
  .fixLoading .loadingbox {
    height: 100px !important;
  }
  .form-control {
    font-size: 14px;
  }
  .fixed-icon {
    padding: 4px;
    margin-left: 10px;
    margin-top: -2px;
  }
  .scroll-sm {
    min-height: 200px;
    height: 200px;
    overflow-y: auto;
  }
  .text-no-data {
    position: absolute;
    top: 170px;
    left: 43%;
    color: #d0d5dd;
  }
  .border-left {
    border-left: 1px solid #bfcced;
  }
  .border-bottom-custom {
    border-bottom: 1px solid #bfcced;
  }
  .nav-tabs {
    border-bottom: 1px solid #d0d5dd;
  }
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    color: #0047ff;
    background-color: transparent;
    border-bottom: 2px solid #0047ff;
    font-weight: 600;
    font-size: 14px;
  }
  .nav-link {
    color: #344054;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    padding: 10px 35px;
  }
  .nav-tabs .nav-link {
    border: 0;
  }
  .content-menu {
    width: 100%;
    padding-left: 256px;
  }
  .content-box {
    height: 100%;
  }
  .content-scroll {
    // margin-top: 100px;
    overflow: auto;
    height: 87vh;
  }
  .-pointer {
    cursor: pointer;
  }
  .btn {
    margin: 0.125em;
  }
  .boxSetting {
    right: 1%;
    width: 200px;
    text-align: left;
    border: 1px solid #0000000f;
    box-shadow: 6px 7px 20px 0 rgb(0 0 0 / 11%);
  }
  .dropdown-item-setting {
    cursor: pointer;
    padding: 10px;
  }
  .dropdown-item-setting:hover {
    background: #c1c1c129;
  }
  .badge {
    margin-left: 0px;
    min-width: 55px;
    text-align: center;
    border-radius: 16px;
    font-weight: 600;
    font-size: 12px;
    padding: 2px 8px 2px 6px;
  }
  .badge-default {
    background-color: #f2f4f7;
    color: #344054;
  }
  .badge-green {
    background: #ecfdf3;
    mix-blend-mode: multiply;
    color: #12b76a;
  }
  .badge-blue {
    background-color: #0047ff;
    color: #fff;
  }
  .badge-primary {
    background-color: #eaf0ff;
    color: #0047ff;
  }
  .badge-yellow {
    background-color: #fec84b;
    color: #344054;
  }
  .badge-white {
    border: solid 1px #d0d5dd;
    background-color: #fff;
    color: #344054;
  }
  .table {
    svg {
      color: #98a2b3;
      cursor: pointer;
    }
  }

  .close:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit;
  }
  button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
  }
  .close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
  }
  .alert-box {
    position: absolute;
    width: 30%;
    z-index: 999999;
    padding: 0px;
    top: 45px;
    right: 20px;
  }
  .input-group-text {
    padding: 10px 0.75rem;
    line-height: 1.5;
    background-color: #fff;
    display: flex;
    align-items: center;
    border: 1px solid #ced4da;
    border-right: 0;
    background: #e9ecef;
    border-radius: 1 !important;
    color: #8390a6;
  }
  .boxsearch {
    right: 16%;
    width: 32%;
    text-align: left;
    border: 1px solid #0000000f;
    box-shadow: 6px 7px 20px 0 rgb(0 0 0 / 11%);
    z-index: 9;
  }
  .text-gray {
    color: #d0d5dd;
  }
  .card {
    border: none;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1);
    border-radius: 10px;
  }
  .card-body {
    padding: 21px;
    border-radius: 10px;
    min-height: 141px;
  }
  .integrate-name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    margin: 0;
  }
  .integrate-name2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    margin: 0;
  }
  .integrate-desc {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }
  .settings-but {
    border: 1px solid #d0d5dd;
    border-radius: 5px;
    padding: 5px 20px;
    background: none;
    font-size: 14px;
  }
  .min-h-300 {
    min-height: 300px;
  }

  #totalUserChart {
    height: 300px;
  }
  .w-120 {
    width: 120px;
  }
  .w-256 {
    width: 256px;
  }
  .border-right {
    border-right: 1px solid #bfcced;
  }
  .event-active {
    color: #0047ff;
  }
`
const TableDashboardBox = styled.div`
  .table-purple {
    --bs-table-color: #000;
    --bs-table-bg: #eff3ff !important;
    --bs-table-border-color: #ffffff;
    --bs-table-striped-bg: #f6f9ff !important;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #badce3;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #bfe2e9;
    --bs-table-hover-color: #000;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color);
  }
  .table-primary {
    --bs-table-color: #000;
    --bs-table-bg: #bfcced !important;
    --bs-table-border-color: #bacbe6;
    --bs-table-striped-bg: #c5d7f2;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #bacbe6;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #bfd1ec;
    --bs-table-hover-color: #000;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color);
  }

  thead > tr > th {
    position: sticky;
    top: 0;
    z-index: 0;
  }

  tr:first-child th:first-child {
    border-top-left-radius: 10px;
  }
  tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
  }
  tr:first-child th:last-child {
    border-top-right-radius: 10px;
  }
  tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
  }
  .table > :not(caption) > * > * {
    border-color: transparent !important;
  }
  .table-fixed {
    height: auto;
    min-height: 150px;
    max-height: 400px;
    overflow: auto;
    background: #f6f9ff;
    border-radius: 10px;
  }

  .loading .loadingbox {
    height: 40vh;
  }
`
const TableExpandDashboardBox = styled.div`
  .table-purple {
    --bs-table-color: #000;
    --bs-table-bg: #eff3ff !important;
    --bs-table-border-color: #ffffff;
    --bs-table-striped-bg: #f6f9ff !important;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #badce3;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #bfe2e9;
    --bs-table-hover-color: #000;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color);
  }
  .table-primary {
    --bs-table-color: #000;
    --bs-table-bg: #bfcced !important;
    --bs-table-border-color: #bacbe6;
    --bs-table-striped-bg: #c5d7f2;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #bacbe6;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #bfd1ec;
    --bs-table-hover-color: #000;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color);
  }

  thead > tr > th {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  tr:first-child th:first-child {
    border-top-left-radius: 10px;
  }
  tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
  }
  tr:first-child th:last-child {
    border-top-right-radius: 10px;
  }
  tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
  }
  .table > :not(caption) > * > * {
    border-color: transparent !important;
  }
  .table-fixed {
    height: auto;
    min-height: 150px;
    max-height: 400px;
    overflow: auto;
    background: #f6f9ff;
    border-radius: 10px;
  }

  .loading .loadingbox {
    height: 40vh;
  }
`
const FunnelBox = styled.div`
  .funnel-box {
    display: -webkit-box;
  }
  .step-box {
    width: 280px !important;
  }
  .border-step-box {
    border: 1px solid #dedede;
  }
  .scroll {
    overflow-x: scroll;
    width: 98%;
  }
  .zero-scroll {
    height: 365px;
    display: grid !important;
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: center;
  }
  .border-x {
    border-left: 1px solid #dedede;
    border-right: 1px solid #dedede;
  }
  .loadingbox {
    text-align: center;
    height: 40vh;
    display: grid;
    place-content: center space-evenly;
    place-items: center;
    -webkit-box-align: center;
  }
  .border-y-top {
    border-top: 1px solid #dedede;
  }
  .border-y-bottom {
    border-bottom: 1px solid #dedede;
  }
`

export {
  TagBox,
  DashboardBox,
  TableDashboardBox,
  TableExpandDashboardBox,
  FunnelBox,
}
