import React, { Fragment, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { MenuBox } from './style'
import {
  MdSell,
  MdExtension,
  MdSettings,
  MdWork,
  MdManageHistory,
  MdChevronLeft,
  MdDashboard,
  MdAccountTree,
} from 'react-icons/md'

const MenuComponent = (props) => {
  const path = window.location.pathname
  const { workspaceId, accountId } = useParams()
  const [showPopup, setShowPopup] = useState(true)
  const [statusUsed, setStatusUsed] = useState(0)
  const { planInfo } = props

  useEffect(() => {
    if (props.planInfo.status === 'Suspended') {
      window.location.href = `/package/${accountId}`
    }
  }, [props])

  return (
    <MenuBox>
      <div className={`sidenav ${planInfo.is_trial ? 'sidenav-free' : ''}`}>
        <div
          data-step="1"
          data-title=""
          data-intro='<div class="container">
          <div class="row">
            <div class="col-2">
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="text-18" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z"></path></svg>
            </div>
            <div class="col-10">
              <p class="text-14 text-semibold mb-0">Dashboard</p>
              <p class="text-12">Your one-stop shop for insights! The dashboard gives you a quick overview of key metrics.</p>
            </div>
          </div>
          <div class="row">
            <div class="col-2">
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="text-18" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M21.41 11.41l-8.83-8.83c-.37-.37-.88-.58-1.41-.58H4c-1.1 0-2 .9-2 2v7.17c0 .53.21 1.04.59 1.41l8.83 8.83c.78.78 2.05.78 2.83 0l7.17-7.17c.78-.78.78-2.04-.01-2.83zM6.5 8C5.67 8 5 7.33 5 6.5S5.67 5 6.5 5 8 5.67 8 6.5 7.33 8 6.5 8z"></path></svg>
            </div>
            <div class="col-10">
              <p class="text-14 text-semibold mb-0">Tag</p>
              <p class="text-12">Build custom tracking magic! Tags let you group elements on your website.</p>
            </div>
          </div>
          <div class="row">
            <div class="col-2">
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="text-18" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M20 6h-4V4c0-1.11-.89-2-2-2h-4c-1.11 0-2 .89-2 2v2H4c-1.11 0-1.99.89-1.99 2L2 19c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V8c0-1.11-.89-2-2-2zm-6 0h-4V4h4v2z"></path></svg>
            </div>
            <div class="col-10">
              <p class="text-14 text-semibold mb-0">Versions</p>
              <p class="text-12">Time travel for your tracking! Versions capture snapshots of your tag configuration, so you can easily revert to a previous setup if needed.</p>
            </div>
          </div>
          <div class="row">
            <div class="col-2">
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="text-18" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M22.69 18.37l1.14-1-1-1.73-1.45.49c-.32-.27-.68-.48-1.08-.63L20 14h-2l-.3 1.49c-.4.15-.76.36-1.08.63l-1.45-.49-1 1.73 1.14 1c-.08.5-.08.76 0 1.26l-1.14 1 1 1.73 1.45-.49c.32.27.68.48 1.08.63L18 24h2l.3-1.49c.4-.15.76-.36 1.08-.63l1.45.49 1-1.73-1.14-1c.08-.51.08-.77 0-1.27zM19 21c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zM11 7v5.41l2.36 2.36 1.04-1.79-1.4-1.39V7h-2zm10 5a9 9 0 00-9-9C9.17 3 6.65 4.32 5 6.36V4H3v6h6V8H6.26A7.01 7.01 0 0112 5c3.86 0 7 3.14 7 7h2zm-10.14 6.91c-2.99-.49-5.35-2.9-5.78-5.91H3.06c.5 4.5 4.31 8 8.94 8h.07l-1.21-2.09z"></path></svg>
            </div>
            <div class="col-10">
              <p class="text-14 text-semibold mb-0">Activity</p>
              <p class="text-12">The activity feed shows you everything happening in your Tag Turbo workspace.</p>
            </div>
          </div>
          <div class="row">
            <div class="col-2">
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="text-18" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M20.5 11H19V7c0-1.1-.9-2-2-2h-4V3.5a2.5 2.5 0 00-5 0V5H4c-1.1 0-1.99.9-1.99 2v3.8H3.5c1.49 0 2.7 1.21 2.7 2.7s-1.21 2.7-2.7 2.7H2V20c0 1.1.9 2 2 2h3.8v-1.5c0-1.49 1.21-2.7 2.7-2.7 1.49 0 2.7 1.21 2.7 2.7V22H17c1.1 0 2-.9 2-2v-4h1.5a2.5 2.5 0 000-5z"></path></svg>
            </div>
            <div class="col-10">
              <p class="text-14 text-semibold mb-0">Integrations</p>
              <p class="text-12">Integrate Tag Turbo with your favorite tools to streamline your workflow.</p>
            </div>
          </div>
          <div class="row">
            <div class="col-2">
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="text-18" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M22 11V3h-7v3H9V3H2v8h7V8h2v10h4v3h7v-8h-7v3h-2V8h2v3z"></path></svg>
            </div>
            <div class="col-10">
              <p class="text-14 text-semibold mb-0">Tracking Code</p>
              <p class="text-12">Get started tracking! Grab your unique tracking code here and install it on your website to collect valuable data.</p>
            </div>
          </div>
        </div>'
          data-position="right"
          data-highlight-class="overlayUT"
          data-tooltip-class="introjs-tooltip-mid"
          className="d-flex align-items-start flex-column mb-3"
        >
          <div className="mb-auto p-2 w-100">
            <div className="mt-0">
              <Fragment>
                {' '}
                {workspaceId && (
                  <a
                    onClick={() => (window.location.href = '/home')}
                    className="text-12  text-regular my-2 cursor"
                  >
                    {' '}
                    <MdChevronLeft className="text-14" /> Account & Workspace
                  </a>
                )}
              </Fragment>
              <a
                href={`/dashboard/${accountId}/${workspaceId}`}
                className={`${
                  path.includes('dashboard') ? 'sidenav_active' : ''
                }`}
              >
                {' '}
                <MdDashboard className="text-18" /> Dashboard
              </a>
              <a
                href={`/tag/${accountId}/${workspaceId}`}
                className={`${path.includes('tag') ? 'sidenav_active' : ''}`}
              >
                {' '}
                <MdSell className="text-18" /> Tags
              </a>
              {props.activeAccountRole ? (
                props.activeWorkspaceRole === 'Admin' ||
                props.activeWorkspaceRole === 'Publish' ? (
                  <a
                    href={`/versions/${accountId}/${workspaceId}`}
                    className={`${
                      path.includes('versions') ? 'sidenav_active' : ''
                    }`}
                  >
                    {' '}
                    <MdWork className="text-18" /> Versions
                  </a>
                ) : (
                  <Fragment />
                )
              ) : (
                <Fragment />
              )}

              {props.activeAccountRole ? (
                props.activeWorkspaceRole === 'Admin' ||
                props.activeWorkspaceRole === 'Publish' ||
                props.activeWorkspaceRole === 'Editor' ? (
                  <a
                    href={`/activity/${accountId}/${workspaceId}`}
                    className={`${
                      path.includes('activity') ? 'sidenav_active' : ''
                    }`}
                  >
                    {' '}
                    <MdManageHistory className="text-18" /> Activity
                  </a>
                ) : (
                  <Fragment />
                )
              ) : (
                <Fragment />
              )}

              {props.activeAccountRole ? (
                <a
                  href={`/integration/${accountId}/${workspaceId}`}
                  className={`${
                    path.includes('integration') ? 'sidenav_active' : ''
                  }`}
                >
                  {' '}
                  <MdExtension className="text-18" /> Integrations
                </a>
              ) : (
                <Fragment />
              )}

              {props.activeAccountRole ? (
                props.activeWorkspaceRole === 'Admin' ||
                props.activeWorkspaceRole === 'Publish' ||
                props.activeWorkspaceRole === 'Editor' ? (
                  <a
                    href={`/trackingcodes/${accountId}/${workspaceId}`}
                    className={`${
                      path.includes('trackingcodes') ? 'sidenav_active' : ''
                    }`}
                  >
                    {' '}
                    <MdAccountTree className="text-18" /> Tracking Code
                  </a>
                ) : (
                  <Fragment />
                )
              ) : (
                <Fragment />
              )}
            </div>
          </div>

          <div className="menu-bt w-100">
            {statusUsed >= 80 && (
              <div
                className={`card card-bg-light mb-4 mini-bar ${
                  !showPopup ? 'd-none' : ''
                }`}
              >
                <div className="card-header card-bg-light d-flex justify-content-between item-center border-bottom-0 text-14">
                  <span className="text-semibold">Used space </span>
                </div>
                <div className="card-body card-bg-light text-14">
                  Your account has used{' '}
                  {statusUsed >= 100 ? 'FULL' : statusUsed + '%'} of your
                  quotas. Need more?
                  <div className="progress my-2" style={{ height: '8px' }}>
                    <div
                      className={` "progress-bar " ${
                        statusUsed < 90 ? 'bg-warning' : 'bg-danger'
                      } `}
                      role="progressbar"
                      aria-valuenow={statusUsed}
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: statusUsed + '%' }}
                    ></div>
                  </div>
                  <p>
                    <span
                      onClick={() => setShowPopup(false)}
                      className="cursor"
                    >
                      Dismiss
                    </span>{' '}
                    <span
                      onClick={() => setShowPopup(false)}
                      className={` "cursor " ${
                        statusUsed < 90 ? 'text-warning' : 'text-danger'
                      } `}
                    >
                      Upgrade plan
                    </span>
                  </p>
                </div>
              </div>
            )}

            {props.activeAccountRole ? (
              props.activeWorkspaceRole === 'Admin' ? (
                <a
                  href="#settings"
                  className={`${
                    path.includes('settings') ? 'sidenav_active' : ''
                  }`}
                  onClick={() =>
                    (window.location.href = `/workspacesettings/${accountId}/${workspaceId}`)
                  }
                >
                  {' '}
                  <MdSettings className="text-18" /> Settings
                </a>
              ) : (
                <Fragment />
              )
            ) : (
              <Fragment />
            )}
          </div>
        </div>
      </div>
    </MenuBox>
  )
}

export default MenuComponent
