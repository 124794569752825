import styled from 'styled-components'

const ForgotPasswordBox = styled.div`
  .content-box {
    height: 100%;
    // display: flex;
    // -webkit-box-align: center;
    // align-items: center;
    min-height: 660px;
  }
  .content {
    border-radius: 0px;
    box-shadow: 4px 10px 30px 0 rgb(0 0 0 / 5%);
    background-color: #fff;
    padding: 30px;
  }
  .input {
    width: 3.3rem !important;
    height: 3.5rem;
    margin: 0 0.2rem;
    font-size: 2rem;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
  }
  .passwords-condition-box > p {
    font-size: 12px;
    color: #c7c7c7;
    margin-bottom: 0.5rem;
  }
  .content {
    min-height: 80vh;
    max-height: 100vh;
  }
`

export { ForgotPasswordBox }
