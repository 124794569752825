import React, { Fragment, useState, useEffect } from 'react'
import { BsThreeDotsVertical } from '@react-icons/all-files/bs/BsThreeDotsVertical'
import Modal from 'react-modal'
import { useParams } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import { planDetail } from '../../apis/plan/api'

import { AccountUsersBox, RadioBox, ModalBox, InviteBox } from './style'
import FooterComponent from '../layout/footer'
import TopbarNotiComponent from '../layout/topbarNotification'
import { allPlan } from '../../apis/plan/api'
import { MdClear } from 'react-icons/md'

import TopbarSettingsComponent from '../layout/topbarworkspacesettings'
import MenuSettingComponent from '../layout/menusetting'
import {
  accountList,
  inviteAccount,
  roleAccountByEmail,
  editRoleAccount,
  deleteUserInAccount,
  roleByAccountId,
  roleByWorkspaceId,
  checkLimitAccountUser,
} from '../../apis/account/api'
import {
  workspaceUserList,
  editRoleWorkspace,
  roleWorkspaceByEmail,
} from '../../apis/workspace/api'
import { MdNavigateNext, MdPersonAddAlt } from 'react-icons/md'

import AlertComponent from '../layout/alert'
import DataTable from 'react-data-table-component'
import Dropdown from 'react-bootstrap/Dropdown'
import LoadingComponent from '../layout/loading'
import TopbarFreeNotiComponent from '../layout/topbarFreeNotification'
const AccountUsersComponent = () => {
  const [isClickInvite, setIsClickInvite] = useState(false)
  const [isClickEdit, setIsClickEdit] = useState(false)
  const [openPopupInviteAccount, setOpenPopupInviteAccount] = useState(false)
  const [openPopupEditAccountRole, setOpenPopupEditAccountRole] =
    useState(false)
  const [openPopupEditWorkspaceRole, setOpenPopupEditWorkspaceRole] =
    useState(false)
  const [openPopupConfirmDelete, setOpenPopupConfirmDelete] = useState(false)
  const [isSuspended, setIsSuspended] = useState(false)

  const [activeAccountRole, setActiveAccountRole] = useState('')
  const [activeWorkspaceRole, setActiveWorkspaceRole] = useState('')
  const [accountDetail, setAccountDetail] = useState('')
  const [accountRole, setAccountRole] = useState('AccountAdmin')
  const [workspaceRole, setWorkspaceRole] = useState('Admin')
  const [inviteAccountRole, setInviteAccountRole] =
    useState('SuperAccountAdmin')
  const [roleAccountList, setRoleAccountList] = useState([])
  const [selectUser, setSelectUser] = useState('')

  const { accountId } = useParams()
  const { workspaceId } = useParams()
  const [planInfo, setPlanInfo] = useState('')
  const [accountDetailName, setAccountDetailName] = useState('')
  const [listWorkspaceDetail, setListWorkspaceDetail] = useState('')
  const [workspace_Id, setWorkspace_Id] = useState('')
  const [showAlert, setShowAlert] = useState({
    msg: 'Account Role has been updated successfully.',
    status: true,
    isShow: false,
  })
  const [isLoadingPage, setIsLoadingPage] = useState(true)
  const [openPopupInviteLimit, setOpenPopupInviteLimit] = useState(false)
  const [planList, setPlanList] = useState([])
  const [emailInviteList, setEmailInviteList] = useState([])
  const [checkedEmailList, setCheckedEmailList] = useState([])
  const [openPopupFormatEmail, setOpenPopupFormatEmail] = useState(false)
  const [emailInvalidList, setEmailInvalidList] = useState([])
  const [accountActiveList, setAccountActiveList] = useState([])

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      inset: '50% auto auto 59%',
      position: 'absolute',
      height: '100vh',
      width: '82%',
    },
  }
  const getPlan = async (token) => {
    const { data, status } = await allPlan(token)
    let plan = data.plan_list
    if (status === 200) {
      let arrPlan = plan.map((item) => item)
      setPlanList(arrPlan)
    }
  }
  const rulesAccountRole = [
    {
      role: 'SuperAccountAdmin',
      value: 4,
    },
    {
      role: 'AccountAdmin',
      value: 3,
    },
    {
      role: 'User',
      value: 2,
    },
    {
      role: 'BillingAdmin',
      value: 1,
    },
    {
      role: 'None',
      value: 0,
    },
  ]
  const customStylesSM = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '432px',
    },
  }
  const accessToken = localStorage.getItem('token')

  useEffect(() => {
    document.title = 'Account Users | Tag Turbo'
    setIsLoadingPage(true)
    getPlanInfo()
    getPlan(accessToken)

    if (workspaceId) {
      getRoleByWorkspaceId()
    }
  }, [])

  const getPlanInfo = async () => {
    let suspended = false
    const { data, status } = await planDetail(accessToken, accountId)
    if (status === 200) {
      setIsLoadingPage(false)
      setPlanInfo(data.plan_of_account_detail)
      if (data.plan_of_account_detail.status === 'Suspended') {
        suspended = true
        setIsSuspended(true)
        getRoleByAccountId(suspended)
      }
      getRoleByAccountId(suspended)
    }
  }
  const getRoleByAccountId = async (suspended) => {
    const { data, status } = await roleByAccountId(accessToken, accountId)
    if (status === 200) {
      setActiveAccountRole(data.data.role)

      getRoleAccountList(accessToken, accountId, data.data.role, suspended)
    }
  }
  const getRoleByWorkspaceId = async () => {
    const { data, status } = await roleByWorkspaceId(accessToken, workspaceId)
    if (status === 200) {
      setActiveWorkspaceRole(data.data.role)
    }
  }
  const columns = [
    {
      name: 'Email',
      selector: (row) => row.email,
      sortable: true,
      width: '30%',
    },
    {
      name: 'Account Role',
      selector: (row) => row.accountRole,
      sortable: false,
      width: '30%',
    },
    {
      name: 'Status',
      selector: (row) => row.status,
      sortable: false,
      width: '15%',
    },
    {
      name: 'Workspace Access',
      selector: (row) => row.workspaceAccess,
      sortable: false,
      width: '15%',
    },
    {
      name: '',
      selector: (row) => row.option,
      sortable: false,
    },
  ]
  const getRoleAccountList = async (
    token,
    accountId,
    activeRole = '',
    suspended,
  ) => {
    const { data, status } = await accountList(token, accountId)
    let checkLastSuperAdmin = false
    let res = []
    if (data && data.data && data.data.length > 0 && status === 200) {
      let _tempData = {}
      for (let i = 0; i < data.data.length; i++) {
        if (
          data.data.filter((item) => item.role === 'SuperAccountAdmin')
            .length <= 1
        ) {
          checkLastSuperAdmin = true
        } else {
          checkLastSuperAdmin = false
        }

        _tempData = {
          email: data.data[i].user_email,

          accountRole: <span>{convertToTitleCase(data.data[i].role)}</span>,
          status: (
            <span
              className={`badge badge-${
                data.data[i].status === 'active' ? 'green' : 'yellow'
              }`}
            >
              {data.data[i].status}
            </span>
          ),
          workspaceAccess: (
            <span>
              {data.data[i].workspace_count} Workspace
              {data.data[i].workspace_count > 1 ? 's' : ''}
            </span>
          ),

          option: (
            <Fragment>
              {compareRole(data.data[i].role, activeRole) ? (
                data.data[i].role === rulesAccountRole[0].role &&
                checkLastSuperAdmin ? (
                  ''
                ) : (
                  <Fragment>
                    <Dropdown>
                      <Dropdown.Toggle className="dropdown-dot">
                        <BsThreeDotsVertical />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="position-fixed boxSetting-dot">
                        {!suspended ? (
                          <Dropdown.Item
                            onClick={() => editAccountRole(data.data[i])}
                            className="dropdown-item-setting-dot text-14"
                          >
                            Edit Role
                          </Dropdown.Item>
                        ) : (
                          <Fragment />
                        )}
                        <Dropdown.Item
                          className="dropdown-item-setting-dot text-14"
                          onClick={() => (
                            setOpenPopupConfirmDelete(true),
                            setSelectUser(data.data[i].user_email)
                          )}
                        >
                          Delete User
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Fragment>
                )
              ) : (
                <Fragment />
              )}
            </Fragment>
          ),
        }
        res.push(_tempData)
      }
      setRoleAccountList(res)
      setAccountActiveList(
        data.data.filter((item) => item?.type === 'permissionAccount'),
      )
    }
  }

  const editAccountRole = async (account) => {
    setAccountDetail(account)
    setOpenPopupEditAccountRole(true)
    getRoleAccountByEmail(account.user_email)
  }

  const editWorkspaceRole = async (workspace) => {
    setOpenPopupEditWorkspaceRole(true)
    setWorkspaceRole(workspace.role)
    getRoleWorkspaceByEmail(accountDetail.user_email, workspace.workspace_id)
    setWorkspace_Id(workspace.workspace_id)
  }

  const getRoleWorkspaceByEmail = async (email, workspaceId) => {
    const { data, status } = await roleWorkspaceByEmail(
      accessToken,
      accountId,
      workspaceId,
      email,
    )
    if (status === 200) {
      setWorkspaceRole(data?.data?.role)
    }
  }
  const saveEditRole = async () => {
    let obj = {
      email: accountDetail.user_email,
      role: accountRole,
    }
    const { data, status } = await editRoleAccount(accessToken, accountId, obj)
    if (status === 200) {
      getRoleAccountList(accessToken, accountId)
      handleAlert('Account Role has been updated successfully.', true)
      setOpenPopupEditAccountRole(false)
      getRoleByAccountId()
    } else {
      handleAlert('Failed to update Account Role. Please try again.', false)
    }
  }

  const compareRole = (role, activeRole = '') => {
    let isUseable = false
    let roleFromItem = rulesAccountRole.filter((item) => item.role === role)

    let roleFromActiveAccount = rulesAccountRole.filter(
      (item) => item.role === activeRole || activeAccountRole,
    )
    if (roleFromActiveAccount[0]?.value >= roleFromItem[0]?.value) {
      isUseable = true
    }
    return isUseable
  }

  const saveEditWorkspaceRole = async () => {
    setIsClickEdit(true)
    let obj = {
      email: accountDetail.user_email,
      role: workspaceRole,
    }
    const { data, status } = await editRoleWorkspace(
      accessToken,
      accountId,
      workspace_Id,
      obj,
    )
    if (status === 200) {
      handleAlert('Account Role has been updated successfully.', true)
      setOpenPopupEditAccountRole(false)
      setOpenPopupEditWorkspaceRole(false)
      setIsClickEdit(false)
    } else {
      handleAlert('Failed to update Account Role. Please try again.', false)
      setIsClickEdit(false)
    }
  }

  const getRoleAccountByEmail = async (email) => {
    const { data, status } = await roleAccountByEmail(
      accessToken,
      accountId,
      email,
    )
    if (status === 200) {
      setAccountRole(data.data.permission_account.role)
      setAccountDetailName(data.data.account_detail.name)
      setListWorkspaceDetail(data.data.permission_workspace)
    }
  }

  const inviteUserAccount = async () => {
    setIsClickInvite(true)
    let obj = {
      email_invite_list: checkedEmailList,
      role: inviteAccountRole,
    }

    const { data, status } = await inviteAccount(accessToken, accountId, obj)
    if (status === 200) {
      getRoleAccountList(accessToken, accountId)
      setOpenPopupInviteAccount(false)
      handleAlert('Invitation sent.', true)
      setIsClickInvite(false)
    } else if (status === 400) {
      if (data?.response?.data?.error?.code === 405) {
        setOpenPopupInviteLimit(true)
        setIsClickInvite(false)
      } else {
        if (data.response.data.message === 'some email is invalid') {
          setOpenPopupFormatEmail(true)
        } else {
          handleAlert('Failed to update Account Role. Please try again.', false)
        }
        setIsClickInvite(false)
      }
    } else {
      handleAlert('Failed to update Account Role. Please try again.', false)
      setIsClickInvite(false)
    }
  }

  const convertToTitleCase = (str) => {
    return str
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/^([A-Z])/, ' $1')
      .replace(/\b\w/g, (c) => c.toUpperCase())
  }

  const handleAccountRole = (e) => {
    setAccountRole(e.target.value)
  }
  const handleInviteAccountRole = (e) => {
    setInviteAccountRole(e.target.value)
  }
  const handleWorkspaceRole = (e) => {
    setWorkspaceRole(e.target.value)
  }
  const deleteAccountUser = async () => {
    const myEmail = localStorage.getItem('u_')
    setOpenPopupConfirmDelete(false)

    const res = await deleteUserInAccount(accessToken, accountId, selectUser)
    if (res.status === 200) {
      if (myEmail === selectUser) {
        window.location.href = '/home'
      }
      handleAlert('Account Role has been deleted successfully.', true)
    } else {
      handleAlert('Failed to Delete Account Role. Please try again.', false)
    }
    await getRoleAccountList(accessToken, accountId)
  }

  const handleInviteAccount = () => {
    popupInviteAccount()
    setIsClickInvite(false)
  }

  const popupInviteAccount = async () => {
    const { data, status } = await checkLimitAccountUser(accessToken, accountId)
    setOpenPopupInviteAccount(true)
    if (accountId && status === 200) {
      setOpenPopupInviteAccount(true)
      setEmailInviteList([])
      setInviteAccountRole('')
      setCheckedEmailList([])
    } else if (status === 400) {
      if (data?.response?.data?.error?.code === 405) {
        setOpenPopupInviteLimit(true)
        setOpenPopupInviteAccount(false)
      }
    }
  }

  const handleAlert = (msg, status) => {
    setShowAlert({
      msg,
      status,
      isShow: true,
    })

    setTimeout(() => {
      setShowAlert({
        msg,
        status,
        isShow: false,
      })
    }, 10000)
  }
  const upgradePlan = () => {
    if (accountId && workspaceId) {
      window.location.href = `/package/${accountId}`
    } else {
      console.log('Upgrade Plan is false')
    }
  }
  const enterEmail = (e) => {
    let temp = emailInviteList

    if (e.key === 'Enter') {
      const formatEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/g

      if (e.target.value.match(formatEmail)) {
        temp.push({
          email: e.target.value,
          status: true,
        })
        setEmailInviteList(temp.map((item) => item))
        e.target.value = ''
      } else {
        temp.push({
          email: e.target.value,
          status: false,
        })
        setEmailInviteList(temp.map((item) => item))
        e.target.value = ''
      }
      let email_invalid = emailInviteList.filter(
        (item) => item.status === false,
      )
      setEmailInvalidList(email_invalid)
      setCheckedEmailList(temp.map((item) => item.email))
    }
  }

  const removeEmail = (index) => {
    let list = emailInviteList
    if (index > -1) {
      list.splice(index, 1)
      setCheckedEmailList(list.map((item) => item.email))
      let invite = list.map((item) => item)
      setEmailInviteList(invite)
      let emailInvalid = invite.filter((item) => item.status === false)
      setEmailInvalidList(emailInvalid)
    }
  }
  return (
    <AccountUsersBox>
      <div className="page pull-page-bg-secondary ">
        <div className="content-box">
          <TopbarSettingsComponent />
          {isLoadingPage ? (
            <LoadingComponent />
          ) : (
            <Fragment>
              <TopbarFreeNotiComponent planInfo={planInfo} />
              <TopbarNotiComponent
                planInfo={planInfo}
                activeAccountRole={activeAccountRole}
              />
              <MenuSettingComponent
                accountId={accountId}
                workspaceId={workspaceId}
                activeAccountRole={activeAccountRole}
                activeWorkspaceRole={activeWorkspaceRole}
              />
              {showAlert.isShow && (
                <AlertComponent
                  messageAlert={showAlert.msg}
                  status={showAlert.status}
                  alertType={showAlert.status ? 'alert-success' : 'alert-error'}
                />
              )}

              <div className="content-menu content-scroll ">
                <div className="container-md animate fadeIn one p-4 min-h-82">
                  <div className="content">
                    <div className="d-flex justify-content-between item-center mb-4">
                      <div className="col-6">
                        <h4 className="m-0 text-20 text-semibold">
                          Account Users{' '}
                        </h4>
                      </div>

                      <div className="col-auto">
                        <div className="text-right">
                          <div className="d-flex justify-content-between">
                            {roleAccountList ? (
                              <span className="p-2 text-14">
                                Active account user : {accountActiveList.length}
                                /
                                {
                                  planList.filter(
                                    (plan_item) =>
                                      plan_item?.plan_name === planInfo.plan,
                                  )[0]?.user_per_account
                                }
                              </span>
                            ) : (
                              <Fragment />
                            )}

                            <button
                              type="button"
                              className="btn badge-blue text-14 text-semibold"
                              onClick={() => handleInviteAccount()}
                              disabled={isSuspended}
                            >
                              Invite User
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="border rounded bg-white mt-2 box-shadow-center">
                      <div className="item-center table-border-radius">
                        <Fragment>
                          {roleAccountList ? (
                            roleAccountList.length > 0 ? (
                              <DataTable
                                pagination={true}
                                className="-fadeIn mt-0 none-overflow"
                                center={true}
                                columns={columns}
                                data={roleAccountList}
                                // customStyles={tableStyles}
                              />
                            ) : (
                              <Fragment></Fragment>
                            )
                          ) : (
                            <Fragment></Fragment>
                          )}
                        </Fragment>
                      </div>
                    </div>
                  </div>
                  <div className="my-3">
                    <FooterComponent />
                  </div>
                </div>
              </div>
            </Fragment>
          )}

          {/* Popup Invite user  */}
          <Modal
            shouldCloseOnOverlayClick={false}
            closeTimeoutMS={200}
            ariaHideApp={false}
            isOpen={openPopupInviteAccount}
            contentLabel="modal"
            style={customStyles}
            onRequestClose={() => setOpenPopupInviteAccount(false)}
            className={`modal-box animate  one ${
              openPopupInviteAccount ? 'fadeInRight' : 'fadeOutRight'
            }`}
          >
            <div className="modal-dialog modal-md p-3">
              <div className="modal-content p-1">
                <div className="modal-header">
                  <div>
                    <button
                      type="button"
                      className="btn-close"
                      aria-label="Close"
                      onClick={() => setOpenPopupInviteAccount(false)}
                    ></button>
                    <span className="mx-3 text-20 text-semibold">
                      Invite people to join your account
                    </span>
                  </div>
                </div>
                <div className="modal-body border p-3 mt-3 rounded">
                  <p className="text-16 text-semibold">Enter the email</p>
                  <MdPersonAddAlt className="icon-addon-input text-18" />
                  <div className="inputBubble">
                    {emailInviteList.map((list, index) => {
                      return (
                        <InviteBox className="max-height30">
                          <Fragment>
                            <span
                              key={index}
                              className={`label label-info ${
                                list.status ? 'tag' : 'tag-danger'
                              }`}
                            >
                              <span>{list.email} </span>
                              {''}
                              <MdClear
                                className="-cursor"
                                onClick={() => removeEmail(index)}
                              />
                            </span>
                          </Fragment>
                        </InviteBox>
                      )
                    })}
                    <input
                      className="input-invite w-100"
                      onKeyPress={(e) => enterEmail(e)}
                    />
                  </div>
                  <p className="text-14 my-3">
                    Enter their email addresses in the field above (press enter
                    to add more than one).
                  </p>
                </div>
                <div className="modal-body border p-3 mt-3 rounded">
                  <p className="text-18 text-semibold">Account Role</p>
                  <RadioBox>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="WithoutinlineRadio1"
                        id="WithoutinlineRadio1"
                        value="SuperAccountAdmin"
                        onClick={handleInviteAccountRole}
                        checked={inviteAccountRole === 'SuperAccountAdmin'}
                      />
                      <label className="form-check-label mx-3">
                        <p className="text-14 text-semibold">
                          Super Account Admin <br />
                          <span className="text-14 text-regular">
                            Full control over the account and workspace. Can
                            manage account users. Includes permissions of the
                            workspace’s admin role.
                          </span>
                        </p>
                      </label>
                    </div>

                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="WithoutinlineRadio2"
                        id="WithoutinlineRadio2"
                        value="AccountAdmin"
                        onClick={handleInviteAccountRole}
                        checked={inviteAccountRole === 'AccountAdmin'}
                      />
                      <label className="form-check-label mx-3">
                        <p className="text-14 text-semibold">
                          Account Admin <br />
                          <span className="text-14 text-regular">
                            Can manage the account and account users. Includes
                            permissions of the workspace’s admin role.
                          </span>
                        </p>
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="WithoutinlineRadio3"
                        id="WithoutinlineRadio3"
                        value="User"
                        onClick={handleInviteAccountRole}
                        checked={inviteAccountRole === 'User'}
                      />
                      <label className="form-check-label mx-3">
                        <p className="text-14 text-semibold">
                          User <br />
                          <span className="text-14 text-regular">
                            Can view the account information.
                          </span>
                        </p>
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="WithoutinlineRadio4"
                        id="WithoutinlineRadio4"
                        value="BillingAdmin"
                        onClick={handleInviteAccountRole}
                        checked={inviteAccountRole === 'BillingAdmin'}
                      />
                      <label className="form-check-label mx-3">
                        <p className="text-14 text-semibold">
                          Billing Admin <br />
                          <span className="text-14 text-regular">
                            Can manage billing and subscription.
                          </span>
                        </p>
                      </label>
                    </div>
                  </RadioBox>
                </div>

                <div className="modal-footer justify-content-start">
                  <button
                    type="button"
                    className="btn w-lg btn-primary waves-effect waves-light mt-3"
                    onClick={() => inviteUserAccount()}
                    disabled={isClickInvite}
                  >
                    Invite
                  </button>
                </div>
              </div>
            </div>
          </Modal>

          {/* Popup edit Account Role */}
          <Modal
            shouldCloseOnOverlayClick={false}
            closeTimeoutMS={200}
            ariaHideApp={false}
            isOpen={openPopupEditAccountRole}
            contentLabel="modal"
            style={customStyles}
            onRequestClose={() => setOpenPopupEditAccountRole(false)}
            className={`modal-box animate  one ${
              openPopupEditAccountRole ? 'fadeInRight' : 'fadeOutRight'
            }`}
          >
            <div className="modal-dialog modal-md p-3">
              <div className="modal-content p-1">
                <div className="modal-header">
                  <div>
                    <button
                      type="button"
                      className="btn-close"
                      aria-label="Close"
                      onClick={() => setOpenPopupEditAccountRole(false)}
                    ></button>
                    <span className="mx-3 text-20 text-semibold">
                      {accountDetail && accountDetail.user_email} |{' '}
                      {accountDetailName}
                    </span>
                  </div>
                  <button
                    type="button"
                    className="btn w-lg btn-primary waves-effect waves-light"
                    onClick={() => saveEditRole()}
                    // onClick={() => setOpenPopupEditAccountRole(false)}
                  >
                    Save
                  </button>
                </div>

                <div className="modal-body border p-3 mt-3 rounded">
                  <p className="text-18 text-semibold">Account Role</p>
                  <RadioBox>
                    <div className="form-check my-4">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="SuperAccountAdmin"
                        checked={accountRole === 'SuperAccountAdmin'}
                        onClick={handleAccountRole}
                      />
                      <label className="form-check-label mx-3 text-14 text-semibold">
                        Super Account Admin <br />
                        <span className="text-14 text-regular">
                          Full control over the account and workspace. Can
                          manage account users. Includes permissions of the
                          workspace’s admin role.
                        </span>
                      </label>
                    </div>

                    <div className="form-check my-4">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="AccountAdmin"
                        checked={accountRole === 'AccountAdmin'}
                        onClick={handleAccountRole}
                      />
                      <label className="form-check-label mx-3 text-14 text-semibold">
                        Account Admin <br />
                        <span className="text-14 text-regular">
                          Can manage the account and account users. Includes
                          permissions of the workspace’s admin role.
                        </span>
                      </label>
                    </div>

                    <div className="form-check my-4">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="User"
                        onClick={handleAccountRole}
                        checked={accountRole === 'User'}
                      />
                      <label className="form-check-label mx-3 text-14 text-semibold">
                        User <br />
                        <span className="text-14 text-regular">
                          Can view the account information.
                        </span>
                      </label>
                    </div>
                    <div className="form-check my-4">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="BillingAdmin"
                        onClick={handleAccountRole}
                        checked={accountRole === 'BillingAdmin'}
                      />
                      <label className="form-check-label mx-3 text-14 text-semibold">
                        Billing Admin <br />
                        <span className="text-14 text-regular">
                          Can manage billing and subscription.
                        </span>
                      </label>
                    </div>
                    <div className="form-check my-4">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="None"
                        onClick={handleAccountRole}
                        checked={accountRole === 'None'}
                      />
                      <label className="form-check-label mx-3 text-14 text-semibold">
                        None <br />
                        <span className="text-14 text-regular">
                          Have no role for this account, the user may have a
                          role for workspace.
                        </span>
                      </label>
                    </div>
                  </RadioBox>
                </div>
                {listWorkspaceDetail.length > 0 ? (
                  <div className="modal-body border mt-3 rounded">
                    <table className="table mb-0">
                      <thead>
                        <tr>
                          <td colSpan={3} className="text-18 text-semibold p-3">
                            Workspace Role
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {listWorkspaceDetail.length > 0 ? (
                          listWorkspaceDetail.map((item, i) => {
                            return (
                              <tr
                                key={i}
                                onClick={() => editWorkspaceRole(item)}
                                className="cursor"
                              >
                                <td className="text-14 p-3 ">
                                  <b>{item.name}</b> ( {item.workspace_id} )
                                </td>
                                <td className="text-14 p-3 ">{item.role}</td>
                                <td>
                                  <MdNavigateNext />
                                </td>
                              </tr>
                            )
                          })
                        ) : (
                          <Fragment />
                        )}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <Fragment></Fragment>
                )}
              </div>
            </div>
          </Modal>

          {/* Popup ConfirmDelete*/}
          <Modal
            shouldCloseOnOverlayClick={false}
            closeTimeoutMS={200}
            ariaHideApp={false}
            isOpen={openPopupConfirmDelete}
            contentLabel="modal"
            style={customStylesSM}
            onRequestClose={() => setOpenPopupConfirmDelete(false)}
            className={'modal-Publish-box animate-5 fadeIn two'}
          >
            <div className="modal-dialog modal-md ">
              <div className="modal-content">
                <div className="modal-header bg-danger">
                  <div className="p-1 text-white"></div>
                </div>
                <div className="modal-body p-4 mt-3">
                  <div className="text-center">
                    <img className="mb-2" src="/assets/img/warning.png" />
                  </div>
                  <p className="text-18 text-semibold text-danger text-center">
                    Delete Account User
                  </p>
                  <p className="text-14 text-center">
                    Are you sure you want to delete {selectUser} from the
                    account? Please note that this action cannot be undone.
                  </p>
                </div>
                <div className="modal-footer justify-content-center px-4 pb-3">
                  <div
                    className="text-danger mx-3 cursor"
                    onClick={() => setOpenPopupConfirmDelete(false)}
                  >
                    Cancel
                  </div>
                  <div
                    className="btn btn-danger"
                    onClick={() => deleteAccountUser()}
                  >
                    Delete
                  </div>
                </div>
              </div>
            </div>
          </Modal>

          {/* Popup  edit workspace role  */}
          <Modal
            shouldCloseOnOverlayClick={false}
            closeTimeoutMS={200}
            ariaHideApp={false}
            isOpen={openPopupEditWorkspaceRole}
            contentLabel="modal"
            style={customStyles}
            onRequestClose={() => setOpenPopupEditWorkspaceRole(false)}
            className={`modal-box animate  one ${
              openPopupEditWorkspaceRole ? 'fadeInRight' : 'fadeOutRight'
            }`}
          >
            <div className="modal-dialog modal-md p-3">
              <div className="modal-content p-1">
                <div className="modal-header">
                  <div>
                    <button
                      type="button"
                      className="btn-close"
                      aria-label="Close"
                      onClick={() => setOpenPopupEditWorkspaceRole(false)}
                    ></button>
                    <span className="mx-3 text-20 text-semibold">
                      {accountDetail && accountDetail.user_email} |{' '}
                      {accountDetailName}
                    </span>
                  </div>
                  <button
                    type="button"
                    className="btn w-lg btn-primary waves-effect waves-light"
                    onClick={() => saveEditWorkspaceRole()}
                    disabled={isClickEdit}
                  >
                    Save
                  </button>
                </div>

                <div className="modal-body border p-3 mt-3 rounded">
                  <p className="text-18 text-semibold">Workspace Role</p>
                  <RadioBox>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="workspaceRole1"
                        id="workspaceRole1"
                        value="Admin"
                        onClick={handleWorkspaceRole}
                        checked={workspaceRole === 'Admin'}
                      />
                      <label className="form-check-label mx-3 text-14 text-semibold">
                        <p className="">
                          Admin <br />
                          <span className="text-14 text-regular">
                            Full control over the workspace and can manage
                            workspace users.
                          </span>
                        </p>
                      </label>
                    </div>

                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="workspaceRole2"
                        id="workspaceRole2"
                        value="Publish"
                        onClick={handleWorkspaceRole}
                        checked={workspaceRole === 'Publish'}
                      />
                      <label className="form-check-label mx-3 text-14 text-semibold">
                        <p className="">
                          Publisher <br />
                          <span className="text-14 text-regular">
                            Can view, modify, and publish tags in the workspace.
                          </span>
                        </p>
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="workspaceRole3"
                        id="workspaceRole3"
                        value="Editor"
                        onClick={handleWorkspaceRole}
                        checked={workspaceRole === 'Editor'}
                      />
                      <label className="form-check-label mx-3 text-14 text-semibold">
                        <p className="">
                          Editor <br />
                          <span className="text-14 text-regular">
                            Can view and modify tags in the workspace.
                          </span>
                        </p>
                      </label>
                    </div>

                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="workspaceRole4"
                        id="workspaceRole4"
                        value="Viewer"
                        onClick={handleWorkspaceRole}
                        checked={workspaceRole === 'Viewer'}
                      />
                      <label className="form-check-label mx-3 text-14 text-semibold">
                        <p className="">
                          Viewer <br />
                          <span className="text-14 text-regular">
                            Can view all tags in the workspace.
                          </span>
                        </p>
                      </label>
                    </div>
                  </RadioBox>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
      <Modal
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={200}
        ariaHideApp={false}
        isOpen={openPopupInviteLimit}
        contentLabel="modal"
        style={customStylesSM}
        onRequestClose={() => setOpenPopupInviteLimit(false)}
        className={'modal-Publish-box animate-5 fadeIn two'}
      >
        <ModalBox>
          <div className="modal-dialog modal-md ">
            <div className="modal-content">
              <div className="modal-header bg-danger">
                <div className="p-1 text-white"></div>
              </div>
              <div className="modal-body p-3 mt-3">
                <div className="text-center">
                  <img className="mb-2" src="/assets/img/warning.png" />
                </div>
                <p className="text-18 text-semibold text-danger text-center">
                  Your Account Requires an Upgrade
                </p>

                <p className="text-14 text-center mb-2">
                  You have reached the total number of account users <br />{' '}
                  allowed for your plan. To continue to invite more workspace{' '}
                  <br /> users, your account will need to be upgraded to a new
                  plan.
                </p>
              </div>
              <div className="modal-footer justify-content-center px-4 pb-3">
                {activeAccountRole === 'SuperAccountAdmin' ? (
                  <Fragment>
                    {' '}
                    <div
                      className="text-danger mx-3 cursor text-14"
                      onClick={() => setOpenPopupInviteLimit(false)}
                    >
                      Cancel
                    </div>
                    <div
                      className="btn btn-danger text-14"
                      onClick={() => upgradePlan()}
                    >
                      Upgrade Plan
                    </div>
                  </Fragment>
                ) : (
                  <div
                    className="btn btn-danger text-14"
                    onClick={() => setOpenPopupInviteLimit(false)}
                  >
                    Close
                  </div>
                )}
              </div>
            </div>
          </div>
        </ModalBox>
      </Modal>
      {/* Popup formatemail*/}
      <Modal
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={200}
        ariaHideApp={false}
        isOpen={openPopupFormatEmail}
        contentLabel="modal"
        style={customStylesSM}
        onRequestClose={() => setOpenPopupFormatEmail(false)}
        className={'modal-Publish-box animate-5 fadeIn two'}
      >
        <div className="modal-dialog modal-md ">
          <div className="modal-content">
            <div className="modal-header bg-danger">
              <div className="p-1 text-white"></div>
            </div>
            <div className="modal-body p-4 mt-3">
              <div className="text-center">
                <img className="mb-2" src="/assets/img/warning.png" />
              </div>
              <p className="text-18 text-semibold text-danger text-center">
                Not Found!
              </p>
              <p className="text-14 text-center">
                The address '{' '}
                {emailInvalidList.map((list, index) => {
                  return (
                    <Fragment>
                      <span className="text-grey" key={index}>
                        {list.email}
                        {index === emailInvalidList.length - 1 ? (
                          <Fragment />
                        ) : (
                          ' , '
                        )}
                      </span>
                    </Fragment>
                  )
                })}
                ' in the 'Enter the email' field is not recognized. Please
                ensure that all addresses have the correct format.
              </p>
            </div>
            <div className="modal-footer  justify-content-center px-4 pb-3">
              <div
                className="btn btn-danger"
                onClick={() => setOpenPopupFormatEmail(false)}
              >
                OK
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </AccountUsersBox>
  )
}

export default AccountUsersComponent
