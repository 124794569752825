import styled from 'styled-components'

const Login = styled.div`
  .content-box {
    height: 100%;
    min-height: 660px;
    overflow-y: hidden;
  }
  .content {
    border-radius: 0px;
    box-shadow: 4px 10px 30px 0 rgb(0 0 0 / 5%);
    background-color: #fff;
    padding: 30px;
  }
  .content {
    min-height: 80vh;
    max-height: 100vh;
  }
`

export { Login }
