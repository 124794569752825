import React from 'react'
import { MenuBox } from './style'

import { MdChevronLeft, MdPerson, MdVpnKey } from 'react-icons/md'

const MenuProfileComponent = () => {
  const path = window.location.pathname

  return (
    <MenuBox>
      <div className="sidenav">
        <div className="mt-0">
          <a
            onClick={() => (window.location.href = '/home')}
            className="text-12  text-regular my-2 cursor"
          >
            {' '}
            <MdChevronLeft className="text-14" /> Account & Workspace
          </a>
          <p className="py-3 m-0 px-4 text-semibold">Profile</p>
          <a
            href={`/profilesettings`}
            className={`${
              path.includes('profilesettings') ? 'sidenav_active' : ''
            }`}
          >
            {' '}
            <MdPerson className="mx-1 text-18" /> Profile
          </a>
          <a
            href={`/changepassword`}
            className={`${
              path.includes('changepassword') ? 'sidenav_active' : ''
            }`}
          >
            {' '}
            <MdVpnKey className="mx-1 text-18" /> Password & Security
          </a>
        </div>
      </div>
    </MenuBox>
  )
}

export default MenuProfileComponent
