import axios from 'axios'
import { redirectLogin, redirectPermission } from '../../helper/helper'
const { REACT_APP_API_TAG } = process.env
// const fs = require('fs');

const billingInfo = async (token, account_id) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      account_id,
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/payment/billing-info`
    const response = await axios.get(URL, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else if (error.response?.status === 400) {
      res = {
        status: 400,
        data: error.response.data.message,
      }
    } else {
      res = {
        status: 500,
        data: error,
      }
    }
  }
  return res
}
const updateBillingInfo = async (token, account_id, obj) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      account_id,
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/payment/billing-info`
    const response = await axios.patch(URL, obj, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: 500,
        data: error,
      }
    }
  }
  return res
}
const paymentHistory = async (token, account_id) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      account_id,
      'Access-Control-Allow-Origin': true,
      project: 'GTP',
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/payment/payment-history`
    const response = await axios.get(URL, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: 500,
        data: error,
      }
    }
  }
  return res
}
const downloadingPaymentHistory = (token, account_id, transaction_id) => {
  let res = {}

  const customConfigHeader = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
      'Cache-Control': 'no-cache',
      Authorization: token,
      account_id,
      'Access-Control-Allow-Origin': true,
      project: 'GTP',
    },
    credentials: 'same-origin',
    responseType: 'arraybuffer',
  }
  return new Promise(function (resolve, reject) {
    let URL = `${REACT_APP_API_TAG}/payment/payment-history/download?payment_transaction_id=${transaction_id}`
    axios
      .get(URL, customConfigHeader)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `receipt_${transaction_id}.pdf`)
        document.body.appendChild(link)
        link.click()
        res = {
          status: 200,
          data: 'success',
        }
        resolve(res)
      })
      .catch((error) => {
        res = {
          status: 500,
          data: 'error',
        }
        resolve(res)
      })
  })
}

export {
  billingInfo,
  updateBillingInfo,
  paymentHistory,
  downloadingPaymentHistory,
}
