import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import FooterComponent from '../layout/footer'
import { ErrorBox } from './style'
import { MdHome } from 'react-icons/md'

const PermissionComponent = () => {
  useEffect(() => {
    ;(function () {
      function onTidioChatApiReady() {
        window.tidioChatApi.hide()
        window.tidioChatApi.on('close', function () {
          window.tidioChatApi.hide()
        })
      }

      if (window.tidioChatApi) {
        window.tidioChatApi.on('ready', onTidioChatApiReady)
      } else {
        document.addEventListener('tidioChat-ready', onTidioChatApiReady)
      }
    })()
  }, [])

  return (
    <ErrorBox>
      <div className="page pull-page-bg-secondary">
        <div className="content-box">
          <div className="container-md ">
            <div className="row">
              <h5 className="text-primary mb-3">403 Error</h5>
              <h1 className="mb-5">
                <b>You’re not permitted to see this.</b>
              </h1>
              <h5 className="text-secondary line-height-2">
                The page you’re trying to access has restricted access. <br />
                If you feel this is a mistake. contact your admin.
              </h5>
            </div>
            <div className="row mt-2">
              <div className="col-lg-2 mt-3">
                <Link to="/home">
                  <a className="btn btn-primary w-100 text-16">
                    {' '}
                    <MdHome className="mr-2 text-18" /> Go to Home{' '}
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <FooterComponent />
      </div>
    </ErrorBox>
  )
}

export default PermissionComponent
