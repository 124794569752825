import axios from 'axios'
import { redirectLogin, redirectPermission } from '../../helper/helper'
const { REACT_APP_API_TAG } = process.env

const versionList = async (token, workspace_id) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      workspace_id,
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/workspace/version-list`
    const response = await axios.get(URL, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: 500,
        data: error,
      }
    }
  }
  return res
}

const reVision = async (token, workspace_id, obj) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      workspace_id,
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/workspace/revision`
    const response = await axios.patch(URL, obj, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: 500,
        data: error,
      }
    }
  }
  return res
}
const workspaceUserList = async (token, account_id, workspace_id) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      account_id,
      workspace_id,
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/user-management/role-user-workspace-list`
    const response = await axios.get(URL, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: 500,
        data: error,
      }
    }
  }
  return res
}

const editRoleWorkspace = async (token, account_id, workspace_id, obj) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      account_id,
      workspace_id,
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/user-management/role-user-workspace`
    const response = await axios.patch(URL, obj, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: 500,
        data: error,
      }
    }
  }
  return res
}
const inviteWorkspaceUser = async (token, account_id, workspace_id, obj) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      account_id,
      workspace_id,
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/user-management/invite-workspace-user`
    const response = await axios.post(URL, obj, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else if (error.response?.status === 400) {
      res = {
        status: 400,
        data: error,
      }
    } else {
      res = {
        status: 500,
        data: error,
      }
    }
  }
  return res
}
const roleWorkspaceByEmail = async (token, account_id, workspace_id, email) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      account_id,
      workspace_id,
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/user-management/role-user-workspace-by-email/${email}`
    const response = await axios.get(URL, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: 500,
        data: error,
      }
    }
  }
  return res
}

const workspaceListByAccount = async (token, account_id) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      account_id,
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/workspace-list`
    const response = await axios.get(URL, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: 500,
        data: error,
      }
    }
  }
  return res
}

const editWorkspaceSetting = async (token, workspace_id, obj) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      workspace_id,
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/workspace-setting`
    const response = await axios.patch(URL, obj, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: 500,
        data: error,
      }
    }
  }
  return res
}

const workspaceDetail = async (token, workspace_id) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      workspace_id,
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/workspace-detail`
    const response = await axios.get(URL, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: 500,
        data: error,
      }
    }
  }
  return res
}
const deleteWorkspace = async (token, account_id, workspace_id) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      account_id,
      workspace_id,
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/workspace/delete-workspace`
    const response = await axios.post(URL, {}, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: 500,
        data: error,
      }
    }
  }
  return res
}
const deleteUserInWorkspace = async (
  token,
  account_id,
  workspace_id,
  email,
) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      account_id,
      workspace_id,
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/user-management/delete-workspace-user?email=${encodeURIComponent(
      email,
    )}`
    const response = await axios.delete(URL, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: 500,
        data: error,
      }
    }
  }
  return res
}

const publishTagList = async (token, obj, workspace_id) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      workspace_id,
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/workspace/get-public-tags`
    const response = await axios.post(URL, obj, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: 500,
        data: error,
      }
    }
  }
  return res
}
const exportTags = async (token, workspace_id, obj, workspaceName) => {
  let res = {}
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      workspace_id,
      'Access-Control-Allow-Origin': true,
    },
    credentials: 'same-origin',
    responseType: 'arraybuffer',
  }

  return new Promise(function (resolve, reject) {
    let URL = `${REACT_APP_API_TAG}/workspace/export-tags`
    axios
      .post(URL, obj, customConfigHeader)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          `TagTurbo_${workspaceName}_${obj.version}.json`,
        )
        document.body.appendChild(link)
        link.click()
        res = {
          status: 200,
          data: 'success',
        }
        resolve(res)
      })
      .catch((error) => {
        res = {
          status: 500,
          data: 'error',
        }
        resolve(res)
      })
  })
}
const uploadFileStatus = async (token, workspace_id, file) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'multipart/form-data',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      // account_id,
      workspace_id,
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/workspace/import-tags`
    const response = await axios.post(URL, file, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: 500,
        data: error,
      }
    }
  }
  return res
}

const importTagById = async (token, workspace_id, obj) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      // account_id,
      workspace_id,
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/workspace/submit-import-tags`
    const response = await axios.post(URL, obj, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: 500,
        data: error,
      }
    }
  }
  return res
}

export {
  versionList,
  reVision,
  workspaceUserList,
  editRoleWorkspace,
  inviteWorkspaceUser,
  roleWorkspaceByEmail,
  workspaceListByAccount,
  editWorkspaceSetting,
  workspaceDetail,
  deleteWorkspace,
  deleteUserInWorkspace,
  publishTagList,
  exportTags,
  uploadFileStatus,
  importTagById,
}
