import React, { useState, useEffect } from 'react'
import { VerifyBox } from './style'
import FooterComponent from '../layout/footer'

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import ReCaptchaComponent from '../login/recaptcha'
import { MdCheckCircle } from 'react-icons/md'
import { redirectLogin } from '../../helper/helper'

const { REACT_APP_CAPTCHA } = process.env

const VerifiedAccountComponent = () => {
  const [loading, setLoading] = useState(false)
  const [verifyToken, setVerifyToken] = useState('')
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(0)

  useEffect(() => {
    document.title = 'Verify | Tag Turbo'
    document
      .getElementById('viewport')
      .setAttribute('content', 'initial-scale=0.9, minimum-scale=0.9,width=450')
    ;(function () {
      function onTidioChatApiReady() {
        window.tidioChatApi.hide()
        window.tidioChatApi.on('close', function () {
          window.tidioChatApi.hide()
        })
      }

      if (window.tidioChatApi) {
        window.tidioChatApi.on('ready', onTidioChatApiReady)
      } else {
        document.addEventListener('tidioChat-ready', onTidioChatApiReady)
      }
    })()
  }, [])

  useEffect(() => {
    setVerifyToken(verifyToken)
  }, [verifyToken])

  const keepTokenFn = (token) => {
    setVerifyToken(token)
  }
  const onSubmit = () => {
    redirectLogin()
  }

  return (
    <VerifyBox>
      <GoogleReCaptchaProvider reCaptchaKey={REACT_APP_CAPTCHA}>
        <div className="page full-page">
          <div className="content-box">
            <div className="container-fluid animate fadeIn one h-100">
              <div className="row justify-content-md-center h-100">
                <div className="col-lg-6 text-center p-0 d-none d-lg-block">
                  <img
                    src="/assets/img/BG_login.png"
                    className="w-100 p-0"
                    alt="Tag Turbo"
                  />
                </div>

                <div className="col-lg-6 text-center p-0 content h-100 d-flex-center">
                  <div id="verify_acc" className="min-h-100 max-w-718">
                    <div className="p-5 min-h-95">
                      <h2 className="mt-4">
                        <img
                          src="/assets/img/logo-Tagtb.svg"
                          className="img-180"
                        />
                      </h2>
                      <div className="text-left mt-4">
                        <p className="mb-1">
                          <b>
                            Account Verified!{' '}
                            <MdCheckCircle className="text-success" />
                          </b>
                        </p>
                        <p className="text-14">
                          Voila! You have successfully verified the account .
                        </p>
                      </div>
                      <div className="text-center">
                        <button
                          className="btn btn-primary w-50 text-14 text-semibold mt-4"
                          onClick={() => onSubmit()}
                          disabled={loading ? true : false}
                        >
                          Go to Sign in
                        </button>
                      </div>
                    </div>
                    <FooterComponent />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ReCaptchaComponent
          keepTokenFn={keepTokenFn}
          refreshToken={refreshReCaptcha}
        />
      </GoogleReCaptchaProvider>
    </VerifyBox>
  )
}

export default VerifiedAccountComponent
