import React, { useState } from 'react'
import { AlertBox } from './style'
import { MdCheckCircle, MdError, MdInfo, MdSdCardAlert } from 'react-icons/md'

const AlertComponent = (props) => {
  const [alert, setAlert] = useState(true)

  const closeAlert = () => {
    setAlert(false)
  }

  return (
    <AlertBox>
      {alert && (
        <div className="alert-box d-flex justify-content-end">
          <div
            className={`alert alert-dismissible fade show text-semibold w-100 d-flex ${props.alertType} `}
            role="alert"
          >
            {props.alertType === 'alert-success' && (
              <MdCheckCircle className="mx-2  text-semibold text-20" />
            )}
            {props.alertType === 'alert-error' && (
              <MdError className="mx-2  text-semibold text-20" />
            )}
            {props.alertType === 'alert-warning' && (
              <MdSdCardAlert className="mx-2 text-semibold text-20" />
            )}
            {props.alertType === 'alert-info' && (
              <MdInfo className="mx-2  text-semibold text-20" />
            )}

            {props.messageAlert}

            <button type="button" className="close">
              <span onClick={() => closeAlert()}>&times;</span>
            </button>
          </div>
        </div>
      )}
    </AlertBox>
  )
}

export default AlertComponent
