import React, { Fragment, useState, useEffect } from 'react'
import { Payment } from './style'
import { MdCreditCard, MdHome, MdClose } from 'react-icons/md'
import Select from 'react-select'
import { numberWithCommas, numberWithToFixed } from '../../helper/helper'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { createAccount } from '../../apis/home/api'
import {
  checkStatusChargeAccount,
  chargeDetailPayment,
} from '../../apis/plan/api'
import Modal from 'react-modal'
import { enumCode } from '../../helper/enum'
import introJs from 'intro.js'

const { REACT_APP_OMISE_SECRET_KEY } = process.env

const PaymentComponent = (props) => {
  const accessToken = localStorage.getItem('token')

  const specialChars = /[||\\<“>"'‘\;&*%!+{}@#=?$^\[\]]/
  const specialCharsAndnumber = /[!0-9||\\<“>"'‘\;&*%!+{}@#=?$^\[\]]/

  const [cardType, setCardType] = useState('')
  const [isWaiting, setIsWaiting] = useState(false)

  const [receiptNumber, setReceiptNumber] = useState('')
  const [cardID, setCardID] = useState('')
  const [cardName, setCardName] = useState('')
  const [cardDate, setCardDate] = useState('')
  const [cardCVV, setCardCVV] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [taxNumber, setTaxNumber] = useState('')
  const [address, setAddress] = useState('')
  const [city, setCity] = useState('')
  const [country, setCountry] = useState('Thailand')
  const [postalCode, setPostalCode] = useState('')
  const [coupon, setCoupon] = useState('')

  const [withholdingTax, setWithholdingTax] = useState('')
  const [createAccountId, setCreateAccountId] = useState('') // new id from api

  const [inValidateCardID, setValidateCardID] = useState(false)
  const [inValidateCardName, setValidateCardName] = useState(false)
  const [inValidateCardDate, setValidateCardDate] = useState(false)
  const [inValidateCardCVV, setValidateCardCVV] = useState(false)
  const [showInputCoupon, setShowInputCoupon] = useState(false)
  const [isStatusCoupon, setIsStatusCoupon] = useState(false)

  const [inValidateCoupon, setValidateCoupon] = useState(false)
  const [inValidateFirstName, setValidateFirstName] = useState(false)
  const [inValidateLastName, setValidateLastName] = useState(false)
  const [inValidatePhoneNumber, setValidatePhoneNumber] = useState(false)
  const [inValidateAddress, setValidateAddress] = useState(false)
  const [inValidateCity, setValidateCity] = useState(false)
  const [inValidateCountry, setValidateCountry] = useState(false)
  const [inValidatePostalCode, setValidatePostalCode] = useState(false)
  const [checkStatusPayment, setCheckStatusPayment] = useState(false)
  const [openPopupCheckStatusPayment, setOpenPopupCheckStatusPayment] =
    useState(false)
  const [openPopupCouponNotValid, setOpenPopupCouponNotValid] = useState(false)
  const [isClick, setIsClick] = useState(false)
  const [discount, setDiscount] = useState('')
  const [planId, setPlanId] = useState('')
  const [planName, setPlanName] = useState('')
  const [currencyLabel, setCurrencyLabel] = useState('')
  const [preriod, setPreriod] = useState('')
  const [currency, setCurrency] = useState('')
  const [planPrice, setPlanPrice] = useState('')
  const [excludingVat, setExcludingVat] = useState('')
  const [vat, setVat] = useState('')
  const [taxWithholding, setTaxWithholding] = useState('')
  const [amount, setAmount] = useState('')
  const [errorMsg, setErrorMsg] = useState('')

  const [statusToken, setStatusToken] = useState(true)

  const [dafaultCountry, setDafaultCountry] = useState([
    {
      value: 'Thailand',
      label: 'Thailand',
    },
  ])
  const get_user_tour = localStorage.getItem('user_tour')
  const obj_get_user_tour = JSON.parse(get_user_tour)

  const customStylesSM = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '640px',
    },
  }
  const customStylesXS = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '436px',
    },
  }
  useEffect(() => {
    document.title = 'Payment | Tag Turbo'
    if (window) {
      window.Omise.setPublicKey(REACT_APP_OMISE_SECRET_KEY)
    }
    setPlanId(props.plan_id)
    setPlanName(props.plan_name)
    setCurrencyLabel(props.currency_label)
    setPreriod(props.preriod)
    setCurrency(props.currency)
    onChargeDetail()
  }, [])

  const onUtStep = () => {
    if (get_user_tour) {
      if (obj_get_user_tour?.status !== 'Done') {
        let filterPage = obj_get_user_tour?.ut_page?.filter(
          (item) => item.page === document.title,
        )
        if (filterPage.length > 0) {
          if (filterPage[0].step !== '1') {
            //เคยเข้าหน้านี้แต่stepยังไม่ครบ
            renderUserTour()
          }
        } else {
          //ยังไม่เคยเข้าหน้านี้
          renderUserTour()
        }
      }
    } else {
      //เข้าแอพครั้งแรก
      renderUserTour()
    }
  }
  const renderUserTour = () => {
    setTimeout(() => {
      introJs()
        .setOptions({
          steps: [
            {
              element: document.querySelector('.step1'),
              intro:
                "<div><p class='text-semibold text-14 mb-0'>Create first workspace</p><p class='text-12'>Ready to unlock website insights? Click 'Create Workspace' to start tracking right away!</p></div>",
              step: '1',
              position: 'bottom',
            },
          ],
          disableOverlay: false,
          hidePrev: true,
          showStepNumbers: false,
          doneLabel: 'Done',
          exitOnOverlayClick: false,
          showButtons: true,
          showBullets: false,
          hideNext: false,
          highlightClass: 'custom-highlight-class',
        })
        .onbeforechange(function () {
          setTimeout(() => {
            var doneButton = document.querySelector('.introjs-donebutton')
            var skipButton = document.querySelector('.introjs-skipbutton')

            if (doneButton) {
              doneButton.addEventListener('click', function () {
                if (obj_get_user_tour) {
                  let temp = {
                    page: document.title,
                    step: '1',
                  }
                  obj_get_user_tour.ut_page.push(temp)
                  localStorage.setItem(
                    'user_tour',
                    JSON.stringify(obj_get_user_tour),
                  )
                } else {
                  let obj_tour = {
                    ut_page: [
                      {
                        page: document.title,
                        step: '1',
                      },
                    ],
                    status: '',
                  }
                  localStorage.setItem('user_tour', JSON.stringify(obj_tour))
                }
              })
            }
            if (skipButton) {
              skipButton.addEventListener('click', function () {
                let obj_tour = {
                  ut_page: [],
                  status: 'Done',
                }
                localStorage.setItem('user_tour', JSON.stringify(obj_tour))
              })
            }
          }, 500)
        })
        .start()
    }, 1000)
  }
  const onChangeCountry = (e) => {
    setCountry(e.value)
    onCheckCountry(e.value)
  }

  const onChargeDetail = async () => {
    let obj = {
      plan_id: props.plan_id,
      plan_type: props.preriod ? 'year' : 'month',
      currency: props.currency_label,
      country: country || 'Thailand',
    }
    let token = accessToken
    const { data, status } = await chargeDetailPayment(token, obj)
    if (data && status === 200) {
      setPlanPrice(data.data.individual_charge_detail.plan_price)
      setExcludingVat(data.data.individual_charge_detail.excluding_vat)
      setVat(data.data.individual_charge_detail.vat)
      setTaxWithholding(data.data.juristic_charge_detail.withholding_tax)
      setAmount(data.data.individual_charge_detail.amount)
    } else {
    }
  }

  const { checkPayment } = props
  const creditCardType = (cc) => {
    if (cc?.length < 20) {
      let visa = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$')
      let mastercard = new RegExp('^5[1-5][0-9]{14}$')
      let mastercard2 = new RegExp('^2[2-7][0-9]{14}$')

      let jcb = new RegExp('^35[0-9]{14}[0-9]*$')
      let replaceDash = cc.replace('-', '')
      replaceDash = replaceDash.replace('-', '')
      replaceDash = replaceDash.replace('-', '')
      replaceDash = replaceDash.replaceAll(/\s/g, '')
      if (visa.test(replaceDash)) {
        setCardType('VISA')
      } else {
        setCardType()
      }
      if (mastercard.test(replaceDash) || mastercard2.test(replaceDash)) {
        setCardType('MASTERCARD')
      }
      if (jcb.test(replaceDash)) {
        setCardType('JCB')
      }

      setCardID(maskCard(cc))
      return undefined
    }
  }
  const maskCard = (value) => {
    var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
    var matches = v.match(/\d{4,16}/g)
    var match = (matches && matches[0]) || ''
    var parts = []
    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4))
    }
    if (parts.length) {
      return parts.join('-')
    } else {
      return value
    }
  }
  const customInputDate = (text) => {
    let res = text
      .replace(/^(\d\d)(\d)$/g, '$1/$2')
      .replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2')
      .replace(/[^\d\/]/g, '')
    return res
  }
  const onCreateAccount = async (obj) => {
    setIsClick(true)
    setOpenPopupCheckStatusPayment(true)
    setIsWaiting(true)
    const { data, status } = await createAccount(accessToken, obj)

    if (data && status === 200) {
      setCreateAccountId(data.account_detail?.id)
      window.open(`${data.account_detail.authorize_uri_omise}`, '_blank')
      await getStatusChargeAccount(data.account_detail.id)
    } else {
      if (coupon) {
        setCoupon(coupon)
        setShowInputCoupon(true)
        setValidateCoupon(true)
        setIsClick(false)
        setOpenPopupCheckStatusPayment(false)
        setIsStatusCoupon(false)
        // setErrorMsg(data.response.data.message);
        setErrorMsg('The discount code usage limit has been reached.')
      } else {
        setIsClick(false)
        setIsWaiting(false)
        setCheckStatusPayment(false)
        setOpenPopupCheckStatusPayment(false)
      }
    }
  }
  const createTokenOmise = async () => {
    if (!cardID) {
      setValidateCardID(true)
    } else {
      setValidateCardID(false)
    }
    if (!cardName) {
      setValidateCardName(true)
    } else {
      setValidateCardName(false)
    }
    if (!cardDate) {
      setValidateCardDate(true)
    } else {
      setValidateCardDate(false)
    }
    if (!cardCVV) {
      setValidateCardCVV(true)
    } else {
      setValidateCardCVV(false)
    }
    if (!firstName) {
      setValidateFirstName(true)
    } else {
      setValidateFirstName(false)
    }
    if (!lastName) {
      setValidateLastName(true)
    } else {
      setValidateLastName(false)
    }
    if (!address) {
      setValidateAddress(true)
    } else {
      setValidateAddress(false)
    }
    if (!city) {
      setValidateCity(true)
    } else {
      setValidateCity(false)
    }
    if (!phoneNumber) {
      setValidatePhoneNumber(true)
    } else {
      setValidatePhoneNumber(false)
    }
    if (!postalCode) {
      setValidatePostalCode(true)
    } else {
      setValidatePostalCode(false)
    }
    let tokenCard = ''
    if (
      cardID &&
      cardName &&
      cardDate &&
      cardCVV &&
      firstName &&
      lastName &&
      address &&
      city &&
      phoneNumber &&
      postalCode
    ) {
      window.OmiseCard.configure({
        publicKey: REACT_APP_OMISE_SECRET_KEY,
      })
      let exMount = cardDate.split('/')[0]
      let exYear = cardDate.split('/')[1]
      var cardInformation = {
        name: cardName,
        number: cardID,
        expiration_month: exMount,
        expiration_year: exYear,
        security_code: cardCVV,
      }
      window.Omise.createToken(
        'card',
        cardInformation,
        async (statusCode, response) => {
          if (statusCode === 200) {
            tokenCard = response.id

            let obj = {
              account_name: props.accountName,
              billing_info: {
                first_name: firstName,
                last_name: lastName,
                address: address,
                city: city,
                country: country ? country : 'Thailand',
                postal_code: postalCode,
                phone: phoneNumber,
                company: companyName ? companyName : '',
                tax_id: taxNumber ? taxNumber : '',
              },
              token_card: tokenCard,
              invoiceType: 'individual',
              currency: currencyLabel,
              plan_id: planId,
              plan_type: preriod ? 'year' : 'month',
              coupon_code: isStatusCoupon ? coupon : '',
              country: props.country ? props.country : 'Thailand',
            }
            onCreateAccount(obj)
          } else {
            console.log('Omise get token error')
            if (coupon) {
              setCoupon(coupon)
              setShowInputCoupon(true)
              setValidateCoupon(true)
              // setOpenPopupCouponNotValid(true);
            } else {
              setStatusToken(false)
              setIsWaiting(false)
              setCheckStatusPayment(false)
              setOpenPopupCheckStatusPayment(false)
            }
          }
        },
      )
    }

    return tokenCard
  }
  const getStatusChargeAccount = async (accountId) => {
    setOpenPopupCheckStatusPayment(true)
    setIsWaiting(true)

    const { data, status } = await checkStatusChargeAccount(
      accessToken,
      accountId,
    )
    if (status === 200) {
      setReceiptNumber(data?.data?.receipt_number)
      if (data.data.status === 'successful') {
        setCheckStatusPayment(true)
        setOpenPopupCheckStatusPayment(true)
        setIsWaiting(false)
        /////open user tour////
        onUtStep()
      } else if (data.data.status === 'pending') {
        setTimeout(() => {
          getStatusChargeAccount(accountId)
        }, 5000)
      } else if (data.data.status === 'failed') {
        setIsWaiting(false)
        setOpenPopupCheckStatusPayment(true)
        setCheckStatusPayment(false)
        setIsClick(false)
      } else {
        setIsWaiting(false)
        setCheckStatusPayment(false)
        setOpenPopupCheckStatusPayment(true)
      }
    } else {
      setIsWaiting(false)
      setCheckStatusPayment(false)
      setOpenPopupCheckStatusPayment(true)
    }
  }
  const onCheckCountry = async (country) => {
    let obj = {
      plan_id: planId,
      plan_type: preriod ? 'year' : 'month',
      currency: currencyLabel,
      coupon_code: coupon ? coupon : '',
      country: country || 'Thailand',
      payment_event_type: 'manual_renewal_charge_account_assign_plan',
    }
    let token = accessToken
    const { data, status } = await chargeDetailPayment(token, obj)
    if (data && status === 200) {
      setDiscount(
        withholdingTax
          ? data.data.juristic_charge_detail.coupon_discount
          : data.data.individual_charge_detail.coupon_discount,
      )

      setExcludingVat(
        withholdingTax
          ? data.data.juristic_charge_detail.excluding_vat
          : data.data.individual_charge_detail.excluding_vat,
      )
      setVat(
        withholdingTax
          ? data.data.juristic_charge_detail.vat
          : data.data.individual_charge_detail.vat,
      )
      setTaxWithholding(data.data.juristic_charge_detail.withholding_tax)
      setAmount(
        withholdingTax
          ? data.data.juristic_charge_detail.amount
          : data.data.individual_charge_detail.amount,
      )
    } else {
      setValidateCoupon(true)
      setErrorMsg(enumCode['en'][data.response.data.code])
    }
  }

  const onCheckCoupon = async (isCoupon) => {
    if (coupon || isCoupon === 'remove') {
      let obj = {
        plan_id: planId,
        plan_type: preriod ? 'year' : 'month',
        currency: currencyLabel,
        coupon_code: isCoupon === 'remove' ? '' : coupon.toUpperCase(),
        country: country || 'Thailand',
        payment_event_type: 'manual_renewal_charge_account_assign_plan',
      }
      let token = accessToken
      const { data, status } = await chargeDetailPayment(token, obj)
      if (data && status === 200) {
        setCoupon(isCoupon === 'remove' ? '' : coupon)
        setIsStatusCoupon(isCoupon === 'remove' ? false : true)
        setShowInputCoupon(isCoupon === 'remove' ? true : false)
        setValidateCoupon(false)
        setDiscount(
          withholdingTax
            ? data.data.juristic_charge_detail.coupon_discount
            : data.data.individual_charge_detail.coupon_discount,
        )

        setExcludingVat(
          withholdingTax
            ? data.data.juristic_charge_detail.excluding_vat
            : data.data.individual_charge_detail.excluding_vat,
        )
        setVat(
          withholdingTax
            ? data.data.juristic_charge_detail.vat
            : data.data.individual_charge_detail.vat,
        )
        setTaxWithholding(data.data.juristic_charge_detail.withholding_tax)
        setAmount(
          withholdingTax
            ? data.data.juristic_charge_detail.amount
            : data.data.individual_charge_detail.amount,
        )
      } else {
        setValidateCoupon(true)
        setErrorMsg(enumCode['en'][data.response.data.code])
      }
    } else {
      setErrorMsg('Discount Code is required.')
      setValidateCoupon(true)
    }
  }

  const onCloseCouponBox = () => {
    setCoupon('')
    setShowInputCoupon(!showInputCoupon)
    setValidateCoupon(false)
  }

  const numberWithCommasZero = (x) => {
    // x = Number(x);
    // Check if x is a valid number
    if (isNaN(x)) {
      return ''
    }

    // Get the integer part and the decimal part
    const integerPart = Math.floor(x)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    const decimalPart = (x % 1).toFixed(2).slice(2)

    // Check if the decimal part is zero
    const hasDecimalPart = decimalPart
    // Combine the formatted integer and decimal parts
    const formattedNumber = hasDecimalPart
      ? `${integerPart}.${decimalPart}`
      : integerPart
    return formattedNumber
  }

  return (
    <Payment>
      <div className="container-md  animate fadeIn one">
        <div className="card p-2 border box-shadow-center bg-white mt-4">
          <div className="card-body">
            <p className="text-12 text-primary">Payment Details</p>

            <div className="row">
              <div className="col-6">
                <p className="text-20 text-semibold">Add Your Payment</p>
                {statusToken ? (
                  <Fragment />
                ) : (
                  <p className="text-12 text-danger">
                    There was a problem trying to process your payment. Your
                    card was declined. <br />
                    Please verify your payment information and try again.
                  </p>
                )}

                <div id="card_id">
                  <label className="form-label">Card Number *</label>
                  <div className="input-group mb-3">
                    <span
                      className={`input-group-text border-right-0 ${
                        inValidateCardID ? 'is-invalid' : ''
                      }`}
                    >
                      <MdCreditCard />
                    </span>
                    <input
                      className={`form-control form-control-icon border-right-0 border-left-0 ${
                        inValidateCardID ? 'is-invalid' : ''
                      }`}
                      value={cardID}
                      onKeyPress={(event) => {
                        if (
                          !/[0-9]/.test(event.key) ||
                          event?.target?.value?.length > 18
                        ) {
                          event.preventDefault()
                        }
                      }}
                      onChange={(e) => (
                        creditCardType(e.target.value),
                        setValidateCardID(false),
                        setStatusToken(true)
                      )}
                    />
                    <span
                      className={`input-group-text border-left-0 ${
                        inValidateCardID ? 'is-invalid' : ''
                      }`}
                    >
                      {cardType && (
                        <img
                          src={`/assets/img/${cardType}.png`}
                          alt={`${cardType}`}
                          height="20"
                        />
                      )}
                    </span>
                  </div>
                  <div className="col-lg-12 mb-3">
                    {inValidateCardID && (
                      <span className="text-danger">
                        Card Number is required.
                      </span>
                    )}
                  </div>
                </div>
                <div id="card_name">
                  <label className="form-label">Card Holder Name *</label>
                  <input
                    type="text"
                    className={`form-control ${
                      inValidateCardName ? 'is-invalid' : ''
                    }`}
                    value={cardName}
                    onKeyPress={(event) => {
                      if (specialCharsAndnumber.test(event.key)) {
                        event.preventDefault()
                      }
                    }}
                    onChange={(e) => (
                      setCardName(e.target.value),
                      setValidateCardName(false),
                      setStatusToken(true)
                    )}
                  />
                  <div className="col-lg-12 mb-3">
                    {inValidateCardName && (
                      <span className="text-danger">
                        Card Holder Name is required.
                      </span>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div id="card_exp_date">
                      <label className="form-label">Expiration Date *</label>
                      <input
                        type="text"
                        className={`form-control ${
                          inValidateCardDate ? 'is-invalid' : ''
                        }`}
                        value={cardDate}
                        size="5"
                        maxLength="5"
                        onKeyPress={(event) => {
                          if (specialChars.test(event.key)) {
                            event.preventDefault()
                          }
                        }}
                        onChange={(e) => (
                          setCardDate(customInputDate(e.target.value)),
                          setValidateCardDate(false),
                          setStatusToken(true)
                        )}
                      />
                      <div className="col-lg-12 mb-3">
                        {inValidateCardDate && (
                          <span className="text-danger">
                            Card Expiration Date is required.
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-6">
                    <div id="card_cvv">
                      <label className="form-label">CVV *</label>
                      <input
                        type="password"
                        pattern="\d*"
                        className={`form-control ${
                          inValidateCardCVV ? 'is-invalid' : ''
                        }`}
                        value={cardCVV}
                        size="3"
                        maxLength="3"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault()
                          }
                        }}
                        onChange={(e) => (
                          setCardCVV(e.target.value),
                          setValidateCardCVV(false),
                          setStatusToken(true)
                        )}
                      />
                      <div className="col-lg-12 mb-3">
                        {inValidateCardCVV && (
                          <span className="text-danger">
                            Card CVV is required.
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <p className="text-20 text-semibold">Billing Information</p>
                <div className="row">
                  <div className="col-6">
                    <div id="firstName">
                      <label className="form-label">First Name *</label>
                      <input
                        type="text"
                        className={`form-control ${
                          inValidateFirstName ? 'is-invalid' : ''
                        }`}
                        value={firstName}
                        onKeyPress={(event) => {
                          if (specialChars.test(event.key)) {
                            event.preventDefault()
                          }
                        }}
                        onChange={(e) => (
                          setFirstName(
                            !specialChars.test(e.target.value)
                              ? e.target.value
                              : '',
                          ),
                          setValidateFirstName(false)
                        )}
                      />
                      <div className="col-lg-12 mb-3">
                        {inValidateFirstName && (
                          <span className="text-danger">
                            First name is required.
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div id="card_cvv">
                      <label className="form-label">Last Name *</label>
                      <input
                        type="text"
                        className={`form-control ${
                          inValidateLastName ? 'is-invalid' : ''
                        }`}
                        value={lastName}
                        onKeyPress={(event) => {
                          if (specialChars.test(event.key)) {
                            event.preventDefault()
                          }
                        }}
                        onChange={(e) => (
                          setLastName(
                            !specialChars.test(e.target.value)
                              ? e.target.value
                              : '',
                          ),
                          setValidateLastName(false)
                        )}
                      />
                      <div className="col-lg-12 mb-3">
                        {inValidateLastName && (
                          <span className="text-danger">
                            Last Name is required.
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div id="company_name" className="mt-2">
                  <label className="form-label">Company</label>
                  <input
                    type="text"
                    className={`form-control`}
                    value={companyName}
                    onKeyPress={(event) => {
                      if (specialChars.test(event.key)) {
                        event.preventDefault()
                      }
                    }}
                    onChange={(e) =>
                      setCompanyName(
                        !specialChars.test(e.target.value)
                          ? e.target.value
                          : '',
                      )
                    }
                  />
                </div>
                <div id="address" className="mt-3">
                  <label className="form-label">Address *</label>
                  <input
                    type="text"
                    className={`form-control ${
                      inValidateAddress ? 'is-invalid' : ''
                    }`}
                    value={address}
                    onKeyPress={(event) => {
                      if (specialChars.test(event.key)) {
                        event.preventDefault()
                      }
                    }}
                    onChange={(e) => (
                      setAddress(
                        !specialChars.test(e.target.value)
                          ? e.target.value
                          : '',
                      ),
                      setValidateAddress(false)
                    )}
                  />
                  {inValidateAddress && (
                    <span className="text-danger">Address is required.</span>
                  )}
                </div>
                <div className="row mt-2">
                  <div className="col-6">
                    <div id="city">
                      <label className="form-label">City *</label>
                      <input
                        type="text"
                        className={`form-control ${
                          inValidateCity ? 'is-invalid' : ''
                        }`}
                        value={city}
                        onKeyPress={(event) => {
                          if (specialChars.test(event.key)) {
                            event.preventDefault()
                          }
                        }}
                        onChange={(e) => (
                          setCity(
                            !specialChars.test(e.target.value)
                              ? e.target.value
                              : '',
                          ),
                          setValidateCity(false)
                        )}
                      />
                      <div className="col-lg-12 mb-3">
                        {inValidateCity && (
                          <span className="text-danger">City is required.</span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div id="country">
                      <label className="form-label">Country *</label>

                      {dafaultCountry.length > 0 && (
                        <Select
                          options={props.countryList}
                          defaultValue={dafaultCountry[0]}
                          className="mb-3"
                          onChange={onChangeCountry}
                        />
                      )}

                      <div className="col-lg-12 mb-3">
                        {inValidateCountry && (
                          <span className="text-danger">
                            Country is required.
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div id="postal">
                      <label className="form-label">Postal code *</label>
                      <input
                        type="text"
                        className={`form-control ${
                          inValidatePostalCode ? 'is-invalid' : ''
                        }`}
                        value={postalCode}
                        onKeyPress={(event) => {
                          if (specialChars.test(event.key)) {
                            event.preventDefault()
                          }
                        }}
                        onChange={(e) => (
                          setPostalCode(
                            !specialChars.test(e.target.value)
                              ? e.target.value
                              : '',
                          ),
                          setValidatePostalCode(false)
                        )}
                      />
                      <div className="col-lg-12 mb-3">
                        {inValidatePostalCode && (
                          <span className="text-danger">
                            Postal is required.
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div id="phone">
                      <label className="form-label">Phone *</label>
                      <input
                        type="text"
                        className={`form-control ${
                          inValidatePhoneNumber ? 'is-invalid' : ''
                        }`}
                        value={phoneNumber}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault()
                          }
                        }}
                        onChange={(e) => (
                          setPhoneNumber(
                            /[0-9]/.test(e.target.value) ? e.target.value : '',
                          ),
                          setValidatePhoneNumber(false)
                        )}
                      />
                      <div className="col-lg-12 mb-3">
                        {inValidatePhoneNumber && (
                          <span className="text-danger">
                            Phone Number is required.
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div id="tax_id">
                  <label className="form-label">Tax ID </label>
                  <input
                    type="text"
                    className={`form-control `}
                    value={taxNumber}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault()
                      }
                    }}
                    maxLength={country === 'Thailand' ? '13' : '99'}
                    onChange={(e) => setTaxNumber(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-6">
                <p className="text-20 text-semibold">Payment Summary</p>
                <div className="card">
                  <div className="card-header d-flex px-4">
                    <div className="w-50 text-12">Item</div>
                    <div className="w-50 text-right text-12">Price</div>
                  </div>
                  <div className="card-body px-4">
                    <ul className="nav">
                      <li className="nav-item w-50 text-capitalize text-14">
                        <span
                          className={` badge  text-12 text-center mb-1 ${
                            planName
                              ? planName === 'Basic'
                                ? 'badge-default'
                                : planName === 'Pro'
                                  ? 'badge-blue'
                                  : planName === 'Ultimate'
                                    ? 'badge-purple'
                                    : 'badge-gray'
                              : 'badge-danger'
                          }`}
                        >
                          <span className="text-12 text-uppercase">
                            {planName}
                          </span>
                        </span>
                        <br />
                        {planName} - {preriod ? 'Annual' : 'Monthly'}{' '}
                        Subscription
                      </li>
                      <li className="nav-item w-50 text-right text-14">
                        {currency}
                        {numberWithCommasZero(planPrice)}
                      </li>
                    </ul>
                    {isStatusCoupon ? (
                      <ul className="nav">
                        <li className="nav-item w-75 text-left text-14">
                          <span>
                            Discount :{' '}
                            <span className="text-primary">
                              {' '}
                              ( {coupon.toUpperCase()} ){' '}
                              <MdClose
                                className="cursor text-secondary "
                                onClick={() => onCheckCoupon('remove')}
                              />
                            </span>
                          </span>
                        </li>
                        <li className="nav-item w-25 text-right text-14">
                          <span>
                            - {currency}{' '}
                            {numberWithCommasZero(numberWithToFixed(discount))}
                          </span>
                        </li>
                      </ul>
                    ) : (
                      <Fragment>
                        {showInputCoupon ? (
                          <ul className="nav my-0 p-2 bg-coupon-box">
                            <li className="nav-item w-75 text-left my-2 text-14">
                              <span className="text-14 text-semibold text-grey-600">
                                Enter a discount code
                              </span>
                            </li>
                            <li className="nav-item w-25 text-right my-2 text-14">
                              <span>
                                <MdClose
                                  className="cursor text-secondary text-18"
                                  onClick={() => onCloseCouponBox()}
                                />
                              </span>
                            </li>
                            <li className="nav-item w-75 text-right text-primary text-14 ">
                              <div id="coupon">
                                <div className="d-flex justify-content-end pr-2">
                                  <input
                                    type="text"
                                    className={`form-control text-14  ${
                                      inValidateCoupon ? 'is-invalid' : ''
                                    }`}
                                    value={coupon.toUpperCase()}
                                    placeholder="Please Enter"
                                    onChange={(e) => (
                                      setCoupon(
                                        !specialChars.test(e.target.value)
                                          ? e.target.value
                                          : '',
                                      ),
                                      setValidateCoupon(false)
                                    )}
                                    onKeyPress={(event) => {
                                      if (!/[0-9A-Za-z]/.test(event.key)) {
                                        event.preventDefault()
                                      }
                                    }}
                                    maxLength={40}
                                  />
                                  {coupon && (
                                    <div
                                      className="position-absolute"
                                      style={{ padding: 6 }}
                                    >
                                      <MdClose
                                        className="cursor text-secondary "
                                        onClick={() => onCheckCoupon('remove')}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </li>
                            <li className="nav-item  w-25 text-right text-primary text-14">
                              <button
                                className="btn btn-primary w-100 m-0 text-14"
                                onClick={() => onCheckCoupon('add')}
                              >
                                Apply
                              </button>
                            </li>
                            {inValidateCoupon && (
                              <span className="text-danger px-2 text-14">
                                {errorMsg}
                              </span>
                            )}
                          </ul>
                        ) : (
                          <ul className="nav">
                            <li className="nav-item w-100 text-right text-primary text-14">
                              <span
                                onClick={() => setShowInputCoupon(true)}
                                className="cursor"
                              >
                                Have a discount code?
                              </span>
                            </li>
                          </ul>
                        )}
                      </Fragment>
                    )}
                    <hr />
                    <ul className="nav">
                      <li className="nav-item w-50 text-semibold text-14">
                        Subtotal
                      </li>
                      <li className="nav-item w-50 text-right text-14">
                        {currency}
                        {numberWithCommasZero(numberWithToFixed(excludingVat))}
                      </li>
                    </ul>
                    <ul className="nav">
                      <li className="nav-item w-50 text-semibold text-14">
                        Tax
                      </li>
                      <li className="nav-item w-50 text-right text-14">
                        {currency}
                        {numberWithCommasZero(numberWithToFixed(vat))}
                      </li>
                    </ul>
                    <hr />

                    <ul className="nav">
                      <li className="nav-item w-50 text-semibold text-14">
                        Total Amount
                      </li>
                      <li className="nav-item w-50 text-right text-semibold text-14">
                        {currency}
                        {numberWithCommasZero(numberWithToFixed(amount))}
                      </li>
                    </ul>
                  </div>
                </div>
                <p className="text-14 mt-3 mb-4">
                  By clicking Pay, I agree to Tag Turbo’s{' '}
                  <span className="text-decoration-underline mx-1">
                    <a
                      target="_blank"
                      className="text-sky"
                      href="https://tagturbo.com/terms/"
                    >
                      Terms and conditions
                    </a>
                  </span>
                  and{' '}
                  <span className="text-decoration-underline mx-1">
                    <a
                      target="_blank"
                      className="text-sky"
                      href="https://tagturbo.com/privacy-notice/"
                    >
                      Privacy Notice.
                    </a>
                  </span>
                </p>

                <button
                  type="button"
                  onClick={() => createTokenOmise()}
                  className="btn btn-primary w-100"
                  disabled={isClick}
                >
                  Pay {currency}
                  {numberWithCommasZero(amount)}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={200}
        ariaHideApp={false}
        isOpen={openPopupCheckStatusPayment}
        contentLabel="modal"
        style={customStylesSM}
        onRequestClose={() => setOpenPopupCheckStatusPayment(false)}
        className={'modal-Publish-box animate-5 fadeIn two'}
      >
        <div className="step1 modal-dialog modal-md ">
          <div className="modal-content">
            <div
              className={`modal-header ${
                !isWaiting
                  ? checkStatusPayment
                    ? 'bg-primary'
                    : 'bg-danger'
                  : 'bg-primary'
              }`}
            >
              <div className="p-1 text-white"></div>
            </div>

            <div className="modal-body p-4 text-center">
              {!isWaiting ? (
                <Fragment>
                  <div className="row p-2">
                    <div>
                      <img
                        src={`/assets/img/${
                          checkStatusPayment ? 'Success' : 'Fail'
                        }.png`}
                        alt={``}
                        height=""
                        width="263"
                      />
                    </div>

                    <h2 className="my-3">
                      <b
                        className={`${
                          checkStatusPayment ? 'text-primary' : 'text-danger'
                        }`}
                      >
                        {checkStatusPayment
                          ? 'Payment Successful'
                          : 'Oh no! Payment Failed'}{' '}
                      </b>
                    </h2>
                    <p className="text-secondary my-3 text-semibold text-14">
                      Transaction Number: {receiptNumber}
                    </p>
                    {checkStatusPayment ? (
                      <p className="text-14">
                        Thank you for choosing Tag Turbo. A copy of your receipt
                        has been sent to your email.
                        <br /> Create your first workspace to make progress
                        towards achieving your goals.
                      </p>
                    ) : (
                      <p className="text-secondary text-14">
                        We aren’t able to process your payment. Please Try
                        again.
                      </p>
                    )}
                  </div>

                  <div className="row justify-content-center mb-5">
                    {checkStatusPayment ? (
                      <Fragment>
                        {' '}
                        <div className="col-lg-4  text-right">
                          <Link
                            to="/home"
                            onClick={() =>
                              localStorage.setItem(
                                'next_step',
                                JSON.stringify(true),
                              )
                            }
                          >
                            <a className="btn btn-outline-dark  w-100 text-16">
                              <MdHome className="mr-2 text-18" /> Go to Home
                            </a>
                          </Link>
                        </div>
                        <div className="col-lg-4 text-left">
                          <a
                            className="btn btn-primary  w-100 text-16"
                            href={`/createworkspace/${createAccountId}`}
                          >
                            Create Workspace
                          </a>
                        </div>{' '}
                      </Fragment>
                    ) : (
                      <div className="col-lg-4 mt-3">
                        <a
                          className="btn btn-danger w-100 text-16"
                          onClick={() => setOpenPopupCheckStatusPayment(false)}
                        >
                          Go to Check Out
                        </a>
                      </div>
                    )}
                  </div>
                </Fragment>
              ) : (
                <div className="row p-5">
                  <div>
                    <img src={`/assets/img/loading.gif`} alt={``} width="100" />
                    <p className="p-4">
                      <b className="text-30">Payment Processing...</b>
                    </p>
                    <p className="text-secondary text-14">
                      Your payment is being processed. <br /> Please do not
                      close this page.
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={200}
        ariaHideApp={false}
        isOpen={openPopupCouponNotValid}
        contentLabel="modal"
        style={customStylesXS}
        onRequestClose={() => setOpenPopupCouponNotValid(false)}
        className={'modal-Publish-box animate-5 fadeIn two'}
      >
        <div className="modal-dialog modal-md ">
          <div className="modal-content">
            <div className="modal-header bg-danger">
              <div className="p-1 text-white"></div>
            </div>
            <div className="modal-body p-4 mt-3">
              <div className="text-center">
                <img className="mb-2" src="/assets/img/warning.png" />
              </div>
              <p className="text-18 text-semibold text-danger text-center">
                Discount not valid!
              </p>
              <p className="text-14 text-center">
                Sorry, coupon usage limit has been reached or expired.
              </p>
            </div>
            <div className="modal-footer justify-content-center px-4 pb-5">
              <div
                className="btn btn-danger mx-3 cursor"
                onClick={() => setOpenPopupCouponNotValid(false)}
              >
                Back to Check Out
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </Payment>
  )
}

export default PaymentComponent
