import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import LoginComponent from './components/login/login'
import HomeComponent from './components/home/home'
import CreateAccountComponent from './components/home/createaccount'
import 'bootstrap/dist/css/bootstrap.min.css'
import NotfoundComponent from './components/errorPage/notfound'
import CallbackComponent from './components/errorPage/callback'
import PermissionComponent from './components/errorPage/permission'
import ErrorComponent from './components/errorPage/error'
import VerifyComponent from './components/verify/verify'
import ForgotPasswordComponent from './components/forgotpassword/forgotpassword'
import TermServiceComponent from './components/termservice/termservice'
import ResetPasswordComponent from './components/resetpassword/resetpassword'
import VerifyNewPasswordComponent from './components/verify/verifynewpassword'
import CreateWorkspaceComponent from './components/createworkspace/createworkspace'
import TagComponent from './components/tag/tag'
import VersionsComponent from './components/versions/versions'
import AccountSettingComponent from './components/accountsetting/accountsetting'
import IntegrationComponent from './components/integration/integration'
import WorkspaceSettingsComponent from './components/workspacesettings/workspacesettings'
import AccountUsersComponent from './components/accountusers/accountusers'
import WorkspaceUsersComponent from './components/workspaceusers/workspaceusers'
import SignUpComponent from './components/signup/signup'
import VerifySignUpComponent from './components/verify/verifysignup'
import VerifiedAccountComponent from './components/verify/verifiedaccount'
import TrackingCodesComponent from './components/trackingcode/trackingcode'
import ProfileSettingsComponent from './components/profile/profile'
import MetaComponent from './components/metadata/metadata'
import ExportComponent from './components/export/export'
import ImportComponent from './components/import/import'
import ChangePasswordComponent from './components/changepassword/changepassword'
import ActivityComponent from './components/activitylog/activitylog'
import BillingComponent from './components/billing/billing'
import SubscriptionComponent from './components/subscription/subscription'
import ActivityLogComponent from './components/activityaccountlog/activityaccountlog'
import CheckOutComponent from './components/home/checkout'
import AccountSuspendedComponent from './components/accountsuspended/accountsuspended'
import DashboardComponent from './components/dashboard/dashboard'
import { GoogleOAuthProvider } from '@react-oauth/google'

const { REACT_APP_CLIENT_ID } = process.env

function App() {
  return (
    <GoogleOAuthProvider clientId={REACT_APP_CLIENT_ID}>
      <Router>
        <Routes>
          <Route exact path="/" element={<LoginComponent />} />
          <Route exact path="/home" element={<HomeComponent />} />
          <Route exact path="/callback" element={<CallbackComponent />} />
          <Route exact path="/termservice" element={<TermServiceComponent />} />
          <Route exact path="/login" element={<LoginComponent />} />
          <Route exact path="/verify" element={<VerifyComponent />} />
          <Route exact path="/forgot" element={<ForgotPasswordComponent />} />
          <Route exact path="/reset" element={<ResetPasswordComponent />} />
          <Route exact path="/signup" element={<SignUpComponent />} />
          <Route
            exact
            path="/verifysignup"
            element={<VerifySignUpComponent />}
          />
          <Route
            exact
            path="/subscription/:accountId/:workspaceId?"
            element={<SubscriptionComponent />}
          />
          <Route
            exact
            path="/verifiedaccount"
            element={<VerifiedAccountComponent />}
          />
          <Route
            exact
            path="/versions/:accountId/:workspaceId"
            element={<VersionsComponent />}
          />
          <Route
            exact
            path="/dashboard/:accountId/:workspaceId"
            element={<DashboardComponent />}
          />
          <Route
            exact
            path="/tag/:accountId/:workspaceId"
            element={<TagComponent />}
          />
          <Route
            exact
            path="/package/:accountId/:workspaceId?"
            element={<AccountSuspendedComponent />}
          />
          <Route
            exact
            path="/metadata/:accountId/:workspaceId"
            element={<MetaComponent />}
          />
          <Route
            exact
            path="/export/:accountId/:workspaceId"
            element={<ExportComponent />}
          />
          <Route
            exact
            path="/import/:accountId/:workspaceId"
            element={<ImportComponent />}
          />
          <Route
            exact
            path="/activitylog/:accountId/:workspaceId?"
            element={<ActivityLogComponent />}
          />
          <Route
            exact
            path="/profilesettings"
            element={<ProfileSettingsComponent />}
          />
          <Route
            exact
            path="/changepassword"
            element={<ChangePasswordComponent />}
          />

          <Route
            // exact
            path="/accountsetting/:accountId/:workspaceId?"
            element={<AccountSettingComponent />}
          />
          <Route
            exact
            path="/accountusers/:accountId/:workspaceId?"
            element={<AccountUsersComponent />}
          />
          <Route
            exact
            path="/billing/:accountId/:workspaceId?"
            element={<BillingComponent />}
          />
          <Route
            exact
            path="/workspaceusers/:accountId/:workspaceId?"
            element={<WorkspaceUsersComponent />}
          />
          <Route
            exact
            path="/workspacesettings/:accountId/:workspaceId?"
            element={<WorkspaceSettingsComponent />}
          />
          <Route
            exact
            path="/trackingcodes/:accountId/:workspaceId?"
            element={<TrackingCodesComponent />}
          />
          <Route
            exact
            path="/integration/:accountId/:workspaceId"
            element={<IntegrationComponent />}
          />

          <Route
            exact
            path="/verifypassword"
            element={<VerifyNewPasswordComponent />}
          />
          <Route
            exact
            path="/createworkspace/:accountId"
            element={<CreateWorkspaceComponent isCreate={true} />}
          />
          <Route
            exact
            path="/editworkspace/:accountId"
            element={<CreateWorkspaceComponent isCreate={false} />}
          />

          <Route
            exact
            path="/activity/:accountId/:workspaceId?"
            element={<ActivityComponent />}
          />

          <Route exact path="/403" element={<PermissionComponent />} />
          <Route exact path="/404" element={<NotfoundComponent />} />
          <Route exact path="/500" element={<ErrorComponent />} />
          <Route
            exact
            path="/createaccount"
            element={<CreateAccountComponent />}
          />
          <Route
            exact
            path="/checkout/:accountId/:workspaceId?"
            element={<CheckOutComponent />}
          />
          <Route exact path="*" element={<NotfoundComponent />} />
        </Routes>
      </Router>
    </GoogleOAuthProvider>
  )
}

export default App
