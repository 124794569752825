import React from 'react'
import { LoadingSMBox } from './style'

const LoadingSMComponent = () => {
  return (
    <LoadingSMBox>
      <div className="loadingbox">
        <img
          src="/assets/img/Loading-spin.gif"
          className="loading-sm"
          alt="Tag Turbo"
        />
      </div>
    </LoadingSMBox>
  )
}

export default LoadingSMComponent
