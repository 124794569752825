import React, { Fragment, useState, useEffect } from 'react'
import { BsThreeDotsVertical } from '@react-icons/all-files/bs/BsThreeDotsVertical'
import { VersionsBox } from './style'
import FooterComponent from '../layout/footer'
import TopbarComponent from '../layout/topbar'
import MenuComponent from '../layout/menu'
import { useParams } from 'react-router-dom'
import { formatDateShortMonthAndTime } from '../../helper/helper'
import Modal from 'react-modal'
import { versionList, reVision } from '../../apis/workspace/api'
import AlertComponent from '../layout/alert'
import { roleByAccountId, roleByWorkspaceId } from '../../apis/account/api'
import TopbarNotiComponent from '../layout/topbarNotification'
import DataTable from 'react-data-table-component'
import Dropdown from 'react-bootstrap/Dropdown'
import LoadingComponent from '../layout/loading'
import TopbarFreeNotiComponent from '../layout/topbarFreeNotification'
import { planDetail } from '../../apis/plan/api'
const VersionsComponent = () => {
  const [workspace, setWorkspace] = useState({})
  const [versionDetail, setVersionDetail] = useState({})
  const [version, setVersion] = useState()
  const [isReset, setIsReset] = useState(false)
  const [openPopupVersion, setOpenPopupVersion] = useState(false)
  const [isLoadingPage, setIsLoadingPage] = useState(true)
  const [planInfo, setPlanInfo] = useState('')

  const [showAlert, setShowAlert] = useState({
    msg: 'Account Role has been updated successfully.',
    status: true,
    isShow: false,
  })

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '50%',
      padding: 0,
      borderRadius: '10px',
    },
  }

  const { accountId, workspaceId } = useParams()

  const [activeAccountRole, setActiveAccountRole] = useState('')
  const [activeWorkspaceRole, setActiveWorkspaceRole] = useState('')

  const accessToken = localStorage.getItem('token')
  useEffect(() => {
    document.title = 'Versions | Tag Turbo'
    setIsLoadingPage(true)
    getVerionsList()
    getRoleByAccountId()
    getRoleByWorkspaceId()
  }, [])
  const getPlanInfo = async () => {
    const { data, status } = await planDetail(accessToken, accountId)
    if (status === 200) {
      setPlanInfo(data.plan_of_account_detail)
      setIsLoadingPage(false)
    } else {
      setIsLoadingPage(false)
    }
  }
  const getRoleByAccountId = async () => {
    const { data, status } = await roleByAccountId(accessToken, accountId)
    if (status === 200) {
      setActiveAccountRole(data.data.role)
      setIsLoadingPage(false)
      if (data.data.role !== 'None') {
        getPlanInfo()
      }
    } else {
      setIsLoadingPage(false)
    }
  }
  const getRoleByWorkspaceId = async () => {
    const { data, status } = await roleByWorkspaceId(accessToken, workspaceId)
    if (status === 200) {
      setActiveWorkspaceRole(data.data.role)
      setIsLoadingPage(false)
    } else {
      setIsLoadingPage(false)
    }
  }
  const getVerionsList = async () => {
    const { data, status } = await versionList(accessToken, workspaceId)
    let res = []

    if (
      data &&
      data.workspace_detail.version_list &&
      data.workspace_detail.version_list.length > 0 &&
      status === 200
    ) {
      setIsLoadingPage(false)
      setWorkspace(data.workspace_detail)
      let _tempData = {}
      for (let i = 0; i < data.workspace_detail.version_list.length; i++) {
        _tempData = {
          versions: (
            <span>{data.workspace_detail.version_list[i].version}</span>
          ),

          status: (
            <span>
              {data.workspace_detail.version_list[i].active && (
                <span className="badge badge-green">• Live</span>
              )}
            </span>
          ),
          update_at: (
            <span>
              {formatDateShortMonthAndTime(
                data.workspace_detail.version_list[i].update_at,
              )}
            </span>
          ),
          tags: <span>{data.workspace_detail.version_list[i].tags}</span>,
          published: (
            <span>{data.workspace_detail.version_list[i].published_by}</span>
          ),
          option: (
            <Fragment>
              <Dropdown>
                <Dropdown.Toggle className="dropdown-dot">
                  <BsThreeDotsVertical />
                </Dropdown.Toggle>
                <Dropdown.Menu className="position-fixed boxSetting-dot">
                  <Dropdown.Item
                    className="dropdown-item-setting-dot text-14"
                    onClick={() => (
                      setOpenPopupVersion(true),
                      setVersionDetail(data.workspace_detail.version_list[i])
                    )}
                  >
                    Set as Live Version
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Fragment>
          ),
        }
        res.push(_tempData)
      }
      setVersion(res)
    } else {
      setIsLoadingPage(false)
    }
  }

  const reversionById = async (version) => {
    setOpenPopupVersion(false)

    let obj = {
      version: versionDetail.version,
    }

    const { status } = await reVision(accessToken, workspaceId, obj)
    if (status === 200) {
      getVerionsList()
      setIsReset(true)
      handleAlert(`Version ${version} has updated successfully`, true)
    } else {
      handleAlert(`Version ${version} has Failed . Please try again.`, false)
    }
  }

  const filterByValue = (arr, value) => {
    let results = {}

    results = arr.filter((data) => {
      return data.version === parseInt(value)
    })

    return results
  }

  const handleAlert = (msg, status) => {
    setShowAlert({
      msg,
      status,
      isShow: true,
    })

    setTimeout(() => {
      setShowAlert({
        msg,
        status,
        isShow: false,
      })
    }, 10000)
  }
  const columns = [
    {
      name: 'Versions',
      selector: (row) => row.versions,
      sortable: false,
      width: '10%',
    },
    {
      name: 'Status',
      selector: (row) => row.status,
      sortable: false,
      width: '10%',
    },
    {
      name: 'Published',
      selector: (row) => row.update_at,
      sortable: false,
      width: '30%',
    },
    {
      name: 'Tags',
      selector: (row) => row.tags,
      sortable: false,
      width: '10%',
    },
    {
      name: 'Published by',
      selector: (row) => row.published,
      sortable: false,
      width: '30%',
    },
    {
      name: '',
      selector: (row) => row.option,
      sortable: false,
    },
  ]
  return (
    <VersionsBox>
      <div className="page pull-page-bg-secondary ">
        <div className="content-box">
          {isLoadingPage ? (
            <LoadingComponent />
          ) : (
            <Fragment>
              <TopbarComponent
                isPublish={true}
                resetList={isReset}
                callBackFn={getVerionsList}
                activeWorkspaceRole={activeWorkspaceRole}
                activeAccountRole={activeAccountRole}
              />
              <TopbarFreeNotiComponent planInfo={planInfo} />
              <TopbarNotiComponent />
              <MenuComponent
                activeWorkspaceRole={activeWorkspaceRole}
                activeAccountRole={activeAccountRole}
                planInfo={planInfo}
              />
              {showAlert.isShow && (
                <AlertComponent
                  messageAlert={showAlert.msg}
                  status={showAlert.status}
                  alertType={showAlert.status ? 'alert-success' : 'alert-error'}
                />
              )}
              <div className="content-menu content-scroll ">
                <div className="container-md animate fadeIn one p-4 min-h-82">
                  <div className="content">
                    <div className="d-flex justify-content-between item-center mb-4 my-1">
                      <div className="col-6">
                        <h4 className="m-0 text-20 text-semibold">
                          Versions{' '}
                          {Object.keys(workspace).length !== 0 &&
                            workspace?.version_list?.filter((l) => l.active)[0]
                              ?.version + ' is Live'}{' '}
                        </h4>
                      </div>
                      <div className="col-4 text-right"></div>
                    </div>
                    {workspace?.version_list &&
                    workspace?.version_list.length > 0 ? (
                      <div className="border-shadow  rounded bg-white mt-2 box-shadow-center">
                        <div className="d-flex justify-content-between mb-1 p-4 pb-2">
                          <div>
                            <p className="p-0 text-16 text-semibold">
                              <span>Versions</span>
                            </p>
                          </div>
                        </div>
                        <Fragment>
                          {version ? (
                            version.length > 0 ? (
                              <DataTable
                                pagination={true}
                                className="-fadeIn mt-0 none-overflow"
                                center={true}
                                columns={columns}
                                data={version}
                                // customStyles={tableStyles}
                              />
                            ) : (
                              <Fragment></Fragment>
                            )
                          ) : (
                            <Fragment></Fragment>
                          )}
                        </Fragment>
                      </div>
                    ) : (
                      <div className="card p-2 border-1 bg-white mt-2">
                        <div className="card-body my-4">
                          <div className="d-flex justify-content-center item-center text-center">
                            <div>
                              <h6 className="mb-4">
                                <b>No version has been published yet.</b>
                              </h6>{' '}
                              <h6>
                                This page will display information about
                                different versions of your tracking. Currently,
                                there are no versions to display.
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="my-3">
                    <FooterComponent />
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>

      <Modal
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={200}
        ariaHideApp={false}
        isOpen={openPopupVersion}
        contentLabel="modal"
        style={customStyles}
        onRequestClose={() => setOpenPopupVersion(false)}
        className={'modal-Publish-box animate-5 fadeIn two'}
      >
        <div
          className="modal-dialog modal-md"
          style={{ borderTop: '5px solid #0047FF' }}
        >
          <div className="modal-content">
            <div className="modal-header pt-4">
              <div className="p-2">
                <b className="mx-3 text-20">Set as Live Version?</b>
              </div>
            </div>
            <div className="modal-body pt-1 p-4">
              <p style={{ lineHeight: '2' }}>
                Set as the Live Version will change workspace matches Version{' '}
                {versionDetail.version}. <br />
                This will affect all tags you set will be lost.
              </p>
            </div>
            <div className="modal-footer justify-content-end px-4 pb-4">
              <div
                className="mx-5 cursor"
                onClick={() => setOpenPopupVersion(false)}
              >
                Cancel
              </div>
              <div
                className="btn btn-primary"
                style={{ background: '#0047FF' }}
                onClick={() => reversionById(versionDetail.version)}
              >
                Set as Live Version
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </VersionsBox>
  )
}

export default VersionsComponent
