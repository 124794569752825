import styled from 'styled-components'

const ActivityBox = styled.div`
  svg {
    pointer-events: none;
  }
  .box-card {
    height: 406px;
    display: grid;
  }
  .-pointer:hover {
    color: #0047ff;
  }
  .table > :not(caption) > * > * {
    padding: 1rem 1.7rem;
  }
  .table td {
    vertical-align: middle;
  }
  .spacesing {
    width: 320px;
  }
  .form-control {
    box-shadow: none;
  }
  .form-control:focus {
    border-color: #ced4da;
  }
  .content-menu {
    width: 100%;
    padding-left: 256px;
  }
  .content-box {
    height: 100%;
  }
  .content-scroll {
    // margin-top: 100px;
    height: 87vh;
    overflow-y: auto;
  }
  .-pointer {
    cursor: pointer;
  }
  .btn {
    margin: 0.125em;
  }
  .boxSetting {
    right: 2%;
    width: 258px;
    text-align: left;
    border: 1px solid #0000000f;
    box-shadow: 6px 7px 20px 0 rgb(0 0 0 / 11%);
  }

  .dropdown-item-setting:hover {
    background: #c1c1c129;
  }
  .badge {
    margin-left: 0px;
    min-width: 55px;
    text-align: center;
    text-transform: capitalize;
  }
  .badge-default {
    background-color: #f2f4f7;
    color: #344054;
  }
  .badge-green {
    background-color: #ecfdf3;
    color: #037a48;
  }
  .badge-blue {
    background-color: #0047ff;
    color: #fff;
    width: 104px;
    height: 37px;
  }
  .badge-primary {
    background-color: #eaf0ff;
    color: #0047ff;
  }
  .badge-yellow {
    background-color: #fec84b;
    color: #344054;
  }
  .badge-white {
    border: solid 1px #d0d5dd;
    background-color: #fff;
    color: #344054;
  }
  .table {
    svg {
      color: #98a2b3;
      cursor: pointer;
    }
  }

  .close:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit;
  }
  button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
  }
  .close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
  }
  .alert-box {
    position: absolute;
    width: 30%;
    z-index: 999999;
    padding: 0px;
    top: 45px;
    right: 20px;
  }
  .input-group-text {
    padding: 0px 0px 0px 5px;
    line-height: 1.5;
    background-color: #fff;
    display: flex;
    align-items: center;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #ced4da;
    border-right: 0;
  }
  .search_input {
    padding-left: 5px;
  }
  .boxsearch {
    right: 20%;
    width: 32%;
    text-align: left;
    border: 1px solid #0000000f;
    box-shadow: 6px 7px 20px 0 rgb(0 0 0 / 11%);
    z-index: 9;
  }
  .text-gray {
    color: #d0d5dd;
  }
  .ml-20 {
    margin-left: 20px;
  }
  .dropdown-filter {
    top: 64px;
    z-index: 9;
    right: 22px;
    position: absolute;
  }
  .btn-outline {
    border-radius: 5px;
    padding: 5px 20px;
    background: none;
    font-size: 14px;
  }
  .btn-blue {
    border: 1px solid #0047ff;
    color: #0047ff;
  }
  .w-ellipsis {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 10px 15px;
  }
  .search-label {
    border-radius: 16px;
    background: rgb(208, 213, 221);
    padding: 2px 10px;
    margin-left: 13px;
    margin-top: -1px;
    color: rgb(102, 112, 133);
    font-size: 12px;
    font-weight: 600;
  }
  .clear-btn {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
    color: #667085;
  }
`

export { ActivityBox }
