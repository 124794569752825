import React, { Fragment, useState, useEffect, useRef } from 'react'

const CallbackComponent = () => {
  useEffect(() => {
    window.close(``, `_parent`, ``)
  }, [])

  return <Fragment />
}

export default CallbackComponent
