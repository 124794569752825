import React, { Fragment, useState, useEffect, useRef, useForm } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { ForgotPasswordBox } from './style'
import { FaStar, FaEye, FaEyeSlash, FaCheck } from 'react-icons/fa'
import FooterComponent from '../layout/footer'
import { forgotPassword } from '../../apis/login/api'
import { formatEmail } from '../../helper/helper'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import ReCaptchaComponent from '../login/recaptcha'
const { REACT_APP_CAPTCHA } = process.env
var round = 0

const ForgotPasswordComponent = (props) => {
  const [email, setEmail] = useState('')
  const [loginFail, setLoginFail] = useState(false)
  const [verifyToken, setVerifyToken] = useState('')
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(0)
  const [validateEmail, setValidateEmail] = useState({
    msg: 'updated successfully.',
    status: true,
  })
  useEffect(() => {
    document.title = 'Forgot Password | Tag Turbo'
    document
      .getElementById('viewport')
      .setAttribute('content', 'initial-scale=0.9, minimum-scale=0.9,width=450')
    refreshRecaptcha()
    ;(function () {
      function onTidioChatApiReady() {
        window.tidioChatApi.hide()
        window.tidioChatApi.on('close', function () {
          window.tidioChatApi.hide()
        })
      }

      if (window.tidioChatApi) {
        window.tidioChatApi.on('ready', onTidioChatApiReady)
      } else {
        document.addEventListener('tidioChat-ready', onTidioChatApiReady)
      }
    })()
  }, [])

  useEffect(() => {
    setVerifyToken(verifyToken)
  }, [verifyToken])

  const keepTokenFn = (token) => {
    setVerifyToken(token)
  }

  const refreshRecaptcha = () => {
    const interval = setInterval(async () => {
      round = round === 0 ? 1 : 0
      setRefreshReCaptcha(round)
    }, 60000)
    return () => clearInterval(interval)
  }

  const onSubmit = async (email) => {
    if (email) {
      const formatEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/g
      if (email.match(formatEmail)) {
        let obj = {
          username: email,
          recaptcha_token: verifyToken,
        }
        const { data, status } = await forgotPassword(obj)
        if (status === 200 && data) {
          localStorage.setItem('token', data.token)
          localStorage.setItem('ref_code', data.ref_code)
          window.location.href = '/verifypassword'
        } else {
          round = round === 0 ? 1 : 0
          setRefreshReCaptcha(round)
          handleValidateEmail('This email does not exist.', false)
        }
      } else {
        handleValidateEmail('Email Address is in the wrong format.', false)
      }
    } else {
      handleValidateEmail('Email Address is required.', false)
    }
  }
  const handleValidateEmail = (msg, status) => {
    setValidateEmail({ msg, status })
  }
  return (
    <ForgotPasswordBox>
      <GoogleReCaptchaProvider reCaptchaKey={REACT_APP_CAPTCHA}>
        <div className="page full-page">
          <div className="content-box">
            <div className="container-fluid animate fadeIn one h-100">
              <div className="row justify-content-md-center h-100">
                <div className="col-lg-6 text-center p-0 d-none d-lg-block">
                  <img
                    src="/assets/img/BG_login.png"
                    className="w-100 p-0"
                    alt="Tag Turbo"
                  />
                </div>
                <div className="col-lg-6 text-center p-0 content h-100 d-flex-center">
                  <div id="forgot" className="min-h-100 max-w-718">
                    <div className="p-5 min-h-95">
                      <h2 className="mt-4">
                        <img
                          src="/assets/img/logo-Tagtb.svg"
                          className="img-180"
                        />
                      </h2>
                      <div className="text-left mt-5">
                        <p className="text-20 m-0">
                          <b>Reset Password</b>
                        </p>
                        {/* <form id="form" action="/home" className="text-left mb-2"> */}
                        <h5 className="mt-4">
                          {loginFail && (
                            <p className="text-danger text-14">
                              Unable to sign in. Please check your email or
                              password and try again.
                            </p>
                          )}
                        </h5>
                        <div className="mb-5">
                          <label className="form-label text-14">
                            Email Address
                          </label>
                          <input
                            type="email"
                            className={`form-control ${
                              !validateEmail.status ? 'invalidate-box' : ''
                            }`}
                            id="email"
                            placeholder="name@example.com"
                            value={email}
                            defaultValue={email}
                            onChange={(e) => (
                              setEmail(e.target.value),
                              handleValidateEmail('', true),
                              setLoginFail(false)
                            )}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                onSubmit(email)
                              }
                            }}
                          />
                          {!validateEmail.status && (
                            <label className="form-label invalidate-text text-14">
                              {validateEmail.msg}
                            </label>
                          )}
                        </div>
                        {/* </form> */}
                        <div className="text-center">
                          <button
                            className="btn btn-primary w-50 text-16 text-semibold"
                            onClick={() => onSubmit(email)}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                    <FooterComponent />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ReCaptchaComponent
          keepTokenFn={keepTokenFn}
          refreshToken={refreshReCaptcha}
        />
      </GoogleReCaptchaProvider>
    </ForgotPasswordBox>
  )
}

export default ForgotPasswordComponent
