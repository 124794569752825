import axios from 'axios'
import { redirectLogin, redirectPermission } from '../../helper/helper'
const { REACT_APP_API_TAG } = process.env

const tagsList = async (token, workspace_id, domain) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      workspace_id,
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/tag`
    const response = await axios.get(URL, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: 500,
        data: error,
      }
    }
  }
  return res
}

const publishList = async (token, workspace_id) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      workspace_id,
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/tag/publish-lists`
    const response = await axios.get(URL, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: 500,
        data: error,
      }
    }
  }
  return res
}

const publishTag = async (token, workspace_id) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      workspace_id,
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/tag/publish`
    const response = await axios.post(URL, {}, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error.response?.status === 400) {
      res = {
        status: 400,
        data: error.response?.data?.message || 'publishTag error',
      }
    } else if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: 500,
        data: error,
      }
    }
  }
  return res
}
const updateStatusAllTag = async (token, workspace_id, domain, obj) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      workspace_id,
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/tag/all/pause`
    const response = await axios.patch(URL, obj, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    res = {
      status: 500, //error.response?.status,
      data: error, //error.message,
    }
    // }
  }
  return res
}

const deleteAllTag = async (token, workspace_id, domain, obj) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      // origin: domain,
      workspace_id,
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/tag`
    const response = await axios.delete(URL, {
      headers: customConfigHeader.headers,
      data: obj,
    })
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    res = {
      status: 500, //error.response?.status,
      data: error, //error.message,
    }
    // }
  }
  return res
}

const activityList = async (token, workspace_id, obj) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      workspace_id,
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/activity/tag/logs?page_size=${obj.page_size}&last_field_id=${obj.last_field_id}&activity=${obj.activity}&tag_name=${obj.tag_name}&user=${obj.user}`
    const response = await axios.get(URL, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else if (error.response?.status === 400) {
      res = {
        status: 200,
        data: {
          data: {
            activity: [],
          },
        },
      }
    } else {
      res = {
        status: 500,
        data: error,
      }
    }
  }
  return res
}

const accountActivityList = async (token, account_id, obj) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      account_id,
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = ''
    URL = `${REACT_APP_API_TAG}/activity/action/logs?page_size=${obj.page_size}&last_field_id=${obj.last_field_id}&category=${obj.category}&activity=${obj.activity}&workspace_id=${obj.workspace_id}`

    const response = await axios.get(URL, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: 500,
        data: error,
      }
    }
  }

  return res
}
const updateStatusByID = async (token, workspace_id, domain, obj, tag_id) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      workspace_id,

      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/tag/pause/${tag_id}`
    const response = await axios.patch(URL, obj, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    res = {
      status: 500, //error.response?.status,
      data: error, //error.message,
    }
    // }
  }
  return res
}
const deleteTagByID = async (token, workspace_id, domain, tag_id) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      origin: domain,
      workspace_id,
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/tag/${tag_id}`
    const response = await axios.delete(URL, {
      headers: customConfigHeader.headers,
    })
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    res = {
      status: 500, //error.response?.status,
      data: error, //error.message,
    }
    // }
  }
  return res
}
export {
  tagsList,
  publishList,
  publishTag,
  updateStatusAllTag,
  deleteAllTag,
  activityList,
  accountActivityList,
  updateStatusByID,
  deleteTagByID,
}
