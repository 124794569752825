import styled from 'styled-components'

const ResetPasswordBox = styled.div`
  .content-box {
    height: 100%;
    min-height: 660px;
    overflow-y: hidden;
  }
  .content {
    border-radius: 0px;
    box-shadow: 4px 10px 30px 0 rgb(0 0 0 / 5%);
    background-color: #fff;
    padding: 30px;
    min-height: 80vh;
    max-height: 100vh;
  }
  .input {
    width: 3.3rem !important;
    height: 3.5rem;
    margin: 0 0.2rem;
    font-size: 2rem;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
  }
  .invalid {
    color: #000;
  }
`

export { ResetPasswordBox }
