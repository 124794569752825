import React, { Fragment, useState, useEffect } from 'react'
import { Checkout, Payment } from './style'
import { MdCreditCard, MdHome, MdClose } from 'react-icons/md'
import Select from 'react-select'
import {
  numberWithCommas,
  numberWithToFixed,
  formatDateShortMonth,
} from '../../helper/helper'
import { useParams } from 'react-router-dom'
import Modal from 'react-modal'
import FooterComponent from '../layout/footer'
import TopbarComponent from '../layout/topbar'
import { subscriptionDetail } from '../../apis/subscription/api'
import { billingInfo } from '../../apis/billing/api'
import { useForm } from 'react-hook-form'
import {
  changeCard,
  checkStatusChargeChangeCard,
  checkStatusChargeAccount,
  chargeDetail,
} from '../../apis/plan/api'
import {
  checkStatusReceipt,
  chargeDetailChangePlan,
} from '../../apis/payment/api'
import { allCountry } from '../../apis/account/api'
import {
  manualRenewalAssign,
  manualRenewalExtend,
} from '../../apis/payment/api'
import { enumCode } from '../../helper/enum'

const { REACT_APP_OMISE_SECRET_KEY } = process.env

const CheckOutComponent = () => {
  const accessToken = localStorage.getItem('token')
  const { accountId, workspaceId } = useParams()
  const [countryList, setCountryList] = useState([])

  const specialChars = /[||\\<“>"'‘\;&*%!+{}@#=?$^\[\]]/
  const specialCharsAndnumber = /[!0-9||\\<“>"'‘\;&*%!+{}@#=?$^\[\]]/

  const [cardType, setCardType] = useState('')
  const [receiptId, setReceiptId] = useState('-')
  const [isWaiting, setIsWaiting] = useState(false)
  const [cardDetail, setCardDetail] = useState('')

  const [cardID, setCardID] = useState('')
  const [cardName, setCardName] = useState('')
  const [cardDate, setCardDate] = useState('')
  const [cardCVV, setCardCVV] = useState('')

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [taxNumber, setTaxNumber] = useState('')
  const [address, setAddress] = useState('')
  const [city, setCity] = useState('')
  const [country, setCountry] = useState('Thailand')
  const [postalCode, setPostalCode] = useState('')
  const [planName, setPlanName] = useState('')
  const [currency, setCurrency] = useState('')
  const [plan_id, setPlanId] = useState('')
  const [plan_type, setPlanType] = useState('')

  const [withholdingTax, setWithholdingTax] = useState('')

  const [inValidateCardID, setValidateCardID] = useState(false)

  const [inValidateCardName, setValidateCardName] = useState(false)
  const [inValidateCardDate, setValidateCardDate] = useState(false)
  const [inValidateCardCVV, setValidateCardCVV] = useState(false)
  const [showInputCoupon, setShowInputCoupon] = useState(false)
  const [isStatusCoupon, setIsStatusCoupon] = useState(false)

  const [inValidateFirstName, setValidateFirstName] = useState(false)
  const [inValidateLastName, setValidateLastName] = useState(false)
  const [inValidatePhoneNumber, setValidatePhoneNumber] = useState(false)
  const [inValidateAddress, setValidateAddress] = useState(false)
  const [inValidateCity, setValidateCity] = useState(false)
  const [inValidatePostalCode, setValidatePostalCode] = useState(false)
  const [checkStatusPayment, setCheckStatusPayment] = useState(false)
  const [openPopupCheckStatusPayment, setOpenPopupCheckStatusPayment] =
    useState(false)
  const [openPopupChangeCard, setOpenPopupChangeCard] = useState(false)
  const [isClick, setIsClick] = useState(false)
  const [chargeDetailInfo, setChargeDetailInfo] = useState('')

  const [dafaultCountry, setDafaultCountry] = useState([
    {
      value: 'Thailand',
      label: 'Thailand',
    },
  ])
  const [coupon, setCoupon] = useState('')
  const [discount, setDiscount] = useState('')
  const [inValidateCoupon, setValidateCoupon] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [excludingVat, setExcludingVat] = useState('')
  const [vat, setVat] = useState('')
  const [taxWithholding, setTaxWithholding] = useState('')
  const [amount, setAmount] = useState('')
  const [openPopupCouponNotValid, setOpenPopupCouponNotValid] = useState(false)
  const [objPlanDetail, setObjPlanDetail] = useState([])
  const [packageTrial, setPackageTrial] = useState('')

  const customStylesPopupSM = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '511px',
    },
  }
  const customStylesPopupMD = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '553px',
    },
  }
  const customStylesPopupLG = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '640px',
    },
  }
  const customStylesXS = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '436px',
    },
  }
  const [openPopupCheckStatusChangeCard, setOpenPopupCheckStatusChangeCard] =
    useState(false)
  const [checkStatusChangeCard, setCheckStatusChangeCard] = useState(false)

  const [subscriptionInfo, setSubscriptionInfo] = useState('')
  const [invoiceType, setInvoiceType] = useState('')
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm()
  const change_plan = localStorage.getItem('plan_datail')
  const obj_planIn_fo = localStorage.getItem('plan_Info')
  const payment_event = localStorage.getItem('payment_event')
  const url_subscription = `/subscription/${accountId}${
    workspaceId ? '/' + workspaceId : ''
  }`
  const plan_datail_free = JSON.parse(change_plan)
  const obj_planIn_fo_free = JSON.parse(obj_planIn_fo)

  useEffect(() => {
    document.title = 'ชำระเงิน | Tag Turbo'
    if (window) {
      window.Omise.setPublicKey(REACT_APP_OMISE_SECRET_KEY)
    }
    if (change_plan) {
      setObjPlanDetail(plan_datail_free)
      getBillingInfo(accessToken, accountId, plan_datail_free)
      setCurrency(plan_datail_free.currency)
      setPackageTrial(plan_datail_free.package_trial)
    } else {
      getSubscriptionInfo(accessToken, accountId)
    }
    getCountry(accessToken)
  }, [])

  const onChangeCountry = (e) => {
    setCountry(e.value)
    setDafaultCountry([{ value: e.value, label: e.label }])
    onCheckCountry(e.value, objPlanDetail)
  }
  const onChangeCardID = (cc) => {
    setCardID(cc)
    creditCardType(cc)
    setValidateCardID(false)
  }

  const creditCardType = (cc) => {
    let res = ''
    if (cc?.length <= 19) {
      let visa = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$')
      let mastercard = new RegExp('^5[1-5][0-9]{14}$')
      let mastercard2 = new RegExp('^2[2-7][0-9]{14}$')
      let jcb = new RegExp('^35[0-9]{14}[0-9]*$')
      let replaceDash = cc.replace('-', '')
      replaceDash = replaceDash.replace('-', '')
      replaceDash = replaceDash.replace('-', '')
      replaceDash = replaceDash.replaceAll(/\s/g, '')
      if (visa.test(replaceDash)) {
        res = 'VISA'
      }
      if (mastercard.test(replaceDash) || mastercard2.test(replaceDash)) {
        res = 'MASTERCARD'
      }
      if (jcb.test(replaceDash)) {
        res = 'JCB'
      }
      return res
    }
  }
  const chackCreditCardType = (cc) => {
    if (cc?.length < 20) {
      let visa = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$')
      let mastercard = new RegExp('^5[1-5][0-9]{14}$')
      let mastercard2 = new RegExp('^2[2-7][0-9]{14}$')

      let jcb = new RegExp('^35[0-9]{14}[0-9]*$')
      let replaceDash = cc.replace('-', '')
      replaceDash = replaceDash.replace('-', '')
      replaceDash = replaceDash.replace('-', '')
      replaceDash = replaceDash.replaceAll(/\s/g, '')
      if (visa.test(replaceDash)) {
        setCardType('VISA')
      } else {
        setCardType()
      }
      if (mastercard.test(replaceDash) || mastercard2.test(replaceDash)) {
        setCardType('MASTERCARD')
      }
      if (jcb.test(replaceDash)) {
        setCardType('JCB')
      }

      setCardID(maskCard(cc))
      return undefined
    }
  }
  const maskCard = (value) => {
    var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
    var matches = v.match(/\d{4,16}/g)
    var match = (matches && matches[0]) || ''
    var parts = []
    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4))
    }
    if (parts.length) {
      return parts.join('-')
    } else {
      return value
    }
  }
  const customInputDate = (text) => {
    let res = text
      .replace(/^(\d\d)(\d)$/g, '$1/$2')
      .replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2')
      .replace(/[^\d\/]/g, '')
    return res
  }
  const onManualPaymentAssign = async (obj) => {
    let obj_change = {
      billing_info: {
        first_name: firstName,
        last_name: lastName,
        address: address,
        city: city,
        country: country,
        postal_code: postalCode,
        phone: phoneNumber,
        company: companyName ? companyName : '',
        tax_id: taxNumber ? taxNumber : '',
      },

      invoiceType: 'individual',
      currency: currency,
      plan_id: plan_id,
      plan_type: plan_type,
      payment_event_type:
        payment_event === 'Change_plan'
          ? 'charge_change_account_plan'
          : 'manual_renewal_charge_account_assign_plan',
      coupon_code: isStatusCoupon ? coupon : '',
      country: country ? country : 'Thailand',
    }

    if (!firstName) {
      setValidateFirstName(true)
    } else {
      setValidateFirstName(false)
    }
    if (!lastName) {
      setValidateLastName(true)
    } else {
      setValidateLastName(false)
    }
    if (!address) {
      setValidateAddress(true)
    } else {
      setValidateAddress(false)
    }
    if (!city) {
      setValidateCity(true)
    } else {
      setValidateCity(false)
    }
    if (!phoneNumber) {
      setValidatePhoneNumber(true)
    } else {
      setValidatePhoneNumber(false)
    }
    if (!postalCode) {
      setValidatePostalCode(true)
    } else {
      setValidatePostalCode(false)
    }
    if (firstName && lastName && address && city && phoneNumber && postalCode) {
      setIsClick(true)
      setOpenPopupCheckStatusPayment(true)
      setIsWaiting(true)
      if (payment_event === 'Extend') {
        const { data, status } = await manualRenewalExtend(
          accessToken,
          accountId,
          obj_change,
        )
        if (data && status === 200) {
          if (data.authorize_uri_omise) {
            window.open(`${data.authorize_uri_omise}`, '_blank')
          }
          await getStatusReceipt(data.receipt_id)
          localStorage.removeItem('payment_event')
        } else {
          if (coupon) {
            setCoupon(coupon)
            setShowInputCoupon(true)
            setValidateCoupon(true)
            setIsClick(false)
            setOpenPopupCheckStatusPayment(false)
            setIsStatusCoupon(false)
            // setErrorMsg(data.response.data.message);
            setIsWaiting(false)
            setErrorMsg('The discount code usage limit has been reached.')
          } else {
            setIsClick(false)
            setIsWaiting(false)
            setCheckStatusPayment(false)
            setOpenPopupCheckStatusPayment(false)
          }
          setIsWaiting(false)
        }
      } else {
        const { data, status } = await manualRenewalAssign(
          accessToken,
          accountId,
          cardDetail ? obj_change : obj,
        )
        if (data && status === 200) {
          if (data.authorize_uri_omise) {
            window.open(`${data.authorize_uri_omise}`, '_blank')
          }
          await getStatusReceipt(data.receipt_id)
          setReceiptId(data.receipt_id)
          localStorage.removeItem('payment_event')
          localStorage.removeItem('plan_datail')
          localStorage.removeItem('plan_Info')
        } else {
          if (coupon) {
            setCoupon(coupon)
            setShowInputCoupon(true)
            setValidateCoupon(true)
            setIsClick(false)
            setOpenPopupCheckStatusPayment(false)
            setIsStatusCoupon(false)
            // setErrorMsg(data.response.data.message);
            setErrorMsg('The discount code usage limit has been reached.')
          } else {
            setIsClick(false)
            setIsWaiting(false)
            setCheckStatusPayment(false)
            setOpenPopupCheckStatusPayment(false)
          }
        }
      }
    }
  }

  const getStatusReceipt = async (receiptId) => {
    const { data, status } = await checkStatusReceipt(
      accessToken,
      accountId,
      receiptId,
    )
    setReceiptId(receiptId)
    if (status === 200) {
      if (data.data.status === 'successful') {
        setOpenPopupCheckStatusPayment(true)
        setIsWaiting(false)
        setCheckStatusPayment(true)
        localStorage.removeItem('payment_event')
        localStorage.removeItem('plan_datail')
      } else if (data.data.status === 'pending') {
        setTimeout(() => {
          getStatusReceipt(receiptId)
        }, 10000)
      } else {
        setIsClick(false)
        setCheckStatusPayment(false)
        setOpenPopupCheckStatusPayment(true)
        setIsWaiting(false)
      }
    } else {
      setIsClick(false)
      setCheckStatusPayment(false)
      setOpenPopupCheckStatusPayment(true)
      setIsWaiting(false)
    }
  }

  const getStatusChargeAccount = async (accountId) => {
    setOpenPopupCheckStatusPayment(true)
    setIsWaiting(true)

    const { data, status } = await checkStatusChargeAccount(
      accessToken,
      accountId,
    )
    if (status === 200) {
      if (data.data.status === 'successful') {
        setCheckStatusPayment(true)
        setOpenPopupCheckStatusPayment(true)
        setIsWaiting(false)
        localStorage.removeItem('plan_datail')
      } else if (data.data.status === 'pending') {
        setTimeout(() => {
          getStatusChargeAccount(accountId)
        }, 5000)
      } else if (data.data.status === 'failed') {
        setIsWaiting(false)
        setOpenPopupCheckStatusPayment(true)
        setCheckStatusPayment(false)
        setIsClick(false)
      } else {
        setCheckStatusPayment(false)
        setOpenPopupCheckStatusPayment(true)
      }
    } else {
      setCheckStatusPayment(false)
      setOpenPopupCheckStatusPayment(true)
    }
  }
  const getSubscriptionInfo = async () => {
    const { data, status } = await subscriptionDetail(accessToken, accountId)
    if (status === 200) {
      if (data.subscriptionDetail.invoiceType === 'juristic') {
        setInvoiceType(true)
      } else {
        setInvoiceType(false)
      }
      setCardID('****' + data.subscriptionDetail.card_detail.last_digits_card)
      let obj = {
        plan_id: 'plan-' + data.subscriptionDetail.plan.toLowerCase(),
        plan_name: data.subscriptionDetail.plan,
        plan_type: data.subscriptionDetail.subscription_type,
        currency: data.subscriptionDetail.currency,
      }
      getBillingInfo(accessToken, accountId, obj)
      setObjPlanDetail(obj)
    }
  }

  const changePlan = async (token, accountId, change_plan) => {
    let obj_change_plan = {
      plan_id: change_plan.plan_id,
      plan_type: change_plan.plan_type,
      currency: change_plan.currency,
      country: country ? country : 'Thailand',
      coupon_code: coupon ? coupon : '',
      payment_event_type:
        payment_event === 'Change_plan'
          ? 'charge_change_account_plan'
          : 'manual_renewal_charge_account_assign_plan',
    }
    const { data, status } = await chargeDetailChangePlan(
      token,
      accountId,
      obj_change_plan,
    )
    if (data && status === 200) {
      setChargeDetailInfo(data.data)
      setPlanName(change_plan.plan_name)
      setCurrency(change_plan.currency)
      setPlanId(change_plan.plan_id.toLowerCase())
      setPlanType(change_plan.plan_type)
    }
  }

  const getBillingInfo = async (accessToken, accountId, obj) => {
    const { data, status } = await billingInfo(accessToken, accountId)
    if (status === 200) {
      const billingInfo = data.data.billingInfo
      if (Object.keys(billingInfo).length > 0) {
        setFirstName(billingInfo.first_name)
        setLastName(billingInfo.last_name)
        setPhoneNumber(billingInfo.phone)
        setCompanyName(billingInfo.company ? billingInfo.company : '')
        setTaxNumber(billingInfo.tax_id ? billingInfo.tax_id : '')
        setAddress(billingInfo.address)
        setCity(billingInfo.city)
        setPostalCode(billingInfo.postal_code)
        setCardID('****' + data.data.card_detail.last_digits_card)
        setCountry(billingInfo.country)
        setDafaultCountry([
          { value: billingInfo.country, label: billingInfo.country },
        ])
        if (billingInfo.country && obj) {
          onCheckCountry(billingInfo.country, obj)
        }
      } else {
        onCheckCountry('Thailand', obj)
        setCardID('')
      }

      if (Object.keys(data.data.card_detail).length > 0) {
        setCardDetail(data.data.card_detail)
      }
    }
  }
  const onSubmit = async (data) => {
    let tokenCard = ''
    window.OmiseCard.configure({
      publicKey: REACT_APP_OMISE_SECRET_KEY,
    })
    let exMount = data.expireDate.split('/')[0]
    let exYear = data.expireDate.split('/')[1]
    var cardInformation = {
      name: data.cardHolder,
      number: data.cardNumber,
      expiration_month: exMount,
      expiration_year: exYear,
      security_code: data.cvv,
    }
    window.Omise.createToken(
      'card',
      cardInformation,
      async (statusCode, response) => {
        if (statusCode === 200) {
          tokenCard = response.id

          let obj = {
            token_card: tokenCard,
          }

          onChangeCard(obj)
        } else {
          console.log('Omise get token error')
          setCheckStatusChangeCard(false)
          setOpenPopupCheckStatusChangeCard(true)
        }
      },
    )
    return tokenCard
  }

  const onChangeCard = async (obj) => {
    setOpenPopupCheckStatusChangeCard(true)
    setIsWaiting(true)
    const { data, status } = await changeCard(accessToken, accountId, obj)
    if (status === 200) {
      if (data.authorize_uri_omise) {
        window.open(`${data.authorize_uri_omise}`, '_blank')
      }
      await getStatusChargeChangeCard()
    } else {
      // handleAlert("Failed to update Account Role. Please try again.", false);
    }
  }
  const getStatusChargeChangeCard = async () => {
    setOpenPopupCheckStatusChangeCard(true)
    setIsWaiting(true)

    const { data, status } = await checkStatusChargeChangeCard(
      accessToken,
      accountId,
    )
    if (status === 200) {
      if (data.data.status === 'successful') {
        setTimeout(() => {
          if (change_plan) {
            changePlan(accessToken, accountId, JSON.parse(change_plan))
          } else {
            getSubscriptionInfo(accessToken, accountId)
          }
        }, 2000)

        setOpenPopupChangeCard(false)
        setCheckStatusChangeCard(true)
        setOpenPopupCheckStatusChangeCard(true)
        setIsWaiting(false)
        getBillingInfo(accessToken, accountId)
      } else if (data.data.status === 'pending') {
        setOpenPopupChangeCard(false)
        setTimeout(() => {
          getStatusChargeChangeCard()
        }, 5000)
      } else if (data.data.status === 'failed') {
        setCheckStatusChangeCard(false)
        setOpenPopupCheckStatusChangeCard(true)
        setIsWaiting(false)
      } else {
        setCheckStatusChangeCard(false)
        setOpenPopupCheckStatusChangeCard(true)
      }
    } else {
      setIsWaiting(false)
      setCheckStatusChangeCard(false)
      setOpenPopupCheckStatusChangeCard(true)
    }
  }
  const getCountry = async (token) => {
    const { data, status } = await allCountry(token)
    let country = data.country_list
    country.forEach(function (obj) {
      obj.value = obj.country_name
      obj.label = obj.country_name
      delete obj.country_name
      delete obj.id
    })
    if (status === 200) {
      setCountryList(country)
    }
  }

  const handlePayment = () => {
    if (cardDetail) {
      onManualPaymentAssign()
    } else {
      createTokenOmise()
    }
  }

  const createTokenOmise = async () => {
    if (!cardID) {
      setValidateCardID(true)
    } else {
      setValidateCardID(false)
    }
    if (!cardName) {
      setValidateCardName(true)
    } else {
      setValidateCardName(false)
    }
    if (!cardDate) {
      setValidateCardDate(true)
    } else {
      setValidateCardDate(false)
    }
    if (!cardCVV) {
      setValidateCardCVV(true)
    } else {
      setValidateCardCVV(false)
    }
    if (!firstName) {
      setValidateFirstName(true)
    } else {
      setValidateFirstName(false)
    }
    if (!lastName) {
      setValidateLastName(true)
    } else {
      setValidateLastName(false)
    }
    if (!address) {
      setValidateAddress(true)
    } else {
      setValidateAddress(false)
    }
    if (!city) {
      setValidateCity(true)
    } else {
      setValidateCity(false)
    }
    if (!phoneNumber) {
      setValidatePhoneNumber(true)
    } else {
      setValidatePhoneNumber(false)
    }
    if (!postalCode) {
      setValidatePostalCode(true)
    } else {
      setValidatePostalCode(false)
    }
    let tokenCard = ''
    if (
      cardID &&
      cardName &&
      cardDate &&
      cardCVV &&
      firstName &&
      lastName &&
      address &&
      city &&
      phoneNumber &&
      postalCode
    ) {
      window.OmiseCard.configure({
        publicKey: REACT_APP_OMISE_SECRET_KEY,
      })
      let exMount = cardDate.split('/')[0]
      let exYear = cardDate.split('/')[1]
      var cardInformation = {
        name: cardName,
        number: cardID.replace(/-/g, ''),
        expiration_month: exMount,
        expiration_year: exYear,
        security_code: cardCVV,
      }
      window.Omise.createToken(
        'card',
        cardInformation,
        async (statusCode, response) => {
          if (statusCode === 200) {
            tokenCard = response.id
            let obj = {
              account_name:
                obj_planIn_fo_free?.account_name ||
                plan_datail_free?.account_name,
              billing_info: {
                first_name: firstName,
                last_name: lastName,
                address: address,
                city: city,
                country: country,
                postal_code: postalCode,
                phone: phoneNumber,
                company: companyName ? companyName : '',
                tax_id: taxNumber ? taxNumber : '',
              },
              token_card: tokenCard,
              invoiceType: 'individual',
              // invoiceType: withholdingTax ? "individual" : "juristic",
              currency: plan_datail_free.currency,
              plan_id: plan_datail_free.plan_id,
              plan_type: plan_datail_free.plan_type,
              coupon_code: isStatusCoupon ? coupon : '',
              country: country ? country : 'Thailand',
              one_dollar_promotion: packageTrial,
            }
            onManualPaymentAssign(obj)
          } else {
            console.log('Omise get token error')
            if (coupon) {
              setCoupon(coupon)
              setShowInputCoupon(true)
              setValidateCoupon(true)
            } else {
              setIsWaiting(false)
              setCheckStatusPayment(false)
              setOpenPopupCheckStatusPayment(false)
            }
          }
        },
      )
    }
    return tokenCard
  }

  const onCheckCountry = async (country, objPlan) => {
    let obj = {
      plan_id: objPlan.plan_id,
      plan_type: objPlan.plan_type,
      currency: objPlan.currency,
      coupon_code: coupon ? coupon.toUpperCase() : '',
      country: country ? country : 'Thailand',
      payment_event_type:
        payment_event === 'Change_plan'
          ? 'charge_change_account_plan'
          : 'manual_renewal_charge_account_assign_plan',
    }
    let token = accessToken
    const { data, status } = await chargeDetail(token, accountId, obj)
    if (data && status === 200) {
      setChargeDetailInfo(data.data)
      setPlanName(objPlan.plan_name)
      setCurrency(objPlan.currency)
      setPlanId(objPlan.plan_id)
      setPlanType(objPlan.plan_type)
    } else {
      setValidateCoupon(true)
      setErrorMsg(enumCode['en'][data.response.data.code])
    }
  }

  const onCheckCoupon = async (isCoupon) => {
    if (coupon || isCoupon === 'remove') {
      let obj = {
        plan_id: plan_id || plan_datail_free.plan_id,
        plan_type: plan_type || plan_datail_free.plan_type,
        currency: currency || plan_datail_free.currency,
        coupon_code: isCoupon === 'remove' ? '' : coupon.toUpperCase(),
        country: country ? country : 'Thailand',
        payment_event_type:
          payment_event === 'Change_plan'
            ? 'charge_change_account_plan'
            : 'manual_renewal_charge_account_assign_plan',
      }
      let token = accessToken
      const { data, status } = await chargeDetail(token, accountId, obj)
      if (data && status === 200) {
        setChargeDetailInfo(data.data)
        setCoupon(isCoupon === 'remove' ? '' : coupon)
        setIsStatusCoupon(isCoupon === 'remove' ? false : true)
        setShowInputCoupon(isCoupon === 'remove' ? true : false)
        setValidateCoupon(false)
        let individual = data?.data?.individual_charge_detail
        setDiscount(individual.coupon_discount)
        setExcludingVat(individual.excluding_vat)
        setVat(individual.vat)
        setTaxWithholding(individual.withholding_tax)
        setAmount(individual.amount)
      } else {
        setValidateCoupon(true)
        setErrorMsg(enumCode['en'][data.response.data.code])
      }
    } else {
      setErrorMsg('Discount Code is required.')
      setValidateCoupon(true)
    }
  }
  const onCloseCouponBox = () => {
    setCoupon('')
    setShowInputCoupon(!showInputCoupon)
    setValidateCoupon(false)
  }
  const numberWithCommasZero = (x) => {
    // x = Number(x);
    // Check if x is a valid number
    if (isNaN(x)) {
      return ''
    }

    // Get the integer part and the decimal part
    const integerPart = Math.floor(x)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    const decimalPart = (x % 1).toFixed(2).slice(2)

    // Check if the decimal part is zero
    const hasDecimalPart = decimalPart
    // Combine the formatted integer and decimal parts
    const formattedNumber = hasDecimalPart
      ? `${integerPart}.${decimalPart}`
      : integerPart
    return formattedNumber
  }

  return (
    <Checkout>
      <div className="page pull-page-bg-secondary">
        <div className="content-box">
          <div className="content-scroll ">
            <TopbarComponent />
            <div className="container-md  animate fadeIn one">
              <div className="card p-2 border box-shadow-center bg-white mt-4">
                <div className="card-body">
                  <p className="text-12 text-primary">Payment Details</p>
                  <Payment>
                    <div className="row">
                      <div className="col-6">
                        <p className="text-20 text-semibold">
                          Add Your Payment
                        </p>
                        {cardDetail ? (
                          <div id="card_id">
                            <div className="d-flex justify-content-between">
                              <label className="form-label text-14">
                                Card Number *
                              </label>
                              <label
                                onClick={() => (
                                  setOpenPopupChangeCard(true), reset()
                                )}
                                className="form-label cursor text-primary text-14"
                              >
                                Change Card
                              </label>
                            </div>
                            <div className="input-group mb-2">
                              <span
                                className={`input-group-text border-right-0 ${
                                  inValidateCardID ? 'is-invalid' : ''
                                }`}
                              >
                                <MdCreditCard />
                              </span>
                              <input
                                className={`form-control form-control-icon border-right-0 border-left-0 ${
                                  inValidateCardID ? 'is-invalid' : ''
                                }`}
                                value={
                                  cardID || '****' + cardDetail.last_digits_card
                                }
                              />
                              <span
                                className={`input-group-text border-left-0 ${
                                  inValidateCardID ? 'is-invalid' : ''
                                }`}
                              >
                                {cardType && (
                                  <img
                                    src={`/assets/img/${cardType}.png`}
                                    alt={`${cardType}`}
                                    height="20"
                                  />
                                )}
                              </span>
                            </div>
                            <div className="col-lg-12 mb-2">
                              {inValidateCardID && (
                                <span className="text-danger text-12">
                                  Card Number is required.
                                </span>
                              )}
                            </div>
                          </div>
                        ) : (
                          <Fragment>
                            <div id="card_id">
                              <label className="form-label">
                                Card Number *
                              </label>
                              <div className="input-group mb-3">
                                <span
                                  className={`input-group-text border-right-0 ${
                                    inValidateCardID ? 'is-invalid' : ''
                                  }`}
                                >
                                  <MdCreditCard />
                                </span>
                                <input
                                  className={`form-control form-control-icon border-right-0 border-left-0 ${
                                    inValidateCardID ? 'is-invalid' : ''
                                  }`}
                                  value={cardID}
                                  onKeyPress={(event) => {
                                    if (
                                      !/[0-9]/.test(event.key) ||
                                      event?.target?.value?.length > 18
                                    ) {
                                      event.preventDefault()
                                    }
                                  }}
                                  onChange={(e) => (
                                    chackCreditCardType(e.target.value),
                                    setValidateCardID(false)
                                  )}
                                />
                                <span
                                  className={`input-group-text border-left-0 ${
                                    inValidateCardID ? 'is-invalid' : ''
                                  }`}
                                >
                                  {cardType && (
                                    <img
                                      src={`/assets/img/${cardType}.png`}
                                      alt={`${cardType}`}
                                      height="20"
                                    />
                                  )}
                                </span>
                              </div>
                              <div className="col-lg-12 mb-3">
                                {inValidateCardID && (
                                  <span className="text-danger text-12">
                                    Card Number is required.
                                  </span>
                                )}
                              </div>
                            </div>
                            <div id="card_name">
                              <label className="form-label">
                                Card Holder Name *
                              </label>
                              <input
                                type="text"
                                className={`form-control ${
                                  inValidateCardName ? 'is-invalid' : ''
                                }`}
                                value={cardName}
                                onKeyPress={(event) => {
                                  if (specialCharsAndnumber.test(event.key)) {
                                    event.preventDefault()
                                  }
                                }}
                                onChange={(e) => (
                                  setCardName(e.target.value),
                                  setValidateCardName(false)
                                )}
                              />
                              <div className="col-lg-12 mb-3">
                                {inValidateCardName && (
                                  <span className="text-danger text-12">
                                    Card Holder Name is required.
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6">
                                <div id="card_exp_date">
                                  <label className="form-label">
                                    Expiration Date *
                                  </label>
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      inValidateCardDate ? 'is-invalid' : ''
                                    }`}
                                    value={cardDate}
                                    size="5"
                                    maxLength="5"
                                    onKeyPress={(event) => {
                                      if (specialChars.test(event.key)) {
                                        event.preventDefault()
                                      }
                                    }}
                                    onChange={(e) => (
                                      setCardDate(
                                        customInputDate(e.target.value),
                                      ),
                                      setValidateCardDate(false)
                                    )}
                                  />
                                  <div className="col-lg-12 mb-3">
                                    {inValidateCardDate && (
                                      <span className="text-danger text-12">
                                        Card Expiration Date is required.
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div className="col-6">
                                <div id="card_cvv">
                                  <label className="form-label">CVV *</label>
                                  <input
                                    type="password"
                                    pattern="\d*"
                                    className={`form-control ${
                                      inValidateCardCVV ? 'is-invalid' : ''
                                    }`}
                                    value={cardCVV}
                                    size="3"
                                    maxLength="3"
                                    onKeyPress={(event) => {
                                      if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault()
                                      }
                                    }}
                                    onChange={(e) => (
                                      setCardCVV(e.target.value),
                                      setValidateCardCVV(false)
                                    )}
                                  />
                                  <div className="col-lg-12 mb-3">
                                    {inValidateCardCVV && (
                                      <span className="text-danger text-12">
                                        Card CVV is required.
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Fragment>
                        )}

                        <p className="text-20 text-semibold">
                          Billing Information
                        </p>
                        <div className="row">
                          <div className="col-6">
                            <div id="firstName">
                              <label className="form-label text-14">
                                First Name *
                              </label>
                              <input
                                type="text"
                                className={`form-control ${
                                  inValidateFirstName ? 'is-invalid' : ''
                                }`}
                                value={firstName}
                                onKeyPress={(event) => {
                                  if (specialChars.test(event.key)) {
                                    event.preventDefault()
                                  }
                                }}
                                onChange={(e) => (
                                  setFirstName(
                                    !specialChars.test(e.target.value)
                                      ? e.target.value
                                      : '',
                                  ),
                                  setValidateFirstName(false)
                                )}
                              />
                              <div className="col-lg-12 mb-2">
                                {inValidateFirstName && (
                                  <span className="text-danger text-12">
                                    First name is required.
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-6">
                            <div id="card_cvv">
                              <label className="form-label text-14">
                                Last Name *
                              </label>
                              <input
                                type="text"
                                className={`form-control ${
                                  inValidateLastName ? 'is-invalid' : ''
                                }`}
                                value={lastName}
                                onKeyPress={(event) => {
                                  if (specialChars.test(event.key)) {
                                    event.preventDefault()
                                  }
                                }}
                                onChange={(e) => (
                                  setLastName(
                                    !specialChars.test(e.target.value)
                                      ? e.target.value
                                      : '',
                                  ),
                                  setValidateLastName(false)
                                )}
                              />
                              <div className="col-lg-12 mb-2">
                                {inValidateLastName && (
                                  <span className="text-danger text-12">
                                    Last Name is required.
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div id="company_name" className="mt-2">
                          <label className="form-label text-14">Company</label>
                          <input
                            type="text"
                            className={`form-control`}
                            value={companyName}
                            onKeyPress={(event) => {
                              if (specialChars.test(event.key)) {
                                event.preventDefault()
                              }
                            }}
                            onChange={(e) =>
                              setCompanyName(
                                !specialChars.test(e.target.value)
                                  ? e.target.value
                                  : '',
                              )
                            }
                          />
                        </div>
                        <div id="address" className="mt-3">
                          <label className="form-label text-14">
                            Address *
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              inValidateAddress ? 'is-invalid' : ''
                            }`}
                            value={address}
                            onKeyPress={(event) => {
                              if (specialChars.test(event.key)) {
                                event.preventDefault()
                              }
                            }}
                            onChange={(e) => (
                              setAddress(
                                !specialChars.test(e.target.value)
                                  ? e.target.value
                                  : '',
                              ),
                              setValidateAddress(false)
                            )}
                          />
                          {inValidateAddress && (
                            <span className="text-danger text-12">
                              Address is required.
                            </span>
                          )}
                        </div>
                        <div className="row mt-2">
                          <div className="col-6">
                            <div id="city">
                              <label className="form-label text-14">
                                City *
                              </label>
                              <input
                                type="text"
                                className={`form-control ${
                                  inValidateCity ? 'is-invalid' : ''
                                }`}
                                value={city}
                                onKeyPress={(event) => {
                                  if (specialChars.test(event.key)) {
                                    event.preventDefault()
                                  }
                                }}
                                onChange={(e) => (
                                  setCity(
                                    !specialChars.test(e.target.value)
                                      ? e.target.value
                                      : '',
                                  ),
                                  setValidateCity(false)
                                )}
                              />
                              <div className="col-lg-12 mb-3">
                                {inValidateCity && (
                                  <span className="text-danger text-12">
                                    City is required.
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-6">
                            <div id="country">
                              <label className="form-label text-14">
                                Country *
                              </label>

                              {dafaultCountry.length > 0 && (
                                <Select
                                  options={countryList}
                                  defaultValue={dafaultCountry[0]}
                                  className="mb-3"
                                  onChange={onChangeCountry}
                                  value={dafaultCountry[0]}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <div id="postal">
                              <label className="form-label text-14">
                                Postal code *
                              </label>
                              <input
                                type="text"
                                className={`form-control ${
                                  inValidatePostalCode ? 'is-invalid' : ''
                                }`}
                                value={postalCode}
                                onKeyPress={(event) => {
                                  if (specialChars.test(event.key)) {
                                    event.preventDefault()
                                  }
                                }}
                                onChange={(e) => (
                                  setPostalCode(
                                    !specialChars.test(e.target.value)
                                      ? e.target.value
                                      : '',
                                  ),
                                  setValidatePostalCode(false)
                                )}
                              />
                              <div className="col-lg-12 mb-3">
                                {inValidatePostalCode && (
                                  <span className="text-danger text-12">
                                    Postal is required.
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-6">
                            <div id="phone">
                              <label className="form-label text-14">
                                Phone *
                              </label>
                              <input
                                type="text"
                                className={`form-control ${
                                  inValidatePhoneNumber ? 'is-invalid' : ''
                                }`}
                                value={phoneNumber}
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault()
                                  }
                                }}
                                onChange={(e) => (
                                  setPhoneNumber(
                                    /[0-9]/.test(e.target.value)
                                      ? e.target.value
                                      : '',
                                  ),
                                  setValidatePhoneNumber(false)
                                )}
                              />
                              <div className="col-lg-12 mb-3">
                                {inValidatePhoneNumber && (
                                  <span className="text-danger text-12">
                                    Phone Number is required.
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div id="tax_id">
                          <label className="form-label text-14">Tax ID </label>
                          <input
                            type="text"
                            className={`form-control `}
                            value={taxNumber}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault()
                              }
                            }}
                            maxLength={country === 'Thailand' ? '13' : '99'}
                            onChange={(e) => setTaxNumber(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <p className="text-20 text-semibold">Payment Summary</p>
                        <div className="card">
                          <div className="card-header d-flex px-4">
                            <div className="w-50 text-12">Item</div>
                            <div className="w-50 text-right text-12">Price</div>
                          </div>
                          <div className="card-body px-4">
                            <ul className="nav">
                              <li className="nav-item w-50 text-capitalize text-14">
                                <span className="text-14 text-semibold">
                                  <span
                                    className={` badge  text-12 text-center mb-1 ${
                                      planName
                                        ? planName === 'Basic'
                                          ? 'badge-default'
                                          : planName === 'Pro'
                                            ? 'badge-blue'
                                            : planName === 'Ultimate'
                                              ? 'badge-purple'
                                              : 'badge-gray'
                                        : ''
                                    }`}
                                  >
                                    <span className="text-12 text-uppercase">
                                      {planName}
                                    </span>
                                  </span>
                                  <br />
                                  {payment_event === 'Change_plan' && (
                                    <Fragment className="text-capitalize">
                                      [
                                      {change_plan &&
                                        JSON.parse(change_plan).change_type}
                                      ]
                                    </Fragment>
                                  )}
                                </span>{' '}
                                {planName} -{' '}
                                {plan_type === 'month' ? 'Monthly' : 'Annual'}{' '}
                                Subscription
                              </li>

                              <li className="nav-item w-50 text-right text-14">
                                {currency === 'thb' ? '฿' : '$'}{' '}
                                <span
                                  className={`${packageTrial ? 'text-semibold' : ''}`}
                                >
                                  {numberWithCommasZero(
                                    numberWithToFixed(
                                      chargeDetailInfo?.individual_charge_detail
                                        ?.plan_price,
                                    ),
                                  )}
                                </span>
                                {packageTrial ? ' / first month' : <Fragment />}
                                <p className="text-12 text-grey">
                                  {packageTrial ? (
                                    <span>
                                      {currency === 'thb' ? '฿' : '$'}
                                      {plan_datail_free?.plan_price
                                        ?.currency === 'thb'
                                        ? plan_datail_free?.plan_price?.thb
                                        : plan_datail_free?.plan_price?.usd}
                                      / month after that period
                                    </span>
                                  ) : (
                                    <Fragment />
                                  )}
                                </p>
                              </li>
                            </ul>
                            {payment_event === 'Change_plan' && (
                              <ul className="nav">
                                <li className="nav-item w-50 text-capitalize text-14 text-semibold">
                                  Credit Remaining
                                </li>
                                <li className="nav-item w-50 text-right text-14">
                                  - {currency === 'thb' ? '฿' : '$'}{' '}
                                  {numberWithCommasZero(
                                    numberWithToFixed(
                                      chargeDetailInfo?.individual_charge_detail
                                        ?.refund
                                        ? chargeDetailInfo
                                            ?.individual_charge_detail?.refund
                                        : '0',
                                    ),
                                  )}
                                </li>
                              </ul>
                            )}

                            {isStatusCoupon ? (
                              <ul className="nav">
                                <li className="nav-item w-75 text-left text-14">
                                  <span>
                                    Discount :{' '}
                                    <span className="text-primary">
                                      {' '}
                                      ( {coupon.toUpperCase()} ){' '}
                                      <MdClose
                                        className="cursor text-secondary "
                                        onClick={() => onCheckCoupon('remove')}
                                      />
                                    </span>
                                  </span>
                                </li>
                                <li className="nav-item w-25 text-right text-14">
                                  <span>
                                    - {currency === 'thb' ? '฿' : '$'}{' '}
                                    {numberWithCommasZero(
                                      numberWithToFixed(
                                        discount ? discount : '0',
                                      ),
                                    )}
                                  </span>
                                </li>
                              </ul>
                            ) : (
                              <Fragment>
                                {showInputCoupon ? (
                                  <ul className="nav my-0 p-2 bg-coupon-box">
                                    <li className="nav-item w-75 text-left my-2 text-14">
                                      <span className="text-14 text-semibold text-grey-600">
                                        Enter a discount code
                                      </span>
                                    </li>
                                    <li className="nav-item w-25 text-right my-2 text-14">
                                      <span>
                                        <MdClose
                                          className="cursor text-secondary text-18"
                                          onClick={() => onCloseCouponBox()}
                                        />
                                      </span>
                                    </li>
                                    <li className="nav-item w-75 text-right text-primary text-14">
                                      <div id="coupon">
                                        <div className="d-flex justify-content-end pr-2">
                                          <input
                                            type="text"
                                            className={`form-control text-14x ${
                                              inValidateCoupon
                                                ? 'is-invalid'
                                                : ''
                                            }`}
                                            value={coupon.toUpperCase()}
                                            placeholder="Please Enter"
                                            onChange={(e) => (
                                              setCoupon(
                                                !specialChars.test(
                                                  e.target.value,
                                                )
                                                  ? e.target.value
                                                  : '',
                                              ),
                                              setValidateCoupon(false)
                                            )}
                                            onKeyPress={(event) => {
                                              if (
                                                !/[0-9A-Za-z;&*%!+{}@#=?$]/.test(event.key)
                                              ) {
                                                event.preventDefault()
                                              }
                                            }}
                                            maxLength={40}
                                          />
                                          {coupon && (
                                            <div
                                              className="position-absolute"
                                              style={{ padding: 6 }}
                                            >
                                              <MdClose
                                                className="cursor text-secondary "
                                                onClick={() =>
                                                  onCheckCoupon('remove')
                                                }
                                              />
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </li>
                                    <li className="nav-item  w-25 text-right text-primary text-14">
                                      <button
                                        className="btn btn-primary w-100 m-0 text-14"
                                        onClick={() => onCheckCoupon('add')}
                                        style={{ height: '37px' }}
                                      >
                                        Apply
                                      </button>
                                    </li>
                                    {inValidateCoupon && (
                                      <span className="text-danger px-2 text-14">
                                        {errorMsg}
                                      </span>
                                    )}
                                  </ul>
                                ) : (
                                  <ul className="nav">
                                    <li className="nav-item w-100 text-right text-primary text-14">
                                      {packageTrial ? (
                                        <span className="cursor-no-drop text-grey-300">
                                          Have a discount code?
                                        </span>
                                      ) : (
                                        <Fragment>
                                          {' '}
                                          <span
                                            onClick={() =>
                                              setShowInputCoupon(true)
                                            }
                                            className="cursor"
                                          >
                                            Have a discount code?
                                          </span>
                                        </Fragment>
                                      )}
                                    </li>
                                  </ul>
                                )}
                              </Fragment>
                            )}

                            <hr />
                            <ul className="nav">
                              <li className="nav-item w-50 text-semibold text-14">
                                Subtotal
                              </li>
                              <li className="nav-item w-50 text-right text-14">
                                {currency === 'thb' ? '฿' : '$'}{' '}
                                {numberWithCommasZero(
                                  numberWithToFixed(
                                    chargeDetailInfo?.individual_charge_detail
                                      ?.excluding_vat,
                                  ),
                                )}
                              </li>
                            </ul>
                            <ul className="nav">
                              <li className="nav-item w-50 text-semibold text-14">
                                Tax
                              </li>
                              <li className="nav-item w-50 text-right text-14">
                                {currency === 'thb' ? '฿' : '$'}{' '}
                                {numberWithCommasZero(
                                  numberWithToFixed(
                                    chargeDetailInfo?.individual_charge_detail
                                      ?.vat,
                                  ),
                                )}
                              </li>
                            </ul>
                            <hr />

                            <ul className="nav">
                              <li className="nav-item w-50 text-semibold text-14">
                                Total Amount
                              </li>
                              <li className="nav-item w-50 text-right text-semibold text-14">
                                {currency === 'thb' ? '฿' : '$'}{' '}
                                {numberWithCommasZero(
                                  numberWithToFixed(
                                    chargeDetailInfo?.individual_charge_detail
                                      ?.amount,
                                  ),
                                )}
                              </li>
                            </ul>
                          </div>
                        </div>
                        {payment_event === 'Change_plan' && (
                          <div className="p-3 mb-2 worningzone  mt-3">
                            <p className="text-semibold text-14">
                              You’re about to{' '}
                              {change_plan &&
                                JSON.parse(change_plan).change_type}{' '}
                              your account
                            </p>
                            <p className="text-14">
                              By choosing to{' '}
                              {change_plan &&
                                JSON.parse(change_plan).change_type}{' '}
                              your account, you will get benefit of {planName}{' '}
                              Plan immediately. The remaining events will not be
                              carried over to the new plan.
                              <br />
                              <br /> Your remaining credit has been converted to
                              additional days for the new plan.{' '}
                              <span className="text-semibold text-14">
                                Your next payment date will be on{' '}
                                {formatDateShortMonth(
                                  chargeDetailInfo?.plan_end_date,
                                )}
                              </span>
                            </p>
                          </div>
                        )}

                        <p className="text-14 mt-3 mb-4">
                          By clicking Pay, I agree to Tag Turbo’s{' '}
                          <span className="text-decoration-underline mx-1">
                            <a
                              target="_blank"
                              className="text-sky"
                              href="https://tagturbo.com/terms/"
                            >
                              Terms and conditions
                            </a>
                          </span>
                          and{' '}
                          <span className="text-decoration-underline mx-1">
                            <a
                              target="_blank"
                              className="text-sky"
                              href="https://tagturbo.com/privacy-notice/"
                            >
                              Privacy Notice.
                            </a>
                          </span>
                        </p>

                        <button
                          type="button"
                          onClick={() => handlePayment()}
                          className="btn btn-primary w-100"
                          disabled={isClick}
                        >
                          Pay {currency === 'thb' ? '฿' : '$'}{' '}
                          {numberWithCommasZero(
                            numberWithToFixed(
                              chargeDetailInfo?.individual_charge_detail
                                ?.amount,
                            ),
                          )}
                        </button>
                      </div>
                    </div>
                  </Payment>
                </div>
              </div>
            </div>

            <Modal
              shouldCloseOnOverlayClick={false}
              closeTimeoutMS={200}
              ariaHideApp={false}
              isOpen={openPopupCheckStatusPayment}
              contentLabel="modal"
              style={customStylesPopupLG}
              onRequestClose={() => setOpenPopupCheckStatusPayment(false)}
              className={'modal-Publish-box animate-5 fadeIn two'}
            >
              <div className="modal-dialog modal-md ">
                <div className="modal-content">
                  <div
                    className={`modal-header ${
                      !isWaiting
                        ? checkStatusPayment
                          ? 'bg-primary'
                          : 'bg-danger'
                        : 'bg-primary'
                    }`}
                  >
                    <div className="p-1 text-white"></div>
                  </div>

                  <div className="modal-body p-4 text-center">
                    {!isWaiting ? (
                      <Fragment>
                        <div className="row p-4">
                          <div>
                            <img
                              src={`/assets/img/${
                                checkStatusPayment ? 'Success' : 'Fail'
                              }.png`}
                              alt={``}
                              height=""
                              width="263"
                            />
                          </div>

                          <h2 className="my-3">
                            <b
                              className={`${
                                checkStatusPayment
                                  ? 'text-primary'
                                  : 'text-danger'
                              }`}
                            >
                              {checkStatusPayment
                                ? 'Payment Successful'
                                : 'Oh no! Payment Failed'}{' '}
                            </b>
                          </h2>
                          <p className="text-secondary my-3 text-semibold text-14">
                            Receipt Number: {receiptId}
                          </p>
                          {checkStatusPayment ? (
                            <p className="text-14">
                              Thank you for choosing Tag Turbo. A copy of your
                              receipt has been sent to your email.
                              <br /> Create your first workspace to make
                              progress towards achieving your goals.
                            </p>
                          ) : (
                            <p className="text-secondary text-14">
                              Sorry, we aren’t able to process your payment.{' '}
                              <br />
                              Please try again or contact our support.
                            </p>
                          )}
                        </div>

                        <div className="row justify-content-center mb-5">
                          {checkStatusPayment ? (
                            <Fragment>
                              {' '}
                              <div className="col-lg-6  text-right">
                                <a
                                  onClick={() =>
                                    (window.location.href = url_subscription)
                                  }
                                  className="btn btn-outline-dark  w-100 text-16"
                                >
                                  <MdHome className="mr-2 text-18" /> View My
                                  Subscription
                                </a>
                              </div>
                            </Fragment>
                          ) : (
                            <div className="col-lg-5 mt-3">
                              <a
                                className="btn btn-danger w-100 text-16"
                                onClick={() =>
                                  setOpenPopupCheckStatusPayment(false)
                                }
                              >
                                Try Again
                              </a>
                            </div>
                          )}
                        </div>
                      </Fragment>
                    ) : (
                      <div className="row p-5">
                        <div>
                          <img
                            src={`/assets/img/loading.gif`}
                            alt={``}
                            width="100"
                          />
                          <p className="p-4">
                            <b className="text-30">Payment Processing...</b>
                          </p>
                          <p className="text-secondary text-14">
                            Your payment is being processed. <br /> Please do
                            not close this page.
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Modal>

            <Modal
              shouldCloseOnOverlayClick={false}
              closeTimeoutMS={200}
              ariaHideApp={false}
              isOpen={openPopupChangeCard}
              contentLabel="modal"
              style={customStylesPopupMD}
              onRequestClose={() => setOpenPopupChangeCard(false)}
              className={'modal-Publish-box animate-5 fadeIn two'}
            >
              <div className="modal-dialog modal-md ">
                <div className="modal-content">
                  <div className={`modal-header bg-primary`}>
                    <div className="p-1 text-white"></div>
                  </div>

                  <div className="modal-body p-4 mt-3">
                    <p className="text-20 text-semibold">Change Credit Card</p>
                    <Payment>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div id="card_id">
                          <label className="form-label text-14 mb-1">
                            Card Number *
                          </label>
                          <div className="input-group mb-2">
                            <span
                              className={`input-group-text border-right-0 ${
                                errors.cardNumber?.type === 'required'
                                  ? 'is-invalid'
                                  : ''
                              }`}
                            >
                              <MdCreditCard />
                            </span>
                            <input
                              className={`form-control form-control-icon border-right-0 border-left-0 ${
                                errors.cardNumber?.type === 'required'
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              {...register('cardNumber', {
                                required: true,
                              })}
                              value={
                                watch()?.cardNumber &&
                                watch()?.cardNumber?.length <= 19
                                  ? maskCard(watch()?.cardNumber)
                                  : ''
                              }
                              onKeyPress={(event) => {
                                if (
                                  !/[0-9]/.test(event.key) ||
                                  event?.target?.value?.length > 18
                                ) {
                                  event.preventDefault()
                                }
                              }}
                            />
                            <span
                              className={`input-group-text border-left-0 ${
                                errors.cardNumber?.type === 'required'
                                  ? 'is-invalid'
                                  : ''
                              }`}
                            >
                              {creditCardType(watch()?.cardNumber) &&
                                watch()?.cardNumber.length <= 19 && (
                                  <img
                                    src={`/assets/img/${
                                      watch()?.cardNumber
                                        ? creditCardType(watch()?.cardNumber)
                                        : ''
                                    }.png`}
                                    alt={`cardType`}
                                    height="20"
                                  />
                                )}
                            </span>
                          </div>
                          {errors.cardNumber?.type === 'required' && (
                            <p role="alert" className="text-danger text-12">
                              Card Number is required
                            </p>
                          )}
                        </div>
                        <p className="text-14 mb-1">Card Holder Name *</p>
                        <input
                          className={`form-control w-100 mb-2 ${
                            errors.cardHolder?.type === 'required'
                              ? 'is-invalid'
                              : ''
                          }`}
                          {...register('cardHolder', {
                            required: true,
                          })}
                          onKeyPress={(event) => {
                            if (specialCharsAndnumber.test(event.key)) {
                              event.preventDefault()
                            }
                          }}
                        />
                        {errors.cardHolder?.type === 'required' && (
                          <p role="alert" className="text-danger text-12">
                            Card Holder is required
                          </p>
                        )}
                        <div className="row">
                          <div className="col-6">
                            <label className="text-14 mb-1">
                              Expiration Date *
                            </label>
                            <input
                              className={`form-control w-100 mb-2 ${
                                errors.expireDate?.type === 'required'
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              {...register('expireDate', {
                                required: true,
                              })}
                              size="5"
                              maxLength="5"
                              value={
                                watch()?.expireDate
                                  ? customInputDate(watch().expireDate)
                                  : ''
                              }
                            />
                            {errors.expireDate?.type === 'required' && (
                              <p role="alert" className="text-danger text-12">
                                Expiration Date is required
                              </p>
                            )}
                          </div>
                          <div className="col-6">
                            <label className="text-14 mb-1">CVV *</label>
                            <input
                              className={`form-control w-100 mb-2 ${
                                errors.cvv?.type === 'required'
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              {...register('cvv', {
                                required: true,
                              })}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault()
                                }
                              }}
                              maxLength={3}
                              type="password"
                            />
                            {errors.cvv?.type === 'required' && (
                              <p role="alert" className="text-danger text-12">
                                CVV is required
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="modal-footer justify-content-right py-4">
                          <div
                            className="btn cursor w-25"
                            onClick={() => setOpenPopupChangeCard(false)}
                          >
                            Cancel
                          </div>
                          <input
                            type="submit"
                            className="btn btn-primary w-25"
                            Save
                          />
                        </div>
                      </form>
                    </Payment>
                  </div>
                </div>
              </div>
            </Modal>

            <Modal
              shouldCloseOnOverlayClick={false}
              closeTimeoutMS={200}
              ariaHideApp={false}
              isOpen={openPopupCheckStatusChangeCard}
              contentLabel="modal"
              style={!isWaiting ? customStylesPopupSM : customStylesPopupLG}
              onRequestClose={() => setOpenPopupCheckStatusChangeCard(false)}
              className={'modal-Publish-box animate-5 fadeIn two'}
            >
              <div className="modal-dialog modal-md ">
                <div className="modal-content">
                  <div
                    className={`modal-header ${
                      !isWaiting
                        ? checkStatusChangeCard
                          ? 'bg-primary'
                          : 'bg-danger'
                        : 'bg-primary'
                    }`}
                  >
                    <div className="p-1 text-white"></div>
                  </div>

                  <div className="modal-body p-4 text-center">
                    {!isWaiting ? (
                      <Fragment>
                        <div className="row p-4">
                          <div>
                            <img
                              src={`/assets/img/${
                                checkStatusChangeCard ? 'correct' : 'cancle'
                              }.svg`}
                              alt={``}
                              height=""
                              width="80"
                            />
                          </div>

                          <h2 className="my-3">
                            <b
                              className={`${
                                checkStatusChangeCard
                                  ? 'text-primary'
                                  : 'text-danger'
                              }`}
                            >
                              {checkStatusChangeCard ? 'Success' : 'Error!'}{' '}
                            </b>
                          </h2>

                          {checkStatusChangeCard ? (
                            <p className="text-14">
                              Your credit card was successfully updated. <br />{' '}
                              The updated card details will be used for the next
                              payment.
                            </p>
                          ) : (
                            <p className="text-secondary text-14">
                              Oops! Something is wrong here.
                              <br /> Your updated card details wasn’t completed.
                            </p>
                          )}
                        </div>

                        <div className="row justify-content-center mb-5">
                          {checkStatusChangeCard ? (
                            <Fragment>
                              {' '}
                              <div className="col-lg-4 ">
                                <a
                                  className="btn btn-primary  w-100 text-16"
                                  onClick={() =>
                                    setOpenPopupCheckStatusChangeCard(false)
                                  }
                                >
                                  OK
                                </a>
                              </div>{' '}
                            </Fragment>
                          ) : (
                            <div className="col-lg-4 mt-3">
                              <a
                                className="btn btn-danger w-100 text-16"
                                onClick={() =>
                                  setOpenPopupCheckStatusChangeCard(false)
                                }
                              >
                                Try Again
                              </a>
                            </div>
                          )}
                        </div>
                      </Fragment>
                    ) : (
                      <div className="row p-5">
                        <div>
                          <img
                            src={`/assets/img/loading.gif`}
                            alt={``}
                            width="100"
                          />
                          <p className="p-4">
                            <b className="text-30">Change Card Processing...</b>
                          </p>
                          <p className="text-secondary text-14">
                            Your payment is being processed. <br /> Please do
                            not close this page.
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Modal>
            <Modal
              shouldCloseOnOverlayClick={false}
              closeTimeoutMS={200}
              ariaHideApp={false}
              isOpen={openPopupCouponNotValid}
              contentLabel="modal"
              style={customStylesXS}
              onRequestClose={() => setOpenPopupCouponNotValid(false)}
              className={'modal-Publish-box animate-5 fadeIn two'}
            >
              <div className="modal-dialog modal-md ">
                <div className="modal-content">
                  <div className="modal-header bg-danger">
                    <div className="p-1 text-white"></div>
                  </div>
                  <div className="modal-body p-4 mt-3">
                    <div className="text-center">
                      <img className="mb-2" src="/assets/img/warning.png" />
                    </div>
                    <p className="text-18 text-semibold text-danger text-center">
                      Discount not valid!
                    </p>
                    <p className="text-14 text-center">
                      Sorry, coupon usage limit has been reached or expired.
                    </p>
                  </div>
                  <div className="modal-footer justify-content-center px-4 pb-5">
                    <div
                      className="btn btn-danger mx-3 cursor"
                      onClick={() => setOpenPopupCouponNotValid(false)}
                    >
                      Back to Check Out
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </div>

          <FooterComponent />
        </div>
      </div>
    </Checkout>
  )
}

export default CheckOutComponent
