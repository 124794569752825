const redirectLogin = () => {
  localStorage.removeItem('token')
  window.location.href = '/login'
}

const redirectPermission = () => {
  window.location.href = '/403'
}
const refreshToken = (token) => {
  localStorage.setItem('token', token)
}

const numberWithCommas = (x) => {
  // x = Number(x);
  // Check if x is a valid number
  if (isNaN(x)) {
    return ''
  }

  // Get the integer part and the decimal part
  const integerPart = Math.floor(x)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  const decimalPart = (x % 1).toFixed(2).slice(2)

  // Check if the decimal part is zero
  const hasDecimalPart = decimalPart !== '00'
  // Combine the formatted integer and decimal parts
  const formattedNumber = hasDecimalPart
    ? `${integerPart}.${decimalPart}`
    : integerPart
  return formattedNumber
}

const parseJwt = (token) => {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(''),
  )

  return JSON.parse(jsonPayload)
}

const countNumberOfDays = (date1Str, date2Str) => {
  // Parse the date strings into Date objects
  const date1 = new Date(date1Str)
  const date2 = new Date(date2Str)

  // Calculate the time difference in milliseconds
  const timeDifference = Math.abs(date2 - date1)

  // Calculate the number of days by dividing milliseconds by milliseconds per day (24 * 60 * 60 * 1000)
  const numberOfDays = Math.ceil(timeDifference / (24 * 60 * 60 * 1000)) + 1

  return numberOfDays + ` day${numberOfDays <= 1 ? '' : 's'}`
}

const timeSince = (date) => {
  let seconds = Math.floor((new Date() - new Date(date)) / 1000)
  let interval = seconds / 31536000
  if (interval >= 1 && interval < 2) {
    return Math.floor(interval) + ' year ago'
  }
  if (interval >= 2) {
    return Math.floor(interval) + ' years ago'
  }

  interval = seconds / 2592000
  if (interval >= 1 && interval < 2) {
    return Math.floor(interval) + ' month ago'
  }
  if (interval >= 2) {
    return Math.floor(interval) + ' months ago'
  }

  interval = seconds / 86400
  if (interval >= 1 && interval < 2) {
    return Math.floor(interval) + ' day ago'
  }
  if (interval >= 2) {
    return Math.floor(interval) + ' days ago'
  }

  interval = seconds / 3600
  if (interval >= 1 && interval < 2) {
    return Math.floor(interval) + ' hour ago'
  }
  if (interval >= 2) {
    return Math.floor(interval) + ' hours ago'
  }

  interval = seconds / 60
  if (Math.floor(interval) < 1) {
    return ' Just Now'
  }
  if (interval >= 1 && interval < 2) {
    return Math.floor(interval) + ' minute ago'
  }
  if (interval >= 2) {
    return Math.floor(interval) + ' minutes ago'
  } else {
    return 'a minute ago'
  }
}

const timeFormatter = (date) => {
  let d = new Date(date)
  let year = d.getFullYear()
  let month = d.getMonth() + 1
  let dt = d.getDate()
  let res =
    (dt < 10 ? '0' + dt : dt) +
    '/' +
    (month < 10 ? '0' + month : month) +
    '/' +
    year
  return res
}
const formatDateShortMonth = (dateStr) => {
  const date = new Date(dateStr)
  const locales = new Date(dateStr).toLocaleString('th-TH')
  const options = { month: 'short', day: 'numeric', year: 'numeric' }
  const formattedDate = date.toLocaleDateString('en-US', options, locales)
  return formattedDate
}

const formatDateShortMonthAndTime = (dateStr) => {
  const date = new Date(dateStr)
  const options = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  }
  const formattedDate = date.toLocaleString('en-US', options)
  return formattedDate
}
const numberWithToFixed = (x) => {
  x = parseFloat(x)
  return x.toFixed(2)
}
const formatEmail = (email) => {
  var resEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
  return resEmail
}

const periodDateByDay = (startDate, days) => {
  // Parse the input date string into a Date object
  const parsedStartDate = new Date(startDate)

  // Calculate the end date by subtracting the specified number of days minus one
  const endDate = new Date(parsedStartDate)
  endDate.setDate(parsedStartDate.getDate() - days)

  // Calculate the start date by subtracting one day from the parsedStartDate
  const startDateFormatted = new Date(parsedStartDate)
  startDateFormatted.setDate(parsedStartDate.getDate() - 1)

  // Format the start and end dates as strings in the "YYYY-MM-DD" format
  const startDateString = startDateFormatted.toISOString().split('T')[0]
  const endDateString = endDate.toISOString().split('T')[0]
  let peroid = []
  peroid.push(endDateString, startDateString)
  return peroid
}
function addCommasToNumber(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
function calculatePercentageChange(oldValue, newValue) {
  const percentageChange = ((newValue - oldValue) / oldValue) * 100
  const formattedPercentageChange = addCommasToNumber(
    percentageChange.toFixed(2),
  )
  return formattedPercentageChange
  // return percentageChange.toFixed(2);
}

function generateTimeWithSeconds(seconds) {
  seconds = parseInt(seconds).toFixed(0)
  if (isNaN(seconds) || seconds < 0) {
    return 'Invalid input'
  }

  const days = Math.floor(seconds / 86400) // 86400 seconds in a day
  seconds -= days * 86400

  const hours = Math.floor(seconds / 3600) // 3600 seconds in an hour
  seconds -= hours * 3600

  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60

  const parts = []

  if (days > 0) {
    parts.push(`${days}d`)
  }

  if (hours > 0) {
    parts.push(`${hours}h`)
  }

  if (minutes > 0) {
    parts.push(`${minutes}m`)
  }

  if (remainingSeconds > 0) {
    parts.push(`${remainingSeconds}s`)
  }

  return parts.join(' ')
}

const changeFormatDate = (inputDate) => {
  const year = inputDate.substring(0, 4)
  const month = inputDate.substring(4, 6)
  const day = inputDate.substring(6)
  const outputDate = `${year}-${month}-${day}`
  return outputDate
}

const sortDate = (dateArray) => {
  // Custom comparator function to compare date strings in 'dd-mm-yyyy' format
  function compareDates(date1, date2) {
    const [day1, month1, year1] = date1.split('-').map(Number)
    const [day2, month2, year2] = date2.split('-').map(Number)

    // Compare years
    if (year1 !== year2) {
      return year1 - year2
    }

    // Compare months
    if (month1 !== month2) {
      return month1 - month2
    }

    // Compare days
    return day1 - day2
  }

  // Use the custom comparator to sort the dateArray
  dateArray.sort(compareDates)

  return dateArray
}

const findMaxValue = (inputArray) => {
  // Initialize max to the first element in the array
  let max = Number(inputArray[0])

  // Loop through the array to find the maximum value
  for (let i = 1; i < inputArray.length; i++) {
    const currentNumber = Number(inputArray[i])
    if (!isNaN(currentNumber) && currentNumber > max) {
      max = currentNumber
    }
  }

  return max
}

const sumArrayOfStrings = (arr) => {
  // Use the reduce method to sum the numbers
  const sum = arr.reduce((accumulator, currentValue) => {
    // Convert the current value (a string) to a number and add it to the accumulator
    return accumulator + parseInt(currentValue)
  }, 0)

  return sum
}

const sumPercent = (arr, state) => {
  const sum = ((arr || 0) / state || 0) * 100

  return sum === 100 ? sum : sum.toFixed(2)
}

export {
  redirectLogin,
  redirectPermission,
  refreshToken,
  numberWithCommas,
  parseJwt,
  timeSince,
  timeFormatter,
  formatDateShortMonth,
  formatDateShortMonthAndTime,
  numberWithToFixed,
  formatEmail,
  countNumberOfDays,
  periodDateByDay,
  calculatePercentageChange,
  generateTimeWithSeconds,
  changeFormatDate,
  sortDate,
  findMaxValue,
  sumArrayOfStrings,
  sumPercent,
}
