import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { ChangePasswordBox } from './style'
import FooterComponent from '../layout/footer'
import MenuProfileComponent from '../layout/menuprofile'
import TopbarNotiComponent from '../layout/topbarNotification'

import { redirectLogin } from '../../helper/helper'
import { FaEye, FaEyeSlash, FaCheck } from 'react-icons/fa'
import TopbarComponent from '../layout/topbar'
import { changePasswordUserProfile } from '../../apis/login/api'
import AlertComponent from '../layout/alert'

const ChangePasswordComponent = () => {
  const { accountId } = useParams()
  const { id } = useParams()

  const [showNewPassword, setShowNewPassword] = useState(true)
  const [showCfPassword, setShowCfPassword] = useState(true)
  const [showCurrentPassword, setShowCurrentPassword] = useState(true)
  const [currentPass, setCurrentPass] = useState('')

  const [newPassword, setNewPassword] = useState('')
  const [cfPassword, setCfPassword] = useState('')

  const [validateCurrentPass, setValidateCurrentPass] = useState({
    msg: 'updated successfully.',
    status: true,
  })
  const [validateNewPassword, setValidateNewPassword] = useState({
    msg: 'updated successfully.',
    status: true,
  })
  const [validateCfPassword, setValidateCfPassword] = useState({
    msg: 'updated successfully.',
    status: true,
  })

  const [checkedLetter, setCheckedLetter] = useState(false)
  const [checkedCapital, setCheckedCapital] = useState(false)
  const [checkedNumber, setCheckedNumber] = useState(false)
  const [checkedSpecial, setCheckedSpecial] = useState(false)
  const [checkedLength, setCheckedLength] = useState(false)
  const [accessToken, setAccessToken] = useState('')

  const [verifyToken, setVerifyToken] = useState('')
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(0)

  const [passwordsconditionbox, setPasswordsconditionbox] = useState({
    no: 0,
    isOpen: false,
  })
  var round = 0

  const [showAlert, setShowAlert] = useState({
    msg: 'Account Role has been updated successfully.',
    status: true,
    isShow: false,
  })

  const handleAlert = (msg, status) => {
    setShowAlert({
      msg,
      status,
      isShow: true,
    })

    setTimeout(() => {
      setShowAlert({
        msg,
        status,
        isShow: false,
      })
    }, 10000)
  }

  useEffect(() => {
    document.title = 'Change Password | Tag Turbo'
    const token = localStorage.getItem('token')
    document.addEventListener('click', handleClickOutside, true)
    if (token) {
      setAccessToken(token)
      refreshRecaptcha()
    } else {
      redirectLogin()
    }
  }, [])

  useEffect(() => {
    setVerifyToken(verifyToken)
  }, [verifyToken])

  const keepTokenFn = (token) => {
    setVerifyToken(token)
  }

  const refreshRecaptcha = () => {
    const interval = setInterval(async () => {
      round = round === 0 ? 1 : 0
      setRefreshReCaptcha(round)
    }, 60000)
    return () => clearInterval(interval)
  }

  const handleClickOutside = (event) => {
    setPasswordsconditionbox({
      no: 0,
      isOpen: false,
    })
  }

  const handlePasswordsconditionbox = (no) => {
    let obj = {}
    if (passwordsconditionbox.no === no) {
      obj = {
        no: no,
        isOpen: !passwordsconditionbox.isOpen,
      }
    } else {
      obj = {
        no: no,
        isOpen: true,
      }
    }
    setPasswordsconditionbox(obj)
  }

  const checkPassword = (e) => {
    setPasswordsconditionbox({
      no: 1,
      isOpen: true,
    })

    setNewPassword(e.target.value)
    if (e.target.value) {
      setValidateNewPassword(true)
    }
    const lowerCaseLetters = /[a-z]/g
    if (e.target.value.match(lowerCaseLetters)) {
      setCheckedLetter(true)
    } else {
      setCheckedLetter(false)
    }

    const upperCaseLetters = /[A-Z]/g
    if (e.target.value.match(upperCaseLetters)) {
      setCheckedCapital(true)
    } else {
      setCheckedCapital(false)
    }

    // Validate numbers
    const numbers = /[0-9]/g
    if (e.target.value.match(numbers)) {
      setCheckedNumber(true)
    } else {
      setCheckedNumber(false)
    }

    const specials = /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi
    if (e.target.value.match(specials)) {
      setCheckedSpecial(true)
    } else {
      setCheckedSpecial(false)
    }

    // Validate length
    if (e.target.value.length >= 8) {
      setCheckedLength(true)
    } else {
      setCheckedLength(false)
    }
  }

  const onSubmit = async () => {
    if (currentPass) {
      handleCurrentPass('', true)
    } else {
      handleCurrentPass('Current password is required.', false)
    }
    if (newPassword) {
      handleNewPassword('', true)
    } else {
      handleNewPassword('New password is required.', false)
    }
    if (cfPassword) {
      handleCfPassword('', true)
    } else {
      handleCfPassword('Confirm password is required.', false)
    }

    if ((currentPass && newPassword) || cfPassword) {
      if (
        checkedLetter &&
        checkedCapital &&
        checkedNumber &&
        checkedSpecial &&
        checkedLength
      ) {
        handleNewPassword('', true)
        if (newPassword !== cfPassword) {
          if (cfPassword) {
            handleCfPassword('Password is not match. Please try again.', false)
          } else {
            handleCfPassword('', true)
          }
        } else {
          if (!cfPassword) {
          } else {
            let obj = {
              current_password: currentPass,
              new_password: newPassword,
            }
            const { data, status } = await changePasswordUserProfile(
              accessToken,
              obj,
            )
            if (data && status === 200) {
              handleAlert('Your password has been updated successfully.', true)
              handleCurrentPass('', true)
              handleNewPassword('', true)
              handleCfPassword('', true)
            } else {
              handleAlert('The password is incorrect. Please try again.', false)
            }
          }
        }
      } else {
        handleNewPassword('Password is on the wrong format.', false)
        console.log('error')
      }
    }
  }
  const handleCurrentPass = (msg, status) => {
    setValidateCurrentPass({ msg, status })
  }
  const handleNewPassword = (msg, status) => {
    setValidateNewPassword({ msg, status })
  }
  const handleCfPassword = (msg, status) => {
    setValidateCfPassword({ msg, status })
  }

  return (
    <ChangePasswordBox>
      <div className="page pull-page-bg-secondary ">
        <div className="content-box">
          <TopbarComponent isPublish={false} />
          <TopbarNotiComponent />

          <MenuProfileComponent accountId={accountId} id={id} />
          {showAlert.isShow && (
            <AlertComponent
              messageAlert={showAlert.msg}
              status={showAlert.status}
              alertType={showAlert.status ? 'alert-success' : 'alert-error'}
            />
          )}
          <div className="content-menu content-scroll ">
            <div className="container-md animate fadeIn one p-4 min-h-82">
              <div className="content">
                <div className="d-flex justify-content-between item-center mb-4">
                  <div className="col-6">
                    <h4 className="m-0 text-20 text-semibold">Profile</h4>
                  </div>
                </div>

                <div className="card p-2 border box-shadow-center bg-white mt-2">
                  <div className="card-body">
                    <p className="text-18 text-semibold">Password</p>
                    <div className="w-25 p-1">
                      <div className="mb-3">
                        <label className="form-label text-14">
                          Current Password
                        </label>
                        <div className="input-group mb-3">
                          <input
                            id="currentPass"
                            type={showCurrentPassword ? 'password' : ''}
                            className={`form-control border-right-0 ${
                              !validateCurrentPass.status
                                ? 'invalidate-box'
                                : ''
                            }`}
                            value={currentPass}
                            defaultValue={currentPass}
                            onChange={(e) => (
                              setCurrentPass(e.target.value),
                              handleCurrentPass('', true)
                            )}
                          />
                          <span
                            className={`input-group-text border-left-0 bg-transparent ${
                              !validateCurrentPass.status
                                ? 'invalidate-box'
                                : ''
                            }`}
                            id="basic-addon2"
                          >
                            <div
                              className="cursor"
                              onClick={() =>
                                setShowCurrentPassword(!showCurrentPassword)
                              }
                            >
                              {showCurrentPassword ? (
                                <FaEye className="text-lighter" />
                              ) : (
                                <FaEyeSlash className="text-lighter" />
                              )}{' '}
                            </div>
                          </span>
                        </div>

                        {!validateCurrentPass.status && (
                          <label className="form-label invalidate-text text-14">
                            {validateCurrentPass.msg}
                          </label>
                        )}
                      </div>

                      <div>
                        <label className="form-label  text-14">
                          Enter New Password
                        </label>
                        <label id="newpassword" className="form-label"></label>
                        <div className="input-group mb-3">
                          <input
                            id="newpassword"
                            type={showNewPassword ? 'password' : ''}
                            className={`form-control border-right-0 ${
                              !validateNewPassword.status
                                ? 'invalidate-box'
                                : ''
                            }`}
                            value={newPassword}
                            defaultValue={newPassword}
                            onChange={(e) => (
                              checkPassword(e), handleNewPassword('', true)
                            )}
                            onClick={(e) => handlePasswordsconditionbox(1)}
                          />
                          <span
                            className={`input-group-text border-left-0 bg-transparent ${
                              !validateNewPassword.status
                                ? 'invalidate-box'
                                : ''
                            }`}
                            id="basic-addon2"
                          >
                            <div
                              className="cursor"
                              onClick={() =>
                                setShowNewPassword(!showNewPassword)
                              }
                            >
                              {showNewPassword ? (
                                <FaEye className="text-lighter" />
                              ) : (
                                <FaEyeSlash className="text-lighter" />
                              )}{' '}
                            </div>
                          </span>
                        </div>
                        {!validateNewPassword.status && (
                          <label className="form-label invalidate-text  text-14">
                            {validateNewPassword.msg}
                          </label>
                        )}
                        {passwordsconditionbox.no === 1 &&
                          passwordsconditionbox.isOpen && (
                            <div className="passwords-condition-box animate fadeIn one ">
                              <p className="text-12 m-0">
                                Passwords must contain :
                              </p>

                              <p
                                id="pass-length"
                                className={`invalid mb-1 text-12 ${
                                  checkedLength ? 'text-success' : 'invalid'
                                }`}
                              >
                                <FaCheck /> a minimum of 8 characters
                              </p>
                              <p
                                id="pass-lower"
                                className={`invalid mb-1 text-12 ${
                                  checkedLetter ? 'text-success' : 'invalid'
                                }`}
                              >
                                <FaCheck /> a minimum of 1 lowercase letter
                                [a-z]
                              </p>
                              <p
                                id="pass-upper"
                                className={`invalid mb-1 text-12 ${
                                  checkedCapital ? 'text-success' : 'invalid'
                                }`}
                              >
                                <FaCheck /> a minimum of 1 uppercase letter
                                [A-Z]
                              </p>
                              <p
                                id="pass-number"
                                className={`invalid mb-1 text-12 ${
                                  checkedNumber ? 'text-success' : 'invalid'
                                }`}
                              >
                                <FaCheck /> a minimum of 1 numeric character
                                [0-9]
                              </p>
                              <p
                                id="pass-special"
                                className={`invalid mb-1 text-12 ${
                                  checkedSpecial ? 'text-success' : 'invalid'
                                }`}
                              >
                                <FaCheck /> a minimum of 1 special character:
                                ~`!@#$%^&amp;*()- _+={}[]|\;:&quot;&lt;&gt;,./?{' '}
                              </p>
                            </div>
                          )}
                      </div>

                      <div className="mb-3">
                        <label className="form-label text-14">
                          Confirm Password
                        </label>
                        <label id="cfpassword" className="form-label"></label>
                        <div className="input-group mb-3">
                          <input
                            id="cfpassword"
                            type={showCfPassword ? 'password' : ''}
                            className={`form-control border-right-0 ${
                              !validateCfPassword.status ? 'invalidate-box' : ''
                            }`}
                            value={cfPassword}
                            defaultValue={cfPassword}
                            onChange={(e) => (
                              setCfPassword(e.target.value),
                              handleCfPassword('', true)
                            )}
                          />
                          <span
                            className={`input-group-text border-left-0 bg-transparent ${
                              !validateCfPassword.status ? 'invalidate-box' : ''
                            }`}
                            id="basic-addon2"
                          >
                            <div
                              className="cursor"
                              onClick={() => setShowCfPassword(!showCfPassword)}
                            >
                              {showCfPassword ? (
                                <FaEye className="text-lighter" />
                              ) : (
                                <FaEyeSlash className="text-lighter" />
                              )}
                            </div>
                          </span>
                        </div>
                        {!validateCfPassword.status && (
                          <label className="form-label invalidate-text text-14">
                            {validateCfPassword.msg}
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pt-4">
                  <button
                    type="button"
                    onClick={() => onSubmit()}
                    className="btn btn-primary px-5"
                  >
                    Save
                  </button>
                </div>
              </div>

              <div className="my-3">
                <FooterComponent />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ChangePasswordBox>
  )
}

export default ChangePasswordComponent
