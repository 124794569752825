import styled from 'styled-components'

const ImportBox = styled.div`
  .file-name {
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 25px !important;
  }
  .box-card {
    height: 406px;
    display: grid;
  }
  .dropdown-menu-top {
    top: 64px;
    z-index: 9;
    left: 224px;
    position: absolute;
  }
  tbody,
  td,
  tfoot,
  th,
  thead,
  tr {
    padding: 12px 20px;
  }
  .content-menu {
    width: 100%;
    padding-left: 256px;
  }
  .content-box {
    height: 100%;
  }
  .content-scroll {
    // margin-top: 100px;
    height: 87vh;
    overflow-y: auto;
  }
  .-pointer {
    cursor: pointer;
  }
  .btn {
    margin: 0.125em;
  }
  .boxSetting {
    right: 1%;
    width: 200px;
    text-align: left;
    border: 1px solid #0000000f;
    box-shadow: 6px 7px 20px 0 rgb(0 0 0 / 11%);
  }
  .dropdown-item-setting {
    cursor: pointer;
    padding: 10px;
  }
  .dropdown-item-setting:hover {
    background: #c1c1c129;
  }
  .fixed-icon {
    position: relative;
    z-index: -3;
  }
  .badge {
    margin-left: 4px;
    text-align: center;
    text-transform: capitalize;
    border-radius: 10px;
    // height: 19px;
  }
  .badge-default {
    background-color: #f2f4f7;
    color: #344054;
  }
  .badge-green {
    background-color: #ecfdf3;
    color: #037a48;
  }
  .badge-grey {
    background-color: #d0d5dd;
    color: #667085;
  }
  .badge-light-blue {
    background-color: #eaf0ff;
    color: #0047ff;
  }
  .badge-blue {
    background-color: #0047ff;
    color: #fff;
  }
  .badge-primary {
    background-color: #eaf0ff;
    color: #0047ff;
  }
  .badge-yellow {
    background-color: #fec84b;
    color: #344054;
  }
  .badge-white {
    border: solid 1px #d0d5dd;
    background-color: #fff;
    color: #344054;
  }
  .table {
    svg {
      color: #98a2b3;
      cursor: pointer;
    }
  }

  .close:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit;
  }
  button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
  }
  .close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
  }
  .alert-box {
    position: absolute;
    width: 30%;
    z-index: 999999;
    padding: 0px;
    top: 45px;
    right: 20px;
  }
  .input-group-text {
    padding: 10px 0.75rem;
    line-height: 1.5;
    background-color: #fff;
    display: flex;
    align-items: center;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #ced4da;
    border-right: 0;
  }
  .boxsearch {
    right: 16%;
    width: 32%;
    text-align: left;
    border: 1px solid #0000000f;
    box-shadow: 6px 7px 20px 0 rgb(0 0 0 / 11%);
    z-index: 9;
  }
  .text-gray {
    color: #d0d5dd;
  }

  .warningzone {
    background-color: #fff0c6;
    border: 1px solid #eaaa08;
    border-radius: 8px;
    color: #eaaa08;
  }
  .dropdown-menu-top {
    top: 64px;
    z-index: 9;
    left: 224px;
    position: absolute;
  }
  .dropdown-filter {
    top: 64px;
    z-index: 9;
    right: 22px;
    position: absolute;
  }
  .account-hover:hover {
    background-color: #eaf0ff;
  }
  .body-drop-menu {
    min-height: 400px;
    height: 400px;
    overflow-y: auto;
  }
  .btn-outline {
    border-radius: 5px;
    padding: 5px 20px;
    background: none;
    font-size: 14px;
  }
  .btn-blue {
    color: #fff;
    background-color: #0047ff;
  }
  .clear-btn {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
    color: #667085;
  }
  .fixed-select {
    margin-top: -5px;
  }

  .collapsible {
    background-color: #777;
    color: white;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
  }

  .active,
  .collapsible:hover {
    background-color: #555;
  }

  // .content {
  //   padding: 0 18px;
  //   display: none;
  //   overflow: hidden;
  //   background-color: #f1f1f1;
  // }
  .header-collapse {
    height: 52px;
    padding: 11px 40px 11px 38px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 10px;
    background: #eaf0ff;
    border: 1px solid #eaecf0;
  }
  .arrowUp {
    transform: rotate(180deg);
  }
  .card-body {
    padding-bottom: 0px;
  }
  .non-bd-bt {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  #example-collapse-text {
    border: 1px solid #eaecf0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-bottom: 6px;
  }
  .custom-file-input {
    display: inline-block;
    position: relative;
    cursor: pointer;
    color: #333;
    padding: 10px 15px;
    border-radius: 5px;
    overflow: hidden;
    border-radius: 5px;
    border: 1px solid #0047ff;
    background: #fff;
    color: #0047ff;
    width: 115px;
    height: 44px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
  }
  .custom-file-input:hover {
    background: #0047ff;
    color: #fff;
  }

  /* Style the "Choose File" button text */
  .custom-file-label {
    font-weight: bold;
  }

  /* Style the file input itself (hidden) */
  .custom-file-input input[type='file'] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
  }
  .bg-white {
    background: #fff !important;
  }
  .bg-white::placeholder {
    color: #d0d5dd !important;
  }
  .icon-remove {
    position: absolute;
    left: 272px;
    padding-top: 12px;
    width: 30px;
    height: 31px;
    color: #475467;
  }
  .loading-mini {
    width: 15px !important;
  }
`

export { ImportBox }
