import React, { Fragment, useState, useEffect } from 'react'
import { BsThreeDotsVertical } from '@react-icons/all-files/bs/BsThreeDotsVertical'
import Modal from 'react-modal'
import { useParams } from 'react-router-dom'
import TopbarNotiComponent from '../layout/topbarNotification'

import { WorkspaceUsersBox, RadioBox, ModalBox, InviteBox } from './style'
import FooterComponent from '../layout/footer'
import TopbarSettingsComponent from '../layout/topbarworkspacesettings'
import MenuSettingComponent from '../layout/menusetting'
import { MdClear } from 'react-icons/md'
import {
  roleByAccountId,
  roleByWorkspaceId,
  checkLimitAccountUser,
} from '../../apis/account/api'
import {
  workspaceUserList,
  editRoleWorkspace,
  inviteWorkspaceUser,
  roleWorkspaceByEmail,
  deleteUserInWorkspace,
} from '../../apis/workspace/api'
import { MdPersonAddAlt } from 'react-icons/md'
import AlertComponent from '../layout/alert'
import DataTable from 'react-data-table-component'
import Dropdown from 'react-bootstrap/Dropdown'
import LoadingComponent from '../layout/loading'
import TopbarFreeNotiComponent from '../layout/topbarFreeNotification'
import { planDetail } from '../../apis/plan/api'

const WorkspaceUsersComponent = () => {
  const [isClickInvite, setIsClickInvite] = useState(false)
  const [accountDetail, setAccountDetail] = useState('')
  const [planInfo, setPlanInfo] = useState('')
  const [openPopupInviteWorkspace, setOpenPopupInviteWorkspace] =
    useState(false)
  const [openPopupEditWorkspaceRole, setOpenPopupEditWorkspaceRole] =
    useState(false)
  const [openPopupConfirmDelete, setOpenPopupConfirmDelete] = useState(false)
  const [workspaceRole, setWorkspaceRole] = useState('Admin')
  const [inviteWorkspaceRole, setInviteWorkspaceRole] = useState('Admin')
  const [roleWorkspaceList, setRoleWorkspaceList] = useState([])
  const [workspaceDetail, setWorkspaceDetail] = useState('')
  const [workspaceName, setWorkspaceName] = useState('')

  const [showAlert, setShowAlert] = useState({
    msg: 'Account Role has been updated successfully.',
    status: true,
    isShow: false,
  })
  const { accountId } = useParams()
  const { workspaceId } = useParams()
  const [activeWorkspaceRole, setActiveWorkspaceRole] = useState('')
  const [activeAccountRole, setActiveAccountRole] = useState('')
  const [isLoadingPage, setIsLoadingPage] = useState(true)

  const [workspaceEmail, setWorkspaceEmail] = useState([])

  const [openPopupInviteLimit, setOpenPopupInviteLimit] = useState(false)

  const [emailInviteList, setEmailInviteList] = useState([])

  const [checkedEmailList, setCheckedEmailList] = useState([])

  const [openPopupFormatEmail, setOpenPopupFormatEmail] = useState(false)
  const [emailInvalidList, setEmailInvalidList] = useState([])

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      inset: '50% auto auto 59%',
      position: 'absolute',
      height: '100vh',
      width: '82%',
    },
  }

  const customStylesSM = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '432px',
    },
  }
  const accessToken = localStorage.getItem('token')

  useEffect(() => {
    document.title = 'Workspace Users | Tag Turbo'
    setIsLoadingPage(true)
    const accessToken = localStorage.getItem('token')
    getWorkspaceusersList(accessToken, accountId, workspaceId)
    getRoleByAccountId()
    getRoleByWorkspaceId()
  }, [])

  const upgradePlan = () => {
    if (accountId && workspaceId) {
      window.location.href = `/package/${accountId}`
    } else {
      console.log('Upgrade Plan is false')
    }
  }
  const getPlanInfo = async () => {
    const { data, status } = await planDetail(accessToken, accountId)
    if (status === 200) {
      setPlanInfo(data.plan_of_account_detail)
    }
  }
  const getWorkspaceusersList = async (token, accountId, workspaceId) => {
    const { data, status } = await workspaceUserList(
      token,
      accountId,
      workspaceId,
    )
    let res = []
    if (data && data.data && data.data.length > 0 && status === 200) {
      setIsLoadingPage(false)
      let _tempData = {}
      for (let i = 0; i < data.data.length; i++) {
        _tempData = {
          email: data.data[i].user_email,

          workspaceRole: <span>{data.data[i].role}</span>,
          status: (
            <span
              className={`badge badge-${
                data.data[i].status === 'active' ? 'green' : 'yellow'
              }`}
            >
              {data.data[i].status}
            </span>
          ),
          option: (
            <Fragment>
              <Dropdown>
                {data.data[i].is_edit && (
                  <Dropdown.Toggle className="dropdown-dot">
                    <BsThreeDotsVertical />
                  </Dropdown.Toggle>
                )}

                <Dropdown.Menu className="position-fixed boxSetting-dot">
                  <Dropdown.Item
                    className="dropdown-item-setting-dot text-14"
                    onClick={() => editRole(data.data[i])}
                  >
                    Edit Role
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="dropdown-item-setting-dot text-14"
                    onClick={() => {
                      setOpenPopupConfirmDelete(true)
                      setWorkspaceEmail(data.data[i].user_email)
                    }}
                  >
                    Delete User
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Fragment>
          ),
        }
        res.push(_tempData)
      }
      setRoleWorkspaceList(res)
    } else {
      setIsLoadingPage(false)
    }
  }
  const getRoleByWorkspaceId = async () => {
    const { data, status } = await roleByWorkspaceId(accessToken, workspaceId)
    if (status === 200) {
      setActiveWorkspaceRole(data.data.role)
    }
  }
  const getRoleByAccountId = async () => {
    const { data, status } = await roleByAccountId(accessToken, accountId)
    if (status === 200) {
      setActiveAccountRole(data.data.role)
      if (data.data.role !== 'None') {
        getPlanInfo()
      }
    }
  }
  const editRole = async (workspace) => {
    setOpenPopupEditWorkspaceRole(true)
    getRoleWorkspaceByEmail(workspace.user_email)
    setWorkspaceDetail(workspace)
  }

  const saveEditRole = async () => {
    let obj = {
      email: workspaceDetail.user_email,
      role: workspaceRole,
    }
    const { data, status } = await editRoleWorkspace(
      accessToken,
      accountId,
      workspaceId,
      obj,
    )
    if (status === 200) {
      getWorkspaceusersList(accessToken, accountId, workspaceId)
      setOpenPopupEditWorkspaceRole(false)
      handleAlert('Workspace user role has been updated successfully', true)
    } else {
      handleAlert('Failed to update workspace Role. Please try again.', false)
    }
  }

  const getRoleWorkspaceByEmail = async (email) => {
    const { data, status } = await roleWorkspaceByEmail(
      accessToken,
      accountId,
      workspaceId,
      email,
    )
    if (status === 200) {
      setWorkspaceRole(data.data.role)
      setWorkspaceName(data.data.name)
    }
  }

  const inviteUserWorkspace = async () => {
    let obj = {
      email_invite_list: checkedEmailList,
      role: inviteWorkspaceRole,
    }

    const { data, status } = await inviteWorkspaceUser(
      accessToken,
      accountId,
      workspaceId,
      obj,
    )
    setIsClickInvite(true)

    if (status === 200) {
      getWorkspaceusersList(accessToken, accountId, workspaceId)
      setOpenPopupInviteWorkspace(false)
      handleAlert('Invitation sent.', true)
      setIsClickInvite(false)
    } else if (status === 400) {
      if (data?.response?.data?.error?.code === 405) {
        setOpenPopupInviteLimit(true)
        setIsClickInvite(false)
      } else {
        if (data.response.data.message === 'some email is invalid') {
          setOpenPopupFormatEmail(true)
        } else {
          handleAlert(
            'Failed to update Workspace Role. Please try again.',
            false,
          )
        }
        setIsClickInvite(false)
      }
    } else {
      handleAlert('Failed to update Workspace Role. Please try again.', false)
      setIsClickInvite(false)
    }
  }
  const handleInviteWorkspace = () => {
    popupInviteWorkspace()
    setIsClickInvite(false)
  }

  const handleInviteWorkspaceRole = (e) => {
    setInviteWorkspaceRole(e.target.value)
  }
  const handleWorkspaceRole = (e) => {
    setWorkspaceRole(e.target.value)
  }
  const deleteWorkspaceUser = async (workspaceEmail) => {
    setOpenPopupConfirmDelete(false)
    const res = await deleteUserInWorkspace(
      accessToken,
      accountId,
      workspaceId,
      workspaceEmail,
    )
    if (res.status === 200) {
      handleAlert('Workspace role has been deleted successfully.', true)
    } else {
      handleAlert('Failed to delete Workspace role. Please try again.', false)
    }
    await getWorkspaceusersList(accessToken, accountId, workspaceId)
  }
  const handleAlert = (msg, status) => {
    setShowAlert({
      msg,
      status,
      isShow: true,
    })

    setTimeout(() => {
      setShowAlert({
        msg,
        status,
        isShow: false,
      })
    }, 5000)
  }
  const columns = [
    {
      name: 'Email',
      selector: (row) => row.email,
      sortable: true,
      width: '40%',
    },
    {
      name: 'Workspace Role',
      selector: (row) => row.workspaceRole,
      sortable: false,
      width: '25%',
    },
    {
      name: 'Status',
      selector: (row) => row.status,
      sortable: false,
      width: '25%',
    },
    {
      name: '',
      selector: (row) => row.option,
      sortable: false,
    },
  ]

  const popupInviteWorkspace = async () => {
    const { data, status } = await checkLimitAccountUser(accessToken, accountId)
    if (accountId && status === 200) {
      setOpenPopupInviteWorkspace(true)
      setEmailInviteList([])
      setInviteWorkspaceRole('')
      setCheckedEmailList([])
    } else if (status === 400) {
      if (data?.response?.data?.error?.code === 405) {
        setOpenPopupInviteLimit(true)
      }
    }
  }

  const enterEmail = (e) => {
    let temp = emailInviteList

    if (e.key === 'Enter') {
      const formatEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/g

      if (e.target.value.match(formatEmail)) {
        temp.push({
          email: e.target.value,
          status: true,
        })
        setEmailInviteList(temp.map((item) => item))
        e.target.value = ''
      } else {
        temp.push({
          email: e.target.value,
          status: false,
        })
        setEmailInviteList(temp.map((item) => item))
        e.target.value = ''
      }
      let emailInvalid = emailInviteList.filter((item) => item.status === false)
      setEmailInvalidList(emailInvalid)
      setCheckedEmailList(temp.map((item) => item.email))
    }
  }

  const removeEmail = (index) => {
    let list = emailInviteList

    if (index > -1) {
      list.splice(index, 1)
      setCheckedEmailList(list.map((item) => item.email))
      let invite = list.map((item) => item)
      setEmailInviteList(invite)
      let emailInvalid = invite.filter((item) => item.status === false)
      setEmailInvalidList(emailInvalid)
    }
  }
  return (
    <WorkspaceUsersBox>
      <div className="page pull-page-bg-secondary ">
        <div className="content-box">
          <TopbarSettingsComponent accountDetailFn={setAccountDetail} />
          {isLoadingPage ? (
            <LoadingComponent />
          ) : (
            <Fragment>
              <TopbarFreeNotiComponent planInfo={planInfo} />
              <TopbarNotiComponent />

              <MenuSettingComponent
                accountId={accountId}
                id={workspaceId}
                activeWorkspaceRole={activeWorkspaceRole}
                activeAccountRole={activeAccountRole}
              />
              {showAlert.isShow && (
                <AlertComponent
                  messageAlert={showAlert.msg}
                  status={showAlert.status}
                  alertType={showAlert.status ? 'alert-success' : 'alert-error'}
                />
              )}
              <div className="content-menu content-scroll ">
                <div className="container-md animate fadeIn one p-4 min-h-82">
                  <div className="content">
                    <div className="d-flex justify-content-between item-center mb-4">
                      <div className="col-6">
                        <h4 className="m-0 text-20 text-semibold">
                          Workspace Users{' '}
                        </h4>
                      </div>

                      <div className="col-auto text-right">
                        <button
                          type="button"
                          className="btn badge-blue text-14 text-semibold"
                          onClick={() => handleInviteWorkspace()}
                        >
                          Invite User
                        </button>
                      </div>
                    </div>
                    <div className="border rounded bg-white mt-2 box-shadow-center">
                      <div className="item-center table-border-radius">
                        <Fragment>
                          {roleWorkspaceList ? (
                            roleWorkspaceList.length > 0 ? (
                              <DataTable
                                pagination={true}
                                className="-fadeIn mt-0 none-overflow"
                                center={true}
                                columns={columns}
                                data={roleWorkspaceList}
                                // customStyles={tableStyles}
                              />
                            ) : (
                              <Fragment></Fragment>
                            )
                          ) : (
                            <Fragment></Fragment>
                          )}
                        </Fragment>
                      </div>
                    </div>
                  </div>
                  <div className="my-3">
                    <FooterComponent />
                  </div>
                </div>
              </div>
            </Fragment>
          )}

          {/* Popup Invite user  */}
          <Modal
            shouldCloseOnOverlayClick={false}
            closeTimeoutMS={200}
            ariaHideApp={false}
            isOpen={openPopupInviteWorkspace}
            contentLabel="modal"
            style={customStyles}
            onRequestClose={() => setOpenPopupInviteWorkspace(false)}
            className={`modal-box animate  one ${
              openPopupInviteWorkspace ? 'fadeInRight' : 'fadeOutRight'
            }`}
          >
            <div className="modal-dialog modal-md p-3">
              <div className="modal-content p-1">
                <div className="modal-header">
                  <div>
                    <button
                      type="button"
                      className="btn-close"
                      aria-label="Close"
                      onClick={() => setOpenPopupInviteWorkspace(false)}
                    ></button>
                    <span className="mx-3 text-20 text-semibold">
                      Invite people to join your workspace
                    </span>
                  </div>
                </div>
                <div className="modal-body border p-3 mt-3 rounded">
                  <p className="text-16 text-semibold">Enter the email</p>
                  <MdPersonAddAlt className="icon-addon-input text-18" />
                  <div className="inputBubble">
                    {emailInviteList.map((list, index) => {
                      return (
                        <InviteBox className="max-height30">
                          <Fragment>
                            <span
                              key={index}
                              className={`label label-info ${
                                list.status ? 'tag' : 'tag-danger'
                              }`}
                            >
                              <span>{list.email} </span>
                              <MdClear
                                className="-cursor"
                                onClick={() => removeEmail(index)}
                              />
                            </span>
                          </Fragment>
                        </InviteBox>
                      )
                    })}
                    <input
                      className="input-invite w-100"
                      onKeyPress={(e) => enterEmail(e)}
                    />
                  </div>
                  <p className="text-14 my-3">
                    Enter their email addresses in the field above (press enter
                    to add more than one).
                  </p>
                </div>
                <div className="modal-body border p-3 mt-3 rounded">
                  <p className="text-18 text-semibold">Workspace Role</p>
                  <RadioBox>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="WorkspaceRole1"
                        id="WorkspaceRole1"
                        value="Admin"
                        onClick={handleInviteWorkspaceRole}
                        checked={inviteWorkspaceRole === 'Admin'}
                      />
                      <label className="form-check-label mx-3">
                        <p className="text-14 text-semibold">
                          Admin <br />
                          <span className="text-14 text-regular">
                            Full control over the workspace and can manage
                            workspace users.
                          </span>
                        </p>
                      </label>
                    </div>

                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="WorkspaceRole2"
                        id="WorkspaceRole2"
                        value="Publish"
                        onClick={handleInviteWorkspaceRole}
                        checked={inviteWorkspaceRole === 'Publish'}
                      />
                      <label className="form-check-label mx-3">
                        <p className="text-14 text-semibold">
                          Publisher <br />
                          <span className="text-14 text-regular">
                            Can view, modify, and publish tags in the workspace.
                          </span>
                        </p>
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="WorkspaceRole3"
                        id="WorkspaceRole3"
                        value="Editor"
                        onClick={handleInviteWorkspaceRole}
                        checked={inviteWorkspaceRole === 'Editor'}
                      />
                      <label className="form-check-label mx-3">
                        <p className="text-14 text-semibold">
                          Editor <br />
                          <span className="text-14 text-regular">
                            Can view and modify tags in the workspace.
                          </span>
                        </p>
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="WorkspaceRole4"
                        id="WorkspaceRole4"
                        value="Viewer"
                        onClick={handleInviteWorkspaceRole}
                        checked={inviteWorkspaceRole === 'Viewer'}
                      />
                      <label className="form-check-label mx-3">
                        <p className="text-14 text-semibold">
                          Viewer <br />
                          <span className="text-14 text-regular">
                            Can view all tags in the workspace.
                          </span>
                        </p>
                      </label>
                    </div>
                  </RadioBox>
                </div>

                <div className="modal-footer justify-content-start">
                  <button
                    type="button"
                    className="btn w-lg btn-primary waves-effect waves-light mt-3"
                    onClick={() => inviteUserWorkspace()}
                    disabled={isClickInvite}
                  >
                    Invite
                  </button>
                </div>
              </div>
            </div>
          </Modal>

          {/* Popup edit */}
          <Modal
            shouldCloseOnOverlayClick={false}
            closeTimeoutMS={200}
            ariaHideApp={false}
            isOpen={openPopupEditWorkspaceRole}
            contentLabel="modal"
            style={customStyles}
            onRequestClose={() => setOpenPopupEditWorkspaceRole(false)}
            className={`modal-box animate  one ${
              openPopupEditWorkspaceRole ? 'fadeInRight' : 'fadeOutRight'
            }`}
          >
            <div className="modal-dialog modal-md p-3">
              <div className="modal-content p-1">
                <div className="modal-header">
                  <div>
                    <button
                      type="button"
                      className="btn-close"
                      aria-label="Close"
                      onClick={() => setOpenPopupEditWorkspaceRole(false)}
                    ></button>
                    <span className="mx-3 text-20 text-semibold">
                      {workspaceDetail && workspaceDetail.user_email} |{' '}
                      {workspaceName}
                    </span>
                  </div>
                  <button
                    type="button"
                    className="btn w-lg btn-primary waves-effect waves-light"
                    onClick={() => saveEditRole()}
                    // onClick={() => setOpenPopupEditWorkspaceRole(false)}
                  >
                    Save
                  </button>
                </div>

                <div className="modal-body border p-3 mt-3 rounded">
                  <p className="text-18 text-semibold">Workspace Role</p>
                  <RadioBox>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="Admin"
                        checked={workspaceRole === 'Admin'}
                        onClick={handleWorkspaceRole}
                      />
                      <label className="form-check-label mx-3 text-14 text-semibold">
                        Admin <br />
                        <span className="text-14 text-regular">
                          Full control over the workspace and can manage
                          workspace users.
                        </span>
                      </label>
                    </div>

                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="Publish"
                        checked={workspaceRole === 'Publish'}
                        onClick={handleWorkspaceRole}
                      />
                      <label className="form-check-label mx-3 text-14 text-semibold">
                        Publish <br />
                        <span className="text-14 text-regular">
                          Can view, modify, and publish tags in the workspace.
                        </span>
                      </label>
                    </div>

                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="Editor"
                        onClick={handleWorkspaceRole}
                        checked={workspaceRole === 'Editor'}
                      />
                      <label className="form-check-label mx-3 text-14 text-semibold">
                        Editor <br />
                        <span className="text-14 text-regular">
                          Can view and modify tags in the workspace.
                        </span>
                      </label>
                    </div>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="Viewer"
                        onClick={handleWorkspaceRole}
                        checked={workspaceRole === 'Viewer'}
                      />
                      <label className="form-check-label mx-3 text-14 text-semibold">
                        Viewer <br />
                        <span className="text-14 text-regular">
                          Can view all tags in the workspace.
                        </span>
                      </label>
                    </div>
                  </RadioBox>
                </div>
              </div>
            </div>
          </Modal>

          {/* Popup ConfirmDelete*/}
          <Modal
            shouldCloseOnOverlayClick={false}
            closeTimeoutMS={200}
            ariaHideApp={false}
            isOpen={openPopupConfirmDelete}
            contentLabel="modal"
            style={customStylesSM}
            onRequestClose={() => setOpenPopupConfirmDelete(false)}
            className={'modal-Publish-box animate-5 fadeIn two'}
          >
            <div className="modal-dialog modal-md ">
              <div className="modal-content">
                <div className="modal-header bg-danger">
                  <div className="p-1 text-white"></div>
                </div>
                <div className="modal-body p-4 mt-3">
                  <div className="text-center">
                    <img
                      className="mb-2"
                      src="/assets/img/warning.png"
                      alt="tagturbo"
                    />
                  </div>
                  <p className="text-18 text-semibold text-danger text-center">
                    Delete Workspace User
                  </p>
                  <p className="text-14 text-center">
                    Are you sure you want to delete {workspaceEmail} from the
                    Workspace? Please note that this action cannot be undone.
                  </p>
                </div>
                <div className="modal-footer  justify-content-center px-4 pb-3">
                  <div
                    className="text-danger mx-3 cursor"
                    onClick={() => setOpenPopupConfirmDelete(false)}
                  >
                    Cancel
                  </div>
                  <div
                    className="btn btn-danger"
                    onClick={() => deleteWorkspaceUser(workspaceEmail)}
                  >
                    Delete
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
      <Modal
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={200}
        ariaHideApp={false}
        isOpen={openPopupInviteLimit}
        contentLabel="modal"
        style={customStylesSM}
        onRequestClose={() => setOpenPopupInviteLimit(false)}
        className={'modal-Publish-box animate-5 fadeIn two'}
      >
        <ModalBox>
          <div className="modal-dialog modal-md ">
            <div className="modal-content">
              <div className="modal-header bg-danger">
                <div className="p-1 text-white"></div>
              </div>
              <div className="modal-body p-3 mt-3">
                <div className="text-center">
                  <img
                    className="mb-2"
                    src="/assets/img/warning.png"
                    alt="tagturbo"
                  />
                </div>
                <p className="text-18 text-semibold text-danger text-center">
                  Your Account Requires an Upgrade
                </p>

                <p className="text-14 text-center mb-2">
                  You have reached the total number of account users <br />{' '}
                  allowed for your plan. To continue to invite more workspace{' '}
                  <br /> users, your account will need to be upgraded to a new
                  plan.
                </p>
              </div>
              <div className="modal-footer justify-content-center px-4 pb-3">
                {activeAccountRole === 'SuperAccountAdmin' ? (
                  <Fragment>
                    <div
                      className="text-danger mx-3 cursor text-14"
                      onClick={() => setOpenPopupInviteLimit(false)}
                    >
                      Cancel
                    </div>
                    <div
                      className="btn btn-danger text-14"
                      onClick={() => upgradePlan()}
                    >
                      Upgrade Plan
                    </div>
                  </Fragment>
                ) : (
                  <div
                    className="btn btn-danger text-14"
                    onClick={() => setOpenPopupInviteLimit(false)}
                  >
                    Close
                  </div>
                )}
              </div>
            </div>
          </div>
        </ModalBox>
      </Modal>

      {/* Popup formatemail*/}
      <Modal
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={200}
        ariaHideApp={false}
        isOpen={openPopupFormatEmail}
        contentLabel="modal"
        style={customStylesSM}
        onRequestClose={() => setOpenPopupFormatEmail(false)}
        className={'modal-Publish-box animate-5 fadeIn two'}
      >
        <div className="modal-dialog modal-md ">
          <div className="modal-content">
            <div className="modal-header bg-danger">
              <div className="p-1 text-white"></div>
            </div>
            <div className="modal-body p-4 mt-3">
              <div className="text-center">
                <img
                  className="mb-2"
                  src="/assets/img/warning.png"
                  alt="tagturbo"
                />
              </div>
              <p className="text-18 text-semibold text-danger text-center">
                Not Found!
              </p>
              <p className="text-14 text-center">
                The address '{' '}
                {emailInvalidList.map((list, index) => {
                  return (
                    <Fragment>
                      <span className="text-grey" key={index}>
                        {list.email}
                        {index === emailInvalidList.length - 1 ? (
                          <Fragment />
                        ) : (
                          ' , '
                        )}
                      </span>
                    </Fragment>
                  )
                })}
                ' in the 'Enter the email' field is not recognized. Please
                ensure that all addresses have the correct format.
              </p>
            </div>
            <div className="modal-footer  justify-content-center px-4 pb-3">
              <div
                className="btn btn-danger"
                onClick={() => setOpenPopupFormatEmail(false)}
              >
                OK
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </WorkspaceUsersBox>
  )
}

export default WorkspaceUsersComponent
