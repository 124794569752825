import axios from 'axios'
import { redirectLogin, redirectPermission } from '../../helper/helper'
const { REACT_APP_API_TAG } = process.env

const allMetadataList = async (token, workspace_id) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      workspace_id,
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/metadata/get-all-meta-lists`
    const response = await axios.get(URL, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: 500,
        data: error,
      }
    }
  }
  return res
}

const metaEventParamsList = async (
  token,
  workspace_id,
  tag_type,
  event_name,
) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      workspace_id,
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/metadata/get-meta-event-params?tag_type=${tag_type}&channel_type=ga4&event_name=${event_name}`
    const response = await axios.get(URL, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: 500,
        data: error,
      }
    }
  }
  return res
}

const deleteMatedata = async (token, workspace_id, obj) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      workspace_id,
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/metadata/delete-meta-by-id`
    const response = await axios.delete(URL, {
      headers: customConfigHeader.headers,
      data: obj,
    })
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    // if (error.response?.status === 401) {
    //   redirectLogin();
    // } else if (error.response?.status === 403) {
    //   redirectPermission();
    // } else {
    res = {
      status: 500,
      data: error,
    }
    // }
  }
  return res
}

export { allMetadataList, metaEventParamsList, deleteMatedata }
