import { Fragment, useEffect, useState, useMemo, memo } from 'react'
import LoadingSMComponent from '../layout/loadingSM'
import { getGAReport, getGAReportFunnel } from '../../apis/dashboard/api'
import {
  numberWithCommas,
  changeFormatDate,
  findMaxValue,
  sumPercent,
  numberWithToFixed,
} from '../../helper/helper'
import { allMetadataList, metaEventParamsList } from '../../apis/metadata/api'
import Select from 'react-select'
import * as echarts from 'echarts'
import { TableDashboardBox, FunnelBox } from './style'
import LoadingComponent from '../layout/loading'
import { Tooltip as ReactTooltip } from 'react-tooltip'

const TabMenuEvent = (props) => {
  const accessToken = localStorage.getItem('token')

  const [isLoadingChart, setIsLoadingChart] = useState(false)

  const [isLoadingTopEvent, setIsLoadingTopEvent] = useState(false)
  const [topEvent, setTopEvent] = useState([])
  const [topStandard, setTopStandard] = useState([])
  const [topEcom, setTopEcom] = useState([])

  const [eventList, setEventList] = useState([])
  const [pageList, setPageList] = useState([])
  const [masterList, setMasterList] = useState([])
  const [allEventList, setAllEventList] = useState([])
  const [eventParamsList, setEventParamsList] = useState([])
  const [paramsByEventList, setParamsByEventList] = useState([])
  const [isLoadingParamsEvent, setIsLoadingParamsEvent] = useState(false)

  const [selectEventList, setSelectEventList] = useState('')
  const [eventPageList, setEventPageList] = useState('')
  const [masterEventPageList, setMasterEventPageList] = useState('')
  const [isLoadingActivitySummary, setIsLoadingActivitySummary] =
    useState(false)
  const [activeEvent, setActiveEvent] = useState('Event Count')
  const [sumTotalParamsEvent, setSumTotalParamsEvent] = useState(0)
  const [showEventCount, setShowEventCount] = useState(0)
  const [showEventCountPerUser, setShowEventCountPerUser] = useState(0)
  const [showEventTotalUser, setShowEventTotalUser] = useState(0)
  let [yesterday, setYesterday] = useState(props.filterEndDate)
  let [prior, setPrior] = useState(props.filterEndDate)

  const [funnelList, setFunnelList] = useState('')
  const [eventListFunnel, setEventListFunnel] = useState([])
  const [funnelType, setFunnelType] = useState([])
  const [selectedFunnelType, setSelectedFunnelType] = useState([])
  const [selectedFunnelEvent, setSelectedFunnelEvent] = useState('')
  const [funnelExploration, setFunnelExploration] = useState('')
  const [isLoadingFunnelExploration, setIsLoadingFunnelExploration] =
    useState(false)
  const [activeFunnelType, setActiveFunnelType] = useState('Closed Funnel')
  const [funnelEventList, setFunnelEventList] = useState('')
  const [deviceDesktop, setDeviceDesktop] = useState([])
  const [deviceMobile, setDeviceMobile] = useState([])
  const [deviceTablet, setDeviceTablet] = useState([])
  const [deviceSmartTV, setDeviceSmartTV] = useState([])
  const [devices, setDevices] = useState('')
  const [totalUsers, setTotalUsers] = useState([])
  const [selectOption, setSelectOption] = useState(0)

  let [eventArray, setEventArray] = useState([])

  const [valueStep1, setValueStep1] = useState('')
  const [valueStep2, setValueStep2] = useState('')
  const [valueStep3, setValueStep3] = useState('')
  const [valueStep4, setValueStep4] = useState('')
  const [valueStep5, setValueStep5] = useState('')
  const [valueStep6, setValueStep6] = useState('')
  const [valueStep7, setValueStep7] = useState('')
  const [valueStep8, setValueStep8] = useState('')
  const [valueStep9, setValueStep9] = useState('')
  const [valueStep10, setValueStep10] = useState('')

  const funnel_type = localStorage.getItem('funnelType')
  const step_event = localStorage.getItem('stepEvent')

  const select_step = localStorage.getItem('selectstepEvent')

  const obj_funnel_type = JSON.parse(funnel_type)

  const obj_step_event = JSON.parse(step_event)

  const obj_select_step = JSON.parse(select_step)

  const [isLoadingPage, setIsLoadingPage] = useState(false)

  let [eventArrayStep, setEventArrayStep] = useState([])
  const [isClearable, setIsClearable] = useState(true)

  const optionsFunnelType = [
    { value: 'close', label: 'Closed Funnel' },
    { value: 'open', label: 'Open Funnel' },
  ]

  const getAllChart = () => {
    if (accessToken) {
      getGGTopEvent(accessToken)
      getMetadata()
      getGGEventSummary(accessToken)
      getGGActivitySummaryMaster(accessToken)
      getGGActivitySummary(accessToken)
    } else {
      setTimeout(() => {
        setIsLoadingChart(false)
      }, 2000)
    }
  }

  const sumArray = (arr) => {
    let sum = 0
    for (let i = 0; i < arr.length; i++) {
      sum += arr[i]
    }
    return sum
  }

  const getGGParameterByEvent = async (
    eventParamsName,
    accessToken,
    sDate = props.filterStartDate,
    eDate = props.filterEndDate,
  ) => {
    setIsLoadingParamsEvent(true)
    try {
      let obj = {
        dateRanges: [
          {
            startDate: sDate,
            endDate: eDate,
          },
        ],
        metrics: [
          {
            name: 'eventCount',
          },
        ],
        dimensions: [
          {
            name: 'eventName',
          },
          {
            name: `customEvent:${eventParamsName}`,
          },
        ],
      }
      const dataParameterByEvent = await getGAReport(
        obj,
        props.propertyId,
        accessToken,
        props.workspaceId,
      )
      if (
        dataParameterByEvent?.status === 200 &&
        dataParameterByEvent?.data?.rows.length > 0
      ) {
        let _filter = dataParameterByEvent.data.rows.filter(
          (item) => item.dimensionValues[0].value === selectEventList.value,
        )

        setTimeout(() => {
          setSumTotalParamsEvent(
            sumArray(
              _filter.map((item) => parseInt(item.metricValues[0].value)),
            ),
          )
          setParamsByEventList(_filter)
          setIsLoadingParamsEvent(false)
        }, 2000)
      } else {
        setIsLoadingParamsEvent(false)
        setParamsByEventList([])
        setSumTotalParamsEvent(0)
      }
    } catch (error) {
      setIsLoadingParamsEvent(false)
    }
  }

  const getGGTopEvent = async (
    accessToken,
    sDate = props.filterStartDate,
    eDate = props.filterEndDate,
  ) => {
    setIsLoadingTopEvent(true)
    try {
      let obj = {
        dateRanges: [
          {
            startDate: sDate,
            endDate: eDate,
          },
        ],
        metrics: [
          {
            name: 'eventCount',
          },
        ],
        dimensions: [
          {
            name: 'eventName',
          },
        ],
      }
      const dataInfoTopEvent = await getGAReport(
        obj,
        props.propertyId,
        accessToken,
        props.workspaceId,
      )
      if (dataInfoTopEvent?.data?.rows) {
        setTopEvent(dataInfoTopEvent.data.rows.slice(0, 10))
        getMetadata(dataInfoTopEvent.data.rows)
      } else {
        setTopEvent([])
        setTopStandard([])
        setTopEcom([])
      }

      setIsLoadingTopEvent(false)
    } catch (error) {
      setIsLoadingTopEvent(false)
    }
  }

  const getGGEventSummary = async (
    accessToken,
    sDate = props.filterStartDate,
    eDate = props.filterEndDate,
  ) => {
    setIsLoadingTopEvent(true)
    try {
      let obj = {
        dimensions: [{ name: 'date' }, { name: 'eventName' }],
        metrics: [
          { name: 'eventCount' },
          { name: 'eventCountPerUser' },
          { name: 'totalUsers' },
        ],
        dateRanges: [{ startDate: sDate, endDate: eDate }],
      }
      const dataEventSummany = await getGAReport(
        obj,
        props.propertyId,
        accessToken,
        props.workspaceId,
      )
      if (dataEventSummany?.data?.rows) {
        setMasterList(dataEventSummany.data.rows.map((item) => item))
        let eventList = []
        for (let i = 0; i < dataEventSummany.data.rows.length; i++) {
          eventList.push(dataEventSummany.data.rows[i].dimensionValues[1].value)
        }

        let list = removeDuplicates(eventList)
        let _tempEventList = []
        for (let l = 0; l < list.length; l++) {
          let _temp = {
            value: list[l],
            label: list[l],
          }
          _tempEventList.push(_temp)
        }

        setEventList(_tempEventList)
        handleChangeEvent(
          dataEventSummany.data.rows,
          selectEventList.value ||
            dataEventSummany.data.rows[0].dimensionValues[1].value,
        )

        setIsLoadingTopEvent(false)
      } else {
        setMasterList([])
        setEventPageList([])
        handleSelectActiveEvent()
        setIsLoadingTopEvent(false)
      }
    } catch (error) {
      setIsLoadingTopEvent(false)
    }
  }

  const getGGActivitySummaryMaster = async (
    accessToken,
    sDate = props.filterStartDate,
    eDate = props.filterEndDate,
  ) => {
    setIsLoadingActivitySummary(true)
    try {
      let obj = {
        dimensions: [{ name: 'eventName' }],
        metrics: [
          { name: 'eventCount' },
          { name: 'eventCountPerUser' },
          { name: 'totalUsers' },
        ],
        dateRanges: [{ startDate: sDate, endDate: eDate }],
      }
      const dataPageListMaster = await getGAReport(
        obj,
        props.propertyId,
        accessToken,
        props.workspaceId,
      )
      if (dataPageListMaster?.data?.rows) {
        setEventPageList(dataPageListMaster.data.rows)
        setMasterEventPageList(dataPageListMaster.data.rows)
        setIsLoadingActivitySummary(false)
      } else {
        setPageList([])
        setIsLoadingActivitySummary(false)
      }
    } catch (error) {
      setIsLoadingActivitySummary(false)
    }
  }

  const getGGActivitySummary = async (
    accessToken,
    sDate = props.filterStartDate,
    eDate = props.filterEndDate,
  ) => {
    try {
      let obj = {
        dimensions: [{ name: 'pagePath' }],
        metrics: [
          { name: 'eventCount' },
          { name: 'eventCountPerUser' },
          { name: 'totalUsers' },
        ],
        dateRanges: [{ startDate: sDate, endDate: eDate }],
      }
      const dataPageList = await getGAReport(
        obj,
        props.propertyId,
        accessToken,
        props.workspaceId,
      )
      if (dataPageList?.data?.rows) {
        let _tempPageList = []
        for (let i = 0; i < dataPageList.data.rows.length; i++) {
          let _temp = {
            label: dataPageList.data.rows[i].dimensionValues[0].value,
            value: dataPageList.data.rows[i].dimensionValues[0].value,
          }
          _tempPageList.push(_temp)
        }
        setPageList(_tempPageList)

        // getGGEventPage(
        //   accessToken,
        //   sDate,
        //   eDate,
        //   dataPageList.data.rows[0].dimensionValues[0].value
        // );
      } else {
        setPageList([])
      }

      // setIsLoadingTopEvent(false);
    } catch (error) {
      // setIsLoadingTopEvent(false);
    }
  }

  const getGGEventPage = async (
    accessToken,
    sDate = props.filterStartDate,
    eDate = props.filterEndDate,
    selectedPage,
  ) => {
    setIsLoadingActivitySummary(true)
    let objEvent = {
      dimensions: [{ name: 'eventName' }, { name: 'pagePath' }],
      metrics: [
        { name: 'eventCount' },
        { name: 'eventCountPerUser' },
        { name: 'totalUsers' },
      ],
      dateRanges: [{ startDate: sDate, endDate: eDate }],
    }
    const dataEventList = await getGAReport(
      objEvent,
      props.propertyId,
      accessToken,
      props.workspaceId,
    )

    if (dataEventList?.data?.rows) {
      let _tempFilterEvent = []
      for (let i = 0; i < dataEventList.data.rows.length; i++) {
        if (dataEventList.data.rows[i]?.dimensionValues[1]) {
          if (
            dataEventList.data.rows[i].dimensionValues[1].value ===
            selectedPage.value
          ) {
            _tempFilterEvent.push(dataEventList.data.rows[i])
          }
        }
      }
      if (dataEventList.data.rows.length === 0) {
        setEventPageList(masterEventPageList)
        setIsLoadingActivitySummary(false)
      } else {
        setEventPageList(sumMetricValues(_tempFilterEvent))
        setIsLoadingActivitySummary(false)
      }
    }
  }

  const removeDuplicates = (arr) => {
    // Use the filter method to keep only the first occurrence of each element
    return arr.filter((value, index, self) => {
      return self.indexOf(value) === index
    })
  }

  const sumArrayOfStrings = (arr) => {
    // Use the reduce method to sum the numbers
    const sum = arr.reduce((accumulator, currentValue) => {
      // Convert the current value (a string) to a number and add it to the accumulator
      return accumulator + parseInt(currentValue)
    }, 0)

    return sum
  }

  function sortByDate(inputArray) {
    // Sort the input array based on the date in dimensionValues in descending order
    inputArray.sort((a, b) => {
      const dateA = b.dimensionValues[0].value
      const dateB = a.dimensionValues[0].value

      // Compare dates in reverse order for descending sorting
      return dateB.localeCompare(dateA)
    })

    return inputArray
  }

  const handleChangeEventCate = (eventActive) => {
    setActiveEvent(eventActive)

    let filterEvent = masterList.filter(
      (item) => item.dimensionValues[1].value === selectEventList.value,
    )
    let _tempX = []
    let _tempY = []
    let categoryEvent = sortByDate(filterEvent)
    for (let i = 0; i < filterEvent.length; i++) {
      _tempX.push(changeFormatDate(categoryEvent[i].dimensionValues[0].value))
      if (eventActive === 'Event Count') {
        _tempY.push(categoryEvent[i].metricValues[0].value)
      } else if (eventActive === 'Event Per User') {
        _tempY.push(categoryEvent[i].metricValues[1].value)
      } else {
        _tempY.push(categoryEvent[i].metricValues[2].value)
      }
    }

    handleSelectActiveEvent(_tempX, _tempY, eventActive)
  }

  const handleChangeEvent = (master = masterList, eventName) => {
    setSelectEventList({
      label: eventName,
      value: eventName,
    })
    setEventParamsList([])

    setParamsByEventList([])
    setActiveEvent('Event Count')
    let eCount = []
    let eCountPerUser = []
    let eTotalUser = []
    let masterEvent = master.length > 0 ? master : masterList
    let filterEvent = masterEvent.filter(
      (item) => item.dimensionValues[1].value === eventName,
    )
    let _tempX = []
    let _tempY = []
    let categoryEvent = sortByDate(filterEvent)

    for (let i = 0; i < filterEvent.length; i++) {
      _tempX.push(changeFormatDate(categoryEvent[i].dimensionValues[0].value))
      _tempY.push(categoryEvent[i].metricValues[0].value)

      eCount.push(filterEvent[i].metricValues[0].value)
      eCountPerUser.push(filterEvent[i].metricValues[1].value)
      eTotalUser.push(filterEvent[i].metricValues[2].value)
    }

    handleSelectActiveEvent(_tempX, _tempY)
  }

  const handleChangeParamsEvent = (eventParamsName) => {
    getGGParameterByEvent(eventParamsName, accessToken)
  }

  const sumMetricValues = (data) => {
    const result = {}

    data.forEach((item) => {
      const dimensionValue = item.dimensionValues[0].value // เลือก dimensionValues.value ที่มี index 0
      const metricValues = item.metricValues.map((metric) =>
        parseInt(metric.value),
      ) // แปลง metricValues.value เป็นตัวเลขและเก็บในอาร์เรย์

      if (!result[dimensionValue]) {
        // สร้าง dimensionValue ใหม่ถ้ายังไม่มี
        result[dimensionValue] = {
          dimensionValues: [{ value: dimensionValue }],
          metricValues: metricValues.map((value) => ({ value: value })),
        }
      } else {
        // รวมค่าของ metricValues ที่มี dimensionValues.value เหมือนกัน
        for (let i = 0; i < metricValues.length; i++) {
          result[dimensionValue].metricValues[i].value += metricValues[i]
        }
      }
    })

    return Object.values(result) // แปลงค่าจากออบเจ็กเป็นอาร์เรย์และคืนค่า
  }

  const handleChangeActivity = (selectedPage) => {
    getGGEventPage(
      accessToken,
      props.filterStartDate,
      props.filterEndDate,
      selectedPage,
    )
  }
  const handleChangeFunnel = (funnelType) => {
    localStorage.setItem('funnelType', JSON.stringify(funnelType))
    setSelectedFunnelType(funnelType)
    getGGDataFunnel(
      accessToken,
      props.filterStartDate,
      props.filterEndDate,
      eventArray,
      funnelType,
    )
  }
  const handleChangeEventFunnel = (event, no) => {
    setIsLoadingPage(true)
    let arrStep = []
    if (event) {
      if (no === 1) {
        setValueStep1(event)
      } else if (no === 2) {
        setValueStep2(event)
      } else if (no === 3) {
        setValueStep3(event)
      } else if (no === 4) {
        setValueStep4(event)
      } else if (no === 5) {
        setValueStep5(event)
      } else if (no === 6) {
        setValueStep6(event)
      } else if (no === 7) {
        setValueStep7(event)
      } else if (no === 8) {
        setValueStep8(event)
      } else if (no === 9) {
        setValueStep9(event)
      } else {
        setValueStep10(event)
      }

      arrStep = obj_select_step || []
      if (arrStep) {
        if (arrStep[no - 1]) {
          arrStep[no - 1] = event
        } else {
          arrStep.push(event)
        }
      } else {
        arrStep.push(event)
      }
      setEventArrayStep(arrStep.map((item) => item))
      localStorage.setItem('selectstepEvent', JSON.stringify(arrStep))

      setSelectOption(no)

      let arr = eventArray
      // let arr = obj_select_step;
      let obj = {
        name: event.value,
        isDirectlyFollowedBy: no === 1 ? false : true,
        filterExpression: {
          funnelEventFilter: {
            eventName: event.value,
          },
        },
      }
      let mergeStepFormLocal = JSON.parse(step_event)

      if (mergeStepFormLocal && mergeStepFormLocal.length !== 0) {
        mergeStepFormLocal[no - 1] = obj
        arr = mergeStepFormLocal
      } else {
        if (no <= arr.length) {
          arr[no - 1].name = event.value
          arr[no - 1].isDirectlyFollowedBy = no === 1 ? false : true
          arr[no - 1].filterExpression.funnelEventFilter.eventName = event.value
        } else {
          arr.push(obj)
        }

        if (no !== -1) {
          arr = arr.slice(0, no)
        }
      }
      setEventArray(arr.map((item) => item))
    } else {
      let arr = eventArray
      let arrStep = eventArrayStep
      arr = arr.slice(0, no - 1)
      arrStep = arrStep.slice(0, no - 1)
      setEventArrayStep(arrStep.map((item) => item))
      setEventArray(arr.map((item) => item))
      localStorage.setItem('selectstepEvent', JSON.stringify(arrStep))
      setSelectOption(no - 1)

      if (arrStep.length === 0 || no === 1) {
        setTotalUsers([])
        setDeviceDesktop([])
        setDeviceMobile([])
        setDeviceTablet([])
        setDeviceSmartTV([])
        localStorage.removeItem('stepEvent')
        setValueStep1('')
        setValueStep2('')
        setValueStep3('')
        setValueStep4('')
        setValueStep5('')
        setValueStep6('')
        setValueStep7('')
        setValueStep8('')
        setValueStep9('')
        setValueStep10('')
      } else if (no === 2) {
        setValueStep2('')
        setValueStep3('')
        setValueStep4('')
        setValueStep5('')
        setValueStep6('')
        setValueStep7('')
        setValueStep8('')
        setValueStep9('')
        setValueStep10('')
      } else if (no === 3) {
        setValueStep3('')
        setValueStep4('')
        setValueStep5('')
        setValueStep6('')
        setValueStep7('')
        setValueStep8('')
        setValueStep9('')
        setValueStep10('')
      } else if (no === 4) {
        setValueStep4('')
        setValueStep5('')
        setValueStep6('')
        setValueStep7('')
        setValueStep8('')
        setValueStep9('')
        setValueStep10('')
      } else if (no === 5) {
        setValueStep5('')
        setValueStep6('')
        setValueStep7('')
        setValueStep8('')
        setValueStep9('')
        setValueStep10('')
      } else if (no === 6) {
        setValueStep6('')
        setValueStep7('')
        setValueStep8('')
        setValueStep9('')
        setValueStep10('')
      } else if (no === 7) {
        setValueStep7('')
        setValueStep8('')
        setValueStep9('')
        setValueStep10('')
      } else if (no === 8) {
        setValueStep8('')
        setValueStep9('')
        setValueStep10('')
      } else if (no === 9) {
        setValueStep9('')
        setValueStep10('')
      } else {
        setValueStep10('')
      }
    }
  }

  const handleSelectActiveEvent = (
    xdata = [],
    ydata = [],
    active = 'Event Count',
  ) => {
    if (xdata) {
      const max = findMaxValue(ydata) + findMaxValue(ydata) * 0.1

      var chartDom = document.getElementById('eventSummaryChart')
      setTimeout(() => {
        if (chartDom && chartDom.clientWidth !== 0) {
          let chart = echarts.init(chartDom, null, {
            width: chartDom.clientWidth,
            height: 350,
          })
          chart.setOption({
            title: {
              show: xdata.length === 0 ? true : false,
              textStyle: {
                color: '#bcbcbc',
              },
              text: 'No Data',
              left: 'center',
              top: 'center',
            },
            tooltip: {
              trigger: 'axis',
              formatter: (params) => {
                return `
                          <b>${params[0].axisValue}</b><br />
                          ${active || activeEvent} | <b>${numberWithCommas(
                            params[0].data,
                          )}</b>
                          `
              },
            },
            legend: {
              data: xdata,
            },
            xAxis: {
              type: 'category',
              boundaryGap: false,
              data: xdata,
            },
            yAxis: {
              type: 'value',
              max: Math.round(max),
            },
            series: [
              {
                data: ydata,
                type: 'line',
                smooth: true,
                areaStyle: {},
              },
            ],
          })
        }
      }, 500)
    }
  }

  const getMetadata = async (ggTopEvent) => {
    if (ggTopEvent) {
      const { data, status } = await allMetadataList(
        accessToken,
        props.workspaceId,
      )
      if (status === 200) {
        setAllEventList(data.data.filter((item) => item.channel_type === 'ga4'))
        let standard = data.data.filter((item) => item.tag_type === 'standard')
        let ecomm = data.data.filter((item) => item.tag_type === 'ecommerce')
        standard = standard.map((item) => item.event_name)
        ecomm = ecomm.map((item) => item.event_name)
        let tempStandard = []
        let tempEcomm = []
        for (let i = 0; i < ggTopEvent.length; i++) {
          for (let l = 0; l < standard.length; l++) {
            if (ggTopEvent[i].dimensionValues[0].value === standard[l]) {
              tempStandard.push(ggTopEvent[i])
            }
          }
          for (let l = 0; l < ecomm.length; l++) {
            if (ggTopEvent[i].dimensionValues[0].value === ecomm[l]) {
              tempEcomm.push(ggTopEvent[i])
            }
          }
        }
        setTopStandard(
          removeDuplicateObjectsByDimensionValue(tempStandard).slice(0, 10),
        )
        setTopEcom(
          removeDuplicateObjectsByDimensionValue(tempEcomm).slice(0, 10),
        )
        setIsLoadingTopEvent(false)
      } else {
        setIsLoadingTopEvent(false)
      }
    }
  }

  const getMetaEventParams = async (tag_type, event_name) => {
    const { data, status } = await metaEventParamsList(
      accessToken,
      props.workspaceId,
      tag_type,
      event_name,
    )
    if (status === 200) {
      if (data.data.length > 0) {
        let _tempEventParamsList = []
        for (let l = 0; l < data.data.length; l++) {
          let _temp = {
            value: data.data[l].event_parameter_name,
            label: data.data[l].event_parameter_name,
          }
          _tempEventParamsList.push(_temp)
        }
        setEventParamsList(_tempEventParamsList)
        handleChangeParamsEvent(_tempEventParamsList[0].value)
      } else {
        setEventParamsList([
          {
            value: '-',
            label: '-',
          },
        ])
      }
    }
  }

  const getGGEventFunnel = async (
    accessToken,
    sDate = props.filterStartDate,
    eDate = props.filterEndDate,
  ) => {
    setIsLoadingTopEvent(true)
    try {
      let obj = {
        dimensions: [{ name: 'deviceCategory' }, { name: 'eventName' }],
        metrics: [{ name: 'totalUsers' }],
        dateRanges: [{ startDate: sDate, endDate: eDate }],
        keepEmptyRows: true,
        metricAggregations: ['TOTAL'],
      }
      const dataEventFunnel = await getGAReport(
        obj,
        props.propertyId,
        accessToken,
        props.workspaceId,
      )
      if (dataEventFunnel?.data?.rows) {
        let eventList = []
        for (let i = 0; i < dataEventFunnel.data.rows.length; i++) {
          eventList.push(dataEventFunnel.data.rows[i].dimensionValues[1].value)
        }
        let list = removeDuplicates(
          Array.from(eventList).sort((a, b) => {
            return a.localeCompare(b, 'en', { sensitivity: 'base' })
          }),
        )
        let _tempEventList = []
        for (let l = 0; l < list.length; l++) {
          let _temp = {
            value: list[l],
            label: list[l],
          }
          _tempEventList.push(_temp)
        }
        setEventListFunnel(_tempEventList)
        // setIsLoadingTopEvent(false);
      }
    } catch (error) {
      // setIsLoadingTopEvent(false);
    }
  }

  function removeDuplicateObjectsByDimensionValue(data) {
    const seenValues = new Set()
    const uniqueObjects = []

    for (const obj of data) {
      const dimensionValue = obj.dimensionValues[0].value

      if (!seenValues.has(dimensionValue)) {
        seenValues.add(dimensionValue)
        uniqueObjects.push(obj)
      }
    }

    return uniqueObjects
  }

  const getGGDataFunnel = async (
    accessToken,
    sDate = props.filterStartDate,
    eDate = props.filterEndDate,
    arrEvent,
    funnelTypeFn,
  ) => {
    setIsLoadingPage(true)
    localStorage.setItem('stepEvent', JSON.stringify(arrEvent))
    const funnelType = localStorage.getItem('funnelType')
    const objFunnelType = JSON.parse(funnelType)

    const stepEvent = localStorage.getItem('stepEvent')
    const objStepEvent = JSON.parse(stepEvent)
    try {
      let obj = {
        dateRanges: [
          {
            startDate: sDate,
            endDate: eDate,
            name: 'funneloct',
          },
        ],
        funnel: {
          isOpenFunnel: funnelTypeFn
            ? funnelTypeFn?.value === 'open'
              ? arrEvent.length <= 1 || objStepEvent.length <= 1
                ? false
                : true
              : false
            : objFunnelType?.value === 'open'
              ? arrEvent.length <= 1 || objStepEvent.length <= 1
                ? false
                : true
              : false,
          steps: arrEvent || objStepEvent,
        },
        funnelVisualizationType: 'STANDARD_FUNNEL',
        funnelBreakdown: {
          breakdownDimension: {
            name: 'deviceCategory',
          },
        },
      }
      const dataEventFunnel = await getGAReportFunnel(
        obj,
        props.propertyId,
        accessToken,
        props.workspaceId,
      )

      if (dataEventFunnel?.data?.funnelTable) {
        setIsLoadingPage(false)
        let filterEvent = dataEventFunnel.data.funnelTable.rows
        let filterDeviceDesktop = filterEvent.filter(
          (item) => item.dimensionValues[1].value === 'desktop',
        )

        setDeviceDesktop(
          filterDeviceDesktop.length > 0 ? filterDeviceDesktop : '-',
        )

        let filterDeviceMobile = filterEvent.filter(
          (item) => item.dimensionValues[1].value === 'mobile',
        )
        setDeviceMobile(filterDeviceMobile)

        let filterDeviceTablet = filterEvent.filter(
          (item) => item.dimensionValues[1].value === 'tablet',
        )
        setDeviceTablet(filterDeviceTablet)

        let filterDeviceSmartTV = filterEvent.filter(
          (item) => item.dimensionValues[1].value === 'smartTV',
        )
        // numberWithCommas
        setDeviceSmartTV(filterDeviceSmartTV)

        let filterTotalUsers = filterEvent.filter(
          (item) => item.dimensionValues[1].value === 'RESERVED_TOTAL',
        )

        setTotalUsers(filterTotalUsers)

        var chartDomStep0 = document.getElementById('eventFunnelStep1')
        var chartDomStep1 = document.getElementById('eventFunnelStep2')
        var chartDomStep2 = document.getElementById('eventFunnelStep3')
        var chartDomStep3 = document.getElementById('eventFunnelStep4')
        var chartDomStep4 = document.getElementById('eventFunnelStep5')
        var chartDomStep5 = document.getElementById('eventFunnelStep6')
        var chartDomStep6 = document.getElementById('eventFunnelStep7')
        var chartDomStep7 = document.getElementById('eventFunnelStep8')
        var chartDomStep8 = document.getElementById('eventFunnelStep9')
        var chartDomStep9 = document.getElementById('eventFunnelStep10')

        const arrDom = [
          chartDomStep0,
          chartDomStep1,
          chartDomStep2,
          chartDomStep3,
          chartDomStep4,
          chartDomStep5,
          chartDomStep6,
          chartDomStep7,
          chartDomStep8,
          chartDomStep9,
        ]

        let overAllMaxValue = filterTotalUsers.map(
          (item) => item.metricValues[0].value,
        )
        const overAllMaxValueToInt = overAllMaxValue.map(Number)
        const maxAll = Math.max(...overAllMaxValueToInt)
        let maxValue = filterTotalUsers[0]?.metricValues[0]?.value
        maxValue = maxValue * 1.1

        let sortingMaxValue = filterTotalUsers[0]?.metricValues[0]?.value
        sortingMaxValue = Math.max(sortingMaxValue)
        setTimeout(() => {
          for (let i = 0; i < filterTotalUsers.length; i++) {
            let chart1 = echarts.init(arrDom[i], null, {
              width: 270,
              height: 450,
              top: 28,
            })
            const funnelType = localStorage.getItem('funnelType')
            const objFunnelType = JSON.parse(funnelType)
            chart1.setOption({
              color: ['#0047FF'],
              xAxis: {
                type: 'category',
                data: {},
              },
              yAxis: {
                type: 'value',
                axisLabel: {
                  align: 'left',
                  margin: 15,
                },
                max:
                  selectedFunnelType?.value === 'open' ||
                  objFunnelType?.value === 'open'
                    ? maxAll
                    : // ? Math.floor(sortingMaxValue) || 0
                      Math.floor(maxValue) || 0,
              },
              series: [
                {
                  data: [filterTotalUsers[i]?.metricValues[0]?.value],
                  type: 'bar',
                },
              ],
            })
          }
        }, 200)
      } else {
        setIsLoadingPage(false)
      }
    } catch (error) {
      setIsLoadingPage(false)
    }
  }

  useMemo(() => {
    getGGEventFunnel(
      accessToken,
      props.filterStartDate,
      props.filterEndDate,
      funnelType,
    )
  }, [])

  useMemo(() => {
    if (funnel_type) {
      setSelectedFunnelType(obj_funnel_type)
    }
    if (step_event) {
      getGGDataFunnel(
        accessToken,
        props.filterStartDate,
        props.filterEndDate,
        obj_step_event,
      )
      setSelectOption(obj_step_event.length)
    }
    if (obj_select_step && obj_select_step.length > 0) {
      if (obj_select_step[0]) {
        setValueStep1(obj_select_step[0])
      }
      setValueStep1(obj_select_step[0] || '')
      setValueStep2(obj_select_step[1] || '')
      setValueStep3(obj_select_step[2] || '')
      setValueStep4(obj_select_step[3] || '')
      setValueStep5(obj_select_step[4] || '')
      setValueStep6(obj_select_step[5] || '')
      setValueStep7(obj_select_step[6] || '')
      setValueStep8(obj_select_step[7] || '')
      setValueStep9(obj_select_step[8] || '')
      setValueStep10(obj_select_step[9] || '')
    }
  }, [])

  useMemo(() => {
    if (props.menuActive === 'Event') {
      getAllChart()
    }
  }, [props.menuActive])

  useMemo(() => {
    if (props.filterStartDate) {
      let yesterday = new Date(props.filterEndDate)
      yesterday.setHours(0, 0, 0, 0)
      let prior = new Date().setDate(yesterday.getDate() - 30)
      prior = new Date(prior)
      prior.setHours(0, 0, 0, 0)

      setYesterday(yesterday)
      setPrior(prior)
      getAllChart()
    }
  }, [props.filterStartDate])

  useMemo(() => {
    if (eventArray.length > 0) {
      getGGDataFunnel(
        accessToken,
        props.filterStartDate,
        props.filterEndDate,
        eventArray,
      )
    }
  }, [eventArray])

  useMemo(() => {
    let filterEvent = allEventList.filter(
      (item) => item.event_name === selectEventList.value,
    )
    if (filterEvent.length > 0) {
      getMetaEventParams(filterEvent[0].tag_type, filterEvent[0].event_name)
    }
  }, [selectEventList])

  return (
    <div
      id="Event"
      className={`container tab-pane ${props.menuEvent ? 'active' : ''} `}
    >
      <br />
      {isLoadingTopEvent ? (
        <div className="card ">
          <div className="card-body d-center h-400">
            <LoadingSMComponent />
          </div>
        </div>
      ) : (
        <div className="card mb-3">
          <div className="card-body min-h-300">
            <p className="text-16 text-semibold">Top Event</p>
            <p className="text-12">
              Monitor engagement trends, analyze user behavior, and optimize
              your strategies for better results. Get a comprehensive view of
              all Google Analytics events, track metrics, analyze user
              interactions, and optimize your digital strategy for enhanced
              performance.
            </p>
            {topEvent.length === 0 ? (
              <span className="text-no-data">No data found.</span>
            ) : (
              <Fragment>
                <div className="row justify-content-center mb-3">
                  <div className="col-4">
                    <p className="text-12 text-uppercase">TOTAL</p>
                    <div className="d-flex justify-content-between">
                      <div>
                        <img
                          src="/assets/img/gold.png"
                          className="mx-2"
                          alt="tag turbo"
                        />
                        <span className="text-semibold text-20">
                          {' '}
                          {topEvent[0]?.dimensionValues[0]?.value || ''}
                        </span>
                      </div>
                      <div className="text-semibold text-16">
                        {numberWithCommas(
                          topEvent[0]?.metricValues[0]?.value,
                        ) || ''}
                      </div>
                    </div>
                  </div>
                  <div className="col-4 border-left">
                    <p className="text-12 text-uppercase">Standard</p>
                    <div className="d-flex justify-content-between">
                      <div>
                        <img
                          src="/assets/img/gold.png"
                          className="mx-2"
                          alt="tag turbo"
                        />
                        <span className="text-semibold text-20">
                          {' '}
                          {topStandard[0]?.dimensionValues[0]?.value || ''}
                        </span>
                      </div>
                      <div className="text-semibold text-16">
                        {numberWithCommas(
                          topStandard[0]?.metricValues[0]?.value,
                        ) || ''}
                      </div>
                    </div>
                  </div>
                  <div className="col-4 border-left">
                    <p className="text-12 text-uppercase">e-commerce</p>
                    <div className="d-flex justify-content-between">
                      <div>
                        <img
                          src="/assets/img/gold.png"
                          className="mx-2"
                          alt="tag turbo"
                        />
                        <span className="text-semibold text-20">
                          {' '}
                          {topEcom[0]?.dimensionValues[0]?.value || ''}
                        </span>
                      </div>
                      <div className="text-semibold text-16">
                        {numberWithCommas(topEcom[0]?.metricValues[0]?.value) ||
                          ''}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-bottom-custom"></div>{' '}
                <div className="row justify-content-center my-3">
                  <div className="col-4 scroll-sm">
                    <div className="d-flex justify-content-between mb-2">
                      <div className="text-12 text-uppercase"> Event name</div>

                      <div className="text-12 text-uppercase">count</div>
                    </div>

                    {topEvent.length > 0 &&
                      topEvent.map((item, i) => {
                        return (
                          i > 0 && (
                            <div
                              key={i}
                              className="d-flex justify-content-between mb-2"
                            >
                              <div>
                                {i === 1 ? (
                                  <img
                                    src={`/assets/img/silver.png`}
                                    className="mx-2"
                                    alt="tag turbo"
                                  />
                                ) : i === 2 ? (
                                  <img
                                    src={`/assets/img/bronze.png`}
                                    className="mx-2"
                                    alt="tag turbo"
                                  />
                                ) : (
                                  <span className="text-12 mx-3 text-semibold">
                                    {' '}
                                    {i + 1}
                                  </span>
                                )}

                                <span className="text-12 text-semibold">
                                  {' '}
                                  {item.dimensionValues[0].value}
                                </span>
                              </div>
                              <div className="text-12 text-semibold">
                                {numberWithCommas(item.metricValues[0].value)}
                              </div>
                            </div>
                          )
                        )
                      })}
                  </div>
                  <div className="col-4 scroll-sm border-left">
                    <div className="d-flex justify-content-between mb-2">
                      <div className="text-12 text-uppercase"> Event name</div>

                      <div className="text-12 text-uppercase">count</div>
                    </div>
                    {topStandard.length > 0 &&
                      topStandard.map((item, i) => {
                        return (
                          i > 0 && (
                            <div
                              key={i}
                              className="d-flex justify-content-between mb-2"
                            >
                              <div>
                                {i === 1 ? (
                                  <img
                                    src={`/assets/img/silver.png`}
                                    className="mx-2"
                                    alt="tag turbo"
                                  />
                                ) : i === 2 ? (
                                  <img
                                    src={`/assets/img/bronze.png`}
                                    className="mx-2"
                                    alt="tag turbo"
                                  />
                                ) : (
                                  <span className="text-12 mx-3 text-semibold">
                                    {' '}
                                    {i + 1}
                                  </span>
                                )}

                                <span className="text-12 text-semibold">
                                  {' '}
                                  {item.dimensionValues[0].value}
                                </span>
                              </div>
                              <div className="text-12 text-semibold">
                                {numberWithCommas(item.metricValues[0].value)}
                              </div>
                            </div>
                          )
                        )
                      })}
                  </div>
                  <div className="col-4 scroll-sm border-left">
                    <div className="d-flex justify-content-between mb-2">
                      <div className="text-12 text-uppercase"> Event name</div>

                      <div className="text-12 text-uppercase">count</div>
                    </div>
                    {topEcom.length > 0 &&
                      topEcom.map((item, i) => {
                        return (
                          i > 0 && (
                            <div
                              key={i}
                              className="d-flex justify-content-between mb-2"
                            >
                              <div>
                                {i === 1 ? (
                                  <img
                                    src={`/assets/img/silver.png`}
                                    className="mx-2"
                                    alt="tag turbo"
                                  />
                                ) : i === 2 ? (
                                  <img
                                    src={`/assets/img/bronze.png`}
                                    className="mx-2"
                                    alt="tag turbo"
                                  />
                                ) : (
                                  <span className="text-12 mx-3 text-semibold">
                                    {' '}
                                    {i + 1}
                                  </span>
                                )}

                                <span className="text-12 text-semibold">
                                  {' '}
                                  {item.dimensionValues[0].value}
                                </span>
                              </div>
                              <div className="text-12 text-semibold">
                                {numberWithCommas(item.metricValues[0].value)}
                              </div>
                            </div>
                          )
                        )
                      })}
                  </div>
                </div>
              </Fragment>
            )}
          </div>
        </div>
      )}
      <div className="row mb-3">
        <div className="col-md-8">
          <div className="card">
            <div className="card-body min-h-300">
              <p className="text-16 text-semibold">Event Summary</p>
              <div className="row mb-5">
                <span className="col-2 text-16 text-semibold w-125 pt-2">
                  Select Event
                </span>
                {eventList.length !== 0 && (
                  <Select
                    defaultValue={{
                      label: eventList[0].value,
                      value: eventList[0].value,
                    }}
                    options={eventList}
                    placeholder="All Events"
                    className="col-10 w-256"
                    onChange={(e) => handleChangeEvent('', e.value)}
                  />
                )}
              </div>
              <div className="row justify-content-center mb-3">
                <div
                  className={`col-4 border-right px-4 cursor ${
                    activeEvent === 'Event Count' ? 'event-active' : ''
                  }`}
                  onClick={() => handleChangeEventCate('Event Count')}
                >
                  <p className="mb-0 text-12">Event Count</p>
                  <p className="text-36 text-semibold mb-0">
                    {/* {numberWithCommas(showEventCount)} */}
                    {eventPageList.length > 0
                      ? numberWithCommas(
                          eventPageList.filter(
                            (item) =>
                              item?.dimensionValues[0]?.value ===
                              selectEventList.value,
                          )[0]?.metricValues[0]?.value || 0,
                        )
                      : '-'}
                  </p>
                </div>
                <div
                  className={`col-4 border-right px-4 cursor ${
                    activeEvent === 'Total User' ? 'event-active' : ''
                  }`}
                  onClick={() => handleChangeEventCate('Total User')}
                >
                  <p className="mb-0 text-12">Total Users</p>
                  <p className="text-36 text-semibold mb-0">
                    {/* {numberWithCommas(showEventTotalUser)} */}
                    {eventPageList.length > 0
                      ? numberWithCommas(
                          eventPageList.filter(
                            (item) =>
                              item?.dimensionValues[0]?.value ===
                              selectEventList.value,
                          )[0]?.metricValues[2]?.value || 0,
                        )
                      : '-'}
                  </p>
                </div>
                <div
                  className={`col-4 px-4 cursor ${
                    activeEvent === 'Event Per User' ? 'event-active' : ''
                  }`}
                  onClick={() => handleChangeEventCate('Event Per User')}
                >
                  <p className="mb-0 text-12">Event count per user</p>
                  <p className="text-36 text-semibold mb-0">
                    {/* {numberWithCommas(showEventCountPerUser)} */}
                    {eventPageList.length > 0
                      ? numberWithCommas(
                          eventPageList.filter(
                            (item) =>
                              item?.dimensionValues[0]?.value ===
                              selectEventList.value,
                          )[0]?.metricValues[1]?.value || 0,
                        )
                      : '-'}
                  </p>
                </div>
              </div>
              <div className="row">
                <div id="eventSummaryChart"></div>{' '}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card">
            <div className="card-body min-h-300">
              <p className="text-16 text-semibold">Parameter Name</p>
              {eventParamsList.length > 0 ? (
                <Select
                  defaultValue={{
                    label: eventParamsList[0].value,
                    value: eventParamsList[0].value,
                  }}
                  options={eventParamsList}
                  placeholder="Parameter Name"
                  className="col-12"
                  onChange={(e) => handleChangeParamsEvent(e.value)}
                />
              ) : (
                <Fragment>
                  <Select
                    defaultValue={[
                      {
                        label: '-',
                        value: '-',
                      },
                    ]}
                  />
                </Fragment>
              )}
              <div className="row justify-content-center my-3">
                <div className="scroll-sm">
                  <div className="row">
                    <div className="col-7">
                      <span className="text-12 text-uppercase">
                        parameter value
                      </span>
                    </div>
                    <div className="col-3">
                      <span className="text-12 text-uppercase">count</span>
                    </div>
                    <div className="col-2">
                      <span className="text-12 text-uppercase">%</span>
                    </div>
                  </div>
                  {isLoadingParamsEvent ? (
                    <div className="fixLoading">
                      <LoadingComponent />
                    </div>
                  ) : paramsByEventList.length > 0 ? (
                    paramsByEventList.map((item, i) => {
                      return (
                        <div key={i}>
                          <div className="row">
                            <div className="col-7">
                              <span
                                data-tooltip-id={`tooltip-${i}`}
                                className="text-12 text-semibold ellipsis-text"
                              >
                                {item.dimensionValues[1].value}
                              </span>
                              <ReactTooltip
                                id={`tooltip-${i}`}
                                style={{ fontSize: 11, maxWidth: 245 }}
                                place="bottom"
                                content={item.dimensionValues[1].value}
                              />
                            </div>
                            <div className="col-3">
                              <span className="text-10 text-semibold">
                                {numberWithCommas(item.metricValues[0].value)}
                              </span>
                            </div>
                            <div className="col-2">
                              <span className="text-10 text-semibold">
                                {sumPercent(
                                  item.metricValues[0].value,
                                  sumTotalParamsEvent,
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="progress my-1" style={{ height: 5 }}>
                            <div
                              className={`progress-bar bg`}
                              style={{
                                backgroundColor: '#0047FF',
                                width: `${sumPercent(item.metricValues[0].value, sumTotalParamsEvent)}%`,
                              }}
                              role="progressbar"
                              aria-valuenow={sumPercent(
                                item.metricValues[0].value,
                                sumTotalParamsEvent,
                              )}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            ></div>
                          </div>
                        </div>
                      )
                    })
                  ) : (
                    <div className="text-center">
                      <p className="text-gray mt-5">No data found.</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card mb-3">
        <div className="card-body min-h-300">
          <p className="text-16 text-semibold">User Activity Summary</p>
          <p className="text-12">
            A comprehensive overview of user engagement on your website, based
            on events.
          </p>
          <div className="row mb-4">
            <span className="col-2 text-16 text-semibold w-125 pt-2">
              Select Page
            </span>
            {pageList.length !== 0 && (
              <Select
                // isMulti
                options={pageList}
                placeholder="All Pages"
                className="col-3"
                onChange={(e) => handleChangeActivity(e)}
              />
            )}
          </div>
          <div className="row">
            <TableDashboardBox>
              <div className="text-center table-fixed">
                <table className="table table-purple table-striped m-0 ">
                  <thead className="table-primary">
                    <tr className="align-middle text-14">
                      <th className="text-left">Event Name</th>
                      <th>Event Count</th>
                      <th>Total Users</th>
                      <th>Event Count Per User</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isLoadingActivitySummary && eventPageList.length > 0 ? (
                      eventPageList.map((item, i) => {
                        return (
                          <tr key={i} className="text-14">
                            <td className="text-semibold text-left">
                              {item.dimensionValues[0].value}
                            </td>
                            <td>
                              {numberWithCommas(item.metricValues[0].value)}
                            </td>
                            <td>
                              {numberWithCommas(item.metricValues[2].value)}
                            </td>
                            <td>
                              {(
                                item.metricValues[0].value /
                                item.metricValues[2].value
                              ).toFixed(2)}
                            </td>
                          </tr>
                        )
                      })
                    ) : eventPageList.length === 0 ? (
                      <Fragment>
                        <tr className="loading">
                          <td colSpan="4" className="text-gray">
                            <span>No data found.</span>
                          </td>
                        </tr>
                      </Fragment>
                    ) : (
                      <tr className="loading">
                        <td colSpan="4">
                          <LoadingComponent className="fix_loading" />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </TableDashboardBox>
          </div>
        </div>
      </div>

      <div className="card mb-2">
        <div className="card-body min-h-300">
          <p className="text-16 text-semibold">Funnel Exploration</p>
          <p className="text-12">
            Unlock the power of data-driven decision-making with Funnel
            Exploration. Dive deep into user journeys on your website to
            understand how visitors move through your conversion funnels.
          </p>
          <div className="row mb-4">
            <span className="col-2 text-16 text-semibold w-125 pt-2">
              Funnel Type
            </span>

            <Select
              value={selectedFunnelType}
              options={optionsFunnelType}
              placeholder="Closed funnel"
              className="col-3"
              onChange={(e) => handleChangeFunnel(e)}
              isDisabled={true}
            />
          </div>
          <FunnelBox>
            <div className="row">
              <div className="d-flex">
                <div className="scroll">
                  <div className="funnel-box">
                    {/* step 1 */}
                    <div className="step-box">
                      <div className="border-step-box p-2">
                        <button
                          type="button"
                          className="btn badge-blue text-14 text-semibold px-3 mb-2"
                        >
                          Step 1
                        </button>
                        <Select
                          value={valueStep1}
                          isClearable={isClearable}
                          options={eventListFunnel}
                          placeholder={'Event name'}
                          styles={{
                            placeholder: (base) => ({
                              ...base,
                              color: '#D0D5DD',
                            }),
                          }}
                          className="w-100 mb-2"
                          onChange={(e) => handleChangeEventFunnel(e, 1)}
                        />
                        <div className="d-flex justify-content-between">
                          <span className="text-12 text-semibold">
                            Total users{' '}
                          </span>
                          <p className="text-12 mb-0">
                            {(totalUsers.length > 0 &&
                              numberWithCommas(
                                totalUsers[0]?.metricValues[0]?.value,
                              )) ||
                              '-'}{' '}
                            <span className="text-12 text-semibold mb-0">
                              {' '}
                              {totalUsers.length > 0 ? '100.00%' : '0%'}
                            </span>{' '}
                          </p>
                        </div>
                      </div>

                      <div className="border-step-box p-2 zero-scroll text-14 text-grey">
                        <div
                          className={`chart ${
                            totalUsers[0] && !isLoadingPage ? '' : 'd-none'
                          }`}
                          id="eventFunnelStep1"
                        ></div>

                        {totalUsers.length > 0 && totalUsers[0] ? (
                          isLoadingPage ? (
                            <LoadingComponent />
                          ) : (
                            <Fragment></Fragment>
                          )
                        ) : valueStep1.value && isLoadingPage ? (
                          <LoadingComponent />
                        ) : (
                          <div className="text-center">
                            Please select event to <br />
                            populate the table
                          </div>
                        )}
                      </div>

                      <div className="p-2 border-x mt-3">
                        <div className="w-100 text-12 text-semibold">
                          <div className="d-flex justify-content-between">
                            <div>Desktop</div>
                            <div className="d-flex">
                              <div className="text-right mx-2">
                                {(deviceDesktop.length > 0 &&
                                  numberWithCommas(
                                    deviceDesktop[0]?.metricValues[0]?.value,
                                  )) ||
                                  '-'}
                              </div>
                              <div className="text-right w-55px">
                                {numberWithToFixed(
                                  sumPercent(
                                    deviceDesktop.length > 0 &&
                                      deviceDesktop[0]?.metricValues[0]?.value,
                                    totalUsers.length > 0 &&
                                      totalUsers[0]?.metricValues[0]?.value,
                                  ),
                                ) || '-'}{' '}
                                %
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div>Mobile</div>
                            <div className="d-flex">
                              <div className="text-right mx-2">
                                {(deviceMobile.length > 0 &&
                                  numberWithCommas(
                                    deviceMobile[0]?.metricValues[0]?.value,
                                  )) ||
                                  '-'}
                              </div>
                              <div className="text-right w-55px">
                                {numberWithToFixed(
                                  sumPercent(
                                    deviceMobile.length > 0 &&
                                      deviceMobile[0]?.metricValues[0]?.value,
                                    totalUsers.length > 0 &&
                                      totalUsers[0]?.metricValues[0]?.value,
                                  ),
                                ) || '-'}{' '}
                                %
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div>Tablet</div>
                            <div className="d-flex ">
                              <div className="text-right mx-2">
                                {(deviceTablet.length > 0 &&
                                  numberWithCommas(
                                    deviceTablet[0]?.metricValues[0]?.value,
                                  )) ||
                                  '-'}
                              </div>
                              <div className="text-right w-55px">
                                {numberWithToFixed(
                                  sumPercent(
                                    deviceTablet.length > 0 &&
                                      deviceTablet[0]?.metricValues[0]?.value,
                                    totalUsers.length > 0 &&
                                      totalUsers[0]?.metricValues[0]?.value,
                                  ),
                                ) || '-'}{' '}
                                %
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div>Smart TV</div>
                            <div className="d-flex">
                              <div className="text-right mx-2">
                                {(deviceSmartTV.length > 0 &&
                                  numberWithCommas(
                                    deviceSmartTV[0]?.metricValues[0]?.value,
                                  )) ||
                                  '-'}
                              </div>
                              <div className="text-right  w-55px">
                                {numberWithToFixed(
                                  sumPercent(
                                    deviceSmartTV.length > 0 &&
                                      deviceSmartTV[0]?.metricValues[0]?.value,
                                    totalUsers.length > 0 &&
                                      totalUsers[0]?.metricValues[0]?.value,
                                  ),
                                ) || '-'}{' '}
                                %
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* step 1 */}
                    {/* step 2 */}
                    <div className="step-box">
                      <div className="border-step-box p-2">
                        <button
                          type="button"
                          className="btn badge-blue text-14 text-semibold px-3 mb-2"
                          disabled={
                            selectOption > 0 ||
                            valueStep1.value ||
                            valueStep2.value
                              ? false
                              : true
                          }
                        >
                          Step 2
                        </button>
                        <Select
                          value={valueStep2}
                          styles={{
                            placeholder: (base) => ({
                              ...base,
                              color: '#D0D5DD',
                            }),
                          }}
                          isClearable={isClearable}
                          options={eventListFunnel}
                          placeholder="Event name"
                          className="w-100 mb-2"
                          onChange={(e) => handleChangeEventFunnel(e, 2)}
                          isDisabled={
                            selectOption > 0 ||
                            valueStep1.value ||
                            valueStep2.value
                              ? false
                              : true
                          }
                        />
                        <div className="d-flex justify-content-between">
                          <span className="text-12 text-semibold">
                            Total users{' '}
                          </span>
                          <p className="text-12 mb-0">
                            {(totalUsers.length > 0 &&
                              numberWithCommas(
                                totalUsers[1]?.metricValues[0]?.value,
                              )) ||
                              '-'}{' '}
                            <span className="text-12 text-semibold mb-0">
                              {' '}
                              {numberWithToFixed(
                                sumPercent(
                                  totalUsers.length > 0 &&
                                    totalUsers[1]?.metricValues[0]?.value,
                                  totalUsers.length > 0 &&
                                    totalUsers[0]?.metricValues[0]?.value,
                                ),
                              ) || '0'}
                              %
                            </span>{' '}
                          </p>
                        </div>
                      </div>

                      <div className="border-step-box p-2 zero-scroll text-14 text-grey">
                        <div
                          className={`chart ${
                            totalUsers[1] && !isLoadingPage ? '' : 'd-none'
                          }`}
                          id="eventFunnelStep2"
                        ></div>

                        {totalUsers.length > 0 && totalUsers[1] ? (
                          isLoadingPage ? (
                            <LoadingComponent />
                          ) : (
                            <Fragment></Fragment>
                          )
                        ) : valueStep2.value && isLoadingPage ? (
                          <LoadingComponent />
                        ) : (
                          <div className="text-center">
                            Please select event to <br />
                            populate the table
                          </div>
                        )}
                      </div>
                      <div className="p-2 border-x mt-3">
                        <div className="w-100 text-12 text-semibold">
                          <div className="d-flex justify-content-between">
                            <div>Desktop</div>
                            <div className="d-flex">
                              <div className="text-right mx-2">
                                {(deviceDesktop.length > 0 &&
                                  numberWithCommas(
                                    deviceDesktop[1]?.metricValues[0]?.value,
                                  )) ||
                                  '-'}
                              </div>
                              <div className="text-right w-55px">
                                {numberWithToFixed(
                                  sumPercent(
                                    deviceDesktop.length > 0 &&
                                      deviceDesktop[1]?.metricValues[0]?.value,
                                    totalUsers.length > 0 &&
                                      totalUsers[1]?.metricValues[0]?.value,
                                  ),
                                ) || '-'}{' '}
                                %
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div>Mobile</div>
                            <div className="d-flex">
                              <div className="text-right mx-2">
                                {(deviceMobile.length > 0 &&
                                  numberWithCommas(
                                    deviceMobile[1]?.metricValues[0]?.value,
                                  )) ||
                                  '-'}
                              </div>
                              <div className="text-right w-55px">
                                {numberWithToFixed(
                                  sumPercent(
                                    deviceMobile.length > 0 &&
                                      deviceMobile[1]?.metricValues[0]?.value,
                                    totalUsers.length > 0 &&
                                      totalUsers[1]?.metricValues[0]?.value,
                                  ),
                                ) || '-'}{' '}
                                %
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div>Tablet</div>
                            <div className="d-flex ">
                              <div className="text-right mx-2">
                                {(deviceTablet.length > 0 &&
                                  numberWithCommas(
                                    deviceTablet[1]?.metricValues[0]?.value,
                                  )) ||
                                  '-'}
                              </div>
                              <div className="text-right w-55px">
                                {numberWithToFixed(
                                  sumPercent(
                                    deviceTablet.length > 0 &&
                                      deviceTablet[1]?.metricValues[0]?.value,
                                    totalUsers.length > 0 &&
                                      totalUsers[1]?.metricValues[0]?.value,
                                  ),
                                ) || '-'}{' '}
                                %
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div>Smart TV</div>
                            <div className="d-flex">
                              <div className="text-right mx-2">
                                {(deviceSmartTV.length > 0 &&
                                  numberWithCommas(
                                    deviceSmartTV[1]?.metricValues[0]?.value,
                                  )) ||
                                  '-'}
                              </div>
                              <div className="text-right  w-55px">
                                {numberWithToFixed(
                                  sumPercent(
                                    deviceSmartTV.length > 0 &&
                                      deviceSmartTV[1]?.metricValues[0]?.value,
                                    totalUsers.length > 0 &&
                                      totalUsers[1]?.metricValues[0]?.value,
                                  ),
                                ) || '-'}{' '}
                                %
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* step 2 */}

                    {/* step 3 */}
                    <div className="step-box">
                      <div className="border-step-box p-2">
                        <button
                          type="button"
                          className="btn badge-blue text-14 text-semibold px-3 mb-2"
                          disabled={
                            selectOption > 1 ||
                            valueStep2.value ||
                            valueStep3.value
                              ? false
                              : true
                          }
                        >
                          Step 3
                        </button>
                        <Select
                          value={valueStep3}
                          styles={{
                            placeholder: (base) => ({
                              ...base,
                              color: '#D0D5DD',
                            }),
                          }}
                          isClearable={isClearable}
                          options={eventListFunnel}
                          placeholder="Event name"
                          className="w-100 mb-2"
                          onChange={(e) => handleChangeEventFunnel(e, 3)}
                          isDisabled={
                            selectOption > 1 ||
                            valueStep2.value ||
                            valueStep3.value
                              ? false
                              : true
                          }
                        />
                        <div className="d-flex justify-content-between">
                          <span className="text-12 text-semibold">
                            Total users{' '}
                          </span>
                          <p className="text-12 mb-0">
                            {(totalUsers.length > 0 &&
                              numberWithCommas(
                                totalUsers[2]?.metricValues[0]?.value,
                              )) ||
                              '-'}{' '}
                            <span className="text-12 text-semibold mb-0">
                              {' '}
                              {numberWithToFixed(
                                sumPercent(
                                  totalUsers.length > 0 &&
                                    totalUsers[2]?.metricValues[0]?.value,
                                  totalUsers.length > 0 &&
                                    totalUsers[0]?.metricValues[0]?.value,
                                ),
                              ) || '0'}
                              %
                            </span>
                          </p>
                        </div>
                      </div>

                      <div className="border-step-box p-2 zero-scroll text-14 text-grey">
                        <div
                          className={`chart ${
                            totalUsers[2] && !isLoadingPage ? '' : 'd-none'
                          }`}
                          id="eventFunnelStep3"
                        ></div>
                        {totalUsers.length > 0 && totalUsers[2] ? (
                          isLoadingPage ? (
                            <LoadingComponent />
                          ) : (
                            <Fragment></Fragment>
                          )
                        ) : valueStep3.value && isLoadingPage ? (
                          <LoadingComponent />
                        ) : (
                          <div className="text-center">
                            Please select event to <br />
                            populate the table
                          </div>
                        )}
                      </div>
                      <div className="p-2 border-x mt-3">
                        <div className="w-100 text-12 text-semibold">
                          <div className="d-flex justify-content-between">
                            <div>Desktop</div>
                            <div className="d-flex">
                              <div className="text-right mx-2">
                                {(deviceDesktop.length > 0 &&
                                  numberWithCommas(
                                    deviceDesktop[2]?.metricValues[0]?.value,
                                  )) ||
                                  '-'}
                              </div>
                              <div className="text-right w-55px">
                                {numberWithToFixed(
                                  sumPercent(
                                    deviceDesktop.length > 0 &&
                                      deviceDesktop[2]?.metricValues[0]?.value,
                                    totalUsers.length > 0 &&
                                      totalUsers[2]?.metricValues[0]?.value,
                                  ),
                                ) || '-'}{' '}
                                %
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div>Mobile</div>
                            <div className="d-flex">
                              <div className="text-right mx-2">
                                {(deviceMobile.length > 0 &&
                                  numberWithCommas(
                                    deviceMobile[2]?.metricValues[0]?.value,
                                  )) ||
                                  '-'}
                              </div>
                              <div className="text-right w-55px">
                                {numberWithToFixed(
                                  sumPercent(
                                    deviceMobile.length > 0 &&
                                      deviceMobile[2]?.metricValues[0]?.value,
                                    totalUsers.length > 0 &&
                                      totalUsers[2]?.metricValues[0]?.value,
                                  ),
                                ) || '-'}{' '}
                                %
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div>Tablet</div>
                            <div className="d-flex ">
                              <div className="text-right mx-2">
                                {(deviceTablet.length > 0 &&
                                  numberWithCommas(
                                    deviceTablet[2]?.metricValues[0]?.value,
                                  )) ||
                                  '-'}
                              </div>
                              <div className="text-right w-55px">
                                {numberWithToFixed(
                                  sumPercent(
                                    deviceTablet.length > 0 &&
                                      deviceTablet[2]?.metricValues[0]?.value,
                                    totalUsers.length > 0 &&
                                      totalUsers[2]?.metricValues[0]?.value,
                                  ),
                                ) || '-'}{' '}
                                %
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div>Smart TV</div>
                            <div className="d-flex">
                              <div className="text-right mx-2">
                                {(deviceSmartTV.length > 0 &&
                                  numberWithCommas(
                                    deviceSmartTV[2]?.metricValues[0]?.value,
                                  )) ||
                                  '-'}
                              </div>
                              <div className="text-right  w-55px">
                                {numberWithToFixed(
                                  sumPercent(
                                    deviceSmartTV.length > 0 &&
                                      deviceSmartTV[2]?.metricValues[0]?.value,
                                    totalUsers.length > 0 &&
                                      totalUsers[2]?.metricValues[0]?.value,
                                  ),
                                ) || '-'}{' '}
                                %
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* step 3 */}
                    {/* step 4 */}
                    <div className="step-box">
                      <div className="border-step-box p-2">
                        <button
                          type="button"
                          className="btn badge-blue text-14 text-semibold px-3 mb-2"
                          disabled={
                            selectOption > 2 ||
                            valueStep3.value ||
                            valueStep4.value
                              ? false
                              : true
                          }
                        >
                          Step 4
                        </button>
                        <Select
                          value={valueStep4}
                          styles={{
                            placeholder: (base) => ({
                              ...base,
                              color: '#D0D5DD',
                            }),
                          }}
                          options={eventListFunnel}
                          isClearable={isClearable}
                          placeholder="Event name"
                          className="w-100 mb-2"
                          onChange={(e) => handleChangeEventFunnel(e, 4)}
                          isDisabled={
                            selectOption > 2 ||
                            valueStep3.value ||
                            valueStep4.value
                              ? false
                              : true
                          }
                        />
                        <div className="d-flex justify-content-between">
                          <span className="text-12 text-semibold">
                            Total users{' '}
                          </span>
                          <p className="text-12 mb-0">
                            {(totalUsers.length > 0 &&
                              numberWithCommas(
                                totalUsers[3]?.metricValues[0]?.value,
                              )) ||
                              '-'}{' '}
                            <span className="text-12 text-semibold mb-0">
                              {' '}
                              {numberWithToFixed(
                                sumPercent(
                                  totalUsers.length > 0 &&
                                    totalUsers[3]?.metricValues[0]?.value,
                                  totalUsers.length > 0 &&
                                    totalUsers[0]?.metricValues[0]?.value,
                                ),
                              ) || '0'}
                              %
                            </span>
                          </p>
                        </div>
                      </div>

                      <div className="border-step-box p-2 zero-scroll text-14 text-grey">
                        <div
                          className={`chart ${
                            totalUsers[3] && !isLoadingPage ? '' : 'd-none'
                          }`}
                          id="eventFunnelStep4"
                        ></div>

                        {totalUsers.length > 0 && totalUsers[3] ? (
                          isLoadingPage ? (
                            <LoadingComponent />
                          ) : (
                            <Fragment></Fragment>
                          )
                        ) : valueStep4.value && isLoadingPage ? (
                          <LoadingComponent />
                        ) : (
                          <div className="text-center">
                            Please select event to <br />
                            populate the table
                          </div>
                        )}
                      </div>
                      <div className="p-2 border-x mt-3">
                        <div className="w-100 text-12 text-semibold">
                          <div className="d-flex justify-content-between">
                            <div>Desktop</div>
                            <div className="d-flex">
                              <div className="text-right mx-2">
                                {(deviceDesktop.length > 0 &&
                                  numberWithCommas(
                                    deviceDesktop[3]?.metricValues[0]?.value,
                                  )) ||
                                  '-'}
                              </div>
                              <div className="text-right w-55px">
                                {numberWithToFixed(
                                  sumPercent(
                                    deviceDesktop.length > 0 &&
                                      deviceDesktop[3]?.metricValues[0]?.value,
                                    totalUsers.length > 0 &&
                                      totalUsers[3]?.metricValues[0]?.value,
                                  ),
                                ) || '-'}{' '}
                                %
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div>Mobile</div>
                            <div className="d-flex">
                              <div className="text-right mx-2">
                                {(deviceMobile.length > 0 &&
                                  numberWithCommas(
                                    deviceMobile[3]?.metricValues[0]?.value,
                                  )) ||
                                  '-'}
                              </div>
                              <div className="text-right w-55px">
                                {numberWithToFixed(
                                  sumPercent(
                                    deviceMobile.length > 0 &&
                                      deviceMobile[3]?.metricValues[0]?.value,
                                    totalUsers.length > 0 &&
                                      totalUsers[3]?.metricValues[0]?.value,
                                  ),
                                ) || '-'}{' '}
                                %
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div>Tablet</div>
                            <div className="d-flex ">
                              <div className="text-right mx-2">
                                {(deviceTablet.length > 0 &&
                                  numberWithCommas(
                                    deviceTablet[3]?.metricValues[0]?.value,
                                  )) ||
                                  '-'}
                              </div>
                              <div className="text-right w-55px">
                                {numberWithToFixed(
                                  sumPercent(
                                    deviceTablet.length > 0 &&
                                      deviceTablet[3]?.metricValues[0]?.value,
                                    totalUsers.length > 0 &&
                                      totalUsers[3]?.metricValues[0]?.value,
                                  ),
                                ) || '-'}{' '}
                                %
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div>Smart TV</div>
                            <div className="d-flex">
                              <div className="text-right mx-2">
                                {(deviceSmartTV.length > 0 &&
                                  numberWithCommas(
                                    deviceSmartTV[3]?.metricValues[0]?.value,
                                  )) ||
                                  '-'}
                              </div>
                              <div className="text-right  w-55px">
                                {numberWithToFixed(
                                  sumPercent(
                                    deviceSmartTV.length > 0 &&
                                      deviceSmartTV[3]?.metricValues[0]?.value,
                                    totalUsers.length > 0 &&
                                      totalUsers[3]?.metricValues[0]?.value,
                                  ),
                                ) || '-'}{' '}
                                %
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* step 4 */}
                    {/* step 5 */}
                    <div className="step-box">
                      <div className="border-step-box p-2">
                        <button
                          type="button"
                          className="btn badge-blue text-14 text-semibold px-3 mb-2"
                          disabled={
                            selectOption > 3 ||
                            valueStep4.value ||
                            valueStep5.value
                              ? false
                              : true
                          }
                        >
                          Step 5
                        </button>
                        <Select
                          value={valueStep5}
                          styles={{
                            placeholder: (base) => ({
                              ...base,
                              color: '#D0D5DD',
                            }),
                          }}
                          isClearable={isClearable}
                          options={eventListFunnel}
                          placeholder="Event name"
                          className="w-100 mb-2"
                          onChange={(e) => handleChangeEventFunnel(e, 5)}
                          isDisabled={
                            selectOption > 3 ||
                            valueStep4.value ||
                            valueStep5.value
                              ? false
                              : true
                          }
                        />
                        <div className="d-flex justify-content-between">
                          <span className="text-12 text-semibold">
                            Total users{' '}
                          </span>
                          <p className="text-12 mb-0">
                            {(totalUsers.length > 0 &&
                              numberWithCommas(
                                totalUsers[4]?.metricValues[0]?.value,
                              )) ||
                              '-'}{' '}
                            <span className="text-12 text-semibold mb-0">
                              {' '}
                              {numberWithToFixed(
                                sumPercent(
                                  totalUsers.length > 0 &&
                                    totalUsers[4]?.metricValues[0]?.value,
                                  totalUsers.length > 0 &&
                                    totalUsers[0]?.metricValues[0]?.value,
                                ),
                              ) || '0'}
                              %
                            </span>
                          </p>
                        </div>
                      </div>

                      <div className="border-step-box p-2 zero-scroll text-14 text-grey">
                        <div
                          className={`chart ${
                            totalUsers[4] && !isLoadingPage ? '' : 'd-none'
                          }`}
                          id="eventFunnelStep5"
                        ></div>
                        {totalUsers.length > 0 && totalUsers[4] ? (
                          isLoadingPage ? (
                            <LoadingComponent />
                          ) : (
                            <Fragment></Fragment>
                          )
                        ) : valueStep5.value && isLoadingPage ? (
                          <LoadingComponent />
                        ) : (
                          <div className="text-center">
                            Please select event to <br />
                            populate the table
                          </div>
                        )}
                      </div>
                      <div className="p-2 border-x mt-3">
                        <div className="w-100 text-12 text-semibold">
                          <div className="d-flex justify-content-between">
                            <div>Desktop</div>
                            <div className="d-flex">
                              <div className="text-right mx-2">
                                {(deviceDesktop.length > 0 &&
                                  numberWithCommas(
                                    deviceDesktop[4]?.metricValues[0]?.value,
                                  )) ||
                                  '-'}
                              </div>
                              <div className="text-right w-55px">
                                {numberWithToFixed(
                                  sumPercent(
                                    deviceDesktop.length > 0 &&
                                      deviceDesktop[4]?.metricValues[0]?.value,
                                    totalUsers.length > 0 &&
                                      totalUsers[4]?.metricValues[0]?.value,
                                  ),
                                ) || '-'}{' '}
                                %
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div>Mobile</div>
                            <div className="d-flex">
                              <div className="text-right mx-2">
                                {(deviceMobile.length > 0 &&
                                  numberWithCommas(
                                    deviceMobile[4]?.metricValues[0]?.value,
                                  )) ||
                                  '-'}
                              </div>
                              <div className="text-right w-55px">
                                {numberWithToFixed(
                                  sumPercent(
                                    deviceMobile.length > 0 &&
                                      deviceMobile[4]?.metricValues[0]?.value,
                                    totalUsers.length > 0 &&
                                      totalUsers[4]?.metricValues[0]?.value,
                                  ),
                                ) || '-'}{' '}
                                %
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div>Tablet</div>
                            <div className="d-flex ">
                              <div className="text-right mx-2">
                                {(deviceTablet.length > 0 &&
                                  numberWithCommas(
                                    deviceTablet[4]?.metricValues[0]?.value,
                                  )) ||
                                  '-'}
                              </div>
                              <div className="text-right w-55px">
                                {numberWithToFixed(
                                  sumPercent(
                                    deviceTablet.length > 0 &&
                                      deviceTablet[4]?.metricValues[0]?.value,
                                    totalUsers.length > 0 &&
                                      totalUsers[4]?.metricValues[0]?.value,
                                  ),
                                ) || '-'}{' '}
                                %
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div>Smart TV</div>
                            <div className="d-flex">
                              <div className="text-right mx-2">
                                {(deviceSmartTV.length > 0 &&
                                  numberWithCommas(
                                    deviceSmartTV[4]?.metricValues[0]?.value,
                                  )) ||
                                  '-'}
                              </div>
                              <div className="text-right  w-55px">
                                {numberWithToFixed(
                                  sumPercent(
                                    deviceSmartTV.length > 0 &&
                                      deviceSmartTV[4]?.metricValues[0]?.value,
                                    totalUsers.length > 0 &&
                                      totalUsers[4]?.metricValues[0]?.value,
                                  ),
                                ) || '-'}{' '}
                                %
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* step 5 */}
                    {/* step 6 */}
                    <div className="step-box">
                      <div className="border-step-box p-2">
                        <button
                          type="button"
                          className="btn badge-blue text-14 text-semibold px-3 mb-2"
                          disabled={
                            selectOption > 4 ||
                            valueStep5.value ||
                            valueStep6.value
                              ? false
                              : true
                          }
                        >
                          Step 6
                        </button>
                        <Select
                          value={valueStep6}
                          styles={{
                            placeholder: (base) => ({
                              ...base,
                              color: '#D0D5DD',
                            }),
                          }}
                          isClearable={isClearable}
                          options={eventListFunnel}
                          placeholder="Event name"
                          className="w-100 mb-2"
                          onChange={(e) => handleChangeEventFunnel(e, 6)}
                          isDisabled={
                            selectOption > 4 ||
                            valueStep5.value ||
                            valueStep6.value
                              ? false
                              : true
                          }
                        />
                        <div className="d-flex justify-content-between">
                          <span className="text-12 text-semibold">
                            Total users{' '}
                          </span>
                          <p className="text-12 mb-0">
                            {(totalUsers.length > 0 &&
                              numberWithCommas(
                                totalUsers[5]?.metricValues[0]?.value,
                              )) ||
                              '-'}{' '}
                            <span className="text-12 text-semibold mb-0">
                              {' '}
                              {numberWithToFixed(
                                sumPercent(
                                  totalUsers.length > 0 &&
                                    totalUsers[5]?.metricValues[0]?.value,
                                  totalUsers.length > 0 &&
                                    totalUsers[0]?.metricValues[0]?.value,
                                ),
                              ) || '0'}
                              %
                            </span>
                          </p>
                        </div>
                      </div>

                      <div className="border-step-box p-2 zero-scroll text-14 text-grey">
                        <div
                          className={`chart ${
                            totalUsers[5] && !isLoadingPage ? '' : 'd-none'
                          }`}
                          id="eventFunnelStep6"
                        ></div>
                        {totalUsers.length > 0 && totalUsers[5] ? (
                          isLoadingPage ? (
                            <LoadingComponent />
                          ) : (
                            <Fragment></Fragment>
                          )
                        ) : valueStep6.value && isLoadingPage ? (
                          <LoadingComponent />
                        ) : (
                          <div className="text-center">
                            Please select event to <br />
                            populate the table
                          </div>
                        )}
                      </div>
                      <div className="p-2 border-x mt-3">
                        <div className="w-100 text-12 text-semibold">
                          <div className="d-flex justify-content-between">
                            <div>Desktop</div>
                            <div className="d-flex">
                              <div className="text-right mx-2">
                                {(deviceDesktop.length > 0 &&
                                  numberWithCommas(
                                    deviceDesktop[5]?.metricValues[0]?.value,
                                  )) ||
                                  '-'}
                              </div>
                              <div className="text-right w-55px">
                                {numberWithToFixed(
                                  sumPercent(
                                    deviceDesktop.length > 0 &&
                                      deviceDesktop[5]?.metricValues[0]?.value,
                                    totalUsers.length > 0 &&
                                      totalUsers[5]?.metricValues[0]?.value,
                                  ),
                                ) || '-'}{' '}
                                %
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div>Mobile</div>
                            <div className="d-flex">
                              <div className="text-right mx-2">
                                {(deviceMobile.length > 0 &&
                                  numberWithCommas(
                                    deviceMobile[5]?.metricValues[0]?.value,
                                  )) ||
                                  '-'}
                              </div>
                              <div className="text-right w-55px">
                                {numberWithToFixed(
                                  sumPercent(
                                    deviceMobile.length > 0 &&
                                      deviceMobile[5]?.metricValues[0]?.value,
                                    totalUsers.length > 0 &&
                                      totalUsers[5]?.metricValues[0]?.value,
                                  ),
                                ) || '-'}{' '}
                                %
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div>Tablet</div>
                            <div className="d-flex ">
                              <div className="text-right mx-2">
                                {(deviceTablet.length > 0 &&
                                  numberWithCommas(
                                    deviceTablet[5]?.metricValues[0]?.value,
                                  )) ||
                                  '-'}
                              </div>
                              <div className="text-right w-55px">
                                {numberWithToFixed(
                                  sumPercent(
                                    deviceTablet.length > 0 &&
                                      deviceTablet[5]?.metricValues[0]?.value,
                                    totalUsers.length > 0 &&
                                      totalUsers[5]?.metricValues[0]?.value,
                                  ),
                                ) || '-'}{' '}
                                %
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div>Smart TV</div>
                            <div className="d-flex">
                              <div className="text-right mx-2">
                                {(deviceSmartTV.length > 0 &&
                                  numberWithCommas(
                                    deviceSmartTV[5]?.metricValues[0]?.value,
                                  )) ||
                                  '-'}
                              </div>
                              <div className="text-right  w-55px">
                                {numberWithToFixed(
                                  sumPercent(
                                    deviceSmartTV.length > 0 &&
                                      deviceSmartTV[5]?.metricValues[0]?.value,
                                    totalUsers.length > 0 &&
                                      totalUsers[5]?.metricValues[0]?.value,
                                  ),
                                ) || '-'}{' '}
                                %
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* step 6 */}
                    {/* step 7 */}
                    <div className="step-box">
                      <div className="border-step-box p-2">
                        <button
                          type="button"
                          className="btn badge-blue text-14 text-semibold px-3 mb-2"
                          disabled={
                            selectOption > 5 ||
                            valueStep6.value ||
                            valueStep7.value
                              ? false
                              : true
                          }
                        >
                          Step 7
                        </button>
                        <Select
                          value={valueStep7}
                          styles={{
                            placeholder: (base) => ({
                              ...base,
                              color: '#D0D5DD',
                            }),
                          }}
                          isClearable={isClearable}
                          options={eventListFunnel}
                          placeholder="Event name"
                          className="w-100 mb-2"
                          onChange={(e) => handleChangeEventFunnel(e, 7)}
                          isDisabled={
                            selectOption > 5 ||
                            valueStep6.value ||
                            valueStep7.value
                              ? false
                              : true
                          }
                        />
                        <div className="d-flex justify-content-between">
                          <span className="text-12 text-semibold">
                            Total users{' '}
                          </span>
                          <p className="text-12 mb-0">
                            {(totalUsers.length > 0 &&
                              numberWithCommas(
                                totalUsers[6]?.metricValues[0]?.value,
                              )) ||
                              '-'}{' '}
                            <span className="text-12 text-semibold mb-0">
                              {' '}
                              {numberWithToFixed(
                                sumPercent(
                                  totalUsers.length > 0 &&
                                    totalUsers[6]?.metricValues[0]?.value,
                                  totalUsers.length > 0 &&
                                    totalUsers[0]?.metricValues[0]?.value,
                                ),
                              ) || '0'}
                              %
                            </span>
                          </p>
                        </div>
                      </div>

                      <div className="border-step-box p-2 zero-scroll text-14 text-grey">
                        <div
                          className={`chart ${
                            totalUsers[6] && !isLoadingPage ? '' : 'd-none'
                          }`}
                          id="eventFunnelStep7"
                        ></div>
                        {totalUsers.length > 0 && totalUsers[6] ? (
                          isLoadingPage ? (
                            <LoadingComponent />
                          ) : (
                            <Fragment></Fragment>
                          )
                        ) : valueStep7.value && isLoadingPage ? (
                          <LoadingComponent />
                        ) : (
                          <div className="text-center">
                            Please select event to <br />
                            populate the table
                          </div>
                        )}
                      </div>
                      <div className="p-2 border-x mt-3">
                        <div className="w-100 text-12 text-semibold">
                          <div className="d-flex justify-content-between">
                            <div>Desktop</div>
                            <div className="d-flex">
                              <div className="text-right mx-2">
                                {(deviceDesktop.length > 0 &&
                                  numberWithCommas(
                                    deviceDesktop[6]?.metricValues[0]?.value,
                                  )) ||
                                  '-'}
                              </div>
                              <div className="text-right w-55px">
                                {numberWithToFixed(
                                  sumPercent(
                                    deviceDesktop.length > 0 &&
                                      deviceDesktop[6]?.metricValues[0]?.value,
                                    totalUsers.length > 0 &&
                                      totalUsers[6]?.metricValues[0]?.value,
                                  ),
                                ) || '-'}{' '}
                                %
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div>Mobile</div>
                            <div className="d-flex">
                              <div className="text-right mx-2">
                                {(deviceMobile.length > 0 &&
                                  numberWithCommas(
                                    deviceMobile[6]?.metricValues[0]?.value,
                                  )) ||
                                  '-'}
                              </div>
                              <div className="text-right w-55px">
                                {numberWithToFixed(
                                  sumPercent(
                                    deviceMobile.length > 0 &&
                                      deviceMobile[6]?.metricValues[0]?.value,
                                    totalUsers.length > 0 &&
                                      totalUsers[6]?.metricValues[0]?.value,
                                  ),
                                ) || '-'}{' '}
                                %
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div>Tablet</div>
                            <div className="d-flex ">
                              <div className="text-right mx-2">
                                {(deviceTablet.length > 0 &&
                                  numberWithCommas(
                                    deviceTablet[6]?.metricValues[0]?.value,
                                  )) ||
                                  '-'}
                              </div>
                              <div className="text-right w-55px">
                                {numberWithToFixed(
                                  sumPercent(
                                    deviceTablet.length > 0 &&
                                      deviceTablet[6]?.metricValues[0]?.value,
                                    totalUsers.length > 0 &&
                                      totalUsers[6]?.metricValues[0]?.value,
                                  ),
                                ) || '-'}{' '}
                                %
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div>Smart TV</div>
                            <div className="d-flex">
                              <div className="text-right mx-2">
                                {(deviceSmartTV.length > 0 &&
                                  numberWithCommas(
                                    deviceSmartTV[6]?.metricValues[0]?.value,
                                  )) ||
                                  '-'}
                              </div>
                              <div className="text-right  w-55px">
                                {numberWithToFixed(
                                  sumPercent(
                                    deviceSmartTV.length > 0 &&
                                      deviceSmartTV[6]?.metricValues[0]?.value,
                                    totalUsers.length > 0 &&
                                      totalUsers[6]?.metricValues[0]?.value,
                                  ),
                                ) || '-'}{' '}
                                %
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* step 7 */}
                    {/* step 8 */}
                    <div className="step-box">
                      <div className="border-step-box p-2">
                        <button
                          type="button"
                          className="btn badge-blue text-14 text-semibold px-3 mb-2"
                          disabled={
                            selectOption > 6 ||
                            valueStep7.value ||
                            valueStep8.value
                              ? false
                              : true
                          }
                        >
                          Step 8
                        </button>
                        <Select
                          value={valueStep8}
                          styles={{
                            placeholder: (base) => ({
                              ...base,
                              color: '#D0D5DD',
                            }),
                          }}
                          options={eventListFunnel}
                          isClearable={isClearable}
                          placeholder="Event name"
                          className="w-100 mb-2"
                          onChange={(e) => handleChangeEventFunnel(e, 8)}
                          isDisabled={
                            selectOption > 6 ||
                            valueStep7.value ||
                            valueStep8.value
                              ? false
                              : true
                          }
                        />
                        <div className="d-flex justify-content-between">
                          <span className="text-12 text-semibold">
                            Total users{' '}
                          </span>
                          <p className="text-12 mb-0">
                            {(totalUsers.length > 0 &&
                              numberWithCommas(
                                totalUsers[7]?.metricValues[0]?.value,
                              )) ||
                              '-'}{' '}
                            <span className="text-12 text-semibold mb-0">
                              {' '}
                              {numberWithToFixed(
                                sumPercent(
                                  totalUsers.length > 0 &&
                                    totalUsers[7]?.metricValues[0]?.value,
                                  totalUsers.length > 0 &&
                                    totalUsers[0]?.metricValues[0]?.value,
                                ),
                              ) || '0'}
                              %
                            </span>{' '}
                          </p>
                        </div>
                      </div>

                      <div className="border-step-box p-2 zero-scroll text-14 text-grey">
                        <div
                          className={`chart ${
                            totalUsers[7] && !isLoadingPage ? '' : 'd-none'
                          }`}
                          id="eventFunnelStep8"
                        ></div>
                        {totalUsers.length > 0 && totalUsers[7] ? (
                          isLoadingPage ? (
                            <LoadingComponent />
                          ) : (
                            <Fragment></Fragment>
                          )
                        ) : valueStep8.value && isLoadingPage ? (
                          <LoadingComponent />
                        ) : (
                          <div className="text-center">
                            Please select event to <br />
                            populate the table
                          </div>
                        )}
                      </div>
                      <div className="p-2 border-x mt-3">
                        <div className="w-100 text-12 text-semibold">
                          <div className="d-flex justify-content-between">
                            <div>Desktop</div>
                            <div className="d-flex">
                              <div className="text-right mx-2">
                                {(deviceDesktop.length > 0 &&
                                  numberWithCommas(
                                    deviceDesktop[7]?.metricValues[0]?.value,
                                  )) ||
                                  '-'}
                              </div>
                              <div className="text-right w-55px">
                                {numberWithToFixed(
                                  sumPercent(
                                    deviceDesktop.length > 0 &&
                                      deviceDesktop[7]?.metricValues[0]?.value,
                                    totalUsers.length > 0 &&
                                      totalUsers[7]?.metricValues[0]?.value,
                                  ),
                                ) || '-'}{' '}
                                %
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div>Mobile</div>
                            <div className="d-flex">
                              <div className="text-right mx-2">
                                {(deviceMobile.length > 0 &&
                                  numberWithCommas(
                                    deviceMobile[7]?.metricValues[0]?.value,
                                  )) ||
                                  '-'}
                              </div>
                              <div className="text-right w-55px">
                                {numberWithToFixed(
                                  sumPercent(
                                    deviceMobile.length > 0 &&
                                      deviceMobile[7]?.metricValues[0]?.value,
                                    totalUsers.length > 0 &&
                                      totalUsers[7]?.metricValues[0]?.value,
                                  ),
                                ) || '-'}{' '}
                                %
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div>Tablet</div>
                            <div className="d-flex ">
                              <div className="text-right mx-2">
                                {(deviceTablet.length > 0 &&
                                  numberWithCommas(
                                    deviceTablet[7]?.metricValues[0]?.value,
                                  )) ||
                                  '-'}
                              </div>
                              <div className="text-right w-55px">
                                {numberWithToFixed(
                                  sumPercent(
                                    deviceTablet.length > 0 &&
                                      deviceTablet[7]?.metricValues[0]?.value,
                                    totalUsers.length > 0 &&
                                      totalUsers[7]?.metricValues[0]?.value,
                                  ),
                                ) || '-'}{' '}
                                %
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div>Smart TV</div>
                            <div className="d-flex">
                              <div className="text-right mx-2">
                                {(deviceSmartTV.length > 0 &&
                                  numberWithCommas(
                                    deviceSmartTV[7]?.metricValues[0]?.value,
                                  )) ||
                                  '-'}
                              </div>
                              <div className="text-right  w-55px">
                                {numberWithToFixed(
                                  sumPercent(
                                    deviceSmartTV.length > 0 &&
                                      deviceSmartTV[7]?.metricValues[0]?.value,
                                    totalUsers.length > 0 &&
                                      totalUsers[7]?.metricValues[0]?.value,
                                  ),
                                ) || '-'}{' '}
                                %
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* step 8 */}
                    {/* step 9 */}
                    <div className="step-box">
                      <div className="border-step-box p-2">
                        <button
                          type="button"
                          className="btn badge-blue text-14 text-semibold px-3 mb-2"
                          disabled={
                            selectOption > 7 ||
                            valueStep8.value ||
                            valueStep9.value
                              ? false
                              : true
                          }
                        >
                          Step 9
                        </button>
                        <Select
                          value={valueStep9}
                          styles={{
                            placeholder: (base) => ({
                              ...base,
                              color: '#D0D5DD',
                            }),
                          }}
                          options={eventListFunnel}
                          isClearable={isClearable}
                          placeholder="Event name"
                          className="w-100 mb-2"
                          onChange={(e) => handleChangeEventFunnel(e, 9)}
                          isDisabled={
                            selectOption > 7 ||
                            valueStep8.value ||
                            valueStep9.value
                              ? false
                              : true
                          }
                        />
                        <div className="d-flex justify-content-between">
                          <span className="text-12 text-semibold">
                            Total users{' '}
                          </span>
                          <p className="text-12 mb-0">
                            {(totalUsers.length > 0 &&
                              numberWithCommas(
                                totalUsers[8]?.metricValues[0]?.value,
                              )) ||
                              '-'}{' '}
                            <span className="text-12 text-semibold mb-0">
                              {' '}
                              {numberWithToFixed(
                                sumPercent(
                                  totalUsers.length > 0 &&
                                    totalUsers[8]?.metricValues[0]?.value,
                                  totalUsers.length > 0 &&
                                    totalUsers[0]?.metricValues[0]?.value,
                                ),
                              ) || '0'}
                              %
                            </span>
                          </p>
                        </div>
                      </div>

                      <div className="border-step-box p-2 zero-scroll text-14 text-grey">
                        <div
                          className={`chart ${
                            totalUsers[8] && !isLoadingPage ? '' : 'd-none'
                          }`}
                          id="eventFunnelStep9"
                        ></div>
                        {totalUsers.length > 0 && totalUsers[8] ? (
                          isLoadingPage ? (
                            <LoadingComponent />
                          ) : (
                            <Fragment></Fragment>
                          )
                        ) : valueStep9.value && isLoadingPage ? (
                          <LoadingComponent />
                        ) : (
                          <div className="text-center">
                            Please select event to <br />
                            populate the table
                          </div>
                        )}
                      </div>
                      <div className="p-2 border-x mt-3">
                        <div className="w-100 text-12 text-semibold">
                          <div className="d-flex justify-content-between">
                            <div>Desktop</div>
                            <div className="d-flex">
                              <div className="text-right mx-2">
                                {(deviceDesktop.length > 0 &&
                                  numberWithCommas(
                                    deviceDesktop[8]?.metricValues[0]?.value,
                                  )) ||
                                  '-'}
                              </div>
                              <div className="text-right w-55px">
                                {numberWithToFixed(
                                  sumPercent(
                                    deviceDesktop.length > 0 &&
                                      deviceDesktop[8]?.metricValues[0]?.value,
                                    totalUsers.length > 0 &&
                                      totalUsers[8]?.metricValues[0]?.value,
                                  ),
                                ) || '-'}{' '}
                                %
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div>Mobile</div>
                            <div className="d-flex">
                              <div className="text-right mx-2">
                                {(deviceMobile.length > 0 &&
                                  numberWithCommas(
                                    deviceMobile[8]?.metricValues[0]?.value,
                                  )) ||
                                  '-'}
                              </div>
                              <div className="text-right w-55px">
                                {numberWithToFixed(
                                  sumPercent(
                                    deviceMobile.length > 0 &&
                                      deviceMobile[8]?.metricValues[0]?.value,
                                    totalUsers.length > 0 &&
                                      totalUsers[8]?.metricValues[0]?.value,
                                  ),
                                ) || '-'}{' '}
                                %
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div>Tablet</div>
                            <div className="d-flex ">
                              <div className="text-right mx-2">
                                {(deviceTablet.length > 0 &&
                                  numberWithCommas(
                                    deviceTablet[8]?.metricValues[0]?.value,
                                  )) ||
                                  '-'}
                              </div>
                              <div className="text-right w-55px">
                                {numberWithToFixed(
                                  sumPercent(
                                    deviceTablet.length > 0 &&
                                      deviceTablet[8]?.metricValues[0]?.value,
                                    totalUsers.length > 0 &&
                                      totalUsers[8]?.metricValues[0]?.value,
                                  ),
                                ) || '-'}{' '}
                                %
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div>Smart TV</div>
                            <div className="d-flex">
                              <div className="text-right mx-2">
                                {(deviceSmartTV.length > 0 &&
                                  numberWithCommas(
                                    deviceSmartTV[8]?.metricValues[0]?.value,
                                  )) ||
                                  '-'}
                              </div>
                              <div className="text-right  w-55px">
                                {numberWithToFixed(
                                  sumPercent(
                                    deviceSmartTV.length > 0 &&
                                      deviceSmartTV[8]?.metricValues[0]?.value,
                                    totalUsers.length > 0 &&
                                      totalUsers[8]?.metricValues[0]?.value,
                                  ),
                                ) || '-'}{' '}
                                %
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* step 9 */}
                    {/* step 10 */}
                    <div className="step-box">
                      <div className="border-step-box p-2">
                        <button
                          type="button"
                          className="btn badge-blue text-14 text-semibold px-3 mb-2"
                          disabled={
                            selectOption > 8 ||
                            valueStep9.value ||
                            valueStep10.value
                              ? false
                              : true
                          }
                        >
                          Step 10
                        </button>
                        <Select
                          value={valueStep10}
                          styles={{
                            placeholder: (base) => ({
                              ...base,
                              color: '#D0D5DD',
                            }),
                          }}
                          options={eventListFunnel}
                          isClearable={isClearable}
                          placeholder="Event name"
                          className="w-100 mb-2"
                          onChange={(e) => handleChangeEventFunnel(e, 10)}
                          isDisabled={
                            selectOption > 8 ||
                            valueStep9.value ||
                            valueStep10.value
                              ? false
                              : true
                          }
                        />
                        <div className="d-flex justify-content-between">
                          <span className="text-12 text-semibold">
                            Total users{' '}
                          </span>
                          <p className="text-12 mb-0">
                            {(totalUsers.length > 0 &&
                              numberWithCommas(
                                totalUsers[9]?.metricValues[0]?.value,
                              )) ||
                              '-'}{' '}
                            <span className="text-12 text-semibold mb-0">
                              {' '}
                              {numberWithToFixed(
                                sumPercent(
                                  totalUsers.length > 0 &&
                                    totalUsers[9]?.metricValues[0]?.value,
                                  totalUsers.length > 0 &&
                                    totalUsers[0]?.metricValues[0]?.value,
                                ),
                              ) || '0'}
                              %
                            </span>
                          </p>
                        </div>
                      </div>

                      <div className="border-step-box p-2 zero-scroll text-14 text-grey">
                        <div
                          className={`chart ${
                            totalUsers[9] && !isLoadingPage ? '' : 'd-none'
                          }`}
                          id="eventFunnelStep10"
                        ></div>
                        {totalUsers.length > 0 && totalUsers[9] ? (
                          isLoadingPage ? (
                            <LoadingComponent />
                          ) : (
                            <Fragment></Fragment>
                          )
                        ) : valueStep10.value && isLoadingPage ? (
                          <LoadingComponent />
                        ) : (
                          <div className="text-center">
                            Please select event to <br />
                            populate the table
                          </div>
                        )}
                      </div>
                      <div className="p-2 border-x mt-3">
                        <div className="w-100 text-12 text-semibold">
                          <div className="d-flex justify-content-between">
                            <div>Desktop</div>
                            <div className="d-flex">
                              <div className="text-right mx-2">
                                {(deviceDesktop.length > 0 &&
                                  numberWithCommas(
                                    deviceDesktop[9]?.metricValues[0]?.value,
                                  )) ||
                                  '-'}
                              </div>
                              <div className="text-right w-55px">
                                {numberWithToFixed(
                                  sumPercent(
                                    deviceDesktop.length > 0 &&
                                      deviceDesktop[9]?.metricValues[0]?.value,
                                    totalUsers.length > 0 &&
                                      totalUsers[9]?.metricValues[0]?.value,
                                  ),
                                ) || '-'}{' '}
                                %
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div>Mobile</div>
                            <div className="d-flex">
                              <div className="text-right mx-2">
                                {(deviceMobile.length > 0 &&
                                  numberWithCommas(
                                    deviceMobile[9]?.metricValues[0]?.value,
                                  )) ||
                                  '-'}
                              </div>
                              <div className="text-right w-55px">
                                {numberWithToFixed(
                                  sumPercent(
                                    deviceMobile.length > 0 &&
                                      deviceMobile[9]?.metricValues[0]?.value,
                                    totalUsers.length > 0 &&
                                      totalUsers[9]?.metricValues[0]?.value,
                                  ),
                                ) || '-'}{' '}
                                %
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div>Tablet</div>
                            <div className="d-flex ">
                              <div className="text-right mx-2">
                                {(deviceTablet.length > 0 &&
                                  numberWithCommas(
                                    deviceTablet[9]?.metricValues[0]?.value,
                                  )) ||
                                  '-'}
                              </div>
                              <div className="text-right w-55px">
                                {numberWithToFixed(
                                  sumPercent(
                                    deviceTablet.length > 0 &&
                                      deviceTablet[9]?.metricValues[0]?.value,
                                    totalUsers.length > 0 &&
                                      totalUsers[9]?.metricValues[0]?.value,
                                  ),
                                ) || '-'}{' '}
                                %
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div>Smart TV</div>
                            <div className="d-flex">
                              <div className="text-right mx-2">
                                {(deviceSmartTV.length > 0 &&
                                  numberWithCommas(
                                    deviceSmartTV[9]?.metricValues[0]?.value,
                                  )) ||
                                  '-'}
                              </div>
                              <div className="text-right  w-55px">
                                {numberWithToFixed(
                                  sumPercent(
                                    deviceSmartTV.length > 0 &&
                                      deviceSmartTV[9]?.metricValues[0]?.value,
                                    totalUsers.length > 0 &&
                                      totalUsers[9]?.metricValues[0]?.value,
                                  ),
                                ) || '-'}{' '}
                                %
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* step 10 */}
                  </div>
                </div>
              </div>
            </div>
          </FunnelBox>
        </div>
      </div>
    </div>
  )
}

export default TabMenuEvent
