import axios from 'axios'
const { REACT_APP_API_TAG } = process.env

const getGAReport = async (obj, property_id, token, workspaceId) => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/workspace/dashboard`
    // let URL = `https://analyticsdata.googleapis.com/v1beta/properties/${property_id}:runReport`;
    const response = await axios.post(URL, obj, {
      headers: {
        'content-Type': 'application/json',
        Accept: '*/*',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`,
        workspace_id: workspaceId,
        'Access-Control-Allow-Origin': true,
      },
    })
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data.data,
      }
    }
  } catch (error) {
    res = {
      status: error.response.data.error.code,
      data: error.response.data,
    }
  }
  return res
}
const getGAReportFunnel = async (obj, property_id, token, workspaceId) => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/workspace/funnel`
    // let URL = `https://analyticsdata.googleapis.com/v1alpha/${property_id}:runFunnelReport`;
    const response = await axios.post(URL, obj, {
      headers: {
        'content-Type': 'application/json',
        Accept: '*/*',
        'Cache-Control': 'no-cache',
        Authorization: `Bearer ${token}`,
        workspace_id: workspaceId,
        'Access-Control-Allow-Origin': true,
      },
    })
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data.data,
      }
    }
  } catch (error) {
    res = {
      status: error.response.data.error.code,
      data: error.response.data,
    }
  }
  return res
}
export { getGAReport, getGAReportFunnel }
