import { Fragment, useEffect, useState, useMemo } from 'react'
import LoadingSMComponent from '../layout/loadingSM'
import { FaUser, FaUserPlus } from 'react-icons/fa'
import {
  MdOutlineDesktopWindows,
  MdOutlineTabletMac,
  MdPhoneIphone,
  MdTrendingDown,
  MdTrendingUp,
} from 'react-icons/md'

import {
  numberWithCommas,
  countNumberOfDays,
  periodDateByDay,
  calculatePercentageChange,
} from '../../helper/helper'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import ErrorIcon from '@mui/icons-material/Error'
import { BiRedo } from 'react-icons/bi'
import { getGAReport } from '../../apis/dashboard/api'
import { useGoogleLogin } from '@react-oauth/google'
import * as echarts from 'echarts'
import Select from 'react-select'
import AlertDialogModal from './modal/alertdialog.js'
const { REACT_APP_REDIRECT } = process.env
const TabMenuOverview = (props) => {
  const [totalUsersByDate, setTotalUsersByDate] = useState(0)
  const [totalUsers, setTotalUsers] = useState('-')
  const [prepareTotalUser, setPrepareTotalUser] = useState(0)
  const [newUsers, setNewUsers] = useState('-')
  const [prepareNewUsers, setPrepareNewUsers] = useState(0)
  const [returningUser, setReturningUser] = useState('-')
  const [prepareReturningUser, setPrepareReturningUser] = useState(0)
  const [newUsersByDate, setNewUsersByDate] = useState(0)
  const accessToken = localStorage.getItem('token')

  const [isLoadingTotalUser, setIsLoadingTotalUser] = useState(false)
  const [isLoadingTotalNewUser, setIsLoadingTotalNewUser] = useState(false)
  const [isLoadingReturningUser, setIsLoadingReturningUser] = useState(false)
  const [isLoadingChart, setIsLoadingChart] = useState(false)
  const [isLoadingSessionSummary, setIsLoadingSessionSummary] = useState(false)
  const [isLoadingUserLocationLeft, setIsLoadingUserLocationLeft] =
    useState(false)
  const [isLoadingUserLocationRigth, setIsLoadingUserLocationRigth] =
    useState(false)
  const [isLoadingEngagement, setIsLoadingEngagement] = useState(false)
  const [isLoadingSessionDuration, setIsLoadingSessionDuration] =
    useState(false)
  const [isLoadingDevices, setIsLoadingDevices] = useState(false)
  const [isLoadingSessionTraffic, setIsLoadingSessionTraffic] = useState(false)
  const [isLoadingSessionHeatMap, setIsLoadingSessionHeatMap] = useState(false)
  const [maxDevice, setMaxDevice] = useState('xx')
  const [maxDevicePercent, setMaxDevicePercent] = useState('xx')
  const [userUserLocationList, setUserLocationList] = useState([])
  const [userUserLocationSelect, setUserLocationSelect] = useState('')
  const [userUserLocationChartData, setUserUserLocationChartData] = useState([])
  const [userUserLocationChartDataFilter, setUserLocationChartDataFilter] =
    useState([])
  const [userLocationRigth, setUserLocationRigth] = useState([])
  const [sessionHeatMapDataFilter, setSessionHeatMapDataFilter] = useState([])
  const [sessionTrafficXDataFilter, setSessionTrafficXDataFilter] = useState([])
  const [sessionTrafficYDataFilter, setSessionTrafficYDataFilter] = useState([])
  let [yesterday, setYesterday] = useState(props.filterEndDate)
  let [prior, setPrior] = useState(props.filterEndDate)
  const [openPopupSessionEnded, setOpenPopupSessionEnded] = useState(false)
  const [openPopupAuthError, setOpenPopupAuthError] = useState(false)

  const ggToken = localStorage.getItem('tokenGoogle')
  const [session, setSession] = useState('-')
  const [avgSessionDuration, setAvgSessionDuration] = useState('-')
  const [devices, setDevices] = useState([
    { device: 'desktop', value: '0.00', weight: '0.00' },
    { device: 'tablet', value: '0.00', weight: '0.00' },
    { device: 'mobile', value: '0.00', weight: '0.00' },
  ])

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: '14px', // กำหนดขนาด font ที่ต้องการ
      width: '200px',
    }),
    control: (provided) => ({
      ...provided,
      fontSize: '14px', // กำหนดขนาด font ที่ต้องการ
      width: '200px',
    }),
  }

  const getAllChart = () => {
    if (accessToken) {
      getGGTotalUser(accessToken)
      getGGTotalUserByDate(accessToken)
      getGGSessionSummary(accessToken)
      getGGSessionDuration(accessToken)
      getGGDevices(accessToken)
      getGGChartUserLocationLeft(accessToken)
      getGGUserLocationRigth(accessToken)
      getChartSessionTraffic(accessToken)
      getChartSessionHeatMap(accessToken)
    } else {
      setTimeout(() => {
        googleLogin()
        setIsLoadingChart(false)
      }, 2000)
    }
  }

  useEffect(() => {
    if (props.filterStartDate) {
      let yesterday = new Date(props.filterEndDate)
      yesterday.setHours(0, 0, 0, 0)
      let prior = new Date().setDate(yesterday.getDate() - 30)
      prior = new Date(prior)
      prior.setHours(0, 0, 0, 0)

      setYesterday(yesterday)
      setPrior(prior)

      getAllChart()
    }
  }, [props.filterStartDate])

  // UserLocation Left panel Cahrt
  useEffect(() => {
    const filteredData = userUserLocationChartData.filter(
      (item) => item.country === userUserLocationSelect,
    )
    if (filteredData.length > 0) {
      setUserLocationChartDataFilter(filteredData)
    }

    if (filteredData.length > 0) {
      setTimeout(() => {
        var chartDom = document.getElementById('userLocationChart')
        const colors = [
          '#0047FF',
          '#7DA5FB',
          '#5D8DF9',
          '#91B3FD',
          '#2B67F9',
          '#1B5BFA',
          '#002EA5',
          '#8CB0FD',
        ]
        if (
          chartDom &&
          chartDom.clientWidth !== 0 &&
          chartDom.clientHeight !== 0
        ) {
          let chart = echarts.init(chartDom)
          chart.setOption({
            tooltip: {
              trigger: 'item',
              backgroundColor: 'rgba(0,7,24,0.7)',
              borderColor: 'rgba(0,7,24,0)',
              textStyle: { color: '#fff' },
              formatter(params) {
                return `${params.name}<br>${
                  params.marker
                }User<span style="float: right; margin-left: 20px"><b>${params.value.toLocaleString()}</span>`
              },
            },
            legend: {
              orient: 'vertical',
              type: 'scroll',
              right: 'right',
              itemWidth: 14,
            },
            series: [
              {
                // name: "City",
                type: 'pie',
                radius: '80%', // กำหนดขนาดของ Pie Chart
                data: filteredData[0].cityData,
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)',
                  },
                },
                itemStyle: {
                  color: function (params) {
                    return colors[params.dataIndex % colors.length]
                  },
                },
              },
            ],
          })
        } else {
          mergeDataChart()
        }
      }, 500)
    }
  }, [userUserLocationSelect, userUserLocationChartData])

  // Session Traffic Cahrt
  useEffect(() => {
    if (
      sessionTrafficXDataFilter.length > 0 &&
      sessionTrafficYDataFilter.length > 0
    ) {
      setTimeout(() => {
        var chartDom = document.getElementById('sessionTrafficChart')

        if (
          chartDom &&
          chartDom.clientWidth !== 0 &&
          chartDom.clientHeight !== 0
        ) {
          let chart = echarts.init(chartDom)
          chart.setOption({
            legend: {
              orient: 'vertical',
              bottom: '1%',
              left: '1%',
              itemWidth: 14,
            },
            xAxis: {
              type: 'category',
              boundaryGap: false,
              data: sessionTrafficXDataFilter,
            },
            yAxis: {
              type: 'value',
            },
            series: [
              {
                name: 'Session',
                data: sessionTrafficYDataFilter,
                type: 'line',
                showSymbol: false,
                lineStyle: { color: 'rgba(0, 71, 255, 1)' },
              },
            ],
            tooltip: {
              trigger: 'axis',
              backgroundColor: 'rgba(0,7,24,0.7)',
              textStyle: { color: '#fff' },
              position: ['50%', '50%'],
            },
            // legend: {
            //   data: ["Total", "New", "Returning"],
            // },
            grid: {
              left: '3%',
              right: '6%',
              // bottom: "3%",
              height: '65%',
              containLabel: true,
            },
          })
        } else {
          mergeDataChart()
        }
      }, 500)
    }
  }, [sessionTrafficXDataFilter, sessionTrafficYDataFilter])

  // Sesstion HeatMap Cahrt
  useEffect(() => {
    if (sessionHeatMapDataFilter.length > 0) {
      setTimeout(() => {
        var chartDom = document.getElementById('sessionHeatMapChart')
        const hours = [
          '02:00',
          '04:00',
          '06:00',
          '08:00',
          '10:00',
          '12:00',
          '14:00',
          '16:00',
          '18:00',
          '20:00',
          '22:00',
          '24:00',
        ]

        const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

        const max = sessionHeatMapDataFilter.reduce((prev, current) =>
          prev[2] > current[2] ? prev : current,
        )
        const maxIndex2 = max[2]
        if (
          chartDom &&
          chartDom.clientWidth !== 0 &&
          chartDom.clientHeight !== 0
        ) {
          let chart = echarts.init(chartDom)
          chart.setOption({
            tooltip: {
              backgroundColor: 'rgba(0,7,24,0.7)',
              textStyle: { color: '#fff' },
              position: 'top',
            },
            grid: {
              width: '90%',
              height: '65%',
              top: '18%',
            },
            xAxis: {
              type: 'category',
              data: days,
              alignTicks: false,
            },
            yAxis: {
              type: 'category',
              data: hours,
              alignTicks: false,
            },
            visualMap: {
              type: 'piecewise',
              min: 0,
              max: maxIndex2,
              orient: 'horizontal',
              bottom: '-1%',
              inRange: {
                color: [
                  '#F2F2F2',
                  '#DAE4FF',
                  '#91B3FD',
                  '#AAC2FF',
                  '#0047FF',
                  '#002EA5',
                ],
              },
              splitNumber: 5,
              precision: 0,
              formatter: (value, value2) => {
                return value.toFixed(0) + '-' + value2.toFixed(0)
              },
            },
            series: [
              {
                name: 'Total Session',
                type: 'heatmap',
                data: sessionHeatMapDataFilter,
                emphasis: {
                  itemStyle: {
                    shadowColor: 'rgba(0, 0, 0, 0.5)',
                  },
                },
              },
            ],
          })
        } else {
          mergeDataChart()
        }
      }, 500)
    }
  }, [sessionHeatMapDataFilter])

  function formatDate(inputDate) {
    const date = new Date(inputDate)
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')

    return `${year}-${month}-${day}`
  }

  const mergeDataChart = () => {
    try {
      const total = transformData(totalUsersByDate, 'Total')
      let newUser = transformData(newUsersByDate, 'New')
      let returningUser = transformData(newUsersByDate, 'Returning')
      newUser = checkArrayPositionIsNull(total, newUser)
      returningUser = checkArrayPositionIsNull(total, returningUser)

      let mergeY = []
      mergeY.push(
        ...total.metricValues,
        ...newUser.metricValues,
        ...returningUser.metricValues,
      )
      getChartTotalUsers(total.dimensionValues, mergeY)
    } catch (error) {
      setNewUsersByDate(0)
    }
  }

  const getChartTotalUsers = async (xData, yData) => {
    setTimeout(() => {
      var chartDom = document.getElementById('totalUserChart')
      if (
        chartDom &&
        chartDom.clientWidth !== 0 &&
        chartDom.clientHeight !== 0
      ) {
        let chart = echarts.init(chartDom)
        chart.setOption({
          title: {
            text: 'Total Users',
          },
          tooltip: {
            trigger: 'axis',
          },
          legend: {
            data: ['Total', 'New', 'Returning'],
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: xData,
          },
          yAxis: {
            type: 'value',
          },
          series: yData,
        })
      } else {
        mergeDataChart()
      }
    }, 1000)
  }

  function checkArrayPositionIsNull(arr1, arr2) {
    const results = {
      dimensionValues: arr1.dimensionValues,
      metricValues: [
        {
          name: arr2.metricValues[0].name,
          type: arr2.metricValues[0].type,
          stack: arr2.metricValues[0].stack,
          data: arr1.dimensionValues.map((date) => {
            const position = arr2.dimensionValues.indexOf(date)
            return position !== -1 ? arr2.metricValues[0].data[position] : 0
          }),
        },
      ],
    }

    return results
  }

  const transformData = (inputArr, name) => {
    const dimensionValues = []
    const metricValues = []
    let _temp = []
    let tempNew = []
    let tempReturn = []
    if (name === 'New') {
      for (let i = 0; i < inputArr.length; i++) {
        if (inputArr[i].dimensionValues.length > 1) {
          if (inputArr[i].dimensionValues[1].value === 'new') {
            tempNew.push(inputArr[i])
          }
        }
      }
      tempNew = sortByDate(tempNew)
      for (let v = 0; v < tempNew.length; v++) {
        dimensionValues.push(
          changeFormatDate(tempNew[v].dimensionValues[0].value),
        )
        _temp.push(parseInt(tempNew[v].metricValues[0].value))
      }
    } else if (name === 'Returning') {
      for (let i = 0; i < inputArr.length; i++) {
        if (inputArr[i].dimensionValues.length > 1) {
          if (inputArr[i].dimensionValues[1].value === 'returning') {
            tempReturn.push(inputArr[i])
          }
        }
      }
      tempReturn = sortByDate(tempReturn)
      for (let v = 0; v < tempReturn.length; v++) {
        dimensionValues.push(
          changeFormatDate(tempReturn[v].dimensionValues[0].value),
        )
        _temp.push(parseInt(tempReturn[v].metricValues[0].value))
      }
    } else {
      for (const item of inputArr) {
        if (item.dimensionValues && item.dimensionValues.length > 0) {
          dimensionValues.push(changeFormatDate(item.dimensionValues[0].value))
        }
        _temp.push(parseInt(item.metricValues[0].value))
      }
    }

    let mergeMetric = {
      name: `${name}`,
      type: 'line',
      stack: `${name}`,
      data: _temp,
    }
    metricValues.push(mergeMetric)
    return { dimensionValues, metricValues }
  }

  const changeFormatDate = (inputDate) => {
    const year = inputDate.substring(0, 4)
    const month = inputDate.substring(4, 6)
    const day = inputDate.substring(6)
    const outputDate = `${year}-${month}-${day}`
    return outputDate
  }

  const getGGTotalUser = async (
    accessToken,
    sDate = props.filterStartDate,
    eDate = props.filterEndDate,
  ) => {
    // setIsLoadingReturningUser(true);
    setIsLoadingTotalUser(true)
    setIsLoadingChart(true)
    const periodDate = periodDateByDay(
      sDate,
      countNumberOfDays(sDate, eDate).match(/\d+/)[0],
    )
    try {
      let obj = {
        dateRanges: [
          {
            startDate: sDate,
            endDate: eDate,
          },
        ],
        dimensions: [
          {
            name: 'newVsReturning',
          },
        ],
        metrics: [
          {
            name: 'totalUsers',
          },
        ],
        metricAggregations: ['TOTAL'],
      }
      const dataInfo = await getGAReport(
        obj,
        props.propertyId,
        accessToken,
        props.workspaceId,
      ).then((res) => res.data)
      if (
        dataInfo?.error?.code === 401 &&
        dataInfo?.error?.status === 'EXTERNAL_ERROR'
      ) {
        setOpenPopupSessionEnded(true)
      } else if (
        dataInfo?.error?.code === 403 &&
        dataInfo?.error?.status === 'EXTERNAL_ERROR'
      ) {
        setOpenPopupAuthError(true)
      } else if (
        dataInfo?.error?.code === 400 &&
        dataInfo?.error?.status === 'INTERNAL_ERROR'
      ) {
        setOpenPopupAuthError(true)
      }
      if (dataInfo.rows) {
        setTotalUsers(dataInfo?.totals[0]?.metricValues[0]?.value || 0)
        setNewUsers(dataInfo?.rows[0]?.metricValues[0]?.value || 0)
        setReturningUser(dataInfo?.rows[1]?.metricValues[0]?.value || 0)

        let obj = {
          dateRanges: [{ startDate: periodDate[0], endDate: periodDate[1] }],

          dimensions: [
            {
              name: 'newVsReturning',
            },
          ],
          metrics: [
            {
              name: 'totalUsers',
            },
          ],
          metricAggregations: ['TOTAL'],
        }

        const prepareStat = await getGAReport(
          obj,
          props.propertyId,
          accessToken,
          props.workspaceId,
        )
        if (
          prepareStat.status === 200 &&
          prepareStat?.data &&
          prepareStat?.data?.totals?.length > 0 &&
          prepareStat?.data?.rows?.length > 0
        ) {
          setPrepareTotalUser(
            prepareStat?.data?.totals[0]?.metricValues[0]?.value || 0,
          )
          setPrepareNewUsers(
            prepareStat?.data?.rows[0]?.metricValues[0]?.value || 0,
          )
          setPrepareReturningUser(
            prepareStat?.data?.rows[1]?.metricValues[0]?.value || 0,
          )
          setTimeout(() => {
            setIsLoadingTotalUser(false)
          }, 500)
        }
      } else {
        setIsLoadingTotalUser(false)
        setTotalUsers('-')
        setNewUsers('-')
        setReturningUser('-')
        setNewUsersByDate(0)
      }
      setIsLoadingTotalUser(false)
      setIsLoadingReturningUser(false)
      setTimeout(() => {
        setIsLoadingChart(false)
      }, 2000)
    } catch (error) {
      setIsLoadingTotalUser(false)
      setIsLoadingReturningUser(false)
      setNewUsersByDate(0)

      setIsLoadingChart(false)
      setIsLoadingEngagement(false)

      setTimeout(() => {
        setIsLoadingChart(false)
        setIsLoadingTotalUser(false)
      }, 2000)
      console.log('error : ')
    }
  }

  const getGGTotalUserByDate = async (
    accessToken,
    sDate = props.filterStartDate,
    eDate = props.filterEndDate,
  ) => {
    // setIsLoadingTotalUser(true);
    setIsLoadingTotalNewUser(true)
    setIsLoadingReturningUser(true)
    try {
      let obj = {
        dateRanges: [
          {
            startDate: sDate,
            endDate: eDate,
          },
        ],
        metrics: [
          {
            name: 'totalUsers',
          },
        ],
        dimensions: [
          {
            name: 'date',
          },
        ],
      }
      const dataTotalUserByDate = await getGAReport(
        obj,
        props.propertyId,
        accessToken,
        props.workspaceId,
      ).then((res) => {
        setTotalUsersByDate(sortByDate(res.data.rows))
        getGGNewUserByDate(accessToken, sDate, eDate)
        // setIsLoadingTotalUser(false);
        setIsLoadingTotalNewUser(false)
        setIsLoadingReturningUser(false)
      })
    } catch (error) {
      // setIsLoadingTotalUser(false);
      setIsLoadingTotalNewUser(false)
      setIsLoadingReturningUser(false)
      // if (
      //   error &&
      //   error.request.readyState === 4 &&
      //   error.request.status === 401
      // ) {
      //   localStorage.removeItem("tokenGoogle");
      //   setTimeout(() => {
      //     googleLogin();
      //   }, 2000);
      // }
    }
  }

  const getGGNewUserByDate = async (
    accessToken,
    sDate = props.filterStartDate,
    eDate = props.filterEndDate,
  ) => {
    // setIsLoadingTotalNewUser(true);
    try {
      let obj = {
        dateRanges: [
          {
            startDate: sDate,
            endDate: eDate,
          },
        ],
        metrics: [
          {
            name: 'totalUsers',
          },
        ],
        dimensions: [
          {
            name: 'date',
          },
          {
            name: 'newVsReturning',
          },
        ],
      }
      const dataNewUserByDate = await getGAReport(
        obj,
        props.propertyId,
        accessToken,
        props.workspaceId,
      ).then((res) => {
        setNewUsersByDate(res.data.rows)
        setIsLoadingTotalNewUser(false)
      })
    } catch (error) {
      setIsLoadingTotalNewUser(false)
      // if (
      //   error &&
      //   error.request.readyState === 4 &&
      //   error.request.status === 401
      // ) {
      //   localStorage.removeItem("tokenGoogle");
      //   setTimeout(() => {
      //     googleLogin();
      //   }, 2000);
      // }
    }
  }

  const getGGSessionSummary = async (
    accessToken,
    sDate = props.filterStartDate,
    eDate = props.filterEndDate,
  ) => {
    setIsLoadingSessionSummary(true)
    try {
      let obj = {
        metrics: [
          {
            name: 'sessions',
          },
        ],
        dateRanges: [
          {
            startDate: sDate,
            endDate: eDate,
          },
        ],
      }

      const dataSessionSummary = await getGAReport(
        obj,
        props.propertyId,
        accessToken,
        props.workspaceId,
      )

      if (dataSessionSummary.status === 200) {
        const metricValue =
          dataSessionSummary.data.rows[0].metricValues[0].value
        setSession(metricValue)
        setIsLoadingSessionSummary(false)
      } else {
        setIsLoadingSessionSummary(false)
        if (
          dataSessionSummary.data?.error?.code === 401 &&
          dataSessionSummary.data?.error?.status === 'EXTERNAL_ERROR'
        ) {
          setOpenPopupSessionEnded(true)
        } else if (
          dataSessionSummary.data?.error?.code === 403 &&
          dataSessionSummary.data?.error?.status === 'EXTERNAL_ERROR'
        ) {
          setOpenPopupAuthError(true)
        }
      }
    } catch (error) {
      setIsLoadingSessionSummary(false)
      console.log('error')
    }
  }

  const getGGSessionDuration = async (
    accessToken,
    sDate = props.filterStartDate,
    eDate = props.filterEndDate,
  ) => {
    setIsLoadingSessionDuration(true)
    try {
      let obj = {
        metrics: [{ name: 'averageSessionDuration' }],
        dateRanges: [
          {
            startDate: sDate,
            endDate: eDate,
          },
        ],
      }

      const dataSessionSummaryDuration = await getGAReport(
        obj,
        props.propertyId,
        accessToken,
        props.workspaceId,
      )
      const metricValue =
        dataSessionSummaryDuration.data.rows[0].metricValues[0].value

      const seconds = parseFloat(metricValue)
      const minutes = Math.floor(seconds / 60)
      const remainingSeconds = Math.round(seconds % 60)
      const formattedTime = `${minutes} mins ${remainingSeconds} sec`
      setAvgSessionDuration(formattedTime)
      setIsLoadingSessionDuration(false)
    } catch (error) {
      setIsLoadingSessionDuration(false)
    }
  }

  const getGGDevices = async (
    accessToken,
    sDate = props.filterStartDate,
    eDate = props.filterEndDate,
  ) => {
    setIsLoadingDevices(true)
    try {
      let obj = {
        dimensions: [{ name: 'deviceCategory' }],
        metrics: [{ name: 'totalUsers' }],
        dateRanges: [
          {
            startDate: sDate,
            endDate: eDate,
          },
        ],
      }

      const dataDevices = await getGAReport(
        obj,
        props.propertyId,
        accessToken,
        props.workspaceId,
      )

      if (dataDevices.status === 200) {
        // คำนวณผลรวมของค่า "value" ทั้งหมด
        const totalValue = dataDevices.data.rows.reduce((accumulator, row) => {
          const value = parseInt(row.metricValues[0].value)
          return accumulator + value
        }, 0)

        // แปลงค่า "value" เป็นทศนิยมสองตำแหน่งและเปลี่ยนเป็นเปอร์เซ็นต์ "weight"
        const result = dataDevices.data.rows.map((row) => {
          const value = parseFloat(row.metricValues[0].value).toFixed(2)
          const weight = ((value / totalValue) * 100).toFixed(2)
          return { device: row.dimensionValues[0].value, value, weight }
        })

        setDevices(result)
        let maxDevice = ''
        let maxValue = 0
        result.forEach((item) => {
          if (item.value > maxValue) {
            maxValue = item.weight
            maxDevice = item.device
          }
        })
        setMaxDevice(maxDevice)
        setMaxDevicePercent(maxValue)
        setIsLoadingDevices(false)
      } else {
        setIsLoadingDevices(false)
      }
    } catch (error) {
      setIsLoadingDevices(false)
      // if (error && error.request.status === 401) {
      //   localStorage.removeItem("tokenGoogle");
      //   setTimeout(() => {
      //     googleLogin();
      //   }, 2000);
      // }
    }
  }

  const getGGChartUserLocationLeft = async (
    accessToken,
    sDate = props.filterStartDate,
    eDate = props.filterEndDate,
  ) => {
    try {
      setIsLoadingUserLocationLeft(true)
      let obj = {
        dimensions: [
          {
            name: 'country',
          },
          {
            name: 'countryId',
          },
          {
            name: 'region',
          },
        ],
        metrics: [
          {
            name: 'totalUsers',
          },
        ],
        dateRanges: [
          {
            startDate: sDate,
            endDate: eDate,
          },
        ],
      }

      const dataUserLocationLeft = await getGAReport(
        obj,
        props.propertyId,
        accessToken,
        props.workspaceId,
      )

      if (dataUserLocationLeft.status === 200) {
        const rows = dataUserLocationLeft.data.rows
        // สร้าง Map เพื่อจัดกลุ่มข้อมูลตาม "country" และ "countryId"
        const groupedData = new Map()
        rows.forEach((row) => {
          const country = row.dimensionValues[0].value
          const countryId = row.dimensionValues[1].value
          const value = parseInt(row.metricValues[0].value)
          if (groupedData.has(country)) {
            // ถ้ามีข้อมูลของประเทศนี้อยู่แล้ว ให้เพิ่มค่า value เข้าไป
            groupedData.get(country).value += value
          } else {
            // ถ้ายังไม่มีข้อมูลของประเทศนี้ ให้สร้างข้อมูลใหม่
            groupedData.set(country, { label: country, countryId, value })
          }
        })

        // แปลง Map เป็นอาร์เรย์และเรียงลำดับตาม value มากไปน้อย
        const resultArray = Array.from(groupedData.values()).sort(
          (a, b) => b.value - a.value,
        )

        const filteredData = resultArray.filter(
          (item) => item.label !== '(not set)',
        ) // เอาประเทศที่ not set ออก
        if (filteredData.length > 0) {
          setUserLocationSelect(filteredData[0].countryId)
          setUserLocationList(filteredData)
        }

        const groupedDataChart = new Map()

        rows.forEach((item) => {
          const country = item.dimensionValues[1].value
          const city = item.dimensionValues[2].value
          const value = parseInt(item.metricValues[0].value)

          if (!groupedDataChart.has(country)) {
            groupedDataChart.set(country, { country, cityData: [] })
          }

          groupedDataChart.get(country).cityData.push({ value, name: city })
        })

        // แปลง Map เป็นอาร์เรย์และเรียงลำดับตามค่า value มากไปน้อย
        const resultArrayChart = Array.from(groupedDataChart.values())
          .map((countryData) => {
            countryData.cityData = countryData.cityData.sort(
              (a, b) => b.value - a.value,
            )
            return countryData
          })
          .sort((a, b) => b.cityData[0].value - a.cityData[0].value)

        if (resultArrayChart.length > 0) {
          setUserUserLocationChartData(resultArrayChart)
        }
        setIsLoadingUserLocationLeft(false)
      } else {
        setUserUserLocationChartData([])
        setIsLoadingUserLocationLeft(false)
        console.error(dataUserLocationLeft)
      }
    } catch (error) {
      setUserUserLocationChartData([])
      setIsLoadingUserLocationLeft(false)
    }
  }

  const getGGUserLocationRigth = async (
    accessToken,
    sDate = props.filterStartDate,
    eDate = props.filterEndDate,
  ) => {
    try {
      setIsLoadingUserLocationRigth(true)
      let obj = {
        dimensions: [
          {
            name: 'country',
          },
          {
            name: 'countryId',
          },
        ],
        metrics: [
          {
            name: 'totalUsers',
          },
        ],
        dateRanges: [
          {
            startDate: sDate,
            endDate: eDate,
          },
        ],
      }

      const dataUserLocationRigth = await getGAReport(
        obj,
        props.propertyId,
        accessToken,
        props.workspaceId,
      )

      if (dataUserLocationRigth.status === 200) {
        // แปลงข้อมูล JSON ให้เป็นรูปแบบที่ต้องการ
        const mappedData = dataUserLocationRigth.data.rows.map((row) => ({
          country: row.dimensionValues[0].value,
          countryId: row.dimensionValues[1].value,
          value: parseInt(row.metricValues[0].value),
        }))
        // เรียงลำดับข้อมูลตามค่า value มากไปน้อย
        mappedData.sort((a, b) => b.value - a.value)
        const filteredData = mappedData.filter(
          (item) => item.country !== '(not set)',
        )

        const totalValue = filteredData.reduce(
          (total, property) => total + property.value,
          0,
        )

        const newData = filteredData.map((property) => ({
          ...property,
          percent: (property.value * 100) / totalValue,
        }))

        setUserLocationRigth(newData)

        setIsLoadingUserLocationRigth(false)
      } else {
        setIsLoadingUserLocationRigth(false)
        console.error(dataUserLocationRigth)
      }
    } catch (error) {
      setIsLoadingUserLocationRigth(false)
      console.log('getGGUserLocationRigth error')
    }
  }

  const getChartSessionTraffic = async (
    accessToken,
    sDate = props.filterStartDate,
    eDate = props.filterEndDate,
  ) => {
    try {
      setIsLoadingSessionTraffic(true)
      let obj = {
        dimensions: [{ name: 'date' }],
        metrics: [{ name: 'sessions' }],
        dateRanges: [
          {
            startDate: sDate,
            endDate: eDate,
          },
        ],
      }

      const dataSessionTraffic = await getGAReport(
        obj,
        props.propertyId,
        accessToken,
        props.workspaceId,
      )

      if (dataSessionTraffic.status === 200) {
        // สร้างรายการว่างสำหรับ X และ Y
        const X = []
        const Y = []

        // วนลูปผ่านข้อมูลใน data.rows

        const datFilter = dataSessionTraffic.data.rows.sort((a, b) => {
          const dateA = parseInt(a.dimensionValues[0].value, 10)
          const dateB = parseInt(b.dimensionValues[0].value, 10)
          return dateA - dateB
        })
        // เติมข้อมูล
        const sDateFormat = sDate.replace(/-/g, '')
        const eDateFormat = eDate.replace(/-/g, '')
        // แปลงวันที่เป็นรูปแบบ "YYYYMMDD" เป็นตัวเลข
        const startDate = parseInt(sDateFormat, 10)
        const endDate = parseInt(eDateFormat, 10)

        // เพิ่มข้อมูลสำหรับแต่ละวัน
        for (let date = startDate; date <= endDate; date++) {
          const dateString = date.toString()
          // const existingData = datFilter.find(
          //   (item) => item.dimensionValues[0].value === dateString
          // );
          // if (!existingData) {
          //   // ถ้าไม่มีข้อมูลสำหรับวันที่นี้ให้เพิ่มข้อมูลใหม่
          //   datFilter.push({
          //     dimensionValues: [{ value: dateString }],
          //     metricValues: [{ value: "0" }],
          //   });
          // }
        }

        const datFilter2 = datFilter.sort((a, b) => {
          const dateA = parseInt(a.dimensionValues[0].value, 10)
          const dateB = parseInt(b.dimensionValues[0].value, 10)
          return dateA - dateB
        })

        datFilter2.forEach((row) => {
          // ดึงค่า dimensionValues (วันที่) และ metricValues (sessions)
          const date_value = row.dimensionValues[0].value
          const sessions_value = row.metricValues[0].value
          // แปลงวันที่เป็นรูปแบบ "YYYY-MM-DD"
          const date_obj = new Date(
            date_value.slice(0, 4),
            date_value.slice(4, 6) - 1,
            date_value.slice(6, 8),
          )
          const inputDate = new Date(date_obj)
          const year = inputDate.getFullYear()
          const month = String(inputDate.getMonth() + 1).padStart(2, '0')
          const day = String(inputDate.getDate()).padStart(2, '0')
          const formattedDate = `${year}-${month}-${day}`
          // เพิ่มค่าใน X และ Y
          X.push(formattedDate)
          Y.push(parseInt(sessions_value))
        })
        setSessionTrafficXDataFilter(X)
        setSessionTrafficYDataFilter(Y)

        setIsLoadingSessionTraffic(false)
      } else {
        setIsLoadingSessionTraffic(false)
      }
    } catch (error) {
      setIsLoadingSessionTraffic(false)
      // if (error && error.request.status === 401) {
      //   localStorage.removeItem("tokenGoogle");
      //   setTimeout(() => {
      //     googleLogin();
      //   }, 2000);
      // }
    }
  }

  const getChartSessionHeatMap = async (
    accessToken,
    sDate = props.filterStartDate,
    eDate = props.filterEndDate,
  ) => {
    try {
      setIsLoadingSessionHeatMap(true)
      let obj = {
        dimensions: [
          {
            name: 'date',
          },
          {
            name: 'dayOfWeek',
          },
          {
            name: 'hour',
          },
        ],
        metrics: [
          {
            name: 'sessions',
          },
        ],
        dateRanges: [
          {
            startDate: sDate,
            endDate: eDate,
          },
        ],
      }
      const dataHeatMap = await getGAReport(
        obj,
        props.propertyId,
        accessToken,
        props.workspaceId,
      )
      if (dataHeatMap.status === 200) {
        const result = dataHeatMap.data.rows.map((row) => {
          const dimensionValue1 = parseInt(row.dimensionValues[1].value)
          const dimensionValue2 = parseInt(row.dimensionValues[2].value)
          const metricValue = parseInt(row.metricValues[0].value)
          return [dimensionValue1, dimensionValue2, metricValue]
        })
        // console.log("convert to array", JSON.stringify(result));
        result.sort((a, b) => {
          if (a[0] !== b[0]) {
            return a[0] - b[0]
          }
          return a[1] - b[1]
        })
        // console.log("sort by array", JSON.stringify(result));
        const convertedDataTime = result.map((item) => {
          const time =
            item[1] % 2 === 0
              ? item[1] < 10
                ? `0${item[1]}:00`
                : `${item[1]}:00`
              : item[1] < 10
                ? `0${item[1] - 1}:00`
                : `${item[1] - 1}:00`
          return [item[0], time, item[2]]
        })
        // console.log("แปลงเวลา ข้อมูลทุก 2 ชั่วโมง", JSON.stringify(convertedDataTime));
        const dayMapping = {
          0: 'Sun',
          1: 'Mon',
          2: 'Tue',
          3: 'Wed',
          4: 'Thu',
          5: 'Fri',
          6: 'Sat',
        }
        const convertedDataDay = convertedDataTime.map((item) => {
          const day = dayMapping[item[0]]
          return [day, item[1], item[2]]
        })
        // console.log("แปลงวัน", JSON.stringify(convertedDataDay));
        const dayOrder = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
        const convertedDataDaySort = convertedDataDay.sort((a, b) => {
          const dayA = dayOrder.indexOf(a[0])
          const dayB = dayOrder.indexOf(b[0])
          if (dayA < dayB) return -1
          if (dayA > dayB) return 1
          const timeA = a[1]
          const timeB = b[1]
          if (timeA < timeB) return -1
          if (timeA > timeB) return 1
          return 0
        })
        // console.log("sort วัน เวลา", JSON.stringify(convertedDataDay));
        const mergedData = convertedDataDaySort.reduce((acc, item) => {
          const foundIndex = acc.findIndex(
            (el) => el[0] === item[0] && el[1] === item[1],
          )
          if (foundIndex !== -1) {
            acc[foundIndex][2] += item[2]
          } else {
            acc.push(item)
          }
          return acc
        }, [])
        setSessionHeatMapDataFilter(mergedData)

        setIsLoadingSessionHeatMap(false)
      } else {
        setIsLoadingSessionHeatMap(false)
        // setIsLoadingSessionHeatMap(false);
      }
    } catch (error) {
      setIsLoadingSessionHeatMap(false)
      // if (error && error.request.status === 401) {
      //   localStorage.removeItem("tokenGoogle");
      //   setTimeout(() => {
      //     googleLogin();
      //   }, 2000);
      // }
    }
  }

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      if (tokenResponse?.access_token) {
        localStorage.setItem('tokenGoogle', tokenResponse.access_token)
        // getGGTotalUser(tokenResponse.access_token);
        // getGGTotalUserByDate(tokenResponse.access_token);
        // getGGNewUserByDate(tokenResponse.access_token);
      }
    },
    onError: async (error) => {
      localStorage.removeItem('tokenGoogle')
    },
    scope: 'https://www.googleapis.com/auth/analytics.readonly',
    include_granted_scopes: true,
    state: 'pass-through value',
    redirect_uri: REACT_APP_REDIRECT,
  })

  function sortByDate(inputArray) {
    // Sort the input array based on the date in dimensionValues in descending order
    inputArray.sort((a, b) => {
      const dateA = b.dimensionValues[0].value
      const dateB = a.dimensionValues[0].value

      // Compare dates in reverse order for descending sorting
      return dateB.localeCompare(dateA)
    })

    return inputArray
  }

  useMemo(() => {
    try {
      mergeDataChart()
    } catch (err) {
      getAllChart()
    }
  }, [newUsersByDate])

  return (
    <div
      id="Overview"
      className={`container tab-pane ${props.menuOverview ? 'active' : ''} `}
    >
      <br />
      <AlertDialogModal
        openPopup={openPopupSessionEnded}
        setOpenPopup={setOpenPopupSessionEnded}
        title={'Your Session Has Ended'}
        detail={'Your authentication has expired. Please sign in again.'}
        accountId={props.accountId}
        workspaceId={props.workspaceId}
      />
      <AlertDialogModal
        openPopup={openPopupAuthError}
        setOpenPopup={setOpenPopupAuthError}
        title={'Authentication Error'}
        detail={
          'Invalid Credentials Detected. Please change Google authentication email address.'
        }
        accountId={props.accountId}
        workspaceId={props.workspaceId}
      />

      <div className="row mb-3">
        {/* Total User */}
        <div className="col-md-4">
          <div className="card">
            <div className="card-body">
              {isLoadingTotalUser ? (
                <LoadingSMComponent />
              ) : (
                <Fragment>
                  <p className="text-14 mb-1 text-semibold ">
                    <FaUser className="mx-2" />
                    Total User
                  </p>

                  <div className="d-flex justify-content-between">
                    <p className="text-36 text-semibold mb-0">
                      {numberWithCommas(totalUsers) || totalUsers}
                    </p>
                    <div className="text-right">
                      <Fragment>
                        {totalUsers !== '-' ? (
                          !calculatePercentageChange(
                            prepareTotalUser,
                            totalUsers,
                          ).includes('-') ? (
                            <p
                              className={`text-14 text-semibold mb-1 text-primary`}
                            >
                              <MdTrendingUp className="mx-2" />
                              {calculatePercentageChange(
                                prepareTotalUser,
                                totalUsers,
                              )}
                              %
                            </p>
                          ) : (
                            <p
                              className={`text-14 text-semibold mb-1 text-danger`}
                            >
                              <MdTrendingDown className="mx-2" />
                              {calculatePercentageChange(
                                prepareTotalUser,
                                totalUsers,
                              )}
                              %
                            </p>
                          )
                        ) : (
                          ''
                        )}

                        <p className="text-10 mb-0 mt-2">
                          vs last{' '}
                          {countNumberOfDays(
                            props.filterStartDate,
                            props.filterEndDate,
                          )}
                        </p>
                      </Fragment>
                    </div>
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </div>
        {/*  Total New User */}
        <div className="col-md-4">
          <div className="card">
            <div className="card-body">
              {isLoadingTotalUser ? (
                <LoadingSMComponent />
              ) : (
                <Fragment>
                  <p className="text-14 mb-1 text-semibold ">
                    <FaUserPlus className="mx-2" />
                    Total New User
                  </p>
                  <div className="d-flex justify-content-between">
                    <p className="text-36 text-semibold mb-0">
                      {numberWithCommas(newUsers) || newUsers}
                    </p>
                    <div className="text-right">
                      <Fragment>
                        {newUsers !== '-' ? (
                          !calculatePercentageChange(
                            prepareNewUsers,
                            newUsers,
                          ).includes('-') ? (
                            <p
                              className={`text-14 text-semibold mb-1 text-primary`}
                            >
                              <MdTrendingUp className="mx-2" />
                              {calculatePercentageChange(
                                prepareNewUsers,
                                newUsers,
                              )}
                              %
                            </p>
                          ) : (
                            <p
                              className={`text-14 text-semibold mb-1 text-danger`}
                            >
                              <MdTrendingDown className="mx-2" />
                              {calculatePercentageChange(
                                prepareNewUsers,
                                newUsers,
                              )}
                              %
                            </p>
                          )
                        ) : (
                          ''
                        )}

                        <p className="text-10 mb-0 mt-2">
                          vs last{' '}
                          {countNumberOfDays(
                            props.filterStartDate,
                            props.filterEndDate,
                          )}
                        </p>
                      </Fragment>
                    </div>
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </div>
        {/* Returning User */}
        <div className="col-md-4">
          <div className="card">
            <div className="card-body">
              {isLoadingTotalUser ? (
                <LoadingSMComponent />
              ) : (
                <Fragment>
                  {' '}
                  <p className="text-14 mb-1 text-semibold ">
                    <BiRedo className="mx-2" />
                    Returning User
                  </p>
                  <div className="d-flex justify-content-between">
                    <p className="text-36 text-semibold mb-0">
                      {numberWithCommas(returningUser) || returningUser}
                    </p>
                    <div className="text-right">
                      <Fragment>
                        {returningUser !== '-' ? (
                          !calculatePercentageChange(
                            prepareReturningUser,
                            returningUser,
                          ).includes('-') ? (
                            <p
                              className={`text-14 text-semibold mb-1 text-primary`}
                            >
                              {prepareReturningUser > 0 ? (
                                <Fragment>
                                  {calculatePercentageChange(
                                    prepareReturningUser,
                                    returningUser,
                                  ) === '0' ? (
                                    '0%'
                                  ) : (
                                    <Fragment>
                                      <MdTrendingUp className="mx-2" />
                                      {returningUser >= 1000
                                        ? calculatePercentageChange(
                                            prepareReturningUser,
                                            returningUser,
                                          )
                                        : calculatePercentageChange(
                                            prepareReturningUser,
                                            returningUser,
                                          )}
                                      %
                                    </Fragment>
                                  )}
                                </Fragment>
                              ) : (
                                <Tooltip title="Comparison Unavailable">
                                  <IconButton className="fixed-icon">
                                    <ErrorIcon
                                      sx={{
                                        fontSize: 18,
                                        color: '#D0D5DD',
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </p>
                          ) : (
                            <p
                              className={`text-14 text-semibold mb-1 text-danger`}
                            >
                              <MdTrendingDown className="mx-2" />
                              {prepareReturningUser > 0 ? (
                                calculatePercentageChange(
                                  prepareReturningUser,
                                  returningUser,
                                ) + '%'
                              ) : (
                                <Tooltip title="Comparison Unavailable">
                                  <IconButton className="fixed-icon">
                                    <ErrorIcon
                                      sx={{
                                        fontSize: 18,
                                        color: '#D0D5DD',
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </p>
                          )
                        ) : (
                          ''
                        )}

                        <p className="text-10 mb-0 mt-2">
                          vs last{' '}
                          {countNumberOfDays(
                            props.filterStartDate,
                            props.filterEndDate,
                          )}
                        </p>
                      </Fragment>
                    </div>
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-3">
        {/* Total User */}
        <div className="col-md-12">
          <Fragment>
            <div className={`card`}>
              <div className={`card-body`} style={{ minHeight: 416 }}>
                <p className="text-16 text-semibold">Total User</p>
                <p className="text-12">
                  Overview of the total number of unique users who have
                  interacted with your website.
                </p>
                <div
                  className={`text-center ${isLoadingChart ? '' : 'd-none'}`}
                >
                  <div style={{ marginTop: 100 }}>
                    <LoadingSMComponent />
                  </div>
                </div>
                <div
                  className={`text-center ${isLoadingChart ? 'd-none' : ''}`}
                >
                  {newUsersByDate === 0 && (
                    <span className="text-no-data" style={{ marginTop: 40 }}>
                      No data found.
                    </span>
                  )}
                  <div className="donut">
                    <div className="chart" id="totalUserChart"></div>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        </div>
      </div>

      <div className="row">
        {/* Session Summary */}
        <div className="col-md-6 mb-3">
          <div className="card">
            <div className="card-body" style={{ minHeight: '220px' }}>
              {isLoadingSessionSummary ? (
                <div style={{ marginTop: 50 }}>
                  <LoadingSMComponent />
                </div>
              ) : (
                <Fragment>
                  <div className="justify-content-between">
                    <p className="text-16 text-semibold">Session Summary</p>
                    <div className="text-12 mb-4">
                      Total session count and average session duration directly
                      from GA4.
                    </div>

                    <div
                      className="row justify-content-around"
                      style={{ minHeight: '80px' }}
                    >
                      <div
                        className="col-md-4 rounded p-3 mb-2"
                        style={{ backgroundColor: '#F6FAFF' }}
                      >
                        <div className="text-12 mb-2">SESSION</div>

                        <div className="text-20 text-semibold">
                          {session === '-' ? '-' : numberWithCommas(session)}
                        </div>
                      </div>
                      <div
                        className="col-md-7 rounded p-3 mb-2"
                        style={{ backgroundColor: '#F6FAFF' }}
                      >
                        <div className="text-12 mb-2">
                          AVERAGE SESSION DURATION
                        </div>
                        <div className="text-20 text-semibold">
                          {avgSessionDuration}
                        </div>
                      </div>
                    </div>
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </div>
        {/* Devices */}
        <div className="col-md-6 mb-3">
          <div className="card ">
            <div className="card-body" style={{ minHeight: '220px' }}>
              {isLoadingDevices ? (
                <div style={{ marginTop: 50 }}>
                  <LoadingSMComponent />
                </div>
              ) : (
                <Fragment>
                  <div className="text-16 mb-1 text-semibold mb-3">
                    <span>{maxDevicePercent}%</span> of the time,
                    <div className="label-head">
                      your website is accessed from <span>{maxDevice} </span>{' '}
                      devices.
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-md-3 p-3 mb-2">
                      <div>
                        <div className="mb-2">
                          <MdOutlineDesktopWindows color="#667085" size={30} />
                          <span className="text-secondary text-12">
                            {' '}
                            DESKTOP
                          </span>
                        </div>
                        <div className="text-20 text-secondary text-semibold">
                          {devices.filter((item) => item.device === 'desktop')
                            .length > 0
                            ? devices.filter(
                                (item) => item.device === 'desktop',
                              )[0].weight
                            : '0.00'}
                          %
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 p-3 ">
                      <div className="mb-2">
                        <MdOutlineTabletMac color="#667085" size={30} />
                        <span className="text-secondary text-12"> TABLET</span>
                      </div>
                      <div className="text-20 text-secondary text-semibold">
                        {devices.filter((item) => item.device === 'tablet')
                          .length > 0
                          ? devices.filter(
                              (item) => item.device === 'tablet',
                            )[0].weight
                          : '0.00'}
                        %
                      </div>
                    </div>
                    <div className="col-md-3 p-3 ">
                      <div className="mb-2">
                        <MdPhoneIphone color="#667085" size={30} />
                        <span className="text-secondary text-12"> MOBILE</span>
                      </div>
                      <div className="text-20 text-secondary text-semibold">
                        {devices.filter((item) => item.device === 'mobile')
                          .length > 0
                          ? devices.filter(
                              (item) => item.device === 'mobile',
                            )[0].weight
                          : '0.00'}
                        %
                      </div>
                    </div>
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-3">
        {/* Users Location Left */}
        <div className="col-md-9 mb-3">
          <div className={`card`}>
            <div className={`card-body`} style={{ minHeight: '436px' }}>
              <div className="d-flex justify-content-between">
                <p className="text-16 text-semibold">Users Location</p>
                <p className="text-12 ">SELECT COUNTRY</p>
              </div>
              <div className="d-flex justify-content-between mb-3">
                <p className="text-12">
                  See where your audience is located and tailor your content
                  accordingly for a personalized user experience.
                </p>
                {userUserLocationList.length > 0 ? (
                  <div style={{ marginTop: -12 }}>
                    <Select
                      defaultValue={userUserLocationList[0]}
                      options={userUserLocationList}
                      formatOptionLabel={(data) => (
                        <div className="country-option">
                          <img
                            src={`https://flagsapi.com/${data.countryId}/flat/16.png`}
                            className="align-self-center mr-3"
                            alt="flag"
                          />
                          <span style={{ paddingLeft: 10 }}> {data.label}</span>
                        </div>
                      )}
                      styles={customStyles}
                      name="color"
                      onChange={(data) => setUserLocationSelect(data.countryId)}
                    />
                  </div>
                ) : null}
              </div>

              {isLoadingUserLocationLeft ? (
                <div style={{ marginTop: 100 }}>
                  <LoadingSMComponent />
                </div>
              ) : (
                <div className={`text-center `}>
                  {userUserLocationChartData.length === 0 && (
                    <span className="text-no-data" style={{ marginTop: 50 }}>
                      No data found.
                    </span>
                  )}
                  <div
                    id="userLocationChart"
                    style={{
                      position: 'relative',
                      height: 300,
                      overflow: 'auto',
                    }}
                  ></div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* Users Location Right */}
        <div className="col-md-3 mb-3 ">
          <div className="card ">
            <div
              className="card-body scroll-sm"
              style={{ minHeight: '436px', maxHeight: '436px' }}
            >
              <div className="justify-content-between">
                <div className="text-16 text-semibold">Users Location</div>
              </div>
              <div className="d-flex justify-content-between mt-4">
                <div className="text-12 text-semibold ">LOCATION</div>
                <div className="text-12 text-semibold ">USER</div>
              </div>
              <div className="border-bottom-custom mt-2 mb-2 " />
              {isLoadingUserLocationRigth ? (
                <div style={{ marginTop: 100 }}>
                  <LoadingSMComponent />
                </div>
              ) : (
                <Fragment>
                  {userLocationRigth.length > 0 &&
                    userLocationRigth.map((item, i) => {
                      return (
                        <div key={i} className="w-100 mb-3">
                          <div className="d-flex w-100 align-middle justify-content-between">
                            <div>
                              <img
                                src={`https://flagsapi.com/${item.countryId}/flat/16.png`}
                                className="align-self-center mr-3"
                              />
                              <span className="align-middle  pl-2 text-14">
                                {' '}
                                {item.country}
                              </span>
                            </div>
                            <div>
                              <span className="align-middle pl-2 text-14">
                                {' '}
                                {item.value.toLocaleString()}
                              </span>
                            </div>
                          </div>
                          <div className="progress my-1" style={{ height: 5 }}>
                            <div
                              className={`progress-bar bg`}
                              style={{
                                backgroundColor: '#BFCCED',
                                width: `${item.percent}%`,
                              }}
                              role="progressbar"
                              aria-valuenow={item.percent}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            ></div>
                          </div>
                        </div>
                      )
                    })}
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-3">
        {/* Session Traffic */}
        <div className="col-md-6 mb-3">
          <div className={`card`}>
            <div className={`card-body`} style={{ minHeight: '416px' }}>
              <p className="text-16 text-semibold">Session Traffic</p>
              <p className="text-12">
                {' '}
                Daily breakdown of total session counts so you can effortlessly
                monitor your website's performance.
              </p>
              {isLoadingSessionTraffic ? (
                <div style={{ marginTop: 120 }}>
                  <LoadingSMComponent />
                </div>
              ) : (
                <div className={`text-center`}>
                  {sessionTrafficXDataFilter.length === 0 && (
                    <span className="text-no-data" style={{ marginTop: 50 }}>
                      No data found.
                    </span>
                  )}
                  <div
                    id="sessionTrafficChart"
                    style={{
                      position: 'relative',
                      height: 280,
                      overflow: 'auto',
                    }}
                  ></div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* Session Heatmap */}
        <div className="col-md-6 mb-3">
          <div className={`card`}>
            <div
              className={`card-body`}
              style={{ minHeight: '416px', overflow: 'auto' }}
            >
              <p className="text-16 text-semibold">Session Heatmap</p>
              <p className="text-12">
                {' '}
                Visualize user engagement patterns over time and discover when
                your website experiences peak activity.
              </p>
              {isLoadingSessionHeatMap ? (
                <div style={{ marginTop: 100 }}>
                  <LoadingSMComponent />
                </div>
              ) : (
                <div className={`text-center`}>
                  {sessionHeatMapDataFilter.length === 0 && (
                    <span className="text-no-data" style={{ marginTop: 50 }}>
                      No data found.
                    </span>
                  )}
                  <div
                    id="sessionHeatMapChart"
                    style={{
                      marginTop: -45,
                      position: 'relative',
                      height: 300,
                      overflow: 'auto',
                    }}
                  ></div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TabMenuOverview
