import styled from 'styled-components'
const RadioBox = styled.div`
  .form-check .form-check-input {
    float: left;
    margin-left: 0;
  }
  .pl-4rem {
    padding-left: 4rem;
  }
`
const AccountUsersBox = styled.div`
  .table > :not(caption) > * > * {
    padding: 1rem 1.7rem;
  }
  .-pointer:hover {
    color: #0047ff;
  }
  .textarea-custom {
    font-weight: 600;
    line-height: 2.5;
    font-size: 14px;
  }
  tbody,
  td,
  tfoot,
  th,
  thead,
  tr {
    padding: 12px 20px;
  }
  .content-menu {
    width: 100%;
    padding-left: 256px;
  }
  .content-box {
    height: 100%;
  }
  .content-scroll {
    // margin-top: 100px;
    height: 87vh;
    overflow-y: auto;
  }
  .-pointer {
    cursor: pointer;
  }
  .btn {
    margin: 0.125em;
  }
  .boxSetting {
    right: 1%;
    width: 200px;
    text-align: left;
    border: 1px solid #0000000f;
    box-shadow: 6px 7px 20px 0 rgb(0 0 0 / 11%);
  }
  .dropdown-item-setting {
    cursor: pointer;
    padding: 10px;
  }
  .dropdown-item-setting:hover {
    background: #c1c1c129;
  }
  .badge {
    margin-left: 0px;
    min-width: 55px;
    text-align: center;
    text-transform: capitalize;
  }
  .badge-default {
    background-color: #f2f4f7;
    color: #344054;
  }
  .badge-green {
    background-color: #ecfdf3;
    color: #037a48;
  }
  .badge-blue {
    background-color: #0047ff;
    color: #fff;
  }
  .badge-primary {
    background-color: #eaf0ff;
    color: #0047ff;
  }
  .badge-yellow {
    background-color: #fec84b;
    color: #344054;
  }
  .badge-white {
    border: solid 1px #d0d5dd;
    background-color: #fff;
    color: #344054;
  }
  .table {
    svg {
      color: #98a2b3;
      cursor: pointer;
    }
  }

  .close:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit;
  }
  button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
  }
  .close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
  }
  .alert-box {
    position: absolute;
    width: 30%;
    z-index: 999999;
    padding: 0px;
    top: 45px;
    right: 20px;
  }
  .input-group-text {
    padding: 10px 0.75rem;
    line-height: 1.5;
    background-color: #fff;
    display: flex;
    align-items: center;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #ced4da;
    border-right: 0;
  }
  .boxsearch {
    right: 16%;
    width: 32%;
    text-align: left;
    border: 1px solid #0000000f;
    box-shadow: 6px 7px 20px 0 rgb(0 0 0 / 11%);
    z-index: 9;
  }
  .text-gray {
    color: #d0d5dd;
  }
`
const ModalBox = styled.div`
  .w-table {
    max-width: 345px !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
const InviteBox = styled.div`
  .tag {
    font-size: 14px;
    padding: 5px 13px;
    background: rgb(234, 240, 255);
    border-radius: 20px;
    color: #0047ff;
  }
  .tag-danger {
    font-size: 14px;
    padding: 5px 13px;
    background: #fff1f0;
    border-radius: 20px;
    color: #d92d20;
  }

  .tag a {
    color: #0047ff;
    cursor: pointer;
    opacity: 0.6;
  }
  .tag a:hover {
    opacity: 1;
  }
  .tag .remove {
    vertical-align: bottom;
    top: 0;
  }
  .tag a {
    margin: 0 0 0 0.3em;
  }
  .tag a .glyphicon-white {
    color: #fff;
    margin-bottom: 2px;
  }
`
export { AccountUsersBox, RadioBox, ModalBox, InviteBox }
