import React, { Fragment, useState, useEffect } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import { FaRegCalendarAlt } from '@react-icons/all-files/fa/FaRegCalendarAlt'
import Modal from 'react-modal'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { SubscriptionBox, Payment, ModalToggle } from './style'
import FooterComponent from '../layout/footer'
import MenuSettingComponent from '../layout/menusetting'
import {
  MdInfo,
  MdClose,
  MdReport,
  MdCreditCard,
  MdHistory,
  MdAutoAwesome,
  MdArrowRightAlt,
} from 'react-icons/md'
import TopbarNotiComponent from '../layout/topbarNotification'
import TopbarSettingsComponent from '../layout/topbarworkspacesettings'
import { changeCard, checkStatusChargeChangeCard } from '../../apis/plan/api'
import { allPlan, changePlanTrial, planDetail } from '../../apis/plan/api'

import {
  formatDateShortMonth,
  numberWithCommas,
  numberWithToFixed,
} from '../../helper/helper'
import {
  allCountry,
  roleByAccountId,
  roleByWorkspaceId,
  allCurrency,
} from '../../apis/account/api'
import AlertComponent from '../layout/alert'
import {
  subscriptionDetail,
  updateAutoRenewal,
  cancelPlan,
  resubscribePlan,
  usageDetail,
  periodDetail,
} from '../../apis/subscription/api'
import LoadingComponent from '../layout/loading'
import TopbarFreeNotiComponent from '../layout/topbarFreeNotification'

const { REACT_APP_OMISE_SECRET_KEY } = process.env

const SubscriptionComponent = () => {
  const [accountDetail, setAccountDetail] = useState('')
  const [openPopupEditCreditCard, setOpenPopupEditCreditCard] = useState(false)
  const specialChars = /[||\\<“>"'‘\;&*%!+{}@#=?$^\[\]]/
  const [currency, setCurrency] = useState('')
  const [selectedPlan, setSelectedPlan] = useState('')
  const [checkFreeTrial, setCheckFreeTrial] = useState('')

  const { accountId } = useParams()
  const { workspaceId } = useParams()
  const [planList, setPlanList] = useState([])
  const [currencyList, setCurrencyList] = useState([])
  const [subscriptionInfo, setSubscriptionInfo] = useState('')
  const [preriod, setPreriod] = useState(false)
  const [openPopupStopAutoRenewal, setOpenPopupStopAutoRenewal] =
    useState(false)
  const [openPopupCancelSubscription, setOpenPopupCancelSubscription] =
    useState(false)
  const [autoRenewal, setAutoRenewal] = useState(false)
  const [statusUsed, setStatusUsed] = useState(0)

  const [usageDate, setUsageDate] = useState([])
  const [periodList, setPeriodList] = useState([])
  const [workspacesList, setWorkspacesList] = useState([])
  const [eventCard, setEventCard] = useState('')
  const [isExtend, setIsExtend] = useState('')

  const [selectUsageDate, setSelectUsageDate] = useState(usageDate[0])
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm()
  const [isLoadingPage, setIsLoadingPage] = useState(true)
  const [activeAccountRole, setActiveAccountRole] = useState('')
  const [activeWorkspaceRole, setActiveWorkspaceRole] = useState('')

  const [isCancel, setIsCancel] = useState(false)
  const [isSuspended, setIsSuspended] = useState(false)
  const [planNum, setPlanNum] = useState('1')

  const customStylesPopupSM = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '511px',
    },
  }
  const customStylesPopupMD = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '553px',
    },
  }
  const customStylesPopupLG = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '640px',
    },
  }
  const customStylesPopupXLG = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '1210px',
      maxHeight: ' 700px',
    },
  }
  const accessToken = localStorage.getItem('token')
  const [checkStatusChangeCard, setCheckStatusChangeCard] = useState(false)
  const [openPopupCheckStatusChangeCard, setOpenPopupCheckStatusChangeCard] =
    useState(false)
  const [openPopupChangePlan, setOpenPopupChangePlan] = useState(false)
  const [isWaiting, setIsWaiting] = useState(false)
  const [isExpDate, setIsExpDate] = useState(false)

  const [isDisable, setIsDisable] = useState(false)
  const [planInfo, setPlanInfo] = useState('')
  const [detailUsage, setDetailUsage] = useState('')
  const url = `/checkout/${accountId}${workspaceId ? '/' + workspaceId : ''}`
  useEffect(() => {
    document.title = 'Subscription | Tag Turbo'
    setIsLoadingPage(true)
    getRoleByAccountId()
    getPlanInfo()
    getCurrency(accessToken)
    getPlan(accessToken)
    if (workspaceId) {
      getRoleByWorkspaceId()
    }
    getSubscriptionInfo(accessToken, accountId)
    if (window) {
      window.Omise.setPublicKey(REACT_APP_OMISE_SECRET_KEY)
    }
    getPeriod()
  }, [])
  useEffect(() => {
    getUsage()
  }, [planInfo])

  const getPlanInfo = async () => {
    const { data, status } = await planDetail(accessToken, accountId)
    if (status === 200) {
      const oneDay = 24 * 60 * 60 * 1000
      const startDatePackage = new Date().getTime()
      const lastDatePackage = new Date(data.plan_of_account_detail.end_date)
      const diffDays = Math.floor(
        Math.abs((startDatePackage - lastDatePackage) / oneDay),
      )
      setPlanInfo(data.plan_of_account_detail)
      setCheckFreeTrial(data.plan_of_account_detail.is_trial)
      setIsExtend(data.plan_of_account_detail.status === 'Pending Payment')
      setIsExpDate(diffDays <= 0)
      setIsSuspended(data.plan_of_account_detail.status === 'Suspended')
      setIsCancel(data.plan_of_account_detail.is_cancel)
      if (diffDays <= 0 || data.plan_of_account_detail.status !== 'Active') {
        setIsDisable(true)
      }
      setAutoRenewal(data.plan_of_account_detail.is_renewal)
    }
  }
  const getSubscriptionInfo = async (accessToken, accountId) => {
    const { data, status } = await subscriptionDetail(accessToken, accountId)
    if (status === 200) {
      let planNum = 0 // plan

      if (data.subscriptionDetail.plan === 'Basic') {
        planNum = 1
      } else if (data.subscriptionDetail.plan === 'Pro') {
        planNum = 2
      } else if (data.subscriptionDetail.plan === 'Ultimate') {
        planNum = 3
      }
      setPlanNum(planNum)
      setIsLoadingPage(false)
      setSubscriptionInfo(data?.subscriptionDetail)
      setPreriod(data?.subscriptionDetail?.subscription_type)
      if (data?.subscriptionDetail?.status === 'Suspended') {
        getPlanInfo()
      }
      setCurrency(data?.subscriptionDetail?.currency)
    } else {
      setIsLoadingPage(false)
    }
  }
  const getRoleByAccountId = async () => {
    const { data, status } = await roleByAccountId(accessToken, accountId)
    if (status === 200) {
      setActiveAccountRole(data.data.role)
    }
  }
  const handlePreriod = (event) => {
    setPreriod(event.target.checked ? 'year' : 'month')
  }
  const getRoleByWorkspaceId = async () => {
    const { data, status } = await roleByWorkspaceId(accessToken, workspaceId)
    if (status === 200) {
      setActiveWorkspaceRole(data.data.role)
    }
  }

  const [showAlert, setShowAlert] = useState({
    msg: 'Account Role has been updated successfully.',
    status: true,
    isShow: false,
  })
  const handleAlert = (msg, status) => {
    setShowAlert({
      msg,
      status,
      isShow: true,
    })

    setTimeout(() => {
      setShowAlert({
        msg,
        status,
        isShow: false,
      })
    }, 10000)
  }
  const handleAutoRenewal = async (event) => {
    if (event.target.checked) {
      setAutoRenewal(event.target.checked)
      onSubmitAutoRenewal(event.target.checked)
      handleAlert('Your Auto Renewal has been enabled successfully', true)
    } else {
      setOpenPopupStopAutoRenewal(!event.target.checked)
    }
  }

  const onSubmitAutoRenewal = async (checked) => {
    let obj = {
      auto_renewal: checked,
    }
    const { data, status } = await updateAutoRenewal(
      accessToken,
      accountId,
      obj,
    )
    if (status === 200) {
      setAutoRenewal(checked)
    } else {
      handleAlert(
        'Your auto-renewal was unsuccessfully turned off. Please try again. ',
        false,
      )
    }
  }

  const onSubmit = async (data) => {
    let tokenCard = ''
    window.OmiseCard.configure({
      publicKey: REACT_APP_OMISE_SECRET_KEY,
    })
    let exMount = data.expireDate.split('/')[0]
    let exYear = data.expireDate.split('/')[1]
    var cardInformation = {
      name: data.cardHolder,
      number: data.cardNumber,
      expiration_month: exMount,
      expiration_year: exYear,
      security_code: data.cvv,
    }
    window.Omise.createToken(
      'card',
      cardInformation,
      async (statusCode, response) => {
        if (statusCode === 200) {
          tokenCard = response.id

          let obj = {
            token_card: tokenCard,
          }

          onChangeCard(obj)
        } else {
          console.log('Omise get token error')
          setCheckStatusChangeCard(false)
          setOpenPopupCheckStatusChangeCard(true)
        }
      },
    )
    return tokenCard
  }
  const onChangeCard = async (obj) => {
    setOpenPopupCheckStatusChangeCard(true)
    setIsWaiting(true)
    const { data, status } = await changeCard(accessToken, accountId, obj)
    if (status === 200) {
      window.open(`${data.authorize_uri_omise}`, '_blank')
      await getStatusChargeChangeCard()
    } else {
      handleAlert('Failed to Change Card', false)
      setCheckStatusChangeCard(false)
      setOpenPopupCheckStatusChangeCard(true)
      setIsWaiting(false)
    }
  }

  const onchangePlanTrial = async (plan) => {
    let obj = {
      plan_id: plan,
    }
    const { data, status } = await changePlanTrial(accessToken, accountId, obj)
    if (status === 200) {
      setOpenPopupChangePlan(false)
      handleAlert('Change Plan Trial successful', true)
      getSubscriptionInfo(accessToken, accountId)
    } else {
      handleAlert(
        'Failed to update Change Plan Trial. Please try again.',
        false,
      )
    }
  }

  const getStatusChargeChangeCard = async () => {
    setOpenPopupCheckStatusChangeCard(true)
    setIsWaiting(true)
    const { data, status } = await checkStatusChargeChangeCard(
      accessToken,
      accountId,
    )

    if (status === 200) {
      if (data.data.status === 'successful') {
        setTimeout(() => {
          getSubscriptionInfo(accessToken, accountId)
        }, 2000)
        setOpenPopupEditCreditCard(false)
        setCheckStatusChangeCard(true)
        setOpenPopupCheckStatusChangeCard(true)
        setIsWaiting(false)
      } else if (data.data.status === 'pending') {
        setCheckStatusChangeCard(false)
        setOpenPopupCheckStatusChangeCard(true)
        setTimeout(() => {
          getStatusChargeChangeCard()
        }, 5000)
      } else if (data.data.status === 'failed') {
        setCheckStatusChangeCard(false)
        setOpenPopupCheckStatusChangeCard(true)
        setIsWaiting(false)
      } else {
        setCheckStatusChangeCard(false)
        setOpenPopupCheckStatusChangeCard(true)
      }
    } else {
      setCheckStatusChangeCard(false)
      setOpenPopupCheckStatusChangeCard(true)
    }
  }

  const customInputDate = (text) => {
    let res = text
      .replace(/^(\d\d)(\d)$/g, '$1/$2')
      .replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2')
      .replace(/[^\d\/]/g, '')

    return res
  }

  const onCancelPlan = async () => {
    const { data, status } = await cancelPlan(accessToken, accountId)
    if (status === 200) {
      handleAlert('Canceled the subscription successfully.', true)
      setOpenPopupCancelSubscription(false)
      getSubscriptionInfo(accessToken, accountId)
      getPlanInfo(accessToken, accountId)
    } else {
      handleAlert(
        'Failed to cancel your subscription. Please try again.',
        false,
      )
    }
  }

  const creditCardType = (cc) => {
    let res = ''
    if (cc?.length <= 19) {
      let visa = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$')
      let mastercard = new RegExp('^5[1-5][0-9]{14}$')
      let mastercard2 = new RegExp('^2[2-7][0-9]{14}$')
      let jcb = new RegExp('^35[0-9]{14}[0-9]*$')
      let replaceDash = cc.replace('-', '')
      replaceDash = replaceDash.replace('-', '')
      replaceDash = replaceDash.replace('-', '')
      replaceDash = replaceDash.replaceAll(/\s/g, '')
      if (visa.test(replaceDash)) {
        res = 'VISA'
      }
      if (mastercard.test(replaceDash) || mastercard2.test(replaceDash)) {
        res = 'MASTERCARD'
      }
      if (jcb.test(replaceDash)) {
        res = 'JCB'
      }
      return res
    }
  }
  const maskCard = (value) => {
    var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
    var matches = v.match(/\d{4,16}/g)
    var match = (matches && matches[0]) || ''
    var parts = []
    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4))
    }
    if (parts.length) {
      return parts.join('-')
    } else {
      return value
    }
  }
  const onReSubscribePlan = async () => {
    const { data, status } = await resubscribePlan(accessToken, accountId)
    if (status === 200) {
      handleAlert('Your account has been resubscribed successfully  ! ', true)
      setIsDisable(false)
      getSubscriptionInfo(accessToken, accountId)
      getPlanInfo(accessToken, accountId)
    } else {
      handleAlert('Failed to resubscribe the account. Please try again.', false)
    }
  }

  const getPlan = async (token) => {
    const { data, status } = await allPlan(token)
    let { plan } = data.plan_list

    setPlanList(data.plan_list)
  }

  const getCurrency = async (token) => {
    const { data, status } = await allCurrency(token)
    let currency = data.currency_list
    currency.forEach(function (obj) {
      obj.value = obj.symbol
      obj.currency = obj.id
      obj.label = (
        <div className="select-flag">
          <img
            className="-flag"
            src={`/assets/img/Flag-${obj.id.toUpperCase()}.png`}
          />{' '}
          {obj.id.toUpperCase()}
        </div>
      )
      delete obj.id
      delete obj.status
      delete obj.symbol
      delete obj.country
    })
    if (status === 200) {
      setCurrencyList(currency)
    }
  }

  const getChargeDetailChangePlan = (planID, plan_name, new_plan) => {
    let changePlanType = 'current'
    let planNum = 0 // plan

    if (subscriptionInfo.plan === 'Pro') {
      planNum = 1
    } else if (subscriptionInfo.plan === 'Ultimate') {
      planNum = 2
    }

    if (new_plan > planNum) {
      changePlanType = 'upgrade'
    } else if (new_plan < planNum) {
      changePlanType = 'downgrade'
    }
    let obj_change_plan = {
      plan_id: planID,
      plan_type: preriod === 'trial' ? 'month' : preriod,
      currency: currency?.currency ? currency.currency : currency,
      plan_name: plan_name,
      change_type: changePlanType,
    }
    localStorage.setItem('plan_datail', JSON.stringify(obj_change_plan))
    localStorage.setItem('payment_event', 'Change_plan')
    window.location.href = url
  }

  const onPaynow = async () => {
    localStorage.setItem('payment_event', 'Extend')
    localStorage.removeItem('plan_datail')
    localStorage.setItem('event_type', 'Change')
    window.location.href = url
  }
  const getPeriod = async () => {
    const { data, status } = await periodDetail(accessToken, accountId)
    if (status === 200) {
      setPeriodList(data.period)
      setUsageDate([
        formatDateShortMonth(
          data.period[0]?.plan_start_period
            ? data.period[0].plan_start_period
            : '0',
        ) +
          ' - ' +
          formatDateShortMonth(
            data.period[0]?.plan_end_period
              ? data.period[0].plan_end_period
              : '0',
          ),
      ])
    }
  }
  const getUsage = async (period_number = 'current') => {
    let period = period_number || 'current'
    setSelectUsageDate(period)
    const { data, status } = await usageDetail(accessToken, accountId, period)
    if (status === 200) {
      if (data.usage && data.usage > 0 && planInfo) {
        let usagePercent =
          ((data?.usage || 0) / planInfo?.monthly_hit || 0) * 100
        setStatusUsed(usagePercent)
      }
      setDetailUsage(data)

      const rows = []
      for (const property in data.period_detail.workspaces) {
        rows.push(
          <tr>
            <td className="text-14">
              {data.period_detail.workspaces[property].name}
            </td>
            <td className="text-14">{property}</td>
            <td className="text-14">
              {data.period_detail.workspaces[property].count}
            </td>
          </tr>,
        )
      }
      setWorkspacesList(rows)
    }
  }
  const handleOpenChat = () => {
    window.tidioChatApi.open()
    window.tidioChatApi.display(true)
  }

  return (
    <SubscriptionBox>
      <div className="page pull-page-bg-secondary ">
        <div className="content-box">
          <TopbarSettingsComponent accountDetailFn={setAccountDetail} />
          {isLoadingPage ? (
            <LoadingComponent />
          ) : (
            <Fragment>
              <TopbarFreeNotiComponent planInfo={planInfo} />
              <TopbarNotiComponent
                planInfo={planInfo}
                activeAccountRole={activeAccountRole}
              />
              <MenuSettingComponent
                accountId={accountId}
                id={workspaceId}
                activeAccountRole={activeAccountRole}
                activeWorkspaceRole={activeWorkspaceRole}
              />
              {showAlert.isShow && (
                <AlertComponent
                  messageAlert={showAlert.msg}
                  status={showAlert.status}
                  alertType={showAlert.status ? 'alert-success' : 'alert-error'}
                />
              )}
              <div className="content-menu content-scroll ">
                <div className="container-md animate fadeIn one p-4 min-h-82">
                  <div className="content">
                    <div className="d-flex justify-content-between item-center mb-4">
                      <div className="col-6">
                        <h4 className="m-0 text-20 text-semibold">
                          Subscription{' '}
                        </h4>
                      </div>
                    </div>
                    {!planInfo.is_trial && (
                      <Fragment>
                        {!autoRenewal && !isDisable ? (
                          <Fragment>
                            {!isSuspended || !isExtend || isExpDate ? (
                              <div
                                className="alert alert-primary d-flex justify-content-between item-center"
                                role="alert"
                              >
                                {' '}
                                <span className="text-14">
                                  {' '}
                                  <MdInfo className="mx-2" />
                                  Your Auto Renewal has been disabled. To keep
                                  working, make payments by{' '}
                                  {formatDateShortMonth(
                                    subscriptionInfo?.end_extend_at
                                      ? subscriptionInfo?.end_extend_at
                                      : subscriptionInfo?.end_date,
                                  )}
                                </span>
                                <div>
                                  {!isDisable && (
                                    <span
                                      onClick={() => (
                                        onSubmitAutoRenewal(true),
                                        handleAlert(
                                          'Your Auto Renewal has been enabled successfully ',
                                          true,
                                        )
                                      )}
                                      className="text-12 mx-2 cursor text-semibold"
                                    >
                                      ENABLE AUTO RENEWAL
                                    </span>
                                  )}

                                  <span
                                    className="text-12 mx-2 cursor text-semibold"
                                    onClick={() => onPaynow()}
                                  >
                                    PAY NOW
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <Fragment />
                            )}
                          </Fragment>
                        ) : (
                          <Fragment />
                        )}
                      </Fragment>
                    )}

                    <div className="card p-2 border box-shadow-center bg-white mt-2">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <div>
                            <p className="text-18 text-semibold">
                              My Subscription
                            </p>
                          </div>
                          {!planInfo.is_trial && (
                            <Fragment>
                              {!isSuspended && (
                                <Fragment>
                                  {!isCancel ? (
                                    <div>
                                      <span
                                        onClick={() =>
                                          setOpenPopupCancelSubscription(true)
                                        }
                                        className="text-14 text-secondary cursor"
                                      >
                                        Cancel subscription
                                      </span>
                                    </div>
                                  ) : (
                                    <Fragment />
                                  )}
                                </Fragment>
                              )}{' '}
                            </Fragment>
                          )}
                        </div>
                        <div className="row">
                          <div className="col-5">
                            {' '}
                            <div className="row mb-2">
                              <b className="col-5 text-14 mb-1 text-semibold">
                                Account
                              </b>
                              <p className="col-5 -align-right text-14 mb-1">
                                {subscriptionInfo?.account_name}
                              </p>
                            </div>
                            <div className="row mb-2 ">
                              <p className="col-5 text-14 mb-1 text-semibold">
                                Status
                              </p>
                              <p
                                className={`col-5 -align-right text-14 mb-1  text-capitalize ${
                                  subscriptionInfo?.status === 'Suspended' ||
                                  subscriptionInfo?.status === 'Pending Payment'
                                    ? 'text-danger'
                                    : 'text-green'
                                }`}
                              >
                                {subscriptionInfo?.status}
                              </p>
                            </div>
                            <div className="row mb-2">
                              <p className="col-2 text-14 mb-1 text-semibold">
                                Plan
                              </p>
                              <p className="col-8 -align-right text-14 mb-1">
                                {!isSuspended
                                  ? planInfo.is_trial
                                    ? 'Free trial 14 day' +
                                      '(' +
                                      subscriptionInfo?.plan +
                                      ')'
                                    : subscriptionInfo?.plan
                                  : '-'}
                              </p>
                              {!isCancel && (
                                <p className="col-2 -align-right text-14 mb-1 change-plan text-primary">
                                  {!planInfo.is_trial ? (
                                    <Fragment>
                                      {isSuspended || isExpDate || isExtend ? (
                                        ''
                                      ) : (
                                        <span
                                          onClick={() =>
                                            setOpenPopupChangePlan(true)
                                          }
                                          className="cursor"
                                        >
                                          <MdHistory className="text-16" />{' '}
                                          Change Plan
                                        </span>
                                      )}
                                    </Fragment>
                                  ) : (
                                    <Fragment />
                                  )}
                                </p>
                              )}
                            </div>
                            <div className="row mb-2">
                              <p className="col-5 text-14 mb-1 text-semibold">
                                Monthly Event
                              </p>
                              <p className="col-5 -align-right text-14 mb-1">
                                {!isSuspended
                                  ? numberWithCommas(
                                      subscriptionInfo?.monthly_hit,
                                    )
                                  : '-'}
                                {}
                              </p>
                            </div>
                            <div className="row mb-2">
                              <p className="col-5 text-14 mb-1 text-semibold">
                                Price{' '}
                              </p>
                              <p className="col-5 -align-right text-14 mb-1">
                                {subscriptionInfo?.price ? (
                                  <Fragment>
                                    {!isSuspended ? (
                                      <Fragment>
                                        {numberWithCommas(
                                          numberWithToFixed(
                                            subscriptionInfo?.price,
                                          ),
                                        ).replace(/\.00$/, '')}{' '}
                                        <span className="text-uppercase">
                                          {subscriptionInfo?.currency}
                                        </span>{' '}
                                        /{' '}
                                        <span className="text-capitalize">
                                          {subscriptionInfo?.subscription_type}
                                        </span>{' '}
                                      </Fragment>
                                    ) : (
                                      '-'
                                    )}
                                  </Fragment>
                                ) : (
                                  '-'
                                )}
                              </p>
                            </div>
                            <div className="row mb-2">
                              <p className="col-5 text-14 mb-1 text-semibold">
                                Next Payment Date
                              </p>
                              <p className="col-5 -align-right text-14 mb-1">
                                {!isSuspended
                                  ? planInfo.is_trial
                                    ? '-'
                                    : formatDateShortMonth(
                                        subscriptionInfo?.end_date,
                                      )
                                  : '-'}
                              </p>
                            </div>
                            <div className="row mb-2">
                              <p className="col-5 text-14 mb-1 text-semibold">
                                Auto Renewal
                              </p>
                              <div className="col-5 -align-right text-14 mb-1">
                                <span
                                  className={`mx-2 ${
                                    isDisable || planInfo.is_trial
                                      ? 'text-grey-300'
                                      : ''
                                  }`}
                                >
                                  {autoRenewal ? 'On' : 'Off'}
                                </span>

                                {!planInfo.is_trial ? (
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      onChange={(e) => handleAutoRenewal(e)}
                                      className={`form-check-input `}
                                      checked={autoRenewal}
                                      disabled={isDisable}
                                    />
                                    <span
                                      className={`${
                                        isDisable
                                          ? ' slider-disabled slider cursor-no-drop'
                                          : 'slider-md slider'
                                      } round`}
                                    ></span>
                                  </label>
                                ) : (
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      className={`form-check-input `}
                                      checked={autoRenewal}
                                      disabled={true}
                                    />
                                    <span
                                      className={`slider-disabled slider cursor-no-drop round`}
                                    ></span>
                                  </label>
                                )}
                              </div>
                            </div>
                          </div>
                          {!isSuspended && !isExtend ? (
                            <Fragment>
                              {isCancel ? (
                                <div className="col-7">
                                  <div
                                    className="alert alert-warning text-14"
                                    role="alert"
                                  >
                                    <MdReport /> You canceled your subscription.
                                    You can continue to use as usual until{' '}
                                    <span className="text-semibold">
                                      {formatDateShortMonth(
                                        subscriptionInfo?.end_extend_at
                                          ? subscriptionInfo?.end_extend_at
                                          : subscriptionInfo?.end_date,
                                      )}{' '}
                                    </span>
                                    <br />
                                    <br />
                                    After this period, your account will be
                                    suspended and all tags will be <br />
                                    disabled. You and your team members will no
                                    longer be able to access the account or any
                                    of its workspaces.
                                    {isExpDate ? (
                                      <Fragment />
                                    ) : (
                                      <Fragment>
                                        To prevent data loss, you can{' '}
                                        <span
                                          onClick={() => onReSubscribePlan()}
                                          className="text-semibold cursor text-decoration-underline"
                                        >
                                          click here to resubscribe
                                        </span>
                                      </Fragment>
                                    )}
                                  </div>
                                </div>
                              ) : (
                                <Fragment />
                              )}
                            </Fragment>
                          ) : (
                            <Fragment />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="card p-2 border box-shadow-center bg-white mt-2">
                      <div className="card-body">
                        <p className="text-18 text-semibold">Payment Method</p>
                        <div className="row mb-2">
                          {subscriptionInfo?.card_detail?.brand_card ? (
                            <Fragment>
                              <b className="text-14 mb-1">Credit Card</b>
                              <div className="text-14 mb-1 -input-credit">
                                <b>
                                  {subscriptionInfo?.card_detail?.brand_card}
                                </b>{' '}
                                ****
                                {
                                  subscriptionInfo?.card_detail
                                    ?.last_digits_card
                                }
                                {subscriptionInfo?.status === 'Suspended' ? (
                                  <span className="-float-right text-grey-300 cursor-no-drop">
                                    Edit
                                  </span>
                                ) : (
                                  <span
                                    className="-float-right text-blue -cursor"
                                    onClick={() => (
                                      setOpenPopupEditCreditCard(true),
                                      reset(),
                                      setEventCard('Edit')
                                    )}
                                  >
                                    Edit
                                  </span>
                                )}
                              </div>
                            </Fragment>
                          ) : (
                            <button
                              type="button"
                              className="btn btn-primary w-25"
                              onClick={() => (
                                setOpenPopupEditCreditCard(true),
                                reset(),
                                setEventCard('Add')
                              )}
                            >
                              Add Credit Card
                            </button>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="card border box-shadow-center bg-white mt-2">
                      <div className="card-body p-0 ">
                        <div className="d-flex justify-content-between">
                          {' '}
                          <p className="text-18 text-semibold p-4 m-0">
                            Account and Workspace Usage
                          </p>
                          <Dropdown className="p-4">
                            <Dropdown.Toggle className="dropdown-white">
                              <FaRegCalendarAlt />{' '}
                              {usageDate || selectUsageDate}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {periodList.length > 0 &&
                                periodList.map((item, i) => (
                                  <Dropdown.Item
                                    key={i}
                                    onClick={() => getUsage(item.period_number)}
                                  >
                                    {formatDateShortMonth(
                                      item?.plan_start_period
                                        ? item.plan_start_period
                                        : '0',
                                    ) +
                                      ' - ' +
                                      formatDateShortMonth(
                                        item?.plan_end_period
                                          ? item.plan_end_period
                                          : '0',
                                      )}
                                  </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        <div className="usage-box px-4 col-4 mb-4">
                          <p>Usage for {subscriptionInfo?.account_name} </p>
                          <div
                            className="progress my-2 "
                            style={{ height: '8px' }}
                          >
                            <div
                              className={` "progress-bar " ${
                                statusUsed < 80
                                  ? 'bg-primary'
                                  : statusUsed < 90
                                    ? 'bg-warning'
                                    : 'bg-danger'
                              } `}
                              role="progressbar"
                              aria-valuenow={statusUsed}
                              aria-valuemin="0"
                              aria-valuemax="100"
                              style={{ width: statusUsed + '%' }}
                            ></div>
                          </div>
                          <p className="text-right m-2">
                            {numberWithCommas(
                              detailUsage.usage ? detailUsage.usage : '0',
                            )}{' '}
                            / {numberWithCommas(subscriptionInfo?.monthly_hit)}{' '}
                            events
                          </p>
                        </div>

                        {workspacesList && workspacesList.length > 0 ? (
                          <table className="table border rounded m-0">
                            <thead className="table-light">
                              <tr>
                                <td className="text-12">Workspace Name</td>
                                <td className="text-12">Workspace ID</td>
                                <td className="text-12">Event</td>
                              </tr>
                            </thead>
                            <tbody>{workspacesList}</tbody>
                          </table>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="my-3">
                    <FooterComponent />
                  </div>
                </div>
              </div>
            </Fragment>
          )}

          <Modal
            shouldCloseOnOverlayClick={false}
            closeTimeoutMS={200}
            ariaHideApp={false}
            isOpen={openPopupStopAutoRenewal}
            contentLabel="modal"
            style={customStylesPopupSM}
            onRequestClose={() => setOpenPopupStopAutoRenewal(false)}
            className={'modal-Publish-box animate-5 fadeIn two'}
          >
            <div className="modal-dialog modal-md ">
              <div className="modal-content">
                <div className="modal-header bg-warning">
                  <div className="p-1 text-white">
                    <p className="py-0 mb-0 text-20"></p>
                  </div>
                </div>
                <div className="d-flex justify-content-end px-2">
                  <div>
                    <MdClose
                      className="cursor text-secondary"
                      onClick={() => setOpenPopupStopAutoRenewal(false)}
                    />
                  </div>
                </div>
                <div className="modal-body p-4 mt-3 text-center">
                  <img className="mb-2" src="/assets/img/warning_yellow.png" />
                  <p className="text-20 text-warning text-semibold">
                    Stop Auto-Renewal?
                  </p>
                  <p className="text-14">
                    By stopping automatic renewal, your subscription will be{' '}
                    <br /> canceled at the end of current billing cycle. If you
                    want to <br /> avoid service interruption, you must make a
                    manually <br /> payment within{' '}
                    <b>{formatDateShortMonth(subscriptionInfo?.end_date)}</b>
                  </p>
                </div>
                <div className="modal-footer justify-content-center px-5 pb-5">
                  <div
                    className="btn w-50 cursor text-14 text-semibold text-secondary"
                    onClick={() => setOpenPopupStopAutoRenewal(false)}
                  >
                    Keep Auto-Renewal
                  </div>
                  <div
                    className="btn btn-warning w-50 text-white text-14"
                    onClick={() => (
                      setOpenPopupStopAutoRenewal(false),
                      handleAlert(
                        'Your Auto Renewal has been disabled successfully',
                        true,
                      ),
                      onSubmitAutoRenewal(false)
                    )}
                  >
                    Stop Auto-Renewal
                  </div>
                </div>
              </div>
            </div>
          </Modal>

          <Modal
            shouldCloseOnOverlayClick={false}
            closeTimeoutMS={200}
            ariaHideApp={false}
            isOpen={openPopupCancelSubscription}
            contentLabel="modal"
            style={customStylesPopupSM}
            onRequestClose={() => setOpenPopupCancelSubscription(false)}
            className={'modal-Publish-box animate-5 fadeIn two'}
          >
            <div className="modal-dialog modal-md ">
              <div className="modal-content">
                <div className="modal-header bg-danger">
                  <div className="p-1 text-white">
                    <p className="py-0 mb-0 text-20"></p>
                  </div>
                </div>
                <div className="d-flex justify-content-end px-2">
                  <div>
                    <MdClose
                      className="cursor text-secondary"
                      onClick={() => setOpenPopupCancelSubscription(false)}
                    />
                  </div>
                </div>
                <div className="modal-body p-4 mt-3 text-center">
                  <img className="mb-2" src="/assets/img/warning.png" />
                  <p className="text-20 text-danger text-semibold">
                    Cancel Subscription?
                  </p>
                  {subscriptionInfo.end_extend_at ? (
                    <p className="text-14">
                      Please keep in mind that if you cancel your subscription
                      <br />
                      now, your account will be immediately suspended and all{' '}
                      <br />
                      tags will be disabled.
                      <br />
                      <br /> To continue using the account, <br />
                      please stay subscribed and make a payment.
                    </p>
                  ) : (
                    <p className="text-14">
                      If you cancel your subscription now, you can use the{' '}
                      <br /> account until{' '}
                      <b> {formatDateShortMonth(subscriptionInfo?.end_date)}</b>
                      <br />
                      After this period, your account will be suspended and all{' '}
                      <br /> tags will be disabled. You and your team members
                      will no <br />
                      longer be able to access the account or any of its <br />
                      workspaces.
                    </p>
                  )}
                </div>
                <div className="modal-footer justify-content-center px-5 pb-5">
                  <div
                    className="btn w-50 cursor text-14 text-semibold text-secondary"
                    onClick={() => onCancelPlan()}
                  >
                    Cancel Subscription
                  </div>
                  <div
                    className="btn btn-danger w-50 text-white text-14"
                    onClick={() => setOpenPopupCancelSubscription(false)}
                  >
                    Stay Subscribed
                  </div>
                </div>
              </div>
            </div>
          </Modal>

          <Modal
            shouldCloseOnOverlayClick={false}
            closeTimeoutMS={200}
            ariaHideApp={false}
            isOpen={openPopupEditCreditCard}
            contentLabel="modal"
            style={customStylesPopupMD}
            onRequestClose={() => setOpenPopupEditCreditCard(false)}
            className={'modal-Publish-box animate-5 fadeIn two'}
          >
            <div className="modal-dialog modal-md ">
              <div className="modal-content">
                <div className="modal-header bg-primary">
                  <div className="p-1 text-white">
                    <p className="py-0 mb-0 text-20"></p>
                  </div>
                </div>
                <div className="modal-body p-4 mt-3">
                  <p className="text-20 text-semibold">
                    {eventCard} Credit Card
                  </p>
                  <Payment>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div id="card_id">
                        <label className="form-label text-14 mb-1">
                          Card Number *
                        </label>
                        <div className="input-group mb-2">
                          <span
                            className={`input-group-text border-right-0 ${
                              errors.cardNumber?.type === 'required'
                                ? 'is-invalid'
                                : ''
                            }`}
                          >
                            <MdCreditCard />
                          </span>
                          <input
                            className={`form-control form-control-icon  border-left-0 ${
                              errors.cardNumber?.type === 'required'
                                ? 'is-invalid border-right-red'
                                : 'border-right-0'
                            }`}
                            {...register('cardNumber', {
                              required: true,
                            })}
                            value={
                              watch()?.cardNumber &&
                              watch()?.cardNumber?.length <= 19
                                ? maskCard(watch()?.cardNumber)
                                : ''
                            }
                            onKeyPress={(event) => {
                              if (
                                !/[0-9]/.test(event.key) ||
                                event?.target?.value?.length > 18
                              ) {
                                event.preventDefault()
                              }
                            }}
                          />

                          <span
                            className={`input-group-text border-left-0 ${
                              errors.cardNumber?.type === 'required'
                                ? 'd-none'
                                : ''
                            }`}
                          >
                            {creditCardType(watch()?.cardNumber) &&
                              watch()?.cardNumber.length <= 19 && (
                                <img
                                  src={`/assets/img/${
                                    watch()?.cardNumber
                                      ? creditCardType(watch()?.cardNumber)
                                      : ''
                                  }.png`}
                                  alt={`cardType`}
                                  height="20"
                                />
                              )}
                          </span>
                        </div>
                        {errors.cardNumber?.type === 'required' && (
                          <p role="alert" className="text-danger text-12">
                            Card Number is required
                          </p>
                        )}
                      </div>
                      <p className="text-14 mb-1">Card Holder Name *</p>
                      <input
                        className={`form-control w-100 mb-2 ${
                          errors.cardHolder?.type === 'required'
                            ? 'is-invalid'
                            : ''
                        }`}
                        {...register('cardHolder', {
                          required: true,
                        })}
                        onKeyPress={(event) => {
                          if (specialChars.test(event.key)) {
                            event.preventDefault()
                          }
                        }}
                      />
                      {errors.cardHolder?.type === 'required' && (
                        <p role="alert" className="text-danger text-12">
                          Card Holder is required
                        </p>
                      )}
                      <div className="row">
                        <div className="col-6">
                          <label className="text-14 mb-1">
                            Expiration Date *
                          </label>
                          <input
                            className={`form-control w-100 mb-2 ${
                              errors.expireDate?.type === 'required'
                                ? 'is-invalid'
                                : ''
                            }`}
                            {...register('expireDate', {
                              required: true,
                            })}
                            size="5"
                            maxLength="5"
                            value={
                              watch()?.expireDate
                                ? customInputDate(watch().expireDate)
                                : ''
                            }
                          />
                          {errors.expireDate?.type === 'required' && (
                            <p role="alert" className="text-danger text-12">
                              Expiration Date is required
                            </p>
                          )}
                        </div>
                        <div className="col-6">
                          <label className="text-14 mb-1">CVV *</label>
                          <input
                            className={`form-control w-100 mb-2 ${
                              errors.cvv?.type === 'required'
                                ? 'is-invalid'
                                : ''
                            }`}
                            {...register('cvv', {
                              required: true,
                            })}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault()
                              }
                            }}
                            maxLength={3}
                            type="password"
                          />
                          {errors.cvv?.type === 'required' && (
                            <p role="alert" className="text-danger text-12">
                              CVV is required
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="modal-footer justify-content-right py-4">
                        <div
                          className="btn cursor w-25"
                          onClick={() => setOpenPopupEditCreditCard(false)}
                        >
                          Cancel
                        </div>
                        <input
                          type="submit"
                          className="btn btn-primary w-25"
                          Save
                        />
                      </div>
                    </form>
                  </Payment>
                </div>
              </div>
            </div>
          </Modal>

          <Modal
            shouldCloseOnOverlayClick={false}
            closeTimeoutMS={200}
            ariaHideApp={false}
            isOpen={openPopupCheckStatusChangeCard}
            contentLabel="modal"
            style={!isWaiting ? customStylesPopupSM : customStylesPopupLG}
            onRequestClose={() => setOpenPopupCheckStatusChangeCard(false)}
            className={'modal-Publish-box animate-5 fadeIn two'}
          >
            <div className="modal-dialog modal-md ">
              <div className="modal-content">
                <div
                  className={`modal-header ${
                    !isWaiting
                      ? checkStatusChangeCard
                        ? 'bg-primary'
                        : 'bg-danger'
                      : 'bg-primary'
                  }`}
                >
                  <div className="p-1 text-white"></div>
                </div>

                <div className="modal-body p-4 text-center">
                  {!isWaiting ? (
                    <Fragment>
                      <div className="row p-4">
                        <div>
                          <img
                            src={`/assets/img/${
                              checkStatusChangeCard ? 'correct' : 'cancle'
                            }.svg`}
                            alt={``}
                            height=""
                            width="80"
                          />
                        </div>

                        <h2 className="my-3">
                          <b
                            className={`${
                              checkStatusChangeCard
                                ? 'text-primary'
                                : 'text-danger'
                            }`}
                          >
                            {checkStatusChangeCard ? 'Success' : 'Error!'}{' '}
                          </b>
                        </h2>

                        {checkStatusChangeCard ? (
                          <p className="text-14">
                            Your credit card was successfully updated. <br />{' '}
                            The updated card details will be used for the next
                            payment.
                          </p>
                        ) : (
                          <p className="text-secondary text-14">
                            Oops! Something is wrong here.
                            <br /> Your updated card details wasn’t completed.
                          </p>
                        )}
                      </div>

                      <div className="row justify-content-center mb-5">
                        {checkStatusChangeCard ? (
                          <Fragment>
                            <div className="col-lg-4 ">
                              <a
                                className="btn btn-primary  w-100 text-16"
                                onClick={() =>
                                  setOpenPopupCheckStatusChangeCard(false)
                                }
                              >
                                OK
                              </a>
                            </div>
                          </Fragment>
                        ) : (
                          <div className="col-lg-4 mt-3">
                            <a
                              className="btn btn-danger w-100 text-16"
                              onClick={() =>
                                setOpenPopupCheckStatusChangeCard(false)
                              }
                            >
                              Try Again
                            </a>
                          </div>
                        )}
                      </div>
                    </Fragment>
                  ) : (
                    <div className="row p-5">
                      <div>
                        <img
                          src={`/assets/img/loading.gif`}
                          alt={``}
                          width="100"
                        />
                        <p className="p-4">
                          <b className="text-30">Change Card Processing...</b>
                        </p>
                        <p className="text-secondary text-14">
                          Your payment is being processed. <br /> Please do not
                          close this page.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Modal>

          <Modal
            shouldCloseOnOverlayClick={false}
            closeTimeoutMS={200}
            ariaHideApp={false}
            isOpen={openPopupChangePlan}
            contentLabel="modal"
            style={customStylesPopupXLG}
            onRequestClose={() => setOpenPopupChangePlan(false)}
            className={'modal-Publish-box animate-5 fadeIn two'}
          >
            <SubscriptionBox>
              <div className="modal-dialog modal-md ">
                <div className="modal-content">
                  <div className="modal-header bg-primary">
                    <div className="p-1 text-white">
                      <p className="py-0 mb-0 text-20"></p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end px-2">
                    <div>
                      <MdClose
                        className="cursor text-secondary"
                        onClick={() => setOpenPopupChangePlan(false)}
                      />
                    </div>
                  </div>

                  <div className="modal-body p-4 text-center">
                    <p className="text-30 text-semibold">Change Plan</p>
                    <p className="text-14">
                      Select the plan that suits your usage best and enjoy the
                      perfect fit for your requirements.
                    </p>
                    <div>
                      <div className="card-body">
                        <div className="d-flex justify-content-center mb-3">
                          <div
                            className={`justify-content-between item-center d-flex col-4 `}
                          >
                            <div className="flag-box">
                              <div className="select-flag">
                                <img
                                  className="-flag"
                                  src={`/assets/img/Flag-${currency.toUpperCase()}.png`}
                                />{' '}
                                {currency.toUpperCase()}
                              </div>
                            </div>

                            <ModalToggle>
                              <span
                                className={`px-2 ${
                                  preriod === 'month' ? 'text-semibold' : ''
                                }`}
                              >
                                Monthly
                              </span>
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  onChange={(e) => handlePreriod(e)}
                                  value={preriod === 'year' ? true : false}
                                  checked={preriod === 'year' ? true : false}
                                />
                                <span className="slider round"></span>
                              </label>
                              <span
                                className={` px-2 ${
                                  preriod === 'year' ? 'text-semibold' : ''
                                }`}
                              >
                                Yearly
                              </span>
                            </ModalToggle>
                            <span className="badge-saving">Save 20%</span>
                          </div>
                        </div>
                        <div className="d-flex space-between">
                          {planList.length > 0 &&
                            planList.map((item, i) => {
                              return (
                                <div
                                  className={`col-3   pb-0 px-2`}
                                  key={i}
                                  onClick={() => setSelectedPlan(item)}
                                >
                                  <div
                                    className={`card plan-box ${
                                      item.plan_name === 'Pro'
                                        ? 'plan-box-border'
                                        : 'mt-plan-box border-card-plan'
                                    }`}
                                  >
                                    {item.plan_name === 'Pro' ? (
                                      <div className="card-header text-center bg-most p-2 text-white text-semibold text-uppercase text-12">
                                        <MdAutoAwesome /> <b>Most Popular</b>
                                      </div>
                                    ) : (
                                      <div
                                        className={`${
                                          subscriptionInfo?.plan ===
                                          item.plan_name
                                            ? ''
                                            : 'body-plan-box-gray'
                                        }`}
                                      ></div>
                                    )}
                                    <div
                                      className={` card-body text-left line-height-2 pt-4 ${
                                        item.plan_name === 'Pro'
                                          ? 'border-primary'
                                          : ''
                                      }`}
                                    >
                                      <div className="d-flex ">
                                        <p
                                          className={`badge text-center text-uppercase text-12 ${
                                            i === 0
                                              ? 'badge-default'
                                              : i === 1
                                                ? 'badge-blue'
                                                : i === 2
                                                  ? 'badge-purple'
                                                  : 'badge-gray'
                                          } `}
                                        >
                                          {item.plan_name}
                                        </p>
                                        {subscriptionInfo?.plan ===
                                          item.plan_name && (
                                          <p
                                            className={`badge badge-outline-green text-center mx-3`}
                                            style={{ padding: '3px 9px' }}
                                          >
                                            Your Current Plan
                                          </p>
                                        )}
                                      </div>
                                      <b className="text-24">
                                        {item.price_per_year &&
                                        item.price_per_month
                                          ? currency?.value ||
                                            currencyList[
                                              currency === 'thb' ? 0 : 1
                                            ]?.value
                                          : ''}
                                        {preriod === 'year'
                                          ? numberWithCommas(
                                              item.price_per_year?.[
                                                currency?.currency ||
                                                  currencyList[
                                                    currency === 'thb' ? 0 : 1
                                                  ]?.currency
                                              ] / 12 || 'Custom',
                                            )
                                          : numberWithCommas(
                                              item.price_per_month?.[
                                                currency?.currency ||
                                                  currencyList[
                                                    currency === 'thb' ? 0 : 1
                                                  ]?.currency
                                              ] || 'Custom',
                                            )}
                                      </b>{' '}
                                      {item.price_per_year ||
                                      item.price_per_month ? (
                                        <span className="text-12">
                                          <span className="text-uppercase">
                                            {' '}
                                            {currency.currency ||
                                              currencyList[
                                                currency === 'thb' ? 0 : 1
                                              ]?.currency}
                                          </span>{' '}
                                          / month
                                        </span>
                                      ) : (
                                        ''
                                      )}
                                      {item.price_per_year &&
                                      item.price_per_month ? (
                                        <p className="text-12 text-grey mb-1">
                                          {preriod === 'year' ? (
                                            <Fragment>
                                              Billed yearly or{' '}
                                              {numberWithCommas(
                                                item.price_per_month?.[
                                                  currency?.currency ||
                                                    currencyList[
                                                      currency === 'thb' ? 0 : 1
                                                    ]?.currency
                                                ],
                                              )}{' '}
                                              <span className="text-uppercase">
                                                {' '}
                                                {currency.currency ||
                                                  currencyList[
                                                    currency === 'thb' ? 0 : 1
                                                  ]?.currency}
                                              </span>{' '}
                                              / month{' '}
                                            </Fragment>
                                          ) : (
                                            'Monthly Price'
                                          )}{' '}
                                        </p>
                                      ) : (
                                        <p className="text-12 text-grey mb-1">
                                          For teams, businesses
                                          <br />
                                          organizations wanting to maximize{' '}
                                          <br />
                                          growth and scale fast.
                                        </p>
                                      )}
                                      <div className="mb-2">
                                        {item.price_per_year &&
                                        item.price_per_month ? (
                                          <img
                                            src="/assets/img/Check_Blue.png"
                                            className="mr-3 img-20"
                                          />
                                        ) : (
                                          <img
                                            src="/assets/img/Check_Gold.png"
                                            className="mr-3 img-20"
                                          />
                                        )}

                                        <span className="text-12">
                                          {item.visitors === 0
                                            ? 'Custom'
                                            : numberWithCommas(
                                                item.visitors,
                                              )}{' '}
                                          monthly quota of event
                                        </span>
                                      </div>
                                      <div className="mb-2 d-inline-flex">
                                        {item.price_per_year &&
                                        item.price_per_month ? (
                                          <img
                                            src="/assets/img/Check_Blue.png"
                                            className="mr-3 img-20-w-h"
                                          />
                                        ) : (
                                          <img
                                            src="/assets/img/Check_Gold.png"
                                            className="mr-3 img-20-w-h"
                                          />
                                        )}
                                        <span className="text-12">
                                          Tracking channel support : <br/>{item.ga4_integration && "Google Analytics 4" }
                                         {item.google_ads_integration && ", Google Ads," }<br/>
                                         {item.meta_integration && " Meta Ads" }
                                         {item.tiktok_integration && ", TikTok Ads" }  
                                        </span>
                                      </div>
                                      <div className="mb-2">
                                        {item.price_per_year &&
                                        item.price_per_month ? (
                                          <img
                                            src="/assets/img/Check_Blue.png"
                                            className="mr-3 img-20"
                                          />
                                        ) : (
                                          <img
                                            src="/assets/img/Check_Gold.png"
                                            className="mr-3 img-20"
                                          />
                                        )}
                                        <span className="text-12">
                                          Performance Dashboard
                                        </span>
                                      </div>
                                      <div className="mb-2">
                                        {item.price_per_year &&
                                        item.price_per_month ? (
                                          <img
                                            src="/assets/img/Check_Blue.png"
                                            className="mr-3 img-20"
                                          />
                                        ) : (
                                          <img
                                            src="/assets/img/Check_Gold.png"
                                            className="mr-3 img-20"
                                          />
                                        )}
                                        <span className="text-12">
                                          {item.user_per_account === 0
                                            ? 'Unlimited'
                                            : item.user_per_account}{' '}
                                          Account User
                                        </span>
                                      </div>
                                      <div className="mb-2">
                                        {item.price_per_year &&
                                        item.price_per_month ? (
                                          <img
                                            src="/assets/img/Check_Blue.png"
                                            className="mr-3 img-20"
                                          />
                                        ) : (
                                          <img
                                            src="/assets/img/Check_Gold.png"
                                            className="mr-3 img-20"
                                          />
                                        )}
                                        <span className="text-12">
                                          {item.workspace_limit_per_account}{' '}
                                          Workspacese
                                        </span>
                                      </div>
                                      <div className="mb-2">
                                        {item.price_per_year &&
                                        item.price_per_month ? (
                                          <img
                                            src="/assets/img/Check_Blue.png"
                                            className="mr-3 img-20"
                                          />
                                        ) : (
                                          <img
                                            src="/assets/img/Check_Gold.png"
                                            className="mr-3 img-20"
                                          />
                                        )}
                                        <span className="text-12">
                                          {item.support_over_live_chat
                                            ? 'Email and Live chat Support'
                                            : 'Email Support'}
                                        </span>
                                      </div>
                                      <div className="mb-2 d-inline-flex">
                                        {item.price_per_year &&
                                        item.price_per_month ? (
                                          <Fragment>
                                            {item.dedicated_training_session ? (
                                              <img
                                                src="/assets/img/Check_Blue.png"
                                                className="mr-3 img-20-w-h"
                                              />
                                            ) : (
                                              <img
                                                src="/assets/img/Check_dis.png"
                                                className="mr-3 img-20-w-h"
                                              />
                                            )}
                                          </Fragment>
                                        ) : (
                                          <img
                                            src="/assets/img/Check_Gold.png"
                                            className="mr-3 img-20-w-h"
                                          />
                                        )}
                                        <span
                                          className={`text-12 ${
                                            item.dedicated_training_session
                                              ? ''
                                              : 'text-grey'
                                          }`}
                                        >
                                          Designated Customer Success Manager
                                        </span>
                                      </div>
                                      <div className="mb-2">
                                        {item.price_per_year &&
                                        item.price_per_month ? (
                                          <Fragment>
                                            {item.dedicated_training_session ? (
                                              <img
                                                src="/assets/img/Check_Blue.png"
                                                className="mr-3 img-20"
                                              />
                                            ) : (
                                              <img
                                                src="/assets/img/Check_dis.png"
                                                className="mr-3 img-20"
                                              />
                                            )}
                                          </Fragment>
                                        ) : (
                                          <img
                                            src="/assets/img/Check_Gold.png"
                                            className="mr-3 img-20"
                                          />
                                        )}
                                        <span
                                          className={`text-12 ${
                                            item.dedicated_training_session
                                              ? ''
                                              : 'text-grey'
                                          }`}
                                        >
                                          Dedicated Training Session
                                        </span>
                                      </div>
                                      <div className="plan-box-footer mt-4">
                                        <Fragment>
                                          {planNum <= i + 1 ? (
                                            <Fragment />
                                          ) : (
                                            <Fragment>
                                              <p
                                                className="text-12 text-semibold "
                                                onClick={() => handleOpenChat()}
                                              >
                                                Contact Sales to Downgrade{' '}
                                                <MdArrowRightAlt className="text-20 " />
                                              </p>
                                            </Fragment>
                                          )}
                                        </Fragment>
                                        <div
                                          className={`foot-btn ${
                                            planNum <= i ? '' : 'cursor-no-drop'
                                          }`}
                                        >
                                          {item.price_per_year ||
                                          item.price_per_month ? (
                                            checkFreeTrial && <Fragment />
                                          ) : (
                                            <button
                                              id={`subscription_contact_sales`}
                                              type="button"
                                              className={`btn w-100 badge-outline-gold `}
                                              onClick={() => handleOpenChat()}
                                            >
                                              Contact Sales
                                              <MdArrowRightAlt className="text-20" />
                                            </button>
                                          )}
                                          {planList.length - 1 !== i ? (
                                            subscriptionInfo?.plan ===
                                            item.plan_name ? (
                                              subscriptionInfo?.subscription_type ===
                                              preriod ? (
                                                <Fragment>
                                                  <button
                                                    id={`paynow_change_plan_${item.plan_name}`}
                                                    type="button"
                                                    onClick={() =>
                                                      getChargeDetailChangePlan(
                                                        item.id,
                                                        item.plan_name,
                                                        i,
                                                      )
                                                    }
                                                    className="btn btn-primary w-100 "
                                                    disabled={
                                                      planNum === i + 1
                                                        ? true
                                                        : false
                                                    }
                                                  >
                                                    Pay Now
                                                  </button>{' '}
                                                </Fragment>
                                              ) : (
                                                <Fragment>
                                                  <button
                                                    type="button"
                                                    onClick={() =>
                                                      getChargeDetailChangePlan(
                                                        item.id,
                                                        item.plan_name,
                                                        i,
                                                      )
                                                    }
                                                    className="btn btn-primary w-100 "
                                                    disabled={false}
                                                  >
                                                    Pay Now
                                                  </button>
                                                </Fragment>
                                              )
                                            ) : (
                                              <Fragment>
                                                <button
                                                  id={`paynow_change_plan_${item.plan_name}`}
                                                  type="button"
                                                  onClick={() =>
                                                    getChargeDetailChangePlan(
                                                      item.id,
                                                      item.plan_name,
                                                      i,
                                                    )
                                                  }
                                                  className="btn btn-primary w-100 "
                                                  disabled={
                                                    planNum <= i + 1
                                                      ? false
                                                      : true
                                                  }
                                                >
                                                  Pay Now
                                                </button>{' '}
                                              </Fragment>
                                            )
                                          ) : (
                                            ''
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SubscriptionBox>
          </Modal>
        </div>
      </div>
    </SubscriptionBox>
  )
}

export default SubscriptionComponent
