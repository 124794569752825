import axios from 'axios'
import { redirectLogin, redirectPermission } from '../../helper/helper'

const { REACT_APP_API_TAG } = process.env

const handleError = (error) => {
  if (error.response?.status === 401) {
    redirectLogin()
  } else if (error.response?.status === 403) {
    redirectPermission()
  } else {
    return {
      status: 500,
      data: error,
    }
  }
}

const channelList = async (token, workspaceId) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      'Access-Control-Allow-Origin': true,
      workspace_id: workspaceId,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/workspace/integration-status`
    const response = await axios.get(URL, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    handleError(error)
  }
  return res
}

const ga4Info = async (token, workspaceId) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      'Access-Control-Allow-Origin': true,
      workspace_id: workspaceId,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/workspace/integration-info/ga4`
    const response = await axios.get(URL, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    handleError(error)
  }
  return res
}

const saveGa4 = async (token, workspaceId, obj) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      'Access-Control-Allow-Origin': true,
      workspace_id: workspaceId,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/workspace/integration-setting`
    const response = await axios.patch(URL, obj, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    handleError(error)
    res = {
      status: error.response.data.status,
      code: error.response.data.code,
    }
  }
  return res
}

const unsyncGa4 = async (token, workspaceId) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      'Access-Control-Allow-Origin': true,
      workspace_id: workspaceId,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/workspace/integration-unsync/ga4`
    const response = await axios.delete(URL, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    handleError(error)
    res = {
      status: error.response.data.status,
      code: error.response.data.code,
    }
  }
  return res
}

const authGoogleToken = async (token, workspaceId, code) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      'Access-Control-Allow-Origin': true,
      workspace_id: workspaceId,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/workspace/oauth2callback?code=${code}`
    const response = await axios.get(URL, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    handleError(error)
  }
  return res
}

const accountSummaries = async (
  token,
  workspaceId,
  accessToken,
  refreshToken,
) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      'Access-Control-Allow-Origin': true,
      workspace_id: workspaceId,
      access_token: accessToken,
      refresh_token: refreshToken,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/workspace/ga-account-summaries`
    const response = await axios.get(URL, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    handleError(error)
  }
  return res
}

const measurementIdGA4 = async (
  token,
  workspaceId,
  accessToken,
  refreshToken,
  propertyId,
) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      'Access-Control-Allow-Origin': true,
      workspace_id: workspaceId,
      access_token: accessToken,
      refresh_token: refreshToken,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/workspace/ga-measurement-id?property=${propertyId}`
    const response = await axios.get(URL, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    handleError(error)
  }
  return res
}

const googleAdsAccountList = async (
  token,
  workspaceId,
  accessToken,
  refreshToken,
  propertyId,
) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      'Access-Control-Allow-Origin': true,
      workspace_id: workspaceId,
      access_token: accessToken,
      refresh_token: refreshToken,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/workspace/google-ads-customer-list`
    const response = await axios.get(URL, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    handleError(error)
  }
  return res
}

const saveGGAds = async (token, workspaceId, obj) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      'Access-Control-Allow-Origin': true,
      workspace_id: workspaceId,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/workspace/integration-setting`
    const response = await axios.patch(URL, obj, customConfigHeader)


    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    handleError(error)
    res = {
      status: error.response.data.status,
      code: error.response.data.code,
    }
  }
  return res
}
const saveMetaAds = async (token, workspaceId, obj) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      'Access-Control-Allow-Origin': true,
      workspace_id: workspaceId,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/workspace/integration-setting`
    const response = await axios.patch(URL, obj, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    handleError(error)
    res = {
      status: error.response.data.status,
      code: error.response.data.code,
    }
  }
  return res
}
const saveCMP = async (token, workspaceId, obj) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      'Access-Control-Allow-Origin': true,
      workspace_id: workspaceId,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/workspace/integration-setting`
    const response = await axios.patch(URL, obj, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    handleError(error)
    res = {
      status: error.response.data.status,
      code: error.response.data.code,
    }
  }
  return res
}
const saveTikTokAds = async (token, workspaceId, obj) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      'Access-Control-Allow-Origin': true,
      workspace_id: workspaceId,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/workspace/integration-setting`
    const response = await axios.patch(URL, obj, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    handleError(error)
    res = {
      status: error.response.data.status,
      code: error.response.data.code,
    }
  }
  return res
}
const ggAdsInfo = async (token, workspaceId) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      'Access-Control-Allow-Origin': true,
      workspace_id: workspaceId,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/workspace/integration-info/google_ads`
    const response = await axios.get(URL, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    handleError(error)
  }
  return res
}
const metaAdsInfo = async (token, workspaceId) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      'Access-Control-Allow-Origin': true,
      workspace_id: workspaceId,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/workspace/integration-info/meta_pixel`
    const response = await axios.get(URL, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    handleError(error)
  }
  return res
}
const tiktokAdsInfo = async (token, workspaceId) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      'Access-Control-Allow-Origin': true,
      workspace_id: workspaceId,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/workspace/integration-info/tiktok`
    const response = await axios.get(URL, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    handleError(error)
  }
  return res
}
const unsyncGGAds = async (token, workspaceId) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      'Access-Control-Allow-Origin': true,
      workspace_id: workspaceId,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/workspace/integration-unsync/google_ads`
    const response = await axios.delete(URL, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    handleError(error)
    res = {
      status: error.response.data.status,
      code: error.response.data.code,
    }
  }
  return res
}
const unsyncPixelAds = async (token, workspaceId) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      'Access-Control-Allow-Origin': true,
      workspace_id: workspaceId,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/workspace/integration-unsync/meta_pixel`
    const response = await axios.delete(URL, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    handleError(error)
    res = {
      status: error.response.data.status,
      code: error.response.data.code,
    }
  }
  return res
}

const unsyncConsentMode = async (token, workspaceId, cmpType) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      'Access-Control-Allow-Origin': true,
      workspace_id: workspaceId,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/workspace/integration-unsync/${cmpType}`
    const response = await axios.delete(URL, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    handleError(error)
    res = {
      status: error.response.data.status,
      code: error.response.data.code,
    }
  }
  return res
}

const cmpInfo = async (token, workspaceId, type) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      'Access-Control-Allow-Origin': true,
      workspace_id: workspaceId,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/workspace/integration-info/${type}`
    const response = await axios.get(URL, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    handleError(error)
  }
  return res
}

const unsyncTiktokAds = async (token, workspaceId) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      'Access-Control-Allow-Origin': true,
      workspace_id: workspaceId,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_TAG}/workspace/integration-unsync/tiktok`
    const response = await axios.delete(URL, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    handleError(error)
    res = {
      status: error.response.data.status,
      code: error.response.data.code,
    }
  }
  return res
}
const getCurrentPlan = async (token, accountId) => {
  const customConfigHeader = {
    headers: {
      "content-Type": "application/json",
      Accept: "*/*",
      "Cache-Control": "no-cache",
      Authorization: token,
      "Access-Control-Allow-Origin": true,
      account_id: accountId,
    },
  };
  let res = {};
  try {
    let URL = `${REACT_APP_API_TAG}/plan/current-account-plan`;
    const response = await axios.get(URL, customConfigHeader);
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      };
    }
  } catch (error) {
    handleError(error);
  }
  return res;
};
export {
  channelList,
  ga4Info,
  saveGa4,
  unsyncGa4,
  authGoogleToken,
  accountSummaries,
  measurementIdGA4,
  googleAdsAccountList,
  saveGGAds,
  ggAdsInfo,
  unsyncGGAds,
  saveMetaAds,
  metaAdsInfo,
  unsyncPixelAds,
  unsyncConsentMode,
  saveCMP,
  cmpInfo,
  saveTikTokAds,
  tiktokAdsInfo,
  unsyncTiktokAds,
  getCurrentPlan
}
