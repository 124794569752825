import React, { Fragment, useState, useEffect, useRef } from 'react'
import { BsThreeDotsVertical } from '@react-icons/all-files/bs/BsThreeDotsVertical'
import Modal from 'react-modal'
import { Link, useParams } from 'react-router-dom'
import TopbarNotiComponent from '../layout/topbarNotification'
import { planDetail } from '../../apis/plan/api'

import { AccountSettingBox, RadioBox } from './style'
import FooterComponent from '../layout/footer'
import MenuSettingComponent from '../layout/menusetting'
import { MdError, MdWarning, MdClose, MdNavigateNext } from 'react-icons/md'
import Select from 'react-select'
import TopbarSettingsComponent from '../layout/topbarworkspacesettings'
import { accountById } from '../../apis/home/api'
import {
  allCountry,
  editAccountSetting,
  deleteAccount,
  roleByAccountId,
  roleByWorkspaceId,
} from '../../apis/account/api'
import AlertComponent from '../layout/alert'
import LoadingComponent from '../layout/loading'
import TopbarFreeNotiComponent from '../layout/topbarFreeNotification'

const AccountSettingComponent = () => {
  const [accountDetail, setAccountDetail] = useState('')
  const [openPopupConfirmDelete, setOpenPopupConfirmDelete] = useState(false)

  const [isClickSave, setIsClickSave] = useState(false)
  const [isSuspended, setIsSuspended] = useState(false)
  const { accountId } = useParams()
  const { workspaceId } = useParams()
  const [country, setCountry] = useState('')

  const [accountName, setAccountName] = useState('')
  const [planInfo, setPlanInfo] = useState('')

  const [countryList, setCountryList] = useState([])
  const [dafaultCountry, setDafaultCountry] = useState([
    {
      value: 'Thailand',
      label: 'Thailand',
    },
  ])
  const [activeAccountRole, setActiveAccountRole] = useState('')
  const [activeWorkspaceRole, setActiveWorkspaceRole] = useState('')
  const [isLoadingPage, setIsLoadingPage] = useState(true)
  const [isDisabled, setIsDisabled] = useState(true)

  const customStylesSM = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '436px',
    },
  }
  const accessToken = localStorage.getItem('token')

  useEffect(() => {
    document.title = 'Account Settings | Tag Turbo'
    setIsLoadingPage(true)
    getPlanInfo()
    getCountry(accessToken)
    getAccountById(accessToken, accountId)
    getRoleByAccountId()
    if (workspaceId) {
      getRoleByWorkspaceId()
    }
  }, [])
  const getRoleByAccountId = async () => {
    const { data, status } = await roleByAccountId(accessToken, accountId)
    if (status === 200) {
      setActiveAccountRole(data.data.role)
    }
  }
  const getPlanInfo = async () => {
    const { data, status } = await planDetail(accessToken, accountId)
    if (status === 200) {
      setIsLoadingPage(false)
      setPlanInfo(data.plan_of_account_detail)
      if (data.plan_of_account_detail.status === 'Suspended') {
        setIsSuspended(true)
      }
    }
  }
  const checkSpecialsChar = (str) => {
    let res
    const specials = /^[\u0E00-\u0E7Fa-zA-Z0-9\.\s\-_,]+$/gi
    if (str.match(specials)) {
      setAccountName(str)

      res = true
    }
    return res
  }
  const getRoleByWorkspaceId = async () => {
    const { data, status } = await roleByWorkspaceId(accessToken, workspaceId)
    if (status === 200) {
      setActiveWorkspaceRole(data.data.role)
    }
  }
  const [showAlert, setShowAlert] = useState({
    msg: 'Account Role has been updated successfully.',
    status: true,
    isShow: false,
  })
  const handleAlert = (msg, status) => {
    setShowAlert({
      msg,
      status,
      isShow: true,
    })

    setTimeout(() => {
      setShowAlert({
        msg,
        status,
        isShow: false,
      })
    }, 10000)
  }

  const onChangeCountry = (e) => {
    setCountry(e.value)
    setIsDisabled(false)
  }

  const editAccount = async () => {
    setIsClickSave(true)
    setIsDisabled(true)

    let obj = {
      name: accountName,
      country: country,
    }
    const { data, status } = await editAccountSetting(
      accessToken,
      accountId,
      obj,
    )

    if (status === 200) {
      handleAlert('Account Setting has been updated successfully', true)
      setIsClickSave(false)
    } else {
      handleAlert('Account Setting has been updated false', false)
      setIsClickSave(false)
    }
  }

  const getCountry = async (token) => {
    const { data, status } = await allCountry(token)

    if (status === 200) {
      let country = data.country_list
      country.forEach(function (obj) {
        obj.value = obj.country_name
        obj.label = obj.country_name
        delete obj.country_name
        delete obj.id
      })
      setCountryList(country)
      getAccountById()
    }
  }

  const getAccountById = async () => {
    const { data, status } = await accountById(accessToken, accountId)
    if (status === 200) {
      let arr = []
      let objDafaultCountry = {
        value: data?.data?.country || 'Thailand',
        label: data?.data?.country || 'Thailand',
      }
      setAccountName(data.data.name)
      arr.push(objDafaultCountry)
      setDafaultCountry(arr)
      setCountry(data?.data?.country || 'Thailand')
    }
  }

  const closeAccountUser = async () => {
    const { data, status } = await deleteAccount(accessToken, accountId)
    if (status === 200) {
      window.location.href = '/home'
      let str =
        ' ‘ ' + accountName + ' ’ ' + ' Account has been closed successfully.'
      setOpenPopupConfirmDelete(false)
      localStorage.setItem('isHandleAlert', str)
    } else {
      handleAlert(
        'Failed to close ' + accountName + 'Account, please try again later.',
        false,
      )
    }
  }

  return (
    <AccountSettingBox>
      <div className="page pull-page-bg-secondary ">
        <div className="content-box">
          <TopbarSettingsComponent accountDetailFn={setAccountDetail} />
          {isLoadingPage ? (
            <LoadingComponent />
          ) : (
            <Fragment>
              <TopbarFreeNotiComponent planInfo={planInfo} />
              <TopbarNotiComponent
                planInfo={planInfo}
                activeAccountRole={activeAccountRole}
              />

              <MenuSettingComponent
                accountId={accountId}
                id={workspaceId}
                activeAccountRole={activeAccountRole}
                activeWorkspaceRole={activeWorkspaceRole}
                planInfo={planInfo}
              />
              {showAlert.isShow && (
                <AlertComponent
                  messageAlert={showAlert.msg}
                  status={showAlert.status}
                  alertType={showAlert.status ? 'alert-success' : 'alert-error'}
                />
              )}
              <div className="content-menu content-scroll ">
                <div className="container-md animate fadeIn one p-4 min-h-82">
                  <div className="content">
                    <div className="d-flex justify-content-between item-center mb-4">
                      <div className="col-6">
                        <h4 className="m-0 text-20 text-semibold">
                          Account Settings{' '}
                        </h4>
                      </div>
                    </div>

                    <div className="card p-2 border box-shadow-center bg-white mt-2">
                      <div className="card-body">
                        <p className="text-18 text-semibold">Basic Settings</p>
                        <p className="text-14 mb-1">Account ID</p>
                        <p className="text-14">{accountId}</p>
                        <p className="text-14 mb-1">Account Name</p>
                        <input
                          type="text"
                          className="form-control w-25 mb-3"
                          id="account_name"
                          maxLength={30}
                          value={accountName || ''}
                          onChange={(e) => (
                            e.target.value.length === 0
                              ? setAccountName(e.target.value)
                              : checkSpecialsChar(e.target.value),
                            setIsDisabled(false)
                          )}
                          disabled={isSuspended}
                        />
                        <p className="text-14 mb-1">Country</p>
                        {dafaultCountry.length > 0 && (
                          <Select
                            options={countryList}
                            defaultValue={dafaultCountry[0]}
                            className="w-25 mb-3"
                            onChange={onChangeCountry}
                            isDisabled={isSuspended}
                          />
                        )}
                        {isSuspended ? (
                          activeAccountRole === 'SuperAccountAdmin' ? (
                            <Fragment>
                              {' '}
                              <p className="text-18 text-semibold my-4">
                                Danger Zone
                              </p>
                              <div className="p-3 mb-2 dangerzone text-emphasis-danger d-flex">
                                <div className="mx-3">
                                  <MdError />
                                </div>
                                <div>
                                  {' '}
                                  <p className="text-14 mb-1 text-semibold">
                                    Close Account
                                  </p>
                                  <p className="text-14 mb-1">
                                    Closing your account means the account and
                                    all workspaces in this account will be
                                    closed. After you confirm to close, all your
                                    data will be permanently deleted and
                                    unrecoverable.
                                    <span
                                      className="text-14 mb-1 cursor text-decoration-underline mx-2"
                                      onClick={() =>
                                        setOpenPopupConfirmDelete(true)
                                      }
                                    >
                                      Close Account
                                    </span>
                                  </p>
                                </div>
                              </div>{' '}
                            </Fragment>
                          ) : (
                            ''
                          )
                        ) : (
                          <Fragment>
                            <p className="text-18 text-semibold my-4">
                              Danger Zone
                            </p>
                            <div className="p-3 mb-2 dangerzone text-emphasis-danger d-flex">
                              <div className="mx-3">
                                <MdError />
                              </div>
                              <div>
                                <p className="text-14 mb-1 text-semibold">
                                  Close Account
                                </p>
                                <p className="text-14 mb-1">
                                  Closing your account means the account and all
                                  workspaces in this account will be closed.
                                  After you confirm to close, all your data will
                                  be permanently deleted and unrecoverable.
                                  <span
                                    className="text-14 mb-1 cursor text-decoration-underline mx-2"
                                    onClick={() =>
                                      setOpenPopupConfirmDelete(true)
                                    }
                                  >
                                    Close Account
                                  </span>
                                </p>
                              </div>
                            </div>
                          </Fragment>
                        )}
                      </div>
                    </div>
                    <div className="pt-4">
                      <button
                        type="button"
                        onClick={() => editAccount()}
                        className="btn btn-primary px-5"
                        disabled={
                          isDisabled ? true : isClickSave || isSuspended
                        }
                      >
                        Save
                      </button>
                    </div>
                  </div>
                  <div className="my-3">
                    <FooterComponent />
                  </div>
                </div>
              </div>
            </Fragment>
          )}

          {/* Popup ConfirmDelete*/}
          <Modal
            shouldCloseOnOverlayClick={false}
            closeTimeoutMS={200}
            ariaHideApp={false}
            isOpen={openPopupConfirmDelete}
            contentLabel="modal"
            style={customStylesSM}
            onRequestClose={() => setOpenPopupConfirmDelete(false)}
            className={'modal-Publish-box animate-5 fadeIn two'}
          >
            <div className="modal-dialog modal-md ">
              <div className="modal-content">
                <div className="modal-header bg-danger">
                  <div className="p-1 text-white">
                    <p className="py-0 mb-0 text-20"></p>
                  </div>
                </div>
                <div className="d-flex justify-content-end px-2">
                  <div>
                    <MdClose
                      className="cursor"
                      onClick={() => setOpenPopupConfirmDelete(false)}
                    />
                  </div>
                </div>
                <div className="modal-body p-4 mt-3 text-center">
                  <img className="mb-2" src="/assets/img/warning.png" />

                  <p className="text-20 text-danger">Close Account</p>
                  <p className="text-14">
                    Are you sure you want to close your account? Please note
                    that this action is permanent and cannot be undone.
                  </p>
                </div>
                <div className="modal-footer justify-content-center px-5 pb-5">
                  <div
                    className="btn cursor w-50"
                    onClick={() => setOpenPopupConfirmDelete(false)}
                  >
                    Cancel
                  </div>
                  <div
                    className="btn btn-danger w-50"
                    onClick={() => closeAccountUser()}
                  >
                    Close Account
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </AccountSettingBox>
  )
}

export default AccountSettingComponent
