import Modal from 'react-modal'
import { MdClose } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
const AlertDialogModal = (props) => {
  const customStylesSM = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '436px',
    },
  }

  const navigate = useNavigate()

  const redirectToIntegrationsPage = () => {
    navigate(`/integration/${props.accountId}/${props.workspaceId}`) // Replace '/new-path' with the path you want to navigate to.
  }

  return (
    <Modal
      shouldCloseOnOverlayClick={false}
      closeTimeoutMS={200}
      ariaHideApp={false}
      isOpen={props.openPopup}
      contentLabel="modal"
      style={customStylesSM}
      onRequestClose={() => props.setOpenPopup(false)}
      className={'modal-Publish-box animate-5 fadeIn two'}
    >
      <div className="modal-dialog modal-md ">
        <div className="modal-content">
          <div className="modal-header bg-danger">
            <div className="p-1 text-white">
              <p className="py-0 mb-0 text-20"></p>
            </div>
          </div>
          <div className="d-flex justify-content-end px-2">
            <div>
              <MdClose
                className="cursor"
                onClick={() => props.setOpenPopup(false)}
              />
            </div>
          </div>
          <div className="modal-body p-4 mt-3 text-center">
            <img className="mb-2" src="/assets/img/warning.png" />

            <p className="text-20 text-danger">{props.title}</p>
            <p className="text-14">{props.detail}</p>
          </div>
          <div className="modal-footer justify-content-center px-5 pb-5">
            <div
              className="btn cursor w-50"
              onClick={() => props.setOpenPopup(false)}
            >
              Cancel
            </div>
            <div
              className="btn btn-danger w-50"
              onClick={() => redirectToIntegrationsPage()}
            >
              Go to Integration
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default AlertDialogModal
