import React, { Fragment, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { MenuBox } from './style'
import {
  MdChevronLeft,
  MdManageAccounts,
  MdGroup,
  MdBookmark,
  MdCreditCard,
  MdResetTv,
  MdWebStories,
  MdAccountTree,
  MdSupervisorAccount,
  MdVideoLabel,
  MdSubtitles,
  MdOutlineFileUpload,
  MdOutlineFileDownload,
} from 'react-icons/md'
import { planDetail } from '../../apis/plan/api'
import { roleByAccountId } from '../../apis/account/api'

const MenuSettingComponent = (props) => {
  const path = window.location.pathname
  const { accountId, workspaceId } = useParams()
  const accessToken = localStorage.getItem('token')
  const [isSuspended, setIsSuspended] = useState(false)
  const [statusUsed, setStatusUsed] = useState(0)
  const [showPopup, setShowPopup] = useState(true)
  const [basicPlan, setBasicPlan] = useState('Basic')

  useEffect(() => {
    getRoleByAccountId()
  }, [])

  const getRoleByAccountId = async () => {
    const { data, status } = await roleByAccountId(accessToken, accountId)
    if (status === 200) {
      if (data.data.role !== 'None') {
        getPlanInfo()
      }
    }
  }

  const getPlanInfo = async () => {
    const { data, status } = await planDetail(accessToken, accountId)
    if (status === 200) {
      setBasicPlan(data.plan_of_account_detail.plan)
      if (data.plan_of_account_detail.status === 'Suspended') {
        setIsSuspended(true)
      }
    }
  }
  return (
    <MenuBox>
      <div className="sidenav">
        <div className="mt-0">
          {isSuspended && props.activeAccountRole !== 'SuperAccountAdmin' ? (
            <Fragment />
          ) : (
            <Fragment>
              {' '}
              {workspaceId && (
                <a
                  href="#Home"
                  onClick={() =>
                    (window.location.href = isSuspended
                      ? `/package/${accountId}`
                      : `/tag/${accountId}/${workspaceId}`)
                  }
                  className="text-12  text-regular"
                >
                  {' '}
                  <MdChevronLeft className="mb-1" /> Back to Workspace
                </a>
              )}
            </Fragment>
          )}

          {props.activeAccountRole === 'User' ||
          props.activeAccountRole === 'None' ? (
            ''
          ) : (
            <p className="py-3 m-0 px-4 text-semibold">Account</p>
          )}

          {props.activeAccountRole === 'SuperAccountAdmin' ||
          props.activeAccountRole === 'AccountAdmin' ? (
            <a
              href={`/accountsetting/${accountId}${
                workspaceId ? '/' + workspaceId : ''
              }`}
              className={`${
                path.includes('accountsetting') ? 'sidenav_active' : ''
              }`}
            >
              {' '}
              <MdManageAccounts className="text-18" /> Account Settings
            </a>
          ) : (
            <Fragment />
          )}
          {props.activeAccountRole === 'SuperAccountAdmin' ||
          props.activeAccountRole === 'AccountAdmin' ? (
            <a
              href={`/accountusers/${accountId}${
                workspaceId ? '/' + workspaceId : ''
              }`}
              className={`${
                path.includes('accountusers') ? 'sidenav_active' : ''
              }`}
            >
              {' '}
              <MdGroup className="text-18" /> Account Users
            </a>
          ) : (
            <Fragment />
          )}
          {props.activeAccountRole === 'SuperAccountAdmin' ||
          props.activeAccountRole === 'BillingAdmin' ? (
            <a
              href={`/subscription/${accountId}${
                workspaceId ? '/' + workspaceId : ''
              }`}
              className={`${
                path.includes('subscription') ? 'sidenav_active' : ''
              }`}
            >
              {' '}
              <MdBookmark className="text-18" /> Subscription
            </a>
          ) : (
            <Fragment />
          )}
          {props.activeAccountRole === 'SuperAccountAdmin' ||
          props.activeAccountRole === 'BillingAdmin' ? (
            <a
              href={`/billing/${accountId}${
                workspaceId ? '/' + workspaceId : ''
              }`}
              className={`${path.includes('billing') ? 'sidenav_active' : ''}`}
            >
              {' '}
              <MdCreditCard className="text-18" /> Billing
            </a>
          ) : (
            <Fragment />
          )}
          {props.activeAccountRole === 'SuperAccountAdmin' ||
          props.activeAccountRole === 'AccountAdmin' ? (
            <a
              href={`/activitylog/${accountId}${
                workspaceId ? '/' + workspaceId : ''
              }`}
              className={`${
                path.includes('activitylog') ? 'sidenav_active' : ''
              }`}
            >
              {' '}
              <MdResetTv className="text-18" /> Account Activity
            </a>
          ) : (
            <Fragment />
          )}

          {workspaceId && (
            <p className="py-3 m-0 px-4 text-semibold">Workspace</p>
          )}
          {/* -----condition menu when account Suspended -------- */}
          {isSuspended ? (
            <Fragment>
              {props.activeAccountRole ? (
                props.activeWorkspaceRole === 'Admin' ? (
                  workspaceId && (
                    <p className="sidenav-disable">
                      <MdWebStories className="text-18" /> Workspace Settings
                    </p>
                  )
                ) : (
                  <Fragment />
                )
              ) : (
                <Fragment />
              )}
              {props.activeAccountRole ? (
                props.activeWorkspaceRole === 'Admin' ||
                props.activeWorkspaceRole === 'Publish' ||
                props.activeWorkspaceRole === 'Editor' ? (
                  <p className="sidenav-disable">
                    <MdAccountTree className="text-18" /> Tracking Code
                  </p>
                ) : (
                  <Fragment />
                )
              ) : (
                <Fragment />
              )}

              {props.activeAccountRole ? (
                props.activeWorkspaceRole === 'Admin' ? (
                  workspaceId && (
                    <p className="sidenav-disable">
                      <MdSupervisorAccount className="text-18" /> Workspace
                      Users
                    </p>
                  )
                ) : (
                  <Fragment />
                )
              ) : (
                <Fragment />
              )}

              {props.activeAccountRole ? (
                props.activeWorkspaceRole === 'Admin' ||
                props.activeWorkspaceRole === 'Publish' ||
                props.activeWorkspaceRole === 'Editor' ? (
                  <p className="sidenav-disable">
                    <MdVideoLabel className="text-18" /> Metadata
                  </p>
                ) : (
                  <Fragment />
                )
              ) : (
                <Fragment />
              )}
            </Fragment>
          ) : (
            <Fragment>
              {props.activeAccountRole ? (
                props.activeWorkspaceRole === 'Admin' ? (
                  workspaceId && (
                    <a
                      href={`/workspacesettings/${accountId}/${workspaceId}`}
                      className={` ${
                        path.includes('workspacesettings')
                          ? 'sidenav_active'
                          : ''
                      }`}
                    >
                      {' '}
                      <MdWebStories className="text-18" /> Workspace Settings
                    </a>
                  )
                ) : (
                  <Fragment />
                )
              ) : (
                <Fragment />
              )}

              {props.activeAccountRole ? (
                props.activeWorkspaceRole === 'Admin' ? (
                  workspaceId && (
                    <a
                      href={`/workspaceusers/${accountId}/${workspaceId}`}
                      className={`${
                        path.includes('workspaceusers') ? 'sidenav_active' : ''
                      }`}
                    >
                      {' '}
                      <MdSupervisorAccount className="text-18" /> Workspace
                      Users
                    </a>
                  )
                ) : (
                  <Fragment />
                )
              ) : (
                <Fragment />
              )}

              {props.activeAccountRole ? (
                props.activeWorkspaceRole === 'Admin' ||
                props.activeWorkspaceRole === 'Publish' ||
                props.activeWorkspaceRole === 'Editor' ? (
                  <a
                    href={`/metadata/${accountId}/${workspaceId}`}
                    className={`${
                      path.includes('metadata') ? 'sidenav_active' : ''
                    }`}
                  >
                    {' '}
                    <MdSubtitles className="text-18" /> Metadata
                  </a>
                ) : (
                  <Fragment />
                )
              ) : (
                <Fragment />
              )}
              {basicPlan !== 'Basic' ? (
                <Fragment>
                  {props.activeAccountRole ? (
                    props.activeWorkspaceRole === 'Admin' ? (
                      <a
                        href={`/export/${accountId}/${workspaceId}`}
                        className={`${
                          path.includes('export') ? 'sidenav_active' : ''
                        }`}
                      >
                        {' '}
                        <MdOutlineFileUpload className="text-18" /> Export Tag
                        Settings
                      </a>
                    ) : (
                      <Fragment />
                    )
                  ) : (
                    <Fragment />
                  )}

                  {props.activeAccountRole ? (
                    props.activeWorkspaceRole === 'Admin' ? (
                      <a
                        href={`/import/${accountId}/${workspaceId}`}
                        className={`${
                          path.includes('import') ? 'sidenav_active' : ''
                        }`}
                      >
                        {' '}
                        <MdOutlineFileDownload className="text-18" /> Import Tag
                        Settings
                      </a>
                    ) : (
                      <Fragment />
                    )
                  ) : (
                    <Fragment />
                  )}
                </Fragment>
              ) : (
                <Fragment />
              )}
            </Fragment>
          )}
        </div>
        <div className="menu-bt-11">
          {statusUsed >= 80 && (
            <div
              className={`card card-bg-light mb-4 mini-bar ${
                !showPopup ? 'd-none' : ''
              }`}
            >
              <div className="card-header card-bg-light d-flex justify-content-between item-center border-bottom-0 text-14">
                <span className="text-semibold">Used space </span>
              </div>
              <div className="card-body card-bg-light text-14">
                Your account has used{' '}
                {statusUsed >= 100 ? 'FULL' : statusUsed + '%'} of your quotas.
                Need more?
                <div className="progress my-2" style={{ height: '8px' }}>
                  <div
                    className={` "progress-bar " ${
                      statusUsed < 90 ? 'bg-warning' : 'bg-danger'
                    } `}
                    role="progressbar"
                    aria-valuenow={statusUsed}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: statusUsed + '%' }}
                  ></div>
                </div>
                <p>
                  <span onClick={() => setShowPopup(false)} className="cursor">
                    Dismiss
                  </span>{' '}
                  <span
                    onClick={() => setShowPopup(false)}
                    className={` "cursor " ${
                      statusUsed < 90 ? 'text-warning' : 'text-danger'
                    } `}
                  >
                    Upgrade plan
                  </span>
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </MenuBox>
  )
}

export default MenuSettingComponent
