import React, { Fragment, useState, useEffect, useRef } from 'react'
import { TermBox } from './style'
import { Link } from 'react-router-dom'
import FooterComponent from '../layout/footer'
import { acceptTerm } from '../../apis/login/api'

const TermServiceComponent = (props) => {
  const [termLang, setTermLang] = useState('en')
  const [bottom, setBottom] = useState(false)
  const [accessToken, setAccessToken] = useState('')

  useEffect(() => {
    document.title = 'Terms and Conditions | Tag Turbo'
    setTermLang('th')
    ;(function () {
      function onTidioChatApiReady() {
        window.tidioChatApi.hide()
        window.tidioChatApi.on('close', function () {
          window.tidioChatApi.hide()
        })
      }

      if (window.tidioChatApi) {
        window.tidioChatApi.on('ready', onTidioChatApiReady)
      } else {
        document.addEventListener('tidioChat-ready', onTidioChatApiReady)
      }
    })()
  }, [])

  useEffect(() => {
    const token = localStorage.getItem('token')
    setAccessToken(token)
  }, [])

  const handleAcceptTerm = async () => {
    const accessToken = localStorage.getItem('token')
    const { data, status } = await acceptTerm(accessToken)
    if (status === 200 && data) {
      localStorage.setItem('token', data.token)
      window.location.href = '/home'
    } else {
      window.location.href = '/login'
    }
  }

  const handleScroll = (e) => {
    let setHeight = 4000
    const scrollBottom = e.target.scrollTop > setHeight
    setBottom(scrollBottom)
  }

  return (
    <TermBox>
      <div className="page full-page-non-bg">
        <div className="content-box">
          <div className="container-md">
            <div className="row justify-content-center">
              <div className="col-lg-9">
                <div className="text-center mt-4">
                  <h1 className="mb-3">
                    <b>Hello From Tag Turbo Team!</b>
                  </h1>
                  <h6 className="line-height-2">
                    We are happy to welcome you to our service. Before tracking
                    your site, please <br />
                    allow and accept our Terms and Conditions.
                  </h6>
                </div>
                <div className="term-box th">
                  <div className="term-box-header text-center">
                    <h5>Terms and Conditions</h5>
                  </div>
                  <div
                    id="scrolled"
                    className="term-box-body"
                    onScroll={handleScroll}
                  >
                    <p className="text-Secondary term-text-content">
                      These terms and conditions (the "<b>Terms</b>") are an
                      agreement between Predictive Company Limited ("<b>We</b>")
                      and users ("<b>You</b>"). These terms govern your
                      acquisition and use of products or services on the website
                      and any activity related to the services ("<b>Service</b>
                      ")
                      <br />
                      <br />
                      <b>Scope</b> <br />
                      You accept and agree to abide by the terms as set forth in
                      these terms and conditions. These terms may be amended or
                      added, it shall be deemed that you have read these terms
                      and conditions of use and agree to be bound by such terms
                      and conditions. In the event that you do not comply with
                      the terms, it may result in the denial of access to use
                      the service on the website.
                      <br />
                      <br />
                      <b>Accounts and membership</b>
                      <br />
                      When you create an account on the website, you are
                      responsible for all activities that occur as part of your
                      account usage or any other related activities, including
                      the security of your account. We will not undertake the
                      obligation of new account verification before you log in
                      or use the services. In case that you provide false
                      information, which causes the termination of your account,
                      it is your responsibility to notify us immediately of any
                      unauthorized use of such accounts or any other breach of
                      security. We are not responsible for any damages arising
                      out of your actions or omissions and may suspend, disable
                      or delete your account (as the case may be). In the event
                      that you violate these terms or act inconsistently to
                      defame our reputation or any actions which appear to
                      affect a risk to defame our reputation, we have the right
                      to delete your account immediately and you will not be
                      able to re-register to use our services. We may also need
                      to block your email address and IP address to prevent your
                      re-registration.
                      <br />
                      <br />
                      <b>User content</b>
                      <br />
                      You are solely responsible for the accuracy, completeness,
                      reliability, suitability and ownership of all intellectual
                      property or rights to use the content (“Content”) that you
                      have provided on the CookiePlus website at your own
                      discretion. We may check or monitor your content that you
                      submit to or create from the service on the website; We
                      will not use, reproduce, modify, publish or distribute
                      your content for commercial or marketing purposes or for
                      any other purposes, unless specifically authorized by you.
                      We reserve the right to reject or remove any inappropriate
                      content or violate the policy or harm in any way to the
                      terms. We may check or monitor your content that you
                      submit to or create from the service on the website; We
                      will not use, reproduce, modify, publish or distribute
                      your content for commercial or marketing purposes or for
                      any other purposes, unless specifically authorized by you.
                      We reserve the right to reject or remove any inappropriate
                      content or violate the policy or harm in any way to the
                      terms.
                      <br />
                      <br />
                      <b>Personal Data Protection </b>
                      <br />
                      We will collect, use and disclose your personal data that
                      you have provided to us. In accordance with the standard
                      specified in our Privacy Notice. You can learn more about
                      our Privacy Notice on the website.
                      <br />
                      <br />
                      <b>The accuracy of information</b>
                      <br />
                      In case that information of the website contains errors in
                      accuracy or completeness, such as typo mistake or
                      omissions in connection with a promotion or offer, we
                      reserve the right to correct the errors or such
                      inaccuracies, including up to date information, cancel a
                      current order or submitted order without the need to
                      notify you in advance. We are not obliged to update, amend
                      or clarify information on the website or even set the date
                      of change on the website or other related services after
                      such revision or update. Unless required by law.
                      <br />
                      <br />
                      <b>Payment and Invoicing</b>
                      <br />
                      When the payment is due, you are obliged to pay any
                      applicable fees to your account. In the event that you
                      have used the free trial service, you must pay immediately
                      after the trial period has ended. If you have an automatic
                      renewal, you will automatically be billed for your
                      automatic activation of the service that you have chosen.
                      <br />
                      <br />
                      If your transactions are considered a high-risk
                      transaction, we will ask for a government-issued document
                      with a photo or a copy of the recent bank statement for
                      the credit or debit card used for such a purchase.
                      <br />
                      <br />
                      We reserve the right to change our products, services, and
                      prices. and activities related to the CookiePlus website,
                      including cancel or limit orders at any time without
                      notifying or asking for your consent. In case that we
                      change or cancel your order, we will notify you via email,
                      billing address or phone number.
                      <br />
                      <br />
                      Once we have checked and received your order and payment,
                      we will give you access to the products or services to you
                      as soon as possible. However, all fees are non-refundable.
                      <br />
                      <br />
                      <b>Termination of the Services</b>
                      <br />
                      Your subscription will be automatically renewed until you
                      cancel the Service which you can cancel the renewal of the
                      Services before the billing date. If there are the
                      remaining days valid to use from the date of confirmation
                      of cancellation, you are able to use the remaining days
                      until the last date of billing payment which will be
                      notified while canceling the Services and the remaining
                      days will not be able to transfer, change or refund.
                      Nevertheless, you are entitled to all the reliability
                      which occurs as long as you are using our services,
                      including but not limited to, occurred damage if you
                      violate these terms and conditions, we reserve the right
                      to not be entitled to the responsibilities occurred after
                      the termination of the services. In the event that you do
                      not comply with these Terms, including but not limited to,
                      Notice, Agreements, or any other contracts with us. The
                      Company shall reserve the right to temporarily suspend
                      your account or terminate your use of the Services at the
                      company’s sole discretion without prior notification of
                      such suspension or termination.
                      <br />
                      <br />
                      <b>Refund</b>
                      <br />
                      You acknowledge that before purchasing any products and
                      services on the website, we reserve the right to refund
                      all products or services after payment. Please carefully
                      read the service details before deciding purchase. If you
                      have purchased any product or service and would like to
                      change the package in a lower package. You agree that we
                      will not be able to refund the portion of service fees.
                      However, the validity period of the new package will be
                      extended according to the remaining amount.
                      <br />
                      <br />
                      <b>Third-Party Services</b>
                      <br />
                      In case that you choose to enable, access or use
                      third-party services, you acknowledge that your access or
                      use of such services is solely determined by the terms and
                      conditions of that third-party services. We are not liable
                      for such terms and conditions and we do not warrant such
                      services including but not limited to the content or how
                      they handle information (including your personal data).
                      You waive any claim from Predictive Company Limited in
                      relation to such services. Predictive Company Limited
                      shall not be liable for any damage or loss caused or
                      alleged by or in connection with your choosing, accessing,
                      using or your belief in privacy policy or data processing
                      security or any other policies of such services. You may
                      be required to apply or sign in to such services’
                      websites. You have authorized Predictive Company to
                      disclose your information to enable use of such services.
                      <br />
                      <br />
                      <b>Links to other websites</b>
                      <br />
                      The website may contain links to other websites. We do not
                      directly or indirectly endorse, join, support, assist, or
                      affiliate with any other website, unless otherwise
                      expressed. We are not obliged to review or evaluate, and
                      we do not endorse or assume responsibility or liability
                      for the offerings of any business or individual. Any
                      content or otherwise obtained through the use of
                      third-party services is at your own discretion and risk;
                      therefore, you need to review the legal terms and terms of
                      use of those websites that you have connected with the
                      website.
                      <br />
                      <br />
                      <b>Data backup</b>
                      <br />
                      We back-up all content and data. To ensure that the backup
                      data is complete and accurate, we will automatically
                      restore the backed-up data to minimize the impact or
                      downtime.
                      <br />
                      <br />
                      <b>Restricted Matters</b>
                      <br />
                      You cannot edit, print, or copy any share of the website,
                      or merge any parts of the CookiePlus website with other
                      works (whether in publishing or electronic form or in any
                      other form) or gather any parts of the website into
                      another website in the form of embedding, framing or any
                      other means without the prior written permission of
                      Predictive Company Limited. You are prohibited from using
                      the website as the following:
                      <br />
                      <ul>
                        <li>Unlawful purpose</li>
                        <li>
                          Solicit or encourage others to violate laws and
                          regulations
                        </li>
                        <li>Acts that violate laws and regulations</li>
                        <li>
                          Acts that infringe our intellectual property or others
                        </li>
                        <li>
                          Threat, harassment, defamation, or expressions that
                          lead to discrimination by race, national origin,
                          creed, gender, and disability, or for indecent or
                          immoral purposes
                        </li>
                        <li>
                          Import or transmit false or misleading information
                        </li>
                        <li>
                          Upload or transmit viruses or other types of malicious
                          code that may be used in any manner and affect its
                          operation or service or related websites or the
                          Internet
                        </li>
                        <li>Collect or track other personal data</li>
                        <li>
                          Sending unsolicited messages (spam) or deceiving
                          others by using phony email or phish pages, or
                          redirecting website traffic to steal users data
                          (pharm) or impersonating the right owner to pretext or
                          use spiders or crawl to extract data from other
                          websites (scrape)
                        </li>
                        <li>
                          To interfere or circumvent with the security features
                          of the service or related websites, other websites or
                          the Internet
                        </li>
                        <li>
                          To act or cause the Company to be involved in any
                          disputes or claims, or any actions which we considered
                          inappropriate and may affect the negative reputation
                          of the Company and/or cause any damages
                        </li>
                        <li>
                          To use our websites for the purposes of commercial on
                          behalf of oneself or another person
                        </li>
                      </ul>
                      We reserve the right to terminate your use of our service
                      or website from any violation of any prohibited use.
                      <br />
                      <br />
                      <b>Intellectual Property</b>
                      <br />
                      This term does not constitute an assignment of any
                      intellectual property rights by Predictive Company
                      Limited. All rights, ownership, and interests, including
                      but not limited to copyrights, patents, trade secrets, and
                      any other intellectual property rights that remain solely
                      between the parties and Predictive Company Limited. Your
                      use of the website and service does not constitute a
                      license or authorization for you to copy, modify,
                      translate or use any trademarks of Predictive Company
                      Limited or any other stakeholders.
                      <br />
                      <br />
                      <b>No Warranty</b>
                      <br />
                      By using our website or services, you understand and agree
                      that any activities, you are at your own discretion and
                      risk; All Resources we provide are “as is” and “as
                      available”. This means that we do not represent or warrant
                      to you, including but not limited to: warranties of
                      merchantability, suitability for a specific purpose,
                      infringement; the use that meet your needs or
                      requirements, or the use that will be uninterrupted,
                      timely, secure, or free from errors. Furthermore, you
                      understand and agree that any content downloaded or
                      otherwise obtained through the use of our service is
                      downloaded or obtained at your own discretion and risk;
                      that you are solely responsible for any damage to your
                      computer or other devices for any loss of data that may
                      result from the download of such content.
                      <br />
                      <br />
                      You acknowledge that Predictive Company Limited is not a
                      law firm, attorney, or lawyer. We do not warrant the
                      legality or enforceability of the documents for legal
                      requirements, including contracts, forms and other
                      documents on the website. Your use of the service,
                      information, forms or documents is at your own risk.
                      Predictive Company Limited and its employees, consultants
                      or attorneys who are involved in providing services
                      reserve the right to make warranty and it expressly does
                      not create any attorney-client relationship by providing
                      you services or information. You need to seek advice from
                      your own company's legal counsel to assure you that the
                      counseling is consistent with your organization's business
                      practices.
                      <br />
                      <br />
                      <b>Limitation of Liability</b>
                      <br />
                      We reserve the right not to be liable to any person as the
                      following (1) Any indirect damage, special damage,
                      punitive damages, indemnity damage or consequential
                      damages including but not limited to damage from profits,
                      revenue, commerce, goodwill, loss of business opportunity
                      and (2) damage or loss caused by force majeure beyond our
                      ability to prevent or control such as riots, shortages,
                      cyber threat, change of law as well as government orders.
                      <br />
                      <br />
                      <b>Third-Party Services</b>
                      <br />
                      However, the liability incurred includes but is not
                      limited to contract, violation, representation, negligence
                      or any other claim against us will be limited to the
                      amount you have paid for the Services.
                      <br />
                      <br />
                      <b>Indemnification</b>
                      <br />
                      You agree to indemnify Predictive Company Limited for and
                      against all losses, damages or costs, including reasonable
                      attorneys’ fees resulting from any violation, disputes or
                      any claim from a third-party resulting from your use,
                      content or related services, or joining in activities
                      organized by Predictive Company Limited with intentional
                      misconduct.
                      <br />
                      <br />
                      <b>Assignability</b>
                      <br />
                      You are not entitled to assign any rights, all or any part
                      of these terms, to any other person. However, we shall be
                      free to assign part or all of its rights under these terms
                      to third parties as part of our properties, shares or as
                      part of a merger.
                      <br />
                      <br />
                      <b>Severability</b>
                      <br />
                      Any provision of these terms which is determined to be
                      void, invalid, illegal or unenforceable for any reason
                      shall not affect the remainder of these terms, which shall
                      remain in effect.
                      <br />
                      <br />
                      <b>Applicable Law</b>
                      <br />
                      These terms shall be governed by, construed, interpreted
                      and enforced in accordance with the laws of Thailand and
                      submitted to the jurisdiction of Thai courts.
                      <br />
                      <br />
                      <b>Modification to the terms</b>
                      <br />
                      We reserve the right to change these terms or related
                      website policies. We will indicate the date of revision
                      under these terms and the modified version shall
                      immediately become effective. Your continued use of our
                      website or services after such changes will constitute
                      acknowledgment and acceptance of the modified terms.
                      <br />
                      <br />
                      <b>Acceptance of the terms</b>
                      <br />
                      You acknowledge and agree that you have read these terms
                      and agree to all terms and conditions. By using the
                      website or other services, you agree to be bound by these
                      terms. If you do not agree to the terms, you will not have
                      the right to use or access the website or other related
                      services.
                      <br />
                      <b>Contact Information</b>
                      <br />
                      If you have any questions or concerns about these terms as
                      outlined above, you can contact us at
                      <br /> <br />
                      <b>Predictive Company Limited </b> <br />
                      10/35 The Trendy Office Building (1A floor), Sukhumvit
                      Soi13 Klongtoey-Nua, Wattana, Bangkok 10110
                      <br />
                      Email : support@tagturbo.com <br />
                      Website : www.tagturbo.com <br />
                      Tel. : 02 096 6362
                    </p>
                  </div>
                  <div className="row justify-content-center py-3">
                    <button
                      onClick={() => (window.location.href = '/login')}
                      type="button"
                      className="btn btn-outline-secondary w-25 mx-2"
                    >
                      Ignore
                    </button>
                    <button
                      onClick={() => handleAcceptTerm()}
                      type="submit"
                      className="btn btn-primary w-25 mx-2"
                      disabled={bottom ? false : true}
                    >
                      Accept
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <FooterComponent />
            </div>
          </div>
        </div>
      </div>
    </TermBox>
  )
}

export default TermServiceComponent
