import axios from 'axios'
import { redirectLogin, redirectPermission } from '../../helper/helper'

const { REACT_APP_API_AUTHENTICATION } = process.env

const configHeader = {
  headers: {
    'content-Type': 'application/json',
    Accept: '*/*',
    'Cache-Control': 'no-cache',
    //   Authorization: token,
    // origin: window.location.href,
    'Access-Control-Allow-Origin': true,
  },
}

const login = async (obj) => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_AUTHENTICATION}/login`
    const response = await axios.post(URL, obj, configHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    res = {
      status: 500, //error.response?.status,
      data: error, //error.message,
    }
  }
  return res
}

const otpVerify = async (obj) => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_AUTHENTICATION}/otp-login/verify`
    const response = await axios.post(URL, obj, configHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    console.log('otpVerify')

    res = {
      status: 500, //error.response?.status,
      data: error, //error.message,
    }
  }
  return res
}

const otpResend = async (obj) => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_AUTHENTICATION}/otp-login/resend`
    const response = await axios.post(URL, obj, configHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    console.log('otpVerify error')
    res = {
      status: 500, //error.response?.status,
      data: error, //error.message,
    }
    // }
  }
  return res
}

const acceptTerm = async (token) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      project: 'GTP',
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_AUTHENTICATION}/accept-term`
    const response = await axios.patch(URL, {}, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    console.log('acceptTerm error')
    res = {
      status: 500, //error.response?.status,
      data: error, //error.message,
    }
    // }
  }
  return res
}

const forgotPassword = async (obj) => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_AUTHENTICATION}/forgot-password`
    const response = await axios.post(URL, obj, configHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    console.log('forgotPassword error')
    res = {
      status: 500, //error.response?.status,
      data: error, //error.message,
    }
  }
  return res
}

const otpForgotVerify = async (obj) => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_AUTHENTICATION}/otp-forgot/verify`
    const response = await axios.post(URL, obj, configHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    console.log('otpForgotVerify error')
    res = {
      status: 500, //error.response?.status,
      data: error, //error.message,
    }
  }
  return res
}

const otpForgotResend = async (obj) => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_AUTHENTICATION}/otp-forgot/resend`
    const response = await axios.post(URL, obj, configHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    console.log('otpForgotResend error')
    res = {
      status: 500, //error.response?.status,
      data: error, //error.message,
    }
  }
  return res
}

const changePassword = async (obj) => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_AUTHENTICATION}/change-password`
    const response = await axios.patch(URL, obj, configHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    console.log('acceptTerm error')
    res = {
      status: 500, //error.response?.status,
      data: error, //error.message,
    }
    // }
  }
  return res
}

const signUp = async (obj) => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_AUTHENTICATION}/sign-up`
    const response = await axios.post(URL, obj, configHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    console.log('signUp error')
    res = {
      status: 500, //error.response?.status,
      data: error, //error.message,
    }
  }
  return res
}
const verifySignupOTP = async (obj) => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_AUTHENTICATION}/otp-sign-up/verify`
    const response = await axios.post(URL, obj, configHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    console.log('verifySignupOTP error')
    res = {
      status: 500, //error.response?.status,
      data: error, //error.message,
    }
  }
  return res
}
const resendSignupOTP = async (obj) => {
  let res = {}
  try {
    let URL = `${REACT_APP_API_AUTHENTICATION}/otp-sign-up/resend`
    const response = await axios.post(URL, obj, configHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    console.log('verifySignupOTP error')
    res = {
      status: 500, //error.response?.status,
      data: error, //error.message,
    }
  }
  return res
}

const userProfile = async (token) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      project: 'GTP',
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_AUTHENTICATION}/user/profile`
    const response = await axios.get(URL, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: 500,
        data: error,
      }
    }
  }
  return res
}

const editUserProfile = async (token, obj) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      project: 'GTP',
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_AUTHENTICATION}/user/profile`
    const response = await axios.patch(URL, obj, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: 500,
        data: error,
      }
    }
  }
  return res
}

const changePasswordUserProfile = async (token, obj) => {
  const customConfigHeader = {
    headers: {
      'content-Type': 'application/json',
      Accept: '*/*',
      'Cache-Control': 'no-cache',
      Authorization: token,
      project: 'GTP',
      'Access-Control-Allow-Origin': true,
    },
  }
  let res = {}
  try {
    let URL = `${REACT_APP_API_AUTHENTICATION}/user/change-password`
    const response = await axios.patch(URL, obj, customConfigHeader)
    if (response?.status && response.status === 200) {
      res = {
        status: response.status,
        data: response.data,
      }
    }
  } catch (error) {
    if (error.response?.status === 401) {
      redirectLogin()
    } else if (error.response?.status === 403) {
      redirectPermission()
    } else {
      res = {
        status: 500,
        data: error,
      }
    }
  }
  return res
}

export {
  login,
  otpVerify,
  otpResend,
  acceptTerm,
  forgotPassword,
  otpForgotVerify,
  otpForgotResend,
  changePassword,
  signUp,
  verifySignupOTP,
  resendSignupOTP,
  userProfile,
  editUserProfile,
  changePasswordUserProfile,
}
