import React, { Fragment, useState, useEffect } from 'react'
import { AiTwotoneSetting } from '@react-icons/all-files/ai/AiTwotoneSetting'
import { BsThreeDotsVertical } from '@react-icons/all-files/bs/BsThreeDotsVertical'

import { Link } from 'react-router-dom'
import { Home, ModalBox } from './style'
import FooterComponent from '../layout/footer'
import TopbarComponent from '../layout/topbar'
import Dropdown from 'react-bootstrap/Dropdown'
import { MdOutlineAdd } from 'react-icons/md'
import {
  confirmInviteAccount,
  checkLimitWorkspace,
} from '../../apis/account/api'
import { accountList } from '../../apis/home/api'
import AlertComponent from '../layout/alert'
import DataTable from 'react-data-table-component'
import Modal from 'react-modal'
import { allPlan } from '../../apis/plan/api'
import ProgressBar from 'react-bootstrap/ProgressBar'
import introJs from 'intro.js'

const HomeComponent = () => {
  const [userAccountList, setUserAccountList] = useState([])
  const [userInviteList, setUserInviteList] = useState([])
  const [isLoadingPage, setIsLoadingPage] = useState(true)
  const [openPopupWorkspaceLimit, setOpenPopupWorkspaceLimit] = useState(false)
  const [selectAccountData, setSelectAccountData] = useState('')
  const [planList, setPlanList] = useState([])

  const [openPopupInviteLimit, setOpenPopupInviteLimit] = useState(false)
  const [openPopupInviteSuspended, setOpenPopupInviteSuspended] =
    useState(false)
  const [openPopupSuspended, setOpenPopupSuspended] = useState(false)
  const [progress, setProgress] = useState(20)
  const get_user_tour = localStorage.getItem('user_tour')
  const obj_get_user_tour = JSON.parse(get_user_tour)

  const user_tour_next = localStorage.getItem('next_step')
  const obj_user_tour_next = JSON.parse(user_tour_next)

  const [roleWorkspaceLimit, setRoleWorkspaceLimit] = useState(false)

  const customStylesSM = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '450px',
    },
  }
  const [showAlert, setShowAlert] = useState({
    msg: 'Account Role has been updated successfully.',
    status: true,
    isShow: false,
  })

  const accessToken = localStorage.getItem('token')

  useEffect(() => {
    document.title = 'Home | Tag Turbo'
    setIsLoadingPage(true)
    getPlan(accessToken)
    const isHandleAlert = localStorage.getItem('isHandleAlert')
    if (isHandleAlert) {
      handleAlert(isHandleAlert, true)
      localStorage.removeItem('isHandleAlert')
    }
    getAccountList(accessToken)
    const isTrialSuccess = localStorage.getItem('isTrialSuccess')
    if (isTrialSuccess) {
      handleAlert(isTrialSuccess, true)
      localStorage.removeItem('isTrialSuccess')
    }
  }, [])

  const startUserTour = () => {
    if (obj_get_user_tour) {
      if (obj_get_user_tour?.status !== 'Done') {
        let filterPage = obj_get_user_tour?.ut_page?.filter(
          (item) => item.page === document.title,
        )
        if (filterPage.length > 0) {
          if (filterPage[0].step !== '2') {
            //เคยเข้าหน้านี้แต่stepยังไม่ครบ
            renderUserTour()
          } else {
            let filterSubPage = filterPage.filter(
              (item) => item.sub_step === '1',
            )
            if (filterSubPage.length > 0 && !obj_user_tour_next) {
              localStorage.removeItem('next_step')
            } else {
              //create Workspace
              renderUserTourSubStep()
            }
          }
        } else {
          //ยังไม่เคยเข้าหน้านี้
          renderUserTour()
        }
      }
    } else {
      //เข้าแอพครั้งแรก
      renderUserTour()
    }
  }

  const renderUserTourSubStep = () => {
    setTimeout(() => {
      introJs()
        .setOptions({
          steps: [
            {
              element: document.querySelector('.step1'),
              title: "<b class='text-14'>Create first workspace</b>",
              step: 2,
              intro:
                "<p class='text-12'>Ready to unlock website insights? Click <b>'Create Workspace'</b> to start tracking right away!</p>",
            },
          ],
          disableOverlay: false,
          hidePrev: true,
          showStepNumbers: false,
          doneLabel: 'Done',
          exitOnOverlayClick: false,
          showButtons: true,
          showBullets: false,
          hideNext: false,
          highlightClass: 'custom-highlight-class',
        })
        .onbeforechange(function () {
          setTimeout(() => {
            var doneButton = document.querySelector('.introjs-donebutton')
            var skipButton = document.querySelector('.introjs-skipbutton')

            if (doneButton) {
              doneButton.addEventListener('click', function () {
                if (obj_get_user_tour) {
                  let temp = {
                    page: document.title,
                    step: '2',
                    sub_step: '1',
                  }
                  obj_get_user_tour.ut_page.push(temp)
                  localStorage.setItem(
                    'user_tour',
                    JSON.stringify(obj_get_user_tour),
                  )
                  localStorage.removeItem('next_step')
                } else {
                  let obj_tour = {
                    ut_page: [
                      {
                        page: document.title,
                        step: '2',
                        sub_step: '1',
                      },
                    ],
                    status: '',
                  }
                  localStorage.setItem('user_tour', JSON.stringify(obj_tour))
                  localStorage.removeItem('next_step')
                }
              })
            }

            if (skipButton) {
              skipButton.addEventListener('click', function () {
                let obj_tour = {
                  ut_page: [],
                  status: 'Done',
                }
                localStorage.setItem('user_tour', JSON.stringify(obj_tour))
              })
            }
          }, 500)
        })
        .start()
    }, 1000)
  }
  const renderUserTour = () => {
    setTimeout(() => {
      introJs()
        .setOptions({
          steps: [
            {
              title: '',
              intro:
                '<div class="text-center"><img src="/assets/img/ut-start.png" class="my-3" alt="Tag Turbo"/><p class="text-center text-semibold text-20">Welcome to Tag Turbo!</p><p class="text-14">We would love to show you around so you can see what Tag Turbo is all about. <br/> It’ll only take a couple minutes.</p></div>',
              step: 1,
              tooltipClass: 'big-intro',
            },
            {
              element: document.querySelector('.step2'),
              title: '<b class="text-14">Create first account!</b>',
              step: 2,
              intro:
                '<p class="text-12">Start by having your first account. You can choose the account plan that suits you best.</p>',
            },
          ],
          disableOverlay: false,
          hidePrev: true,
          showStepNumbers: false,
          doneLabel: 'Done',
          exitOnOverlayClick: false,
          showButtons: true,
          showBullets: false,
          hideNext: false,
          highlightClass: 'custom-highlight-class',
        })
        .onbeforechange(function () {
          setTimeout(() => {
            var skipButton = document.querySelector('.introjs-skipbutton')
            if (skipButton) {
              skipButton.addEventListener('click', function () {
                let obj_tour = {
                  ut_page: [],
                  status: 'Done',
                }
                localStorage.setItem('user_tour', JSON.stringify(obj_tour))
              })
            }
          }, 500)
        })
        .onbeforeexit(function () {
          if (obj_get_user_tour) {
            let temp = {
              page: document.title,
              step: '2',
              sub_step: '',
            }
            obj_get_user_tour.ut_page.push(temp)
            localStorage.setItem('user_tour', JSON.stringify(obj_get_user_tour))
          } else {
            let obj_tour = {
              ut_page: [
                {
                  page: document.title,
                  step: '2',
                  sub_step: '',
                },
              ],
              status: '',
            }
            localStorage.setItem('user_tour', JSON.stringify(obj_tour))
          }
        })
        .start()
    }, 1000)
  }

  const checkWorkspaceLimit = async (accountId) => {
    const { data, status } = await checkLimitWorkspace(accessToken, accountId)
    if (accountId && status === 200) {
      window.location.href = `/createworkspace/${accountId}`
    } else if (status === 400) {
      if (data?.response?.data?.error?.code === 405) {
        setOpenPopupWorkspaceLimit(true)
      }
    }
  }

  const getPlan = async (token) => {
    const { data, status } = await allPlan(token)
    let plan = data.plan_list
    if (status === 200) {
      setProgress(progress + 40)
      let arrPlan = plan.map((item) => item)
      setPlanList(arrPlan)
    }
  }

  const checkApproveTag = (accountList, workspaceList) => {
    let acceptWorkspaceRole = ['Admin', 'Publish', 'Viewer', 'Editor']
    let acceptAccountRole = ['SuperAccountAdmin', 'BillingAdmin']
    if (accountList.state === 'suspend') {
      if (acceptAccountRole.includes(accountList.role)) {
        window.location.href = `/package/${workspaceList.account_id}`
      } else {
        setOpenPopupSuspended(true)
      }
    } else {
      if (
        accountList.plan_name &&
        acceptWorkspaceRole.includes(workspaceList.role)
      ) {
        window.location.href = `/tag/${workspaceList.account_id}/${workspaceList.id}`
      } else {
        if (acceptAccountRole.includes(accountList.role)) {
          window.location.href = `/package/${workspaceList.account_id}`
        }
      }
    }
  }
  const getAccountList = async (token) => {
    let { data, status } = await accountList(token)
    if (status === 200) {
      setProgress(progress + 40)
      startUserTour()
      setTimeout(() => {
        setIsLoadingPage(false)
      }, 300)
      for (let i = 0; i < data.data.account_list.length; i++) {
        if (data.data.account_list[i].workspace_list.length > 0) {
          for (
            let l = 0;
            l < data.data.account_list[i].workspace_list.length;
            l++
          ) {
            data.data.account_list[i].workspace_list[l].display =
              data.data.account_list[i].workspace_list[l].name
            data.data.account_list[i].workspace_list[l].name = (
              <a
                className={` text-primary text-decoration-none cursor`}
                onClick={() =>
                  checkApproveTag(
                    data.data.account_list[i],
                    data.data.account_list[i].workspace_list[l],
                  )
                }
              >
                {data.data.account_list[i].workspace_list[l].name}
              </a>
            )

            data.data.account_list[i].workspace_list[l].status =
              data.data.account_list[i].state === 'suspend' ? (
                <div className="badge badge-inactive text-center">Inactive</div>
              ) : data.data.account_list[i].workspace_list[l].version === 0 ? (
                <div className="badge badge-draft text-center">Draft</div>
              ) : (
                <div className="badge badge-green text-center">Live</div>
              )

            data.data.account_list[i].workspace_list[l].options = data.data
              .account_list[i].plan_name ? (
              data.data.account_list[i].workspace_list[l].role === 'Admin' ||
                data.data.account_list[i].workspace_list[l].role === 'Publish' ||
                data.data.account_list[i].workspace_list[l].role === 'Editor' ? (
                <Dropdown>
                  <Dropdown.Toggle className="dropdown-dot">
                    <BsThreeDotsVertical />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="position-fixed boxSetting-dot">
                    {data.data.account_list[i].workspace_list[l].role ===
                      'Viewer' ? (
                      ''
                    ) : (
                      <Dropdown.Item
                        onClick={() =>
                          (window.location.href = `/tag/${data.data.account_list[i].id}/${data.data.account_list[i].workspace_list[l].id}`)
                        }
                        className="dropdown-item-setting-dot text-14"
                      >
                        <Link className="text-decoration-none text-dark">
                          Edit
                        </Link>
                      </Dropdown.Item>
                    )}

                    {data.data.account_list[i].workspace_list[l].role ===
                      'Admin' ? (
                      <Dropdown.Item
                        onClick={() =>
                          (window.location.href = `/workspacesettings/${data.data.account_list[i].id}/${data.data.account_list[i].workspace_list[l].id}`)
                        }
                        className="dropdown-item-setting-dot text-14"
                      >
                        <Link className="text-decoration-none text-dark">
                          Workspace Settings
                        </Link>
                      </Dropdown.Item>
                    ) : (
                      ''
                    )}

                    {data.data.account_list[i].workspace_list[l].role ===
                      'Admin' ? (
                      <Dropdown.Item
                        onClick={() =>
                          (window.location.href = `/workspaceusers/${data.data.account_list[i].id}/${data.data.account_list[i].workspace_list[l].id}`)
                        }
                        className="dropdown-item-setting-dot text-14"
                      >
                        <Link className="text-decoration-none text-dark">
                          User Management
                        </Link>
                      </Dropdown.Item>
                    ) : (
                      ''
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <Fragment />
              )
            ) : (
              <Fragment />
            )
          }
        }
      }
      setUserAccountList(data.data.account_list)
      setUserInviteList(data.data.invite_list)
    } else {
      setIsLoadingPage(false)
    }
  }

  const handleAlert = (msg, status) => {
    setShowAlert({
      msg,
      status,
      isShow: true,
    })

    setTimeout(() => {
      setShowAlert({
        msg,
        status,
        isShow: false,
      })
    }, 10000)
  }

  const inviteMember = async (inviteId) => {
    let obj = {
      account_id: inviteId,
    }
    const { data, status } = await confirmInviteAccount(accessToken, obj)
    if (status === 200) {
      handleAlert('Invitation is accepted.', true)
      getAccountList(accessToken)
    } else if (status === 400) {
      if (data?.response?.data?.error?.code === 405) {
        setOpenPopupInviteLimit(true)
      } else if (data?.response?.data?.error?.code === 423) {
        setOpenPopupInviteSuspended(true)
      }
    }
  }

  const createWorkspace = (accountData) => {
    if (accountData) {
      checkWorkspaceLimit(accountData.id)
      setSelectAccountData(accountData)
      setRoleWorkspaceLimit(accountData.role === 'SuperAccountAdmin')
    } else {
      console.log('account Data is false')
    }
  }

  const upgradePlan = () => {
    if (selectAccountData) {
      window.location.href = `/package/${selectAccountData.id}`
    } else {
      console.log('Upgrade Plan is false')
    }
  }

  const columns = [
    {
      name: 'Workspace Name',
      selector: (row) => row.display,
      cell: (row) => row.name,
      sortable: true,
      width: '40%',
    },
    {
      name: 'Workspace ID',
      selector: (row) => row.id,
      sortable: false,
      width: '40%',
    },
    {
      name: 'Status',
      selector: (row) => row.status,
      sortable: false,
      width: '12%',
    },
    {
      name: '',
      selector: (row) => row.options,
      sortable: false,
    },
  ]

  return (
    <Home>
      <div className="page pull-page-bg-secondary ">
        <div className="content-box">
          {isLoadingPage ? (
            <Fragment>
              <div className="loading-fullpage">
                <div className="loading-progress-box">
                  <div className="row justify-content-center">
                    <img
                      src="/assets/img/logo-Tagtb.svg"
                      className="loading-logo-box"
                    />
                    <ProgressBar
                      className="p-0 my-3"
                      variant="loading-progress-background"
                      now={progress}
                    />
                    <p>Loading... </p>
                  </div>
                </div>
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <TopbarComponent isPublish={false} />

              {showAlert.isShow && (
                <AlertComponent
                  messageAlert={showAlert.msg}
                  status={showAlert.status}
                  alertType={showAlert.status ? 'alert-success' : 'alert-error'}
                />
              )}

              <div className="content-scroll">
                <div className="container-md animate fadeIn one  p-4 mb-5 min-h-90">
                  <div className="d-flex justify-content-between item-center mb-4">
                    <div className="col-6">
                      <h4 className="m-0 text-20 text-semibold">
                        Account & Workspace
                      </h4>
                    </div>
                    <div className="text-right">
                      <div className="step2">
                        <Link to="/createaccount">
                          <button
                            type="button"
                            className="btn badge-blue text-14 text-semibold -cursor"
                          >
                            Create Account
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <Fragment>
                    {userInviteList.length > 0 &&
                      userInviteList.map((item, i) => {
                        return (
                          <div
                            key={i}
                            className="border-shadow rounded p-4 bg-white mt-2 box-shadow-cente step3"
                          >
                            <div className="d-flex justify-content-between item-center">
                              <div className="p-0 text-18 text-semibold">
                                {item.name}
                              </div>
                              <div className="text-right">
                                <button
                                  type="button"
                                  className="btn btn-outline-success text-14 text-semibold"
                                  onClick={() => inviteMember(item.id)}
                                >
                                  Accept Invite
                                </button>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    {userAccountList ? (
                      userAccountList.length > 0 ? (
                        userAccountList.map((item, i) => {
                          return (
                            <div
                              className={` border-shadow rounded  bg-white mt-2 box-shadow-center ${item.state === 'close' ? 'd-none' : 'step1'
                                } `}
                              key={i}
                            >
                              <div className="d-flex justify-content-between mb-1 p-4 pb-2">
                                <div>
                                  <p className="p-0 text-18 text-semibold">
                                    {item.name}{' '}
                                    <span
                                      className={` badge text-12 mx-2 text-center ${item.plan_name
                                        ? item.plan_name === 'Basic'
                                          ? 'badge-default'
                                          : item.plan_name === 'Pro'
                                            ? 'badge-blue'
                                            : item.plan_name === 'Ultimate'
                                              ? 'badge-purple'
                                              : 'badge-gray'
                                        : 'badge-danger'
                                        }`}
                                    >
                                      {item.plan_name ? (
                                        <span className="text-12 text-uppercase">
                                          {item.plan_name}
                                        </span>
                                      ) : (
                                        <span>Suspended</span>
                                      )}
                                    </span>
                                    <span
                                      className={`badge badge-outline-blue`}
                                    >
                                      {item.is_trial ? 'Free Trial' : ''}
                                      { }
                                    </span>
                                  </p>{' '}
                                </div>
                                {item.role === 'SuperAccountAdmin' || item.role === 'BillingAdmin' ? (
                                  <div className="text-right">
                                    <div className="d-flex justify-content-between">
                                      {item.plan_name ? (
                                        <Fragment>
                                          <div className="text-14 p-2">Account Usage :</div>
                                          <div className="py-2 pt-9px w-148">
                                            <div className="progress my-2" style={{ height: "6px" }}>
                                              <div
                                                className={` "progress-bar" ${((item?.hit_period || 0) / item?.visitors || 0) * 100 < 80
                                                  ? "bg-primary"
                                                  : ((item?.hit_period || 0) / item?.visitors || 0) * 100 < 90
                                                    ? "bg-warning"
                                                    : "bg-danger"
                                                  } `}
                                                role="progressbar"
                                                aria-valuenow={((item?.hit_period || 0) / item?.visitors || 0) * 100}
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                                style={{ width: ((item?.hit_period || 0) / item?.visitors || 0) * 100 + "%" }}
                                              ></div>
                                            </div>

                                          </div>
                                          {((item?.hit_period || 0) / item?.visitors || 0) * 100 > 100 ?
                                            <div className="text-14 p-2 text-danger text-semibold">Limit Exceeded</div> :
                                            <div className="text-14 p-2 text-semibold">{((item?.hit_period || 0) / item?.visitors || 0) * 100 > 99 ?
                                              Math.floor(((item?.hit_period || 0) / item?.visitors || 0) * 100) + "%" :
                                              Math.ceil(((item?.hit_period || 0) / item?.visitors || 0) * 100) + "%"}</div>}
                                          <span className="line-right">|</span></Fragment>
                                      ) : (
                                        <Fragment />
                                      )}
                                      {item.plan_name ? (
                                        <Fragment><span className="p-2 text-14">
                                          Workspace :{" "}
                                          {item?.workspace_list?.length}/
                                          {
                                            planList.filter(
                                              (plan_item) =>
                                                plan_item?.plan_name ===
                                                item.plan_name
                                            )[0]?.workspace_limit_per_account
                                          }
                                        </span><span className="line-right">|</span></Fragment>
                                      ) : (
                                        <Fragment />
                                      )}
                                      {item.role === 'SuperAccountAdmin' ||
                                        item.role === 'AccountAdmin' ||
                                        item.role === 'BillingAdmin' ? (
                                        <Dropdown>
                                          <Dropdown.Toggle className="dropdown-dot">
                                            <AiTwotoneSetting className="text-18" />
                                          </Dropdown.Toggle>

                                          <Dropdown.Menu className="position-fixed boxSetting-do  p-0 border-0 shadow">
                                            {item.role ===
                                              'SuperAccountAdmin' ||
                                              item.role === 'AccountAdmin' ? (
                                              <Dropdown.Item
                                                className="dropdown-item-setting-dot text-14 h-menu"
                                                onClick={() =>
                                                (window.location.href = `/accountsetting/${item.id
                                                  }${item.workspace_list.length >
                                                    0
                                                    ? '/' +
                                                    item.workspace_list[0]
                                                      .id
                                                    : ''
                                                  }`)
                                                }
                                              >
                                                Account Settings
                                              </Dropdown.Item>
                                            ) : (
                                              ''
                                            )}
                                            {item.role ===
                                              'SuperAccountAdmin' ||
                                              item.role === 'AccountAdmin' ? (
                                              <Dropdown.Item
                                                className="dropdown-item-setting-dot text-14 h-menu"
                                                onClick={() =>
                                                (window.location.href = `/accountusers/${item.id
                                                  }${item.workspace_list.length >
                                                    0
                                                    ? '/' +
                                                    item.workspace_list[0]
                                                      .id
                                                    : ''
                                                  }`)
                                                }
                                              >
                                                User Management
                                              </Dropdown.Item>
                                            ) : (
                                              ''
                                            )}

                                            {item.role ===
                                              'SuperAccountAdmin' ||
                                              item.role === 'BillingAdmin' ? (
                                              <Fragment>
                                                <Dropdown.Item
                                                  className="dropdown-item-setting-dot text-14 h-menu"
                                                  onClick={() =>
                                                  (window.location.href = `/subscription/${item.id
                                                    }${item.workspace_list
                                                      .length > 0
                                                      ? '/' +
                                                      item.workspace_list[0]
                                                        .id
                                                      : ''
                                                    }`)
                                                  }
                                                >
                                                  Subscription & Usage
                                                </Dropdown.Item>
                                              </Fragment>
                                            ) : (
                                              ''
                                            )}

                                            {item.role ===
                                              'SuperAccountAdmin' ||
                                              item.role === 'AccountAdmin' ||
                                              item.role === 'User' ? (
                                              item.plan_name ? (
                                                <Fragment>
                                                  <Dropdown.Item
                                                    className="dropdown-item-setting-dot​ text-14 h-menu  text-primary text-semibold"
                                                    onClick={() =>
                                                      createWorkspace(item)
                                                    }
                                                  >
                                                    <MdOutlineAdd className="text-16 text-semibold" />{' '}
                                                    Create New Workspace
                                                  </Dropdown.Item>
                                                </Fragment>
                                              ) : (
                                                ''
                                              )
                                            ) : (
                                              ''
                                            )}
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  ''
                                )}
                              </div>
                              {!item.workspace_list.length > 0 ? (
                                <div className="text-center pb-4">
                                  {!item.plan_name ? ( //isSuspended
                                    item.role === 'SuperAccountAdmin' ||
                                      item.role === 'BillingAdmin' ? (
                                      <Fragment>
                                        <p className="text-secondary  text-14 mb-3">
                                          <label>
                                            This account is currently suspended.
                                          </label>
                                          <br />
                                          <label>
                                            Reactivate the account to step into
                                            the future of website data tracking.
                                          </label>
                                        </p>
                                        <button
                                          onClick={() =>
                                          (window.location.href = `/subscription/${item.id
                                            }${item.workspace_list.length > 0
                                              ? '/' +
                                              item.workspace_list[0].id
                                              : ''
                                            }`)
                                          }
                                          type="button"
                                          className="btn badge-blue text-14 text-semibold"
                                        >
                                          Go to Subscription & Usage
                                        </button>
                                      </Fragment>
                                    ) : item.role === 'AccountAdmin' ||
                                      item.role === 'User' ? (
                                      <p className="text-secondary  text-14 mb-3">
                                        <label>
                                          This account is currently suspended.
                                        </label>
                                        <br />
                                        <label>
                                          Please contact your Super Account
                                          Admin to reactivate the account.
                                        </label>
                                      </p>
                                    ) : (
                                      // role None
                                      <p className="text-secondary  text-14 mb-3">
                                        No workspace is assigned to you.
                                      </p>
                                    )
                                  ) : item.role === 'SuperAccountAdmin' ||
                                    item.role === 'AccountAdmin' ||
                                    item.role === 'User' ? (
                                    <Fragment>
                                      <p className="text-secondary  text-14 mb-3">
                                        Create your first workspace to start
                                        tracking.
                                      </p>
                                      <button
                                        onClick={() => createWorkspace(item)}
                                        type="button"
                                        className="btn badge-blue text-14 text-semibold"
                                      >
                                        Create Workspace
                                      </button>
                                    </Fragment>
                                  ) : item.role === 'BillingAdmin' ? (
                                    <Fragment>
                                      <p className="text-secondary  text-14 mb-3">
                                        Manage your bill at Subscription & Usage
                                      </p>
                                      <button
                                        onClick={() =>
                                        (window.location.href = `/subscription/${item.id
                                          }${item.workspace_list.length > 0
                                            ? '/' + item.workspace_list[0].id
                                            : ''
                                          }`)
                                        }
                                        type="button"
                                        className="btn badge-blue text-14 text-semibold"
                                      >
                                        Go to Subscription & Usage
                                      </button>
                                    </Fragment>
                                  ) : (
                                    //role None
                                    <p className="text-secondary  text-14 mb-3">
                                      No workspace is assigned to you.
                                    </p>
                                  )}
                                </div>
                              ) : (
                                <div>
                                  <DataTable
                                    pagination={true}
                                    className="-fadeIn mt-0 none-overflow"
                                    center={true}
                                    columns={columns}
                                    data={item.workspace_list}
                                  />
                                </div>
                              )}
                            </div>
                          )
                        })
                      ) : (
                        <Fragment>
                          {' '}
                          <div className="card p-2 border-1 bg-white mt-2">
                            <div className="card-body my-3">
                              <div className="d-flex justify-content-center item-center text-center">
                                <div>
                                  <h6>
                                    <b>No accounts found</b>
                                  </h6>{' '}
                                  <h6>
                                    Start creating by clicking on “Create
                                    Account”.
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Fragment>
                      )
                    ) : (
                      <Fragment />
                      // <div className="loadingbox-md">
                      //   <div className="spinner"></div>
                      // </div>
                    )}
                  </Fragment>
                </div>
                <FooterComponent />
              </div>
            </Fragment>
          )}
        </div>
      </div>

      <Modal
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={200}
        ariaHideApp={false}
        isOpen={openPopupWorkspaceLimit}
        contentLabel="modal"
        style={customStylesSM}
        onRequestClose={() => setOpenPopupWorkspaceLimit(false)}
        className={'modal-Publish-box animate-5 fadeIn two'}
      >
        <ModalBox>
          <div className="modal-dialog modal-md ">
            <div className="modal-content">
              <div className="modal-header bg-danger">
                <div className="p-1 text-white"></div>
              </div>
              <div className="modal-body p-3 mt-3">
                <div className="text-center">
                  <img className="mb-2" src="/assets/img/warning.png" />
                </div>
                <p className="text-18 text-semibold text-danger text-center">
                  {roleWorkspaceLimit
                    ? 'Your Account Requires an Upgrade'
                    : 'Plan Limit Reached'}
                </p>
                {roleWorkspaceLimit ? (
                  <p className="text-14 text-center mb-2">
                    You have reached the total number of workspace allowed{' '}
                    <br />
                    for your plan. To continue to add workspace, <br />
                    your account will need to be upgraded to a new plan.
                  </p>
                ) : (
                  <p className="text-14 text-center mb-2">
                    You have reached the total number of workspace allowed{' '}
                    <br />
                    for your plan. Please contact your Super Account Admin{' '}
                    <br />
                    to upgrade to a new plan.
                  </p>
                )}
              </div>

              <div className="modal-footer justify-content-center px-4 pb-3">
                {roleWorkspaceLimit ? (
                  <Fragment>
                    <div
                      className="text-danger mx-3 cursor text-14"
                      onClick={() => setOpenPopupWorkspaceLimit(false)}
                    >
                      Cancel
                    </div>
                    <div
                      className="btn btn-danger text-14"
                      onClick={() => upgradePlan()}
                    >
                      Upgrade Plan
                    </div>
                  </Fragment>
                ) : (
                  <div
                    className="btn btn-danger text-14"
                    onClick={() => setOpenPopupWorkspaceLimit(false)}
                  >
                    Close
                  </div>
                )}
              </div>
            </div>
          </div>
        </ModalBox>
      </Modal>

      <Modal
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={200}
        ariaHideApp={false}
        isOpen={openPopupInviteLimit}
        contentLabel="modal"
        style={customStylesSM}
        onRequestClose={() => setOpenPopupInviteLimit(false)}
        className={'modal-Publish-box animate-5 fadeIn two'}
      >
        <ModalBox>
          <div className="modal-dialog modal-md ">
            <div className="modal-content">
              <div className="modal-header bg-danger">
                <div className="p-1 text-white"></div>
              </div>
              <div className="modal-body p-3 mt-3">
                <div className="text-center">
                  <img className="mb-2" src="/assets/img/warning.png" />
                </div>
                <p className="text-18 text-semibold text-danger text-center">
                  This Account is Currently Full
                </p>

                <p className="text-14 text-center mb-2">
                  This account has reached the total number of account
                  <br /> users allowed. Please reach out to your host to make
                  room
                  <br />
                  or consider upgrading the account for more space.
                </p>
              </div>
              <div className="modal-footer justify-content-center px-4 pb-3">
                <Fragment>
                  <div
                    className="btn btn-danger text-14"
                    onClick={() => setOpenPopupInviteLimit()}
                  >
                    Close
                  </div>
                </Fragment>
              </div>
            </div>
          </div>
        </ModalBox>
      </Modal>

      <Modal
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={200}
        ariaHideApp={false}
        isOpen={openPopupInviteSuspended}
        contentLabel="modal"
        style={customStylesSM}
        onRequestClose={() => setOpenPopupInviteSuspended(false)}
        className={'modal-Publish-box animate-5 fadeIn two'}
      >
        <ModalBox>
          <div className="modal-dialog modal-md ">
            <div className="modal-content">
              <div className="modal-header bg-danger">
                <div className="p-1 text-white"></div>
              </div>
              <div className="modal-body p-3 mt-3">
                <div className="text-center">
                  <img className="mb-2" src="/assets/img/warning.png" />
                </div>
                <p className="text-18 text-semibold text-danger text-center">
                  This Account is Currently Suspended
                </p>

                <p className="text-14 text-center mb-2">
                  This account has been suspended. <br />
                  Please reach out to your host for more information.
                </p>
              </div>
              <div className="modal-footer justify-content-center px-4 pb-3">
                <Fragment>
                  <div
                    className="btn btn-danger text-14"
                    onClick={() => setOpenPopupInviteSuspended()}
                  >
                    Close
                  </div>
                </Fragment>
              </div>
            </div>
          </div>
        </ModalBox>
      </Modal>

      <Modal
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={200}
        ariaHideApp={false}
        isOpen={openPopupSuspended}
        contentLabel="modal"
        style={customStylesSM}
        onRequestClose={() => setOpenPopupSuspended(false)}
        className={'modal-Publish-box animate-5 fadeIn two'}
      >
        <ModalBox>
          <div className="modal-dialog modal-md ">
            <div className="modal-content">
              <div className="modal-header bg-danger">
                <div className="p-1 text-white"></div>
              </div>
              <div className="modal-body p-3 mt-3">
                <div className="text-center">
                  <img className="mb-2" src="/assets/img/warning.png" />
                </div>
                <p className="text-18 text-semibold text-danger text-center">
                  This Account is Currently Suspended
                </p>

                <p className="text-14 text-center mb-2">
                  You cannot access this workspace due to the suspension
                  <br />
                  of the account. Please contact your Super Account Admin
                  <br />
                  to reactivate the account to ensure continuous data stream.
                </p>
              </div>
              <div className="modal-footer justify-content-center px-4 pb-3">
                <Fragment>
                  <div
                    className="btn btn-danger text-14"
                    onClick={() => setOpenPopupSuspended()}
                  >
                    Close
                  </div>
                </Fragment>
              </div>
            </div>
          </div>
        </ModalBox>
      </Modal>
    </Home>
  )
}

export default HomeComponent
