import React, { useState, useEffect } from 'react'
import { ResetPasswordBox } from './style'
import { FaEye, FaEyeSlash, FaCheck } from 'react-icons/fa'
import FooterComponent from '../layout/footer'
import { changePassword } from '../../apis/login/api'
import { redirectLogin } from '../../helper/helper'

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import ReCaptchaComponent from '../login/recaptcha'
const { REACT_APP_CAPTCHA } = process.env
var round = 0

const ResetPasswordComponent = () => {
  const [showNewPassword, setShowNewPassword] = useState(true)
  const [showCfPassword, setShowCfPassword] = useState(true)
  const [newPassword, setNewPassword] = useState('')
  const [cfPassword, setCfPassword] = useState('')
  const [verifyCFPassword, setVerifyCFPassword] = useState(false)

  const [checkedLetter, setCheckedLetter] = useState(false)
  const [checkedCapital, setCheckedCapital] = useState(false)
  const [checkedNumber, setCheckedNumber] = useState(false)
  const [checkedSpecial, setCheckedSpecial] = useState(false)
  const [checkedLength, setCheckedLength] = useState(false)
  const [accessToken, setAccessToken] = useState('')

  const [verifyToken, setVerifyToken] = useState('')
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(0)

  const [passwordsconditionbox, setPasswordsconditionbox] = useState({
    no: 0,
    isOpen: false,
  })

  const [validateNewPassword, setValidateNewPassword] = useState({
    msg: 'updated successfully.',
    status: true,
  })
  const [validateCfPassword, setValidateCfPassword] = useState({
    msg: 'updated successfully.',
    status: true,
  })

  useEffect(() => {
    document.title = 'ResetPassWord | Tag Turbo'
    document
      .getElementById('viewport')
      .setAttribute('content', 'initial-scale=0.9, minimum-scale=0.9,width=450')

    const token = localStorage.getItem('token')
    document.addEventListener('click', handleClickOutside, true)
    if (token) {
      setAccessToken(token)
      refreshRecaptcha()
    } else {
      redirectLogin()
    }
    ;(function () {
      function onTidioChatApiReady() {
        window.tidioChatApi.hide()
        window.tidioChatApi.on('close', function () {
          window.tidioChatApi.hide()
        })
      }

      if (window.tidioChatApi) {
        window.tidioChatApi.on('ready', onTidioChatApiReady)
      } else {
        document.addEventListener('tidioChat-ready', onTidioChatApiReady)
      }
    })()
  }, [])

  useEffect(() => {
    setVerifyToken(verifyToken)
  }, [verifyToken])

  const keepTokenFn = (token) => {
    setVerifyToken(token)
  }

  const refreshRecaptcha = () => {
    const interval = setInterval(async () => {
      round = round === 0 ? 1 : 0
      setRefreshReCaptcha(round)
    }, 60000)
    return () => clearInterval(interval)
  }

  const handleClickOutside = (event) => {
    setPasswordsconditionbox({
      no: 0,
      isOpen: false,
    })
  }

  const handlePasswordsconditionbox = (no) => {
    let obj = {}
    if (passwordsconditionbox.no === no) {
      obj = {
        no: no,
        isOpen: !passwordsconditionbox.isOpen,
      }
    } else {
      obj = {
        no: no,
        isOpen: true,
      }
    }
    setPasswordsconditionbox(obj)
  }

  const checkPassword = (e) => {
    setPasswordsconditionbox({
      no: 1,
      isOpen: true,
    })

    setNewPassword(e.target.value)
    if (e.target.value) {
      // setValidateNewPassword(true);
      handleNewPassword('', true)
    } else {
      handleNewPassword('1', false)
    }
    const lowerCaseLetters = /[a-z]/g
    if (e.target.value.match(lowerCaseLetters)) {
      setCheckedLetter(true)
    } else {
      setCheckedLetter(false)
    }

    const upperCaseLetters = /[A-Z]/g
    if (e.target.value.match(upperCaseLetters)) {
      setCheckedCapital(true)
    } else {
      setCheckedCapital(false)
    }

    // Validate numbers
    const numbers = /[0-9]/g
    if (e.target.value.match(numbers)) {
      setCheckedNumber(true)
    } else {
      setCheckedNumber(false)
    }

    const specials = /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi
    if (e.target.value.match(specials)) {
      setCheckedSpecial(true)
    } else {
      setCheckedSpecial(false)
    }

    // Validate length
    if (e.target.value.length >= 8) {
      setCheckedLength(true)
    } else {
      setCheckedLength(false)
    }
  }

  const onSubmit = async () => {
    if (newPassword) {
      handleNewPassword('', true)
    } else {
      handleNewPassword('New password is required.', false)
    }
    if (cfPassword) {
      handleCfPassword('', true)
    } else {
      handleCfPassword('Confirm password is required', false)
    }
    if (newPassword && cfPassword) {
      if (
        checkedLetter &&
        checkedCapital &&
        checkedNumber &&
        checkedSpecial &&
        checkedLength
      ) {
        handleNewPassword('', true)

        if (newPassword !== cfPassword) {
          if (cfPassword) {
            handleCfPassword('Password is not match. Please try again.', false)
          } else {
            handleCfPassword('', true)
          }
        } else {
          if (!cfPassword) {
            setVerifyCFPassword(true)
          } else {
            let obj = {
              token: accessToken,
              password: newPassword,
              recaptcha_token: verifyToken,
            }
            const { data, status } = await changePassword(obj)
            if (data && status === 200) {
              localStorage.removeItem('token')
              setTimeout(() => {
                window.location.href = '/login'
              }, 200)
            } else {
              round = round === 0 ? 1 : 0
              setRefreshReCaptcha(round)
            }
          }
        }
      } else {
        round = round === 0 ? 1 : 0
        setRefreshReCaptcha(round)
        handleNewPassword('Password is on the wrong format.', false)

        console.log('error')
      }
    }
  }

  const handleNewPassword = (msg, status) => {
    setValidateNewPassword({ msg, status })
  }
  const handleCfPassword = (msg, status) => {
    setValidateCfPassword({ msg, status })
  }

  return (
    <ResetPasswordBox>
      <GoogleReCaptchaProvider reCaptchaKey={REACT_APP_CAPTCHA}>
        <div className="page full-page">
          <div className="content-box">
            <div className="container-fluid animate fadeIn one h-100">
              <div className="row justify-content-md-center h-100">
                <div className="col-lg-6 text-center p-0 d-none d-lg-block">
                  <img
                    src="/assets/img/BG_login.png"
                    className="w-100 p-0"
                    alt="Tag Turbo"
                  />
                </div>
                <div className="col-lg-6 text-center p-0 content h-100 d-flex-center">
                  <div id="reset" className="min-h-100 max-w-718">
                    <div className="p-5 min-h-95">
                      <h2 className="mt-4">
                        <img
                          src="/assets/img/logo-Tagtb.svg"
                          className="img-180"
                        />
                      </h2>
                      <div className="text-left mt-5">
                        <p className="text-20">
                          <b>Set new password</b>
                        </p>

                        <form
                          id="form"
                          action="/home"
                          className="text-left mb-3"
                        ></form>
                        <div>
                          <label className="form-label  text-14">
                            Enter New Password
                          </label>
                          <label
                            id="newpassword"
                            className="form-label"
                          ></label>
                          <div className="input-group mb-3">
                            <input
                              id="newpassword"
                              type={showNewPassword ? 'password' : ''}
                              className={`form-control border-right-0 ${
                                !validateNewPassword.status
                                  ? 'invalidate-box'
                                  : ''
                              }`}
                              value={newPassword}
                              defaultValue={newPassword}
                              onChange={(e) => (
                                checkPassword(e), handleNewPassword('', true)
                              )}
                              onClick={() => handlePasswordsconditionbox(1)}
                            />
                            <span
                              className={`input-group-text border-left-0 bg-transparent ${
                                !validateNewPassword.status
                                  ? 'invalidate-box'
                                  : ''
                              }`}
                              id="basic-addon2"
                            >
                              <div
                                className="cursor"
                                onClick={() =>
                                  setShowNewPassword(!showNewPassword)
                                }
                              >
                                {showNewPassword ? (
                                  <FaEye className="text-lighter" />
                                ) : (
                                  <FaEyeSlash className="text-lighter" />
                                )}{' '}
                              </div>
                            </span>
                          </div>
                          {!validateNewPassword.status && (
                            <label className="form-label invalidate-text  text-14">
                              {validateNewPassword.msg}
                            </label>
                          )}
                          {passwordsconditionbox.no === 1 &&
                            passwordsconditionbox.isOpen && (
                              <div className="passwords-condition-box animate fadeIn one ">
                                <p className="text-12 m-0">
                                  Passwords must contain :
                                </p>

                                <p
                                  id="pass-length"
                                  className={`invalid mb-1 text-12 ${
                                    checkedLength ? 'text-success' : 'invalid'
                                  }`}
                                >
                                  <FaCheck /> a minimum of 8 characters
                                </p>
                                <p
                                  id="pass-lower"
                                  className={`invalid mb-1 text-12 ${
                                    checkedLetter ? 'text-success' : 'invalid'
                                  }`}
                                >
                                  <FaCheck /> a minimum of 1 lowercase letter
                                  [a-z]
                                </p>
                                <p
                                  id="pass-upper"
                                  className={`invalid mb-1 text-12 ${
                                    checkedCapital ? 'text-success' : 'invalid'
                                  }`}
                                >
                                  <FaCheck /> a minimum of 1 uppercase letter
                                  [A-Z]
                                </p>
                                <p
                                  id="pass-number"
                                  className={`invalid mb-1 text-12 ${
                                    checkedNumber ? 'text-success' : 'invalid'
                                  }`}
                                >
                                  <FaCheck /> a minimum of 1 numeric character
                                  [0-9]
                                </p>
                                <p
                                  id="pass-special"
                                  className={`invalid mb-1 text-12 ${
                                    checkedSpecial ? 'text-success' : 'invalid'
                                  }`}
                                >
                                  <FaCheck /> a minimum of 1 special character:
                                  ~`!@#$%^&amp;*()- _+={}
                                  []|\;:&quot;&lt;&gt;,./?{' '}
                                </p>
                              </div>
                            )}
                        </div>

                        <div className="mb-5">
                          <label className="form-label text-14">
                            Confirm Password
                          </label>
                          <label id="cfpassword" className="form-label"></label>
                          <div className="input-group mb-3">
                            <input
                              id="cfpassword"
                              type={showCfPassword ? 'password' : ''}
                              className={`form-control border-right-0 ${
                                !validateCfPassword.status
                                  ? 'invalidate-box'
                                  : ''
                              }`}
                              value={cfPassword}
                              defaultValue={cfPassword}
                              onChange={(e) => (
                                setCfPassword(e.target.value),
                                handleCfPassword('', true)
                              )}
                            />
                            <span
                              className={`input-group-text border-left-0 bg-transparent ${
                                !validateCfPassword.status
                                  ? 'invalidate-box'
                                  : ''
                              }`}
                              id="basic-addon2"
                            >
                              <div
                                className="cursor"
                                onClick={() =>
                                  setShowCfPassword(!showCfPassword)
                                }
                              >
                                {showCfPassword ? (
                                  <FaEye className="text-lighter" />
                                ) : (
                                  <FaEyeSlash className="text-lighter" />
                                )}{' '}
                              </div>
                            </span>
                          </div>
                          {!validateCfPassword.status && (
                            <label className="form-label invalidate-text text-14">
                              {validateCfPassword.msg}
                            </label>
                          )}
                        </div>
                        <div className="text-center">
                          <button
                            className="btn btn-primary w-50 text-14 text-semibold"
                            onClick={() => onSubmit()}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                    <FooterComponent />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ReCaptchaComponent
          keepTokenFn={keepTokenFn}
          refreshToken={refreshReCaptcha}
        />
      </GoogleReCaptchaProvider>
    </ResetPasswordBox>
  )
}

export default ResetPasswordComponent
