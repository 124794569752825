import React, { Fragment } from 'react'
import { useParams } from 'react-router-dom'
import { RiErrorWarningFill } from 'react-icons/ri'
import { TopbarNotiBox } from './style'
import { formatDateShortMonth } from '../../helper/helper'

const TopbarNotiComponent = (props) => {
  const { accountId, workspaceId } = useParams()
  const { planInfo } = props

  const onPayExtend = async () => {
    localStorage.setItem('payment_event', 'Extend')
    window.location.href = `/checkout/${accountId}${
      workspaceId ? '/' + workspaceId : ''
    }`
  }

  const onPayExpired = async () => {
    localStorage.setItem('payment_event', 'Expired')
    window.location.href = `/package/${accountId}${
      workspaceId ? '/' + workspaceId : ''
    }`
  }
  return planInfo?.subscription_type !== 'trial' ? (
    planInfo?.status === 'Suspended' ||
    planInfo?.status === 'Pending Payment' ? (
      <TopbarNotiBox>
        <div className="d-flex justify-content-between">
          <div className="px-2">
            {' '}
            <RiErrorWarningFill className="mx-1 " />
            {planInfo.status !== 'Suspended' ? (
              <Fragment>
                Your subscription has expired. To prevent the account from being
                suspended, please make a payment by{' '}
                <b>{formatDateShortMonth(planInfo?.end_extend_at)}</b>
              </Fragment>
            ) : (
              ' Your account has been suspended. Please make a payment.'
            )}
          </div>
          <Fragment>
            {props.activeAccountRole === 'SuperAccountAdmin' ||
            props.activeAccountRole === 'BillingAdmin' ? (
              <div
                className="-cursor btn-right"
                onClick={() =>
                  planInfo.status !== 'Suspended'
                    ? onPayExtend()
                    : onPayExpired()
                }
              >
                PAY NOW
              </div>
            ) : (
              ''
            )}
          </Fragment>
        </div>
      </TopbarNotiBox>
    ) : (
      <Fragment />
    )
  ) : (
    <Fragment />
  )
}

export default TopbarNotiComponent
