/*global chrome*/
import React, { Fragment, useState, useEffect, useRef } from 'react'
import Modal from 'react-modal'
import { useParams } from 'react-router-dom'

import { ActivityBox } from './style'
import FooterComponent from '../layout/footer'
import TopbarComponent from '../layout/topbar'
import MenuComponent from '../layout/menu'
import TopbarNotiComponent from '../layout/topbarNotification'

import {
  timeSince,
  redirectLogin,
  formatDateShortMonthAndTime,
} from '../../helper/helper'
import { FiSearch } from 'react-icons/fi'
import { LiaTimesSolid } from 'react-icons/lia'
import AlertComponent from '../layout/alert'
import { activityList } from '../../apis/tags/api'
import { roleByAccountId, roleByWorkspaceId } from '../../apis/account/api'
import LoadingComponent from '../layout/loading'
import TopbarFreeNotiComponent from '../layout/topbarFreeNotification'
import { planDetail } from '../../apis/plan/api'
var _tempTag = []
var lastItem = ''

const ActivityComponent = () => {
  const [isReset, setIsReset] = useState(false)
  const [masterList, setMasterList] = useState([])
  const [textSearch, setTextSearch] = useState('')
  const [openPopupDetails, setOpenPopupDetails] = useState(false)
  const [tagDetails, setTagDetails] = useState('')
  const [showAlert, setShowAlert] = useState({
    msg: 'Account Role has been updated successfully.',
    status: true,
    isShow: false,
  })
  const [isLoading, setIsLoading] = useState(false)
  const { accountId, workspaceId } = useParams()
  const [activeAccountRole, setActiveAccountRole] = useState('')
  const [activeWorkspaceRole, setActiveWorkspaceRole] = useState('')
  const [isLastItem, setIsLastItem] = useState(false)
  const [isLoadingPage, setIsLoadingPage] = useState(true)
  const [planInfo, setPlanInfo] = useState('')
  const [optionSearch, setOptionSearch] = useState('')
  const [dropdownOptionSearch, setDropdownOptionSearch] = useState(false)
  const searchInput = useRef(null)

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      inset: '50% auto auto 59%',
      position: 'absolute',
      height: '100vh',
      width: '82%',
    },
  }
  const [sortBy, setSortBy] = useState({
    sortField: '',
    sortType: 'desc',
  })
  const [tagList, setTagList] = useState('')
  const accessToken = localStorage.getItem('token')

  useEffect(() => {
    document.title = 'Activity | Tag Turbo'
    if (accessToken) {
      getPublishList()
    } else {
      redirectLogin()
    }
    getRoleByAccountId()
    getRoleByWorkspaceId()

    document.addEventListener('click', handleClickOutside, true)
  }, [])

  const selectOption = (option) => {
    setDropdownOptionSearch(false)
    setOptionSearch(option)
    setTimeout(() => {
      if (searchInput.current) {
        searchInput.current.focus()
      }
    }, 100)
  }

  const handleClickOutside = (event) => {
    if (
      event.target.classList[0] !== 'dropdown-item-setting' &&
      event.target.classList[0] !== '-pointer'
    ) {
      setDropdownOptionSearch(false)
    }
  }

  const getPlanInfo = async () => {
    const { data, status } = await planDetail(accessToken, accountId)
    if (status === 200) {
      setPlanInfo(data.plan_of_account_detail)
      setIsLoadingPage(false)
    } else {
      setIsLoadingPage(false)
    }
  }
  const getRoleByAccountId = async () => {
    const { data, status } = await roleByAccountId(accessToken, accountId)
    if (status === 200) {
      setActiveAccountRole(data.data.role)
      setIsLoadingPage(false)
      if (data.data.role !== 'None') {
        getPlanInfo()
      }
    } else {
      setIsLoadingPage(false)
    }
  }
  const getRoleByWorkspaceId = async () => {
    const { data, status } = await roleByWorkspaceId(accessToken, workspaceId)
    if (status === 200) {
      setActiveWorkspaceRole(data.data.role)
      setIsLoadingPage(false)
    } else {
      setIsLoadingPage(false)
    }
  }

  const handleResetList = () => {
    setIsLoadingPage(true)
    lastItem = ''
    setTextSearch('')
    setOptionSearch('')
    _tempTag = []
    setTagList([])
    setMasterList([])

    const intervalBody = setInterval(function () {
      if (_tempTag.length === 0) {
        setTagList([])
        getPublishList('', '', 'new')
        clearInterval(intervalBody)
      } else {
        setTagList([])
      }
    }, 500)
  }

  const getPublishList = async (LastID = '', textSearch = '', option = '') => {
    setIsLoading(true)
    let obj = {
      page_size: 20,
      last_field_id: lastItem,
    }

    if (option === 'new') {
      setTagList([])
      _tempTag = []
    }

    if (optionSearch === 'Tag name') {
      obj.activity = ''
      obj.tag_name = textSearch
      obj.user = ''
    } else if (optionSearch === 'Activity') {
      obj.activity = textSearch
      obj.tag_name = ''
      obj.user = ''
    } else if (optionSearch === 'User') {
      obj.activity = ''
      obj.tag_name = ''
      obj.user = textSearch
    } else {
      obj.activity = ''
      obj.tag_name = ''
      obj.user = ''
    }

    const { data, status } = await activityList(accessToken, workspaceId, obj)
    if (status === 200 && data?.data?.activity?.length !== 0) {
      setIsLastItem(false)
      if (lastItem !== data.data.activity[data.data.activity.length - 1].id) {
        lastItem = data.data.activity[data.data.activity.length - 1].id
        if (option) {
          setTagList([])
          _tempTag = []
          setTagList(data.data.activity)
        } else {
          _tempTag.push(...data.data.activity)

          setTagList(_tempTag.map((item) => item))
        }
        setMasterList(data.data.activity)
        setTimeout(() => {
          setIsLoading(false)
          setIsLoadingPage(false)
        }, 1000)
      }
    } else {
      if (!lastItem) {
        setTagList([])
      }

      setIsLastItem(true)
      setTimeout(() => {
        setIsLoadingPage(false)
        setIsLoading(false)
        setIsLoadingPage(false)
      }, 500)
    }
  }

  const handleScroll = (event) => {
    if (
      event.currentTarget.scrollTop + window.innerHeight - 70 >
      event.currentTarget.scrollHeight
    ) {
      if (!isLoading && !isLastItem) {
        getPublishList(tagList[tagList.length - 1].id, textSearch)
      }
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setIsLoadingPage(true)
      lastItem = ''
      setTimeout(() => {
        getPublishList('', event.target.value, true)
      }, 200)
      setTimeout(() => {
        setIsLoadingPage(false)
      }, 1500)
    }
  }

  const handleLostInput = (event) => {
    lastItem = ''
    setIsLoadingPage(true)
    setTimeout(() => {
      getPublishList('', event.target.value, true)
    }, 200)
    setTimeout(() => {
      setIsLoadingPage(false)
    }, 1500)
  }

  const searchFilter = (value) => {
    setTextSearch(value.target.value)
  }

  return (
    <ActivityBox>
      <div className="page pull-page-bg-secondary ">
        <div className="content-box">
          {isLoadingPage ? (
            <LoadingComponent />
          ) : (
            <Fragment>
              <TopbarComponent
                isPublish={true}
                resetList={isReset}
                callBackFn={''}
                activeWorkspaceRole={activeWorkspaceRole}
                activeAccountRole={activeAccountRole}
              />
              <Fragment>
                <TopbarFreeNotiComponent planInfo={planInfo} />
                <TopbarNotiComponent />

                <MenuComponent
                  activeWorkspaceRole={activeWorkspaceRole}
                  activeAccountRole={activeAccountRole}
                  planInfo={planInfo}
                />
                {showAlert.isShow && (
                  <AlertComponent
                    messageAlert={showAlert.msg}
                    status={showAlert.status}
                    alertType={
                      showAlert.status ? 'alert-success' : 'alert-error'
                    }
                  />
                )}

                <div
                  className="content-menu content-scroll "
                  onScroll={handleScroll}
                >
                  <div className="container-md animate fadeIn one p-4 min-h-82">
                    <div className="content">
                      <div className="d-flex justify-content-between item-center mb-4">
                        <div>
                          <h4 className="m-0 text-20 text-semibold">
                            Activity
                          </h4>
                        </div>
                        <div className="d-flex ">
                          <div className="spacesing">
                            <div className="input-group -pointer">
                              <div
                                className="input-group-prepend h-44 d-flex"
                                onClick={() => setDropdownOptionSearch(true)}
                              >
                                <span className="input-group-text h-44">
                                  <FiSearch className="mx-2" />
                                  {optionSearch ? (
                                    <span className="tag-label">
                                      <b> {optionSearch} is</b>
                                    </span>
                                  ) : (
                                    <span
                                      className="text-gray text-14"
                                      style={{ zIndex: 99 }}
                                    >
                                      Search by Tag name, Activity or User
                                    </span>
                                  )}
                                </span>
                                <div
                                  className={`vertical-line ${
                                    optionSearch ? '' : 'd-none'
                                  }`}
                                ></div>
                              </div>

                              {optionSearch ? (
                                <Fragment>
                                  <input
                                    type="text"
                                    className="form-control border-left-0 h-44 searchInput"
                                    placeholder={`Search by ${optionSearch}`}
                                    ref={searchInput}
                                    value={textSearch}
                                    onChange={(e) => searchFilter(e)}
                                    onKeyDown={(e) => handleKeyDown(e)}
                                    onBlur={(e) => handleLostInput(e)}
                                  />
                                  {textSearch.length > 0 && (
                                    <LiaTimesSolid
                                      className="icon-refresh-search"
                                      onClick={() => handleResetList()}
                                    />
                                  )}
                                </Fragment>
                              ) : (
                                <div
                                  className="form-control border-left-0 h-44 searchInput"
                                  onClick={() => setDropdownOptionSearch(true)}
                                />
                              )}
                              {/* <input
                            type="text"
                            className="form-control border-left-0 h-35 search_input"
                            value={textSearch}
                            onChange={(e) => searchFilter(e.target.value)}
                            placeholder="Search by Tag name or Page"
                          /> */}
                            </div>
                            {dropdownOptionSearch && (
                              <div className="rounded position-absolute boxsearch bg-white">
                                <div className={`dropdown-menu-setting`}>
                                  <div
                                    className="dropdown-item-setting text-14 text-semibold"
                                    onClick={() => selectOption('Tag name')}
                                  >
                                    Tag name is
                                  </div>
                                  <div
                                    className="dropdown-item-setting text-14 text-semibold"
                                    onClick={() => selectOption('Activity')}
                                  >
                                    Activity is
                                  </div>
                                  <div
                                    className="dropdown-item-setting text-14 text-semibold"
                                    onClick={() => selectOption('User')}
                                  >
                                    User is
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {optionSearch && (
                        <div className="d-flex mb-4">
                          <b className="text-12">Search By</b>
                          <div className="search-label">
                            Search : {optionSearch} is `{textSearch}`{' '}
                          </div>
                        </div>
                      )}
                      {isLoadingPage ? (
                        <LoadingComponent />
                      ) : tagList && tagList.length > 0 ? (
                        <div className="border-shadow rounded bg-white mt-2 box-shadow-center  table-border-radius">
                          <table className="table m-0">
                            <thead className="table-light">
                              <tr>
                                <td className="text-12 ">Tag name </td>
                                <td className="text-12 ">Activity</td>
                                <td className="text-12 ">User </td>
                                <td className="text-12 ">Date</td>
                              </tr>
                            </thead>
                            <tbody>
                              {tagList.map((item, i) => {
                                return (
                                  <tr key={i}>
                                    <td className="text-12 w-table">
                                      {item.tag_name}
                                    </td>
                                    <td className="text-12">{item.activity}</td>

                                    <td className="text-12">{item.user}</td>

                                    <td className="text-12">
                                      {formatDateShortMonthAndTime(
                                        item.updated_at,
                                      )}
                                    </td>
                                  </tr>
                                )
                              })}
                              {isLoading && (
                                <tr>
                                  <td className="text-center" colSpan="5">
                                    <img
                                      src="/assets/img/Loading-spin.gif"
                                      className="loading-sm"
                                    />
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <div className="p-2 mt-5">
                          <div className="card-body my-4 box-card">
                            <div className="d-flex justify-content-center item-center text-center">
                              <div>
                                <img
                                  src="/assets/img/nodata.png"
                                  alt="tag turbo"
                                />
                                <h4 className="text-gray">No data found</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="my-3">
                      <FooterComponent />
                    </div>
                  </div>
                </div>
              </Fragment>
            </Fragment>
          )}

          <Modal
            shouldCloseOnOverlayClick={false}
            closeTimeoutMS={200}
            ariaHideApp={false}
            isOpen={openPopupDetails}
            contentLabel="modal"
            style={customStyles}
            onRequestClose={() => setOpenPopupDetails(false)}
            className={`modal-box animate  one ${
              openPopupDetails ? 'fadeInRight' : 'fadeOutRight'
            }`}
          >
            <div className="modal-dialog modal-md p-3">
              <div className="modal-content p-1">
                <div className="modal-header">
                  <div>
                    <button
                      type="button"
                      className="btn-close"
                      aria-label="Close"
                      onClick={() => setOpenPopupDetails(false)}
                    ></button>
                    <span className="mx-3 text-20 text-semibold">
                      {tagDetails.tag_name}
                    </span>
                  </div>

                  <button
                    type="button"
                    className="btn w-lg btn-primary waves-effect waves-light"
                    onClick={() => setOpenPopupDetails(false)}
                  >
                    Edit Tag
                  </button>
                </div>
                <div className="modal-body border p-3 mt-3 rounded">
                  <div className="content">
                    <p className="text-18 text-semibold">Tag Configuration</p>
                    <p className="my-1 text-12 text-secondary">Page</p>
                    <p className="text-14 ">{tagDetails.path}</p>

                    <p className="my-1 text-12 text-secondary">Event Type</p>
                    <p className="text-14 ">{tagDetails.event_type}</p>

                    <p className="my-1 text-12 text-secondary">Trigger Type</p>
                    <p className="text-14 ">{tagDetails.trigger_type}</p>

                    <p className="my-1 text-12 text-secondary">
                      Trigger Condition
                    </p>
                    <p className="text-14 ">
                      {tagDetails.trigger_condition?.type ? (
                        tagDetails.trigger_condition.type
                      ) : (
                        <p className="text-secondary">Not set</p>
                      )}{' '}
                      =
                      {tagDetails.trigger_condition?.value ? (
                        tagDetails.trigger_condition.value
                      ) : (
                        <p className="text-secondary">Not set</p>
                      )}
                    </p>

                    <p className="my-1 text-12 text-secondary">Event Name</p>
                    <p className="text-14 ">{tagDetails.event}</p>

                    <div className="row mb-2">
                      <div className="col-4">
                        <p className="my-1 text-12 text-secondary">
                          Event Parameter
                        </p>
                        {tagDetails.element_events?.length > 0 ? (
                          tagDetails.element_events.map((list, index) => {
                            return (
                              <p key={index} className="text-14 my-1">
                                {list.element_parameter}
                              </p>
                            )
                          })
                        ) : (
                          <p className="text-secondary text-14 my-1">Not set</p>
                        )}
                      </div>
                      <div className="col-4">
                        <p className="my-1 text-12 text-secondary">
                          Event Parameter Value
                        </p>
                        {tagDetails.element_events?.length > 0 ? (
                          tagDetails.element_events.map((list, index) => {
                            return (
                              <p key={index} className="text-14 my-1">
                                {list.element_parameter_value}
                              </p>
                            )
                          })
                        ) : (
                          <p className="text-secondary text-14 my-1">Not set</p>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-4">
                        <p className="my-1 text-12 text-secondary">
                          User Property
                        </p>
                        {tagDetails.user_events?.length > 0 ? (
                          tagDetails.user_events.map((list, index) => {
                            return (
                              <p key={index} className="text-14 my-1">
                                {list.user_property}
                              </p>
                            )
                          })
                        ) : (
                          <p className="text-secondary text-14 my-1">Not set</p>
                        )}
                      </div>
                      <div className="col-4">
                        <p className="my-1 text-12 text-secondary">
                          User Property Value
                        </p>
                        {tagDetails.user_events?.length > 0 ? (
                          tagDetails.user_events.map((list, index) => {
                            return (
                              <p key={index} className="text-14 my-1">
                                {list.user_property_value}
                              </p>
                            )
                          })
                        ) : (
                          <p className="text-secondary text-14 my-1">Not set</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer justify-content-center"></div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </ActivityBox>
  )
}

export default ActivityComponent
