import styled from 'styled-components'

const SignUpBox = styled.div`
  .content-box {
    height: 100%;
    overflow-y: hidden;
    min-height: 660px;
  }
  .content {
    border-radius: 0px;
    box-shadow: 4px 10px 30px 0 rgb(0 0 0 / 5%);
    background-color: #fff;
    padding: 30px;
  }
  .input {
    width: 3.3rem !important;
    height: 3.5rem;
    margin: 0 0.2rem;
    font-size: 2rem;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
  }
  .invalid {
    color: #000;
  }
  .phone-input {
    width: '100%';
    height: 38px;
  }
  .content {
    min-height: 80vh;
    max-height: 100vh;

    .arrow {
      position: absolute;
      top: 50%;
      margin-top: -2px;
      left: 5px;
      width: 0;
      height: 0;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      border-top: 4px solid #555;
    }
  }

  .optional {
    color: var(--Grey-500, #667085);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
  }
`

export { SignUpBox }
