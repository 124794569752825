import React, { Fragment, useState, useEffect } from 'react'
import { VerifyBox } from './style'
import FooterComponent from '../layout/footer'

import { parseJwt, redirectLogin } from '../../helper/helper'
import { otpForgotVerify, otpForgotResend } from '../../apis/login/api'

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import ReCaptchaComponent from '../login/recaptcha'
import OTPBox from './otpbox.js'
const { REACT_APP_CAPTCHA } = process.env
var round = 0

const VerifyNewPasswordComponent = () => {
  const [otp, setOtp] = useState('')
  const [refCode, setRefCode] = useState('')
  const [accessToken, setAccessToken] = useState('')
  const [validateOtp, setValidateOtp] = useState({
    msg: 'updated successfully.',
    status: true,
  })
  const [verifyToken, setVerifyToken] = useState('')
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(0)
  const [isClear, setIsClear] = useState(false)

  useEffect(() => {
    document.title = 'Verify | Tag Turbo'
    const token = localStorage.getItem('token')
    document
      .getElementById('viewport')
      .setAttribute('content', 'initial-scale=0.9, minimum-scale=0.9,width=450')
    if (token) {
      setAccessToken(token)
      setRefCode(parseJwt(token))
      refreshRecaptcha()
    } else {
      redirectLogin()
    }
    ;(function () {
      function onTidioChatApiReady() {
        window.tidioChatApi.hide()
        window.tidioChatApi.on('close', function () {
          window.tidioChatApi.hide()
        })
      }

      if (window.tidioChatApi) {
        window.tidioChatApi.on('ready', onTidioChatApiReady)
      } else {
        document.addEventListener('tidioChat-ready', onTidioChatApiReady)
      }
    })()
  }, [])

  useEffect(() => {
    setVerifyToken(verifyToken)
  }, [verifyToken])

  const keepTokenFn = (token) => {
    setVerifyToken(token)
  }

  const refreshRecaptcha = () => {
    const interval = setInterval(async () => {
      round = round === 0 ? 1 : 0
      setRefreshReCaptcha(round)
    }, 60000)
    return () => clearInterval(interval)
  }

  const onSubmit = async () => {
    if (!otp) {
      handleValidateOTP('The code is required.', false)
    } else {
      if (otp.length > 1 && otp.length < 6) {
        handleValidateOTP('The code is incorrect.', false)
      } else {
        let obj = {
          token: accessToken,
          otp: otp,
          project: 'GTP',
          recaptcha_token: verifyToken,
        }
        const { data, status } = await otpForgotVerify(obj)
        if (status === 200 && data) {
          const token = data.token
          setAccessToken(token)
          localStorage.setItem('token', data.token)
          window.location.href = '/reset'
        } else {
          round = round === 0 ? 1 : 0
          setRefreshReCaptcha(round)
          handleValidateOTP('The code is incorrect.', false)
        }
      }
    }
  }

  const handleChange = (value) => {
    handleValidateOTP('', true)
    setOtp(value)
  }

  const handleResend = async () => {
    setIsClear(!isClear)
    handleValidateOTP('Email Address is required.', !isClear)
    round = round === 0 ? 1 : 0
    setRefreshReCaptcha(round)
    let obj = {
      token: accessToken,
      recaptcha_token: verifyToken,
    }
    const { data, status } = await otpForgotResend(obj)
    if (status === 200 && data) {
      const token = data.token
      setAccessToken(token)
      setRefCode(parseJwt(token))
    } else {
      console.log('Error Resend')
    }
  }
  const censoringEmail = (email) => {
    email = email.split('@')
    const emailName = email[0].substring(0, 3).padEnd(email[0].length, '*')
    let emailProvider = email[1].split('.')
    emailProvider[0] = emailProvider[0].replaceAll(/./g, '*')
    emailProvider = emailProvider.join('.')
    return emailName + ' ' + emailProvider
  }
  const handleValidateOTP = (msg, status) => {
    setValidateOtp({ msg, status })
  }
  return (
    <VerifyBox>
      <GoogleReCaptchaProvider reCaptchaKey={REACT_APP_CAPTCHA}>
        <div className="page full-page">
          <div className="content-box">
            <div className="container-fluid animate fadeIn one h-100">
              <div className="row justify-content-md-center h-100">
                <div className="col-lg-6 text-center p-0 d-none d-lg-block">
                  <img
                    src="/assets/img/BG_login.png"
                    className="w-100 p-0"
                    alt="Tag Turbo"
                  />
                </div>
                <div className="col-lg-6 text-center p-0 content h-100 d-flex-center">
                  <div id="verify" className="min-h-100 max-w-718">
                    <div className="p-5 min-h-95">
                      <h2 className="mt-4">
                        <img
                          src="/assets/img/logo-Tagtb.svg"
                          className="img-180"
                        />
                      </h2>
                      <div className="text-left mt-5 text-16">
                        <p>
                          <b>Verification Code</b>
                        </p>
                        <p className="text-14">
                          Please check your email{' '}
                          <span className="text-primary mx-1 text-14">
                            {refCode.username &&
                              censoringEmail(refCode.username)}
                          </span>
                          for a six-digit code <br /> and enter it below to
                          begin reset password.
                        </p>
                      </div>
                      <div id="verify" className="text-center my-5">
                        <OTPBox
                          isValid={validateOtp.status}
                          onChange={handleChange}
                          clear={isClear}
                          setIsClear={setIsClear}
                        />
                        {!validateOtp.status && (
                          <Fragment>
                            <p className="text-danger mt-2 mb-0 text-14">
                              {validateOtp.msg}{' '}
                            </p>
                          </Fragment>
                        )}
                        <p className="mt-2 text-14 gray-400">
                          Ref code ({refCode.ref_code})
                        </p>
                      </div>
                      <div className="text-center mb-3">
                        <p className="my-5 text-14">
                          It may take a minute to receive your code Haven’t
                          received? <br />{' '}
                          <a
                            onClick={() => handleResend()}
                            className="text-primary cursor"
                          >
                            Resend a new code
                          </a>
                        </p>
                      </div>
                      <div className="text-center">
                        <button
                          className="btn btn-primary w-50 text-16 text-semibold"
                          onClick={() => onSubmit()}
                        >
                          Verify
                        </button>
                      </div>
                    </div>
                    <FooterComponent />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ReCaptchaComponent
          keepTokenFn={keepTokenFn}
          refreshToken={refreshReCaptcha}
        />
      </GoogleReCaptchaProvider>
    </VerifyBox>
  )
}

export default VerifyNewPasswordComponent
