import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'

import { ProfileSettingsBox } from './style'
import FooterComponent from '../layout/footer'
import MenuProfileComponent from '../layout/menuprofile'
import TopbarComponent from '../layout/topbar'
import AlertComponent from '../layout/alert'
import TopbarNotiComponent from '../layout/topbarNotification'

import { redirectLogin } from '../../helper/helper'

import { userProfile, editUserProfile } from '../../apis/login/api'

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const ProfileSettingsComponent = () => {
  const [username, setUserName] = useState('')
  const [validateUserName, setValidateUserName] = useState(false)
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [country, setCountry] = useState('66')

  const [isDisabled, setIsDisabled] = useState(true)

  const { accountId } = useParams()
  const { id } = useParams()
  // const [accountDetail, setAccountDetail] = useState("");

  // const [validateName, setValidateName] = useState(true);
  // const [name, setName] = useState("");
  const [validatePhone, setValidatePhone] = useState({
    msg: 'updated successfully.',
    status: true,
  })
  const [showAlert, setShowAlert] = useState({
    msg: 'Account Role has been updated successfully.',
    status: true,
    isShow: false,
  })
  const accessToken = localStorage.getItem('token')
  useEffect(() => {
    document.title = 'Profile | Tag Turbo'
    getUserProfile(accessToken)
  }, [])

  const getUserProfile = async (token) => {
    const { data, status } = await userProfile(token)
    if (status === 200) {
      setUserName(data.data.name)
      setEmail(data.data.email)
      setPhoneNumber(
        data?.data?.country_code
          ? data.data.country_code + data?.data?.phone_number
          : '',
      )
    }
  }

  const convertToFormatTel = (phoneNumber, dialCode) => {
    if (phoneNumber === dialCode) {
      return null
    } else {
      const regex = new RegExp(`^${dialCode}0?(\\d+)`)
      const match = phoneNumber.match(regex)
      return match ? match[1] : phoneNumber
    }
  }

  const onChangePhoneNumber = (phoneNumber, country) => {
    setPhoneNumber(phoneNumber)
    setCountry(country?.dialCode)
    setIsDisabled(false)
  }

  const updateUserProfile = async () => {
    setIsDisabled(true)
    if (!username) {
      setValidateUserName(true)
    } else {
      handlePhone('', true)
      setValidateUserName(false)

      let obj = {
        name: username,
        country_code: country,
        phone_number: phoneNumber
          ? convertToFormatTel(phoneNumber, country)
          : null,
      }
      const { data, status } = await editUserProfile(accessToken, obj)
      if (status === 200) {
        handleAlert('Your profile has been updated successfully', true)
      } else {
        handleAlert('Error!!', false)
        if (
          data.response.data.message === 'phone_number/country code is invalid'
        ) {
          handlePhone('This phone number is invalid.', false)
        }
      }
    }
  }

  const handlePhone = (msg, status) => {
    setValidatePhone({ msg, status })
  }

  const handleAlert = (msg, status) => {
    setShowAlert({
      msg,
      status,
      isShow: true,
    })

    setTimeout(() => {
      setShowAlert({
        msg,
        status,
        isShow: false,
      })
    }, 10000)
  }
  return (
    <ProfileSettingsBox>
      <div className="page pull-page-bg-secondary ">
        <div className="content-box">
          <TopbarComponent isPublish={false} />
          {accountId && <TopbarNotiComponent />}

          <MenuProfileComponent accountId={accountId} id={id} />
          {showAlert.isShow && (
            <AlertComponent
              messageAlert={showAlert.msg}
              status={showAlert.status}
              alertType={showAlert.status ? 'alert-success' : 'alert-error'}
            />
          )}
          <div className="content-menu content-scroll ">
            <div className="container-md animate fadeIn one p-4 min-h-82">
              <div className="content">
                <div className="d-flex justify-content-between item-center mb-4">
                  <div className="col-6">
                    <h4 className="m-0 text-20 text-semibold">Profile</h4>
                  </div>
                </div>

                <div className="card p-2 border box-shadow-center bg-white mt-2">
                  <div className="card-body">
                    <p className="text-18 text-semibold">Basic Information</p>

                    <p className="text-14 mb-1">Name</p>
                    <div className="input-group d-block mb-3">
                      <input
                        type="text"
                        className={`form-control  w-300px ${
                          validateUserName ? 'invalidate-box' : ''
                        }`}
                        id="Name"
                        value={username}
                        onChange={(e) => (
                          setUserName(e.target.value), setIsDisabled(false)
                        )}
                      />
                      {validateUserName && (
                        <label className="form-label invalidate-text text-14">
                          Username is required.
                        </label>
                      )}
                    </div>
                    <p className="text-14 mb-1">Email Address</p>
                    <input
                      type="text"
                      className="form-control mb-3 w-300px"
                      id="Email_Address"
                      value={email}
                      disabled
                    />
                    <p className="text-14 mb-1">Phone Number</p>

                    <PhoneInput
                      inputStyle={{
                        height: '38px',
                        border: !validatePhone.status
                          ? '1px solid #ed3451'
                          : '1px solid #dee2e6',
                      }}
                      buttonStyle={{
                        border: !validatePhone.status
                          ? '1px solid #ed3451'
                          : '1px solid #dee2e6',
                      }}
                      enableSearch={true}
                      disableSearchIcon={true}
                      country={'th'}
                      value={phoneNumber}
                      onChange={(phoneNumber, country) =>
                        onChangePhoneNumber(phoneNumber, country)
                      }
                      preferredCountries={['th']}
                      masks={{ th: '..........' }}
                      placeholder=""
                    />
                    {!validatePhone.status && (
                      <label className="form-label invalidate-text text-14">
                        {validatePhone.msg}
                      </label>
                    )}
                  </div>
                </div>
                <div className="pt-4">
                  <button
                    type="button"
                    onClick={() => updateUserProfile()}
                    className="btn btn-primary px-5"
                    disabled={isDisabled}
                  >
                    Save
                  </button>
                </div>
              </div>
              <div className="my-3">
                <FooterComponent />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ProfileSettingsBox>
  )
}

export default ProfileSettingsComponent
