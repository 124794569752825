import { useEffect } from 'react'
import { useOtpInput } from 'react-otp-input-hook'

const OTPBox = (props) => {
  const { onChange, isValid } = props
  const { register, clear } = useOtpInput({
    onInputValueChange: onChange,
  })

  useEffect(() => {
    if (props.clear) {
      clear()
      props.setIsClear(false)
    }
  }, [props.clear])

  const defaultOptions = { required: true }

  return (
    <div style={{ padding: '10px' }}>
      <input
        className={isValid ? 'otp-input' : 'otp-input-validate'}
        {...register('digit-1', defaultOptions)}
        onKeyPress={(event) => {
          if (!/[0-9]/.test(event.key)) {
            event.preventDefault()
          }
        }}
      />
      <input
        className={isValid ? 'otp-input' : 'otp-input-validate'}
        {...register('digit-2', defaultOptions)}
        onKeyPress={(event) => {
          if (!/[0-9]/.test(event.key)) {
            event.preventDefault()
          }
        }}
      />
      <input
        className={isValid ? 'otp-input' : 'otp-input-validate'}
        {...register('digit-3', defaultOptions)}
        onKeyPress={(event) => {
          if (!/[0-9]/.test(event.key)) {
            event.preventDefault()
          }
        }}
      />
      <input
        className={isValid ? 'otp-input' : 'otp-input-validate'}
        {...register('digit-4', defaultOptions)}
        onKeyPress={(event) => {
          if (!/[0-9]/.test(event.key)) {
            event.preventDefault()
          }
        }}
      />
      <input
        className={isValid ? 'otp-input' : 'otp-input-validate'}
        {...register('digit-5', defaultOptions)}
        onKeyPress={(event) => {
          if (!/[0-9]/.test(event.key)) {
            event.preventDefault()
          }
        }}
      />
      <input
        className={isValid ? 'otp-input' : 'otp-input-validate'}
        {...register('digit-6', defaultOptions)}
        onKeyPress={(event) => {
          if (!/[0-9]/.test(event.key)) {
            event.preventDefault()
          }
        }}
      />
    </div>
  )
}

export default OTPBox
