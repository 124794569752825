import React, { Fragment, useState, useEffect, useRef } from 'react'
import { RiErrorWarningFill } from 'react-icons/ri'
import { MdBookmark, MdAutoAwesome } from 'react-icons/md'
import { TopbarFreeNotiBox, TopbarFreeNotiRedBox } from './style'
import { formatDateShortMonth } from '../../helper/helper'
import { Link, useHistory, useParams, useLocation } from 'react-router-dom'

const TopbarFreeNotiComponent = (props) => {
  const { planInfo } = props
  const { accountId, workspaceId } = useParams()
  const [countDownEndDate, setCountDownEndDate] = useState('')

  const onSubscribeNow = async () => {
    localStorage.setItem('payment_event', 'Expired')
    localStorage.setItem('plan_Info', JSON.stringify(planInfo))
    localStorage.removeItem('plan_datail')
    localStorage.setItem('event_type', 'Subscribe')
    window.location.href = `/package/${accountId} ${
      workspaceId ? '/' + workspaceId : ''
    }`
  }
  useEffect(() => {
    countDownDate()
  }, [planInfo])
  const countDownDate = async () => {
    var countDownDate = new Date(planInfo.end_date).getTime()
    var now = new Date().getTime()
    var distance = countDownDate - now
    var days = Math.floor(distance / (1000 * 60 * 60 * 24))
    setCountDownEndDate(days)
  }

  return planInfo?.is_trial ? (
    planInfo?.status === 'Suspended' ? (
      <TopbarFreeNotiRedBox>
        <div className="d-flex justify-content-between  ">
          <div className="col-6 px-2 text-span ">
            <RiErrorWarningFill className="mx-1" /> Your account has been
            suspended. Please make a payment.
          </div>
          <Fragment>
            <div className="col-6 px-2 text-right">
              <button
                type="button"
                className="btn btn-white px-5"
                onClick={() => onSubscribeNow()}
              >
                <MdAutoAwesome /> SUBSCRIBE NOW
              </button>
            </div>{' '}
          </Fragment>
        </div>{' '}
      </TopbarFreeNotiRedBox>
    ) : (
      <TopbarFreeNotiBox>
        <div className="d-flex justify-content-between">
          <div className="col-6 px-2 text-span">
            <MdBookmark className="mx-1" /> Your free trial ends in{' '}
            {countDownEndDate} days!
          </div>
          <Fragment>
            <div className="col-6 px-2 text-right">
              <button
                type="button"
                className="btn btn-white px-5"
                onClick={() => onSubscribeNow()}
              >
                <MdAutoAwesome /> SUBSCRIBE NOW
              </button>
            </div>{' '}
          </Fragment>
        </div>
      </TopbarFreeNotiBox>
    )
  ) : (
    <Fragment />
  )
}

export default TopbarFreeNotiComponent
