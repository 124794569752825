import styled from 'styled-components'

const ErrorBox = styled.div`
  .content-box {
    height: 95%;
    display: flex;
    align-items: center;
    min-height: 684px;
  }
  .content {
    border-radius: 10px;
    box-shadow: 4px 10px 30px 0 rgb(0 0 0 / 5%);
    background-color: #fff;
    padding: 30px;
  }
`

export { ErrorBox }
