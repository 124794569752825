import React, { Fragment, useState, useEffect, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import FooterComponent from '../layout/footer'
import { ErrorBox } from './style'
import { FaArrowLeft } from 'react-icons/fa'

const ErrorComponent = (props) => {
  const navigate = useNavigate()
  useEffect(() => {
    ;(function () {
      function onTidioChatApiReady() {
        window.tidioChatApi.hide()
        window.tidioChatApi.on('close', function () {
          window.tidioChatApi.hide()
        })
      }

      if (window.tidioChatApi) {
        window.tidioChatApi.on('ready', onTidioChatApiReady)
      } else {
        document.addEventListener('tidioChat-ready', onTidioChatApiReady)
      }
    })()
  }, [])

  return (
    <ErrorBox>
      <div className="page pull-page-bg-secondary">
        <div className="content-box">
          <div className="container-md">
            <div className="row">
              <h5 className="text-primary mb-3">500 Error</h5>
              <h1 className="mb-5">
                <b>Oops! Something went wrong.</b>
              </h1>
              <h5 className="text-secondary line-height-2">
                We’re very sorry, however something went wrong when trying to
                load this page. <br />
                Please try again or contact support.
              </h5>
            </div>
            <div></div>
            <div className="row mt-2">
              <div className="col-lg-auto mt-3">
                <a
                  onClick={() => navigate(-1)}
                  className="btn btn-outline-dark px-5 w-100 text-16"
                >
                  <FaArrowLeft /> Go back
                </a>
              </div>
              <div className="col-lg-2 mt-3">
                <Link to="/home">
                  <a className="btn btn-primary w-100 text-16">Take me home</a>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <FooterComponent />
      </div>
    </ErrorBox>
  )
}

export default ErrorComponent
