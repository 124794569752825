import styled from 'styled-components'
const TermBox = styled.div`
  .content-box {
    height: 100%;
    display: flex;
    align-items: center;
  }
  .content {
    padding: 30px;
  }
  .term-box {
    padding: 40px;
    background-color: #f9fafb;
    padding-bottom: 0;
  }

  .term-box-header {
    font-size: 24px;
    font-weight: normal;
    color: #212529;
  }
  .term-box-body {
    height: 40vh;
    overflow-y: auto;
    padding: 20px;
    background-color: #f9fafb;
  }
  .term-box-footer {
    padding-top: 20px;
  }
  .term-text-content {
    line-height: 2.3;
  }
  .btn-fix {
    width: 200px;
  }
`

export { TermBox }
