import React, { Fragment, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import TopbarComponent from '../layout/topbar'
import MenuComponent from '../layout/menu'

import { TrackingCodesBox } from './style'
import FooterComponent from '../layout/footer'
import TopbarNotiComponent from '../layout/topbarNotification'

import { roleByAccountId, roleByWorkspaceId } from '../../apis/account/api'
import { redirectLogin } from '../../helper/helper'
import { MdDoneAll } from 'react-icons/md'
import { workspaceDetail } from '../../apis/workspace/api'
import LoadingComponent from '../layout/loading'
import TopbarFreeNotiComponent from '../layout/topbarFreeNotification'
import { planDetail } from '../../apis/plan/api'

const { REACT_APP_CDN } = process.env
const TrackingCodesComponent = () => {
  const { accountId } = useParams()
  const { workspaceId } = useParams()
  const [showAlert, setShowAlert] = useState(false)
  const [domain, setDomain] = useState(false)
  let scriptTrackingCode = `<!-- Tag Turbo script start for ${domain} -->
<script src="${REACT_APP_CDN}/${workspaceId}/tag-turbo.js" charset="UTF-8"></script>
<!-- Tag Turbo script end for ${domain} -->`

  const accessToken = localStorage.getItem('token')
  const [activeAccountRole, setActiveAccountRole] = useState('')
  const [activeWorkspaceRole, setActiveWorkspaceRole] = useState('')
  const [isLoadingPage, setIsLoadingPage] = useState(true)
  const [planInfo, setPlanInfo] = useState('')

  const coppyScript = () => {
    var copyText = document.getElementById('textScriptTrackingCode')
    copyText.select()
    copyText.setSelectionRange(0, 99999)
    navigator.clipboard.writeText(copyText.value)
    setShowAlert(true)
    setTimeout(() => {
      setShowAlert(false)
    }, 5000)
  }
  useEffect(() => {
    document.title = 'Tracking Code | Tag Turbo'
    setIsLoadingPage(true)
    getWorkspace()
    getRoleByAccountId()
    getRoleByWorkspaceId()
  }, [])
  const getPlanInfo = async () => {
    const { data, status } = await planDetail(accessToken, accountId)
    if (status === 200) {
      setPlanInfo(data.plan_of_account_detail)
    }
  }
  const getWorkspace = async (token) => {
    const { data, status } = await workspaceDetail(accessToken, workspaceId)
    if (status === 200) {
      setIsLoadingPage(false)
      setDomain(data.workspace_detail.domain)
    }
  }
  const getRoleByAccountId = async () => {
    const { data, status } = await roleByAccountId(accessToken, accountId)
    if (status === 200) {
      setActiveAccountRole(data.data.role)
      if (data.data.role !== 'None') {
        getPlanInfo()
      }
    }
  }
  const getRoleByWorkspaceId = async () => {
    const { data, status } = await roleByWorkspaceId(accessToken, workspaceId)
    if (status === 200) {
      setActiveWorkspaceRole(data.data.role)
    }
  }
  return (
    <TrackingCodesBox>
      <div className="page pull-page-bg-secondary ">
        <div className="content-box">
          {isLoadingPage ? (
            <LoadingComponent />
          ) : (
            <Fragment>
              <TopbarComponent
                isPublish={true}
                callBackFn={''}
                activeWorkspaceRole={activeWorkspaceRole}
                activeAccountRole={activeAccountRole}
              />
              <TopbarFreeNotiComponent planInfo={planInfo} />
              <TopbarNotiComponent />

              <MenuComponent
                activeWorkspaceRole={activeWorkspaceRole}
                activeAccountRole={activeAccountRole}
                planInfo={planInfo}
              />
              <div className="content-menu content-scroll ">
                <div className="container-md animate fadeIn one p-4 min-h-82">
                  <div className="content">
                    <div className="d-flex justify-content-between item-center mb-4">
                      <div className="col-6">
                        <h4 className="m-0 text-20 text-semibold">
                          Tracking Code
                        </h4>
                      </div>
                    </div>

                    <div className="card p-2 border box-shadow-center bg-white mt-2">
                      <div className="card-body">
                        <p className="text-14 mb-1">
                          Copy the code below and paste it between the{' '}
                          {' <head> </head> '}tags on every page of your
                          website.
                        </p>

                        {showAlert && (
                          <div className="tracking-alert">
                            <div role="alert">
                              <span className="badge border-5 px-3 badge-copy text-12">
                                <MdDoneAll className="text-semibold text-14" />{' '}
                                Copied
                              </span>
                            </div>
                          </div>
                        )}
                        <textarea
                          className="tracking-font form-control mt-4 line-height-25"
                          type="text"
                          aria-label="With textarea"
                          id="textScriptTrackingCode"
                          rows="3"
                          value={scriptTrackingCode}
                          disabled
                        ></textarea>
                      </div>
                    </div>
                    <div className="pt-4">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => coppyScript()}
                      >
                        Copy
                      </button>
                    </div>
                  </div>

                  <div className="my-3">
                    <FooterComponent />
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </TrackingCodesBox>
  )
}

export default TrackingCodesComponent
