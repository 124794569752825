import React from 'react'
import { LoadingBox } from './style'

const LoadingComponent = () => {
  return (
    <LoadingBox>
      <div className="loadingbox">
        <img
          src="/assets/img/Loading-spin.gif"
          className="loading-sm"
          alt="Tag Turbo"
        />
      </div>
    </LoadingBox>
  )
}

export default LoadingComponent
