/*global chrome*/
import React, { Fragment, useState, useEffect, useRef } from 'react'
import { BsThreeDotsVertical } from '@react-icons/all-files/bs/BsThreeDotsVertical'
import Modal from 'react-modal'
import { useParams } from 'react-router-dom'
import TopbarNotiComponent from '../layout/topbarNotification'
import { TagBox, TrackingBox, ModalBox, ModalTagBox } from './style'
import FooterComponent from '../layout/footer'
import TopbarComponent from '../layout/topbar'
import MenuComponent from '../layout/menu'
import { roleByAccountId, roleByWorkspaceId } from '../../apis/account/api'
import DataTable from 'react-data-table-component'
import Dropdown from 'react-bootstrap/Dropdown'
import LoadingComponent from '../layout/loading'
import { LiaTimesSolid } from 'react-icons/lia'
import introJs from 'intro.js'

import {
  tagsList,
  updateStatusAllTag,
  deleteAllTag,
  updateStatusByID,
  deleteTagByID,
} from '../../apis/tags/api'
import { timeSince, redirectLogin } from '../../helper/helper'
import { FiSearch } from 'react-icons/fi'
import AlertComponent from '../layout/alert'
import {
  MdOpenInNew,
  MdClose,
  MdInfo,
  MdDoneAll,
  MdSource,
} from 'react-icons/md'
import TopbarFreeNotiComponent from '../layout/topbarFreeNotification'
import { planDetail } from '../../apis/plan/api'
import { IoMdArrowDropupCircle, IoMdArrowDropdownCircle } from 'react-icons/io'
var fullWorkspaceDetail = ''
var fullDomain = ''
const { REACT_APP_EXTENSION_ID, REACT_APP_CDN, REACT_APP_DOWNLOAD_EXTENSION } =
  process.env
const TagComponent = () => {
  const { accountId, workspaceId } = useParams()
  const [isReset, setIsReset] = useState(false)
  const [allTagsList, setAllTagsList] = useState([])
  const [masterList, setMasterList] = useState([])
  const [textSearch, setTextSearch] = useState('')
  const [openPopupDetails, setOpenPopupDetails] = useState(false)
  const [tagDetails, setTagDetails] = useState('')
  const [domain, setDomain] = useState('')
  const [tagID, setTagID] = useState('')
  const [tagName, setTagName] = useState('')
  const [pageName, setPageName] = useState('')
  const [pathName, setPathName] = useState('')
  const [statusExtensions, setStatusExtensions] = useState(true)

  const [openPopupConfirmDeleteByID, setOpenPopupConfirmDeleteByID] =
    useState(false)
  const [openPopupConfirmDeleteAll, setOpenPopupConfirmDeleteAll] =
    useState(false)
  const [openPopupTrackingCode, setOpenPopupTrackingCode] = useState(false)
  let scriptTrackingCode = `<!-- Tag Turbo script start for ${domain} -->
<script src="${REACT_APP_CDN}/${workspaceId}/tag-turbo.js" charset="UTF-8"></script>
<!-- Tag Turbo script end for ${domain} -->`
  const [showAlert, setShowAlert] = useState({
    msg: 'Account Role has been updated successfully.',
    status: true,
    isShow: false,
  })
  const [showAlertCopies, setShowAlertCopies] = useState(false)

  const workspace_created = localStorage.getItem('workspace_created')
  const [isLoadingPage, setIsLoadingPage] = useState(true)
  const [planInfo, setPlanInfo] = useState('')
  const searchInput = useRef(null)

  const [menuGa4, setMenuGa4] = useState(true)
  const [menuGgAds, setMenuGgAds] = useState(false)
  const [menuMetaAds, setMenuMetaAds] = useState(false)
  const [menuTiktokAds, setMenuTiktokAds] = useState(false)

  const [menuActive, setMenuActive] = useState('ga4')

  const [eventGa4, setEventGa4] = useState([])
  const [eventMetaAds, setEventMetaAds] = useState([])
  const [collapseGA4, setcollapseGA4] = useState(true)
  const [collapseMetaAds, setcollapseMetaAds] = useState(true)
  const [collapseTiktokAds, setcollapseTiktokAds] = useState(true)

  const [stepActive, setStepActive] = useState(false)

  const [eventTiktokAds, setEventTiktokAds] = useState([])

  const [eventContentsTiktokAds, setEventContentsTiktokAds] = useState([])

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      inset: '50% auto auto 59%',
      position: 'absolute',
      height: '100vh',
      width: '82%',
    },
  }
  const customStylesSM = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '396px',
    },
  }
  const customStylesTrackingCode = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '689px',
    },
  }
  const handleMenuTab = (menuId) => {
    setMenuActive(menuId)
    if (menuId === 'ga4') {
      setMenuGa4(true)
      setMenuGgAds(false)
      setMenuMetaAds(false)
      setMenuTiktokAds(false)
    } else if (menuId === 'google_ads') {
      setMenuGa4(false)
      setMenuGgAds(true)
      setMenuMetaAds(false)
      setMenuTiktokAds(false)
    } else if (menuId === 'meta_pixel') {
      setMenuGa4(false)
      setMenuGgAds(false)
      setMenuMetaAds(true)
      setMenuTiktokAds(false)
    } else if (menuId === 'tiktok') {
      setMenuGa4(false)
      setMenuGgAds(false)
      setMenuMetaAds(false)
      setMenuTiktokAds(true)
    }
  }
  const [optionSearch, setOptionSearch] = useState('')
  const [dropdownOptionSearch, setDropdownOptionSearch] = useState(false)
  const accessToken = localStorage.getItem('token')
  const [showSettingTag, setShowSettingTag] = useState({
    no: 0,
    isOpen: false,
  })
  const [showSettingPage, setShowSettingPage] = useState({
    no: 0,
    isOpen: false,
  })
  const [activeAccountRole, setActiveAccountRole] = useState('')
  const [activeWorkspaceRole, setActiveWorkspaceRole] = useState('')
  const get_user_tour = localStorage.getItem('user_tour')
  const obj_get_user_tour = JSON.parse(get_user_tour)

  useEffect(() => {
    document.title = 'Tags | Tag Turbo'
    setIsLoadingPage(true)
    setOpenPopupTrackingCode(workspace_created === 'created' ? true : false)
    if (accessToken) {
      getAllTags(accessToken)
    } else {
      redirectLogin()
    }
    document.addEventListener('click', handleClickOutside, true)
    getRoleByAccountId()
    getRoleByWorkspaceId()
    checkExtensions()
  }, [])

  const closePopupCopy = () => {
    setOpenPopupTrackingCode(false)
    localStorage.removeItem('workspace_created')
    startUserTour()
  }

  const startUserTour = () => {
    if (obj_get_user_tour) {
      if (obj_get_user_tour?.status !== 'Done') {
        let filterPage = obj_get_user_tour?.ut_page?.filter(
          (item) => item.page === document.title,
        )
        if (filterPage.length > 0) {
          if (filterPage[0].step !== '3') {
            //เคยเข้าหน้านี้แต่stepยังไม่ครบ
            renderUserTour()
          }
        } else {
          //ยังไม่เคยเข้าหน้านี้
          renderUserTour()
        }
      }
    } else {
      //เข้าแอพครั้งแรก
      renderUserTour()
    }
  }
  const renderUserTour = () => {
    setTimeout(() => {
      introJs()
        .setOptions({
          disableOverlay: false,
          hidePrev: true,
          showStepNumbers: false,
          doneLabel: 'Next',
          exitOnOverlayClick: false,
          showButtons: true,
          showBullets: false,
          hideNext: false,
          highlightClass: 'custom-highlight-class',
        })
        .onbeforechange(function () {
          setTimeout(() => {
            var skipButton = document.querySelector('.introjs-skipbutton')
            if (skipButton) {
              skipButton.addEventListener('click', function () {
                let obj_tour = {
                  ut_page: [],
                  status: 'Done',
                }
                localStorage.setItem('user_tour', JSON.stringify(obj_tour))
              })
            }
          }, 500)
        })
        .onbeforeexit(function () {
          if (obj_get_user_tour) {
            let temp = {
              page: document.title,
              step: '3',
            }
            obj_get_user_tour.ut_page.push(temp)
            localStorage.setItem('user_tour', JSON.stringify(obj_get_user_tour))
            setStepActive(true)
          } else {
            let obj_tour = {
              ut_page: [
                {
                  page: document.title,
                  step: '3',
                },
              ],
              status: '',
            }
            localStorage.setItem('user_tour', JSON.stringify(obj_tour))
            setStepActive(true)
          }
        })
        .start()
    }, 1000)
  }
  const checkExtensions = () => {
    try {
      var myPort = chrome.runtime.connect(REACT_APP_EXTENSION_ID)
      setStatusExtensions(true)
    } catch (error) {
      setStatusExtensions(false)
    }
  }
  const getRoleByAccountId = async () => {
    const { data, status } = await roleByAccountId(accessToken, accountId)
    if (status === 200) {
      setActiveAccountRole(data.data.role)
      if (data.data.role !== 'None') {
        getPlanInfo()
      }
    }
  }
  const getPlanInfo = async () => {
    const { data, status } = await planDetail(accessToken, accountId)
    if (status === 200) {
      setPlanInfo(data.plan_of_account_detail)
    }
  }

  const getRoleByWorkspaceId = async () => {
    const { data, status } = await roleByWorkspaceId(accessToken, workspaceId)
    if (status === 200) {
      setActiveWorkspaceRole(data.data.role)
    }
  }
  const getAllTags = async () => {
    const { data, status } = await tagsList(accessToken, workspaceId)
    if (status === 200) {
      setIsLoadingPage(false)

      for (let i = 0; i < data.data.length; i++) {
        if (data.data[i].is_global) {
          data.data[i].path = 'global'
        }
      }
      setMasterList(data.data)
      const mergeData = mergeToRender(data.data)
      setAllTagsList(mergeData)
      setDomain(data?.workspace_detail?.domain)
      fullWorkspaceDetail = data?.workspace_detail
      fullDomain = data?.workspace_detail?.domain
    } else {
      setIsLoadingPage(false)
    }
  }

  const mergeToRender = (list) => {
    let arr = list.map((item) => (item?.path ? item.path : 'globals'))
    let uniq = [...new Set(arr)]
    let wrapList = []
    let wrapTemp = []
    for (let i = 0; i < uniq.length; i++) {
      let wrapArr = []
      for (let v = 0; v < list.length; v++) {
        if (list[v]?.path === uniq[i]) {
          list[v].tags_name = list[v].tag_name
          list[v].tagName = (
            <span
              onClick={() => getTagDetail(list[v])}
              className="text-14 text-primary cursor"
            >
              {list[v].tag_name}
            </span>
          )
          list[v].tag_type = (
            <span onClick={() => getTagDetail(list[v])} className="text-14 ">
              {list[v].is_global
                ? list[v].global_detail.type === 'somepage'
                  ? 'Some Page'
                  : 'All Pages'
                : 'This Page'}
            </span>
          )

          list[v].trigger_channel =
            list[v].channels?.length > 0 ? (
              list[v]?.channels.map((list, index) => {
                return (
                  <span key={index} className="text-14 ">
                    <img
                      src={`/assets/img/${list.channel}.png`}
                      style={{ height: '18px' }}
                      className="mx-1"
                    />
                  </span>
                )
              })
            ) : (
              <Fragment />
            )

          list[v].option = (
            <Fragment>
              <Dropdown>
                <Dropdown.Toggle className="dropdown-dot">
                  <BsThreeDotsVertical />
                </Dropdown.Toggle>
                <Dropdown.Menu className="position-fixed boxSetting-dot">
                  <Dropdown.Item
                    onClick={
                      () => handleOpenExtensions(list[v])
                      // openExtension(domain, list[v].id), closeAllPopup()
                    }
                    className="dropdown-item-setting-dot text-14"
                  >
                    View & Edit Tag
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="dropdown-item-setting-dot text-14"
                    onClick={() =>
                      onUpdateStatusByID(!list[v].is_pause, list[v].id, list)
                    }
                  >
                    {list[v].is_pause ? 'Unpause tag' : 'Pause Tag'}
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="dropdown-item-setting-dot text-14 text-danger"
                    onClick={() =>
                      handleDelete(list[v].id, list[v].tags_name, list[v].path)
                    }
                  >
                    Delete Tag
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Fragment>
          )

          list[v].publishStatus = (
            <span
              className={`badge ${
                list[v].is_pause ? 'badge-default' : 'badge-primary'
              }`}
            >
              {list[v].is_pause ? 'Paused' : list[v].publish_status}
            </span>
          )
          wrapArr.push(list[v])
          let temp = {}
          temp.name = uniq[i]
          temp.list = wrapArr
          wrapTemp.push(temp)
        }
      }
    }
    wrapTemp = wrapTemp.filter(
      (v, i, a) => a.findIndex((v2) => v2.name === v.name) === i,
    )
    wrapList.push(...wrapTemp)
    return wrapList
  }

  const searchFilter = (event) => {
    setTextSearch(event.target.value)
    if (event.target.value.length > 0) {
      const filteredArray = masterList.filter((obj) =>
        obj?.[optionSearch]
          ?.toLowerCase()
          .includes(event.target.value.toLowerCase()),
      )
      const mergeData = mergeToRender(filteredArray)
      setAllTagsList(mergeData)
    } else if (event.target.value.length === 0) {
      const mergeData = mergeToRender(masterList)
      setAllTagsList(mergeData)
    }
  }

  const caseInsensitiveSort = (rowA, rowB) => {
    const a = rowA.tags_name.toLowerCase()
    const b = rowB.tags_name.toLowerCase()

    if (a > b) {
      return 1
    }

    if (b > a) {
      return -1
    }

    return 0
  }

  const selectOption = (option) => {
    setDropdownOptionSearch(false)
    setOptionSearch(option)
    setTimeout(() => {
      if (searchInput.current) {
        searchInput.current.focus()
      }
    }, 100)
  }
  const handleClickOutside = (event) => {
    if (
      event.target.classList[0] !== 'dropdown-item-setting' &&
      event.target.classList[0] !== '-pointer'
    ) {
      setDropdownOptionSearch(false)
      setShowSettingTag({
        no: 0,
        isOpen: false,
      })
      setShowSettingPage({
        no: 0,
        isOpen: false,
      })
    }
  }

  const getTagDetail = async (data) => {
    if (data?.channels && data.channels.length > 0) {
      handleMenuTab(data.channels[0].channel)
      let filterEvent = data.channels.filter((item) => item.channel === 'ga4')
      setEventGa4(filterEvent[0])
      let filterEventMetaAds = data.channels.filter(
        (item) => item.channel === 'meta_pixel',
      )
      if (filterEventMetaAds.length > 0) {
        setEventMetaAds(filterEventMetaAds[0].event_list[0].element_events)
      }

      let filterEventTiktokAds = data.channels.filter(
        (item) => item.channel === 'tiktok',
      )

      if (filterEventTiktokAds.length > 0) {
        setEventTiktokAds(filterEventTiktokAds[0].event_list[0].element_events)
      }

      let filterEventContentsTiktokAds =
        filterEventTiktokAds[0]?.event_list[0]?.element_events.filter(
          (item) => item.event_parameter_name === 'contents',
        ) || []

      if (filterEventContentsTiktokAds.length > 0) {
        setEventContentsTiktokAds(
          filterEventContentsTiktokAds[0]?.event_parameter_value,
        )
      }
    }
    setOpenPopupDetails(true)
    setTagDetails(data)
  }

  const handleTagSetting = (no) => {
    let obj = {}
    setShowSettingTag({
      no: 0,
      isOpen: false,
    })
    setShowSettingPage({
      no: 0,
      isOpen: false,
    })
    if (showSettingTag.no === no) {
      obj = {
        no: no,
        isOpen: !showSettingTag.isOpen,
      }
    } else {
      obj = {
        no: no,
        isOpen: true,
      }
    }
    setShowSettingTag(obj)
  }

  const handleStatusTag = () => {
    setShowSettingTag({
      no: 0,
      isOpen: false,
    })
    setShowSettingPage({
      no: 0,
      isOpen: false,
    })
  }

  const statusAllTag = async (option, pageName) => {
    let obj = {
      pause: option === 'pause' ? true : false,
      is_global: pageName === 'global' ? true : false,
      path: pageName ? pageName : domain ? domain : '',
    }
    const { data, status } = await updateStatusAllTag(
      accessToken,
      workspaceId,
      domain,
      obj,
    )
    if (data && status === 200) {
      closeAllPopup()
      getAllTags()
      setIsReset(true)
      if (pageName === 'global') {
        handleAlert(
          `All tags has been ${
            option === 'pause' ? 'paused' : 'unpaused'
          } from every page successfully.`,
          true,
        )
      } else {
        handleAlert(
          `All tags has been ${
            option === 'pause' ? 'paused' : 'unpaused'
          } from '${pageName}' page successfully.`,
          true,
        )
      }
    } else {
      handleAlert(
        'Failed to Tag has ' +
          `${option === 'pause' ? 'paused' : 'unpaused'}` +
          ' all.  Please try again.',
        false,
      )
    }
  }
  const onUpdateStatusByID = async (option, tag_id, info) => {
    let obj = {
      pause: option,
    }
    const { data, status } = await updateStatusByID(
      accessToken,
      workspaceId,
      domain,
      obj,
      tag_id,
    )
    handleStatusTag()

    if (data && status === 200) {
      for (let i = 0; i < info.length; i++) {
        if (info[i].id === tag_id) {
          info[i].is_pause = option
        }
      }
      mergeToRender(info)
      // getAllTags(accessToken);
      setIsReset(true)
      handleAlert(
        'Tag has been ' +
          `${option ? 'paused' : ' unpaused'}` +
          ' successfully',
        true,
      )
    } else {
      // getAllTags(accessToken);
      handleAlert(
        'Failed to Tag has ' +
          `${option ? 'paused' : 'unpaused'}` +
          ' . Please try again.',
        false,
      )
    }
  }

  const closeAllPopup = () => {
    setShowSettingTag({
      no: 0,
      isOpen: false,
    })
    setShowSettingPage({
      no: 0,
      isOpen: false,
    })
  }

  const removeAllTag = async (pageName) => {
    closeAllPopup()
    let obj = {
      is_global: pageName === 'global' ? true : false,
      path: pageName,
    }

    const { data, status } = await deleteAllTag(
      accessToken,
      workspaceId,
      domain,
      obj,
    )
    if (data && status === 200) {
      getAllTags(accessToken)
      setOpenPopupConfirmDeleteAll(false)
      handleAlert(
        `All Tags has been deleted from ${pageName} page successfully.`,
        true,
      )
    } else {
      handleAlert('All tags have been deleted unsuccessfully!', false)
      setOpenPopupConfirmDeleteAll(false)
    }
  }

  const onRemoveTagByID = async (tag_id) => {
    closeAllPopup()
    const { data, status } = await deleteTagByID(
      accessToken,
      workspaceId,
      domain,
      tag_id,
    )
    if (data && status === 200) {
      getAllTags(accessToken)
      setOpenPopupConfirmDeleteByID(false)
      handleAlert(`${tagName}` + '  have been deleted Successfully!', true)
    } else {
      handleAlert(`${tagName}` + 'have been deleted unsuccessfully!', false)
    }
  }

  const openExtension = (url, tagId) => {
    const editorExtensionId = REACT_APP_EXTENSION_ID
    if (tagId && tagId !== 'create') {
      fullWorkspaceDetail.tagId = tagId
    } else {
      delete fullWorkspaceDetail.tagId
    }
    try {
      chrome.runtime.sendMessage(
        editorExtensionId,
        {
          openUrlInEditor: url,
          token: accessToken,
          workspace_detail: fullWorkspaceDetail,
        },
        function (error) {
          var lastError = chrome.runtime.lastError
          if (lastError) {
            handleAlert('Extensions Error!!', false)
            // 'Could not establish connection. Receiving end does not exist.'
            return
          }
          // Success, do something with response...
        },
      )
    } catch (error) {
      console.log('extensions error : ', error)
    }
  }

  const handleAlert = (msg, status) => {
    setShowAlert({
      msg,
      status,
      isShow: true,
    })

    setTimeout(() => {
      setShowAlert({
        msg,
        status,
        isShow: false,
      })
    }, 10000)
  }
  const handleDelete = (tag_id, tag_name, path_Name) => {
    closeAllPopup()
    setOpenPopupConfirmDeleteByID(true)
    setTagID(tag_id)
    setTagName(tag_name)
    setPathName(path_Name)
  }
  const handleOpenExtensions = (tagDetail) => {
    if (tagDetail.is_global) {
      openExtension(fullDomain, tagDetail.id)
    } else {
      openExtension(fullDomain + tagDetail.path, tagDetail.id)
    }
    closeAllPopup()
  }

  const MappingEventNameLabel = (eventName) => {
    switch (eventName) {
      case 'AddPaymentInfo':
        return 'Add Payment Info'
      case 'AddToCart':
        return 'Add to Cart'
      case 'AddToWishlist':
        return 'Add to Wishlist'
      case 'CustomizeProduct':
        return 'Customize Product'
      case 'FindLocation':
        return 'Find Location'
      case 'InitiateCheckout':
        return 'Initiate Check Out'
      case 'StartTrial':
        return 'Start Trial'
      case 'SubmitApplication':
        return 'Submit Application'
      case 'ViewContent':
        return 'View Content'
      case 'CompleteRegistration':
        return 'Complete Registration'
      default:
        return eventName
    }
  }

  const handleDeleteAllTag = (tag_length, page) => {
    closeAllPopup()
    setOpenPopupConfirmDeleteAll(true)
    setPageName(page)
  }
  const coppyScript = () => {
    var copyText = document.getElementById('textScriptTrackingCode')
    copyText.select()
    copyText.setSelectionRange(0, 99999)
    navigator.clipboard.writeText(copyText.value)
    setShowAlertCopies(true)
    setTimeout(() => {
      setShowAlertCopies(false)
    }, 10000)
  }

  const columns = [
    {
      name: 'Name',
      selector: (row) => row.tags_name,
      cell: (row) => row.tagName,
      sortable: true,
      sortFunction: caseInsensitiveSort,
      width: '15%',
    },
    {
      name: 'Activity',
      selector: (row) => row.publishStatus,
      sortable: false,
      width: '10%',
    },
    {
      name: 'Type',
      selector: (row) => row.tag_type,
      sortable: false,
      width: '15%',
    },
    {
      name: 'Trigger Action',
      selector: (row) => <span className="text-14">{row.trigger_action}</span>,
      sortable: false,
      width: '15%',
    },
    {
      name: 'Tracking Channel',
      selector: (row) => row.trigger_channel,
      sortable: false,
      width: '15%',
      center: true,
    },
    {
      name: 'Last Edited',
      selector: (row) => (
        <span className="text-14">{timeSince(row.update_at)}</span>
      ),
      sortable: true,
      width: '20%',
    },
    {
      name: '',
      selector: (row) => row.option,
      sortable: false,
    },
  ]
  return (
    <TagBox>
      <div className="page pull-page-bg-secondary ">
        <div className="content-box">
          {isLoadingPage ? (
            <LoadingComponent />
          ) : (
            <Fragment>
              <TopbarComponent
                isPublish={true}
                resetList={isReset}
                callBackFn={''}
                activeWorkspaceRole={activeWorkspaceRole}
                activeAccountRole={activeAccountRole}
                stepActive={stepActive}
              />
              <TopbarFreeNotiComponent planInfo={planInfo} />
              <TopbarNotiComponent />

              <MenuComponent
                activeWorkspaceRole={activeWorkspaceRole}
                activeAccountRole={activeAccountRole}
                planInfo={planInfo}
              />
              {showAlert.isShow && (
                <AlertComponent
                  messageAlert={showAlert.msg}
                  status={showAlert.status}
                  alertType={showAlert.status ? 'alert-success' : 'alert-error'}
                />
              )}
              <div className="content-menu content-scroll ">
                <div className="container-md animate fadeIn one p-4 min-h-82">
                  <div className="content">
                    <div className="d-flex justify-content-between item-center mb-4">
                      <div>
                        <h4 className="m-0 text-20 text-semibold">Tags</h4>
                      </div>
                      <div className="d-flex ">
                        <div className="spacesing ">
                          <div className="input-group -pointer">
                            <div
                              className="input-group-prepend h-44 d-flex"
                              onClick={() => setDropdownOptionSearch(true)}
                            >
                              <span className="input-group-text h-44">
                                <FiSearch className="mx-2" />

                                {optionSearch ? (
                                  <span className="tag-label">
                                    <b>
                                      {' '}
                                      {optionSearch === 'tag_name'
                                        ? 'Tags'
                                        : 'Pages'}{' '}
                                      contains
                                    </b>
                                  </span>
                                ) : (
                                  <span className="text-gray text-14">
                                    Search by Tag name or Page
                                  </span>
                                )}
                              </span>
                              <div
                                className={`vertical-line ${
                                  optionSearch ? '' : 'd-none'
                                }`}
                              ></div>
                            </div>
                            {optionSearch ? (
                              <Fragment>
                                <input
                                  type="text"
                                  className="form-control border-left-0 h-44 searchInput"
                                  placeholder={`Search by ${
                                    optionSearch === 'tag_name'
                                      ? 'Tags'
                                      : 'Pages'
                                  } name`}
                                  ref={searchInput}
                                  value={textSearch}
                                  onChange={searchFilter}
                                />
                                {textSearch.length > 0 && (
                                  <LiaTimesSolid
                                    className="icon-refresh-search"
                                    onClick={() => (
                                      setTextSearch(''),
                                      setOptionSearch(''),
                                      setAllTagsList(mergeToRender(masterList))
                                    )}
                                  />
                                )}
                              </Fragment>
                            ) : (
                              <div
                                className="form-control border-left-0 h-44 searchInput"
                                onClick={() => setDropdownOptionSearch(true)}
                              />
                            )}
                          </div>
                          {dropdownOptionSearch && (
                            <div>
                              <div className="rounded position-absolute boxsearch bg-white">
                                <div className={`dropdown-menu-setting`}>
                                  <div
                                    className="dropdown-item-setting text-14 text-semibold"
                                    onClick={() => selectOption('tag_name')}
                                  >
                                    Tags contain
                                  </div>
                                  <div
                                    className="dropdown-item-setting text-14 text-semibold"
                                    onClick={() => selectOption('path')}
                                  >
                                    Page contain
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        {activeAccountRole ? (
                          activeWorkspaceRole === 'Admin' ||
                          activeWorkspaceRole === 'Publish' ||
                          activeWorkspaceRole === 'Editor' ? (
                            <div className="text-right spacesing-left">
                              <div
                                data-step="3"
                                data-intro='<div><p class="text-semibold text-14 mb-1">Create tag</p><p class="text-12">One click away from tracking greatness! Click <b>"Create Tag"</b> now.</p></div>'
                                data-position="right"
                                data-highlight-class="overlayUT"
                              >
                                <button
                                  type="button"
                                  className="btn badge-blue text-14 text-semibold"
                                  onClick={() =>
                                    openExtension(domain, 'create')
                                  }
                                >
                                  <MdOpenInNew className="mx-2" />
                                  Create tag
                                </button>
                              </div>{' '}
                            </div>
                          ) : (
                            <Fragment />
                          )
                        ) : (
                          <Fragment />
                        )}
                      </div>
                    </div>
                    {optionSearch && (
                      <div className="d-flex mb-4">
                        <b className="text-12">Search By</b>
                        <div className="search-label">
                          Search :{' '}
                          {optionSearch === 'tag_name' ? 'Tags' : 'Pages'}{' '}
                          contains `{textSearch}`{' '}
                        </div>
                      </div>
                    )}

                    {!statusExtensions && (
                      <div
                        className="alert alert-primary d-flex justify-content-between item-center p-0"
                        role="alert"
                        data-step="2"
                        data-intro='<p class="text-14 text-semibold">Download extension</p><p class="text-12 mb-0">Before creating a tag, you will need to install the Tag Turbo extension.</p>
                        <a href="https://help.tagturbo.com/documents/google-extension/" target="_blank" class="text-12">How to install the extension</a>'
                        data-position="bottom-right"
                        data-highlight-class="overlayUT"
                      >
                        {' '}
                        <span className="text-14">
                          {' '}
                          <MdInfo className="mx-2" />
                          Extension is required for tag creation.
                        </span>
                        <a
                          href={`${REACT_APP_DOWNLOAD_EXTENSION}`}
                          download="TagTurbo-Extensions.zip"
                          target="_blank"
                        >
                          <button className="btn btn-light btn-right p-1 px-3 m-2 btn-text-primary text-semibold text-12">
                            Download extension
                          </button>
                        </a>
                      </div>
                    )}
                    {allTagsList && allTagsList.length > 0 ? (
                      allTagsList.map((item, i) => {
                        return (
                          item.list.length > 0 && (
                            <div
                              key={i}
                              className="border-shadow rounded bg-white mt-2 box-shadow-center"
                            >
                              <div className="d-flex justify-content-between mb-1 p-4 pb-2">
                                <div>
                                  <p className="p-0 text-16 text-semibold">
                                    {item.name ? (
                                      <span>
                                        {item.name === 'global'
                                          ? 'Global'
                                          : item.name}
                                      </span>
                                    ) : (
                                      <Fragment>
                                        <span>Global Tags</span>
                                        <span className="text-12">
                                          Tags that are defined on a global
                                          level are applied to multiple pages.
                                        </span>{' '}
                                      </Fragment>
                                    )}
                                  </p>
                                  {item.name === 'global' && (
                                    <p className="text-14">
                                      Tags that are defined on a global level
                                      are applied to multiple pages.
                                    </p>
                                  )}
                                </div>
                                <div className="text-right">
                                  <Dropdown>
                                    <Dropdown.Toggle className="dropdown-dot">
                                      <BsThreeDotsVertical />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className="position-fixed boxSetting-dot">
                                      <Dropdown.Item
                                        className="dropdown-item-setting-dot text-14"
                                        onClick={() => (
                                          openExtension(domain), closeAllPopup()
                                        )}
                                      >
                                        New Tag
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        className="dropdown-item-setting-dot text-14"
                                        onClick={() =>
                                          statusAllTag('pause', item.name)
                                        }
                                      >
                                        Pause All Tags
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        className="dropdown-item-setting-dot text-14"
                                        onClick={() =>
                                          statusAllTag('active', item.name)
                                        }
                                      >
                                        Unpause all Tags
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        className="dropdown-item-setting-dot text-14 text-danger"
                                        onClick={() =>
                                          handleDeleteAllTag(
                                            allTagsList[i].list.length,
                                            item.name,
                                          )
                                        }
                                      >
                                        Delete All Tags
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>

                              <DataTable
                                pagination={true}
                                className="-fadeIn mt-0 none-overflow"
                                center={true}
                                columns={columns}
                                data={allTagsList[i].list}
                              />
                            </div>
                          )
                        )
                      })
                    ) : (
                      <div className="card p-2 border-1 bg-white mt-2">
                        <div className="card-body my-3">
                          <div className="d-flex justify-content-center item-center text-center">
                            <div>
                              <h6>
                                <b>No tags found</b>
                              </h6>{' '}
                              <h6>
                                Start creating by clicking on "Create tag".
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="my-3">
                    <FooterComponent />
                  </div>
                </div>
              </div>
            </Fragment>
          )}
          <Modal
            shouldCloseOnOverlayClick={false}
            closeTimeoutMS={200}
            ariaHideApp={false}
            isOpen={openPopupDetails}
            contentLabel="modal"
            style={customStyles}
            onRequestClose={() => setOpenPopupDetails(false)}
            className={`modal-box animate  one ${
              openPopupDetails ? 'fadeInRight' : 'fadeOutRight'
            }`}
          >
            <div className="modal-dialog modal-md p-3">
              <div className="modal-content p-1">
                <div className="modal-header">
                  <div>
                    <button
                      type="button"
                      className="btn-close"
                      aria-label="Close"
                      onClick={() => setOpenPopupDetails(false)}
                    ></button>
                    <span className="mx-3 text-20 text-semibold">
                      {tagDetails?.tag_name ? tagDetails?.tag_name : '-'}
                    </span>
                  </div>

                  <button
                    type="button"
                    className="btn w-lg btn-primary waves-effect waves-light"
                    onClick={() => (
                      setOpenPopupDetails(false),
                      handleOpenExtensions(tagDetails)
                    )}
                  >
                    <MdOpenInNew className="mx-2" /> Edit Tag
                  </button>
                </div>
                <div className="modal-body border p-3 mt-3 rounded">
                  <div className="content">
                    <p className="text-18 text-semibold">Tag Configuration</p>
                    <p className="my-1 text-12 text-secondary">Page</p>
                    <p className="text-14 ">{tagDetails.path}</p>

                    <div className="row">
                      <div className="col-3">
                        <p className="my-1 text-12 text-secondary">Track on</p>
                        <p className="text-14 ">{tagDetails.tag_type}</p>
                      </div>
                      <div className="col-9">
                        <p className="my-1 text-12 text-secondary">
                          Page Condition
                        </p>
                        <p className="text-14 ">
                          {tagDetails?.global_detail?.page_condition
                            ? tagDetails.global_detail.page_condition
                            : '-'}{' '}
                          :{' '}
                          {tagDetails?.global_detail?.value
                            ? tagDetails.global_detail.value
                            : '-'}
                        </p>
                      </div>
                    </div>

                    <p className="my-1 text-12 text-secondary">
                      Trigger Action
                    </p>
                    <p className="text-14 ">
                      {tagDetails?.trigger_action === 'pageview'
                        ? 'Page Load'
                        : tagDetails?.trigger_action}
                    </p>

                    {tagDetails?.trigger_condition?.type &&
                    tagDetails?.trigger_condition?.value ? (
                      <Fragment>
                        <p className="my-1 text-12 text-secondary">
                          Trigger Condition
                        </p>
                        <p className="text-14 ">
                          {tagDetails?.trigger_condition?.type ? (
                            tagDetails.trigger_condition.type
                          ) : (
                            <p className="text-secondary">Not set</p>
                          )}{' '}
                          ={' '}
                          {tagDetails?.trigger_condition?.value ? (
                            tagDetails.trigger_condition.value
                          ) : (
                            <p className="text-secondary">Not set</p>
                          )}
                        </p>
                      </Fragment>
                    ) : (
                      <Fragment />
                    )}

                    {/* --------- */}

                    <ModalTagBox>
                      <div className="card">
                        <div className="card-header text-16 bg-white text-semibold">
                          Tracking Channel
                        </div>
                        <div id="tab">
                          <ul className="nav nav-tabs" role="tablist">
                            {tagDetails?.channels?.length > 0 ? (
                              tagDetails.channels.map((listChannels, index) => {
                                return (
                                  <div key={index}>
                                    {listChannels.channel === 'ga4' && (
                                      <li className="nav-item">
                                        <a
                                          className={`nav-link ${
                                            menuGa4 ? 'active' : ''
                                          } `}
                                          data-toggle="tab"
                                          onClick={() => handleMenuTab('ga4')}
                                        >
                                          <img
                                            src="/assets/img/ga4.png"
                                            style={{ height: '18px' }}
                                            className="mx-2"
                                          />
                                          Google Analytics 4
                                        </a>
                                      </li>
                                    )}
                                    {listChannels.channel === 'google_ads' && (
                                      <li className="nav-item">
                                        <a
                                          className={`nav-link ${
                                            menuGgAds ? 'active' : ''
                                          } `}
                                          data-toggle="tab"
                                          onClick={() =>
                                            handleMenuTab('google_ads')
                                          }
                                        >
                                          <img
                                            src="/assets/img/google_ads.png"
                                            style={{ height: '18px' }}
                                            className="mx-2"
                                          />{' '}
                                          Google Ads
                                        </a>
                                      </li>
                                    )}
                                    {listChannels.channel === 'meta_pixel' && (
                                      <li className="nav-item">
                                        <a
                                          className={`nav-link ${
                                            menuMetaAds ? 'active' : ''
                                          } `}
                                          data-toggle="tab"
                                          onClick={() =>
                                            handleMenuTab('meta_pixel')
                                          }
                                        >
                                          <img
                                            src="/assets/img/meta_pixel.png"
                                            style={{ height: '18px' }}
                                            className="mx-2"
                                          />{' '}
                                          Meta Ads
                                        </a>
                                      </li>
                                    )}
                                    {listChannels.channel === 'tiktok' && (
                                      <li className="nav-item">
                                        <a
                                          className={`nav-link ${
                                            menuTiktokAds ? 'active' : ''
                                          } `}
                                          data-toggle="tab"
                                          onClick={() =>
                                            handleMenuTab('tiktok')
                                          }
                                        >
                                          <img
                                            src="/assets/img/tiktok.png"
                                            style={{ height: '18px' }}
                                            className="mx-2"
                                          />{' '}
                                          TikTok Ads
                                        </a>
                                      </li>
                                    )}
                                  </div>
                                )
                              })
                            ) : (
                              <Fragment />
                            )}
                          </ul>

                          <div className="tab-content">
                            {tagDetails?.channels?.length > 0 ? (
                              tagDetails.channels.map((listChannels, index) => {
                                return (
                                  <Fragment>
                                    {listChannels.channel === 'ga4' && (
                                      <div
                                        style={{
                                          display:
                                            menuActive === 'ga4'
                                              ? 'block'
                                              : 'none',
                                        }}
                                      >
                                        <Fragment>
                                          <div className="p-3">
                                            <span className="badge text-12 mx-2 text-center custom-badge-tagtype">
                                              Tag Type :{' '}
                                              <span className="text-capitalize">
                                                {tagDetails.channels.filter(
                                                  (item) =>
                                                    item.channel === 'ga4',
                                                ).length > 0
                                                  ? tagDetails.channels.filter(
                                                      (item) =>
                                                        item.channel === 'ga4',
                                                    )[0].tag_type
                                                  : '-'}
                                              </span>
                                            </span>
                                          </div>
                                          <div className="collapse-box">
                                            <div className="collapse-header p-3 border border-bottom">
                                              <div className="d-flex justify-content-between">
                                                <div>
                                                  <span className="text-12 ">
                                                    <MdSource className="text-gray text-14" />
                                                    <span className="text-secondary mx-2">
                                                      Event Name :
                                                    </span>{' '}
                                                    <b className="text-14">
                                                      {' '}
                                                      {tagDetails.channels.filter(
                                                        (item) =>
                                                          item.channel ===
                                                          'ga4',
                                                      ).length > 0
                                                        ? tagDetails.channels.filter(
                                                            (item) =>
                                                              item.channel ===
                                                              'ga4',
                                                          )[0].event_name
                                                        : '-'}
                                                    </b>{' '}
                                                  </span>
                                                </div>

                                                <div
                                                  className="-cursor mx-2 text-primary text-12"
                                                  onClick={() =>
                                                    setcollapseGA4(!collapseGA4)
                                                  }
                                                >
                                                  {collapseGA4 ? (
                                                    <Fragment>
                                                      <span className="mx-2 text-semibold">
                                                        Hide
                                                      </span>
                                                      <IoMdArrowDropupCircle className="text-14" />
                                                    </Fragment>
                                                  ) : (
                                                    <Fragment>
                                                      <span className="mx-2 text-semibold">
                                                        Show
                                                      </span>
                                                      <IoMdArrowDropdownCircle className="text-14" />
                                                    </Fragment>
                                                  )}{' '}
                                                </div>
                                              </div>
                                            </div>
                                            <div
                                              className={`collapse-content p-3 ${
                                                collapseGA4
                                                  ? 'd-block'
                                                  : 'd-none'
                                              }`}
                                            >
                                              <Fragment>
                                                <div className="row mb-2">
                                                  <div className="col-4">
                                                    <p className="my-1 text-12 text-secondary">
                                                      Event Parameter Name
                                                    </p>
                                                    {eventGa4?.element_events
                                                      ?.length > 0 ? (
                                                      eventGa4.element_events.map(
                                                        (list, index) => {
                                                          return (
                                                            <p
                                                              key={index}
                                                              className="text-14 my-1"
                                                            >
                                                              {
                                                                list.event_parameter_name
                                                              }
                                                            </p>
                                                          )
                                                        },
                                                      )
                                                    ) : (
                                                      <p className="text-secondary text-14 my-1">
                                                        Not set
                                                      </p>
                                                    )}
                                                  </div>
                                                  <div className="col-4">
                                                    <p className="my-1 text-12 text-secondary">
                                                      Event Parameter Value
                                                    </p>
                                                    {eventGa4?.element_events
                                                      ?.length > 0 ? (
                                                      eventGa4.element_events.map(
                                                        (list, index) => {
                                                          return (
                                                            <p
                                                              key={index}
                                                              className="text-14 my-1"
                                                            >
                                                              {list?.event_parameter_value_type ===
                                                              'dynamic' ? (
                                                                <span>
                                                                  {
                                                                    list
                                                                      ?.event_parameter_value
                                                                      ?.ref
                                                                  }{' '}
                                                                  :{' '}
                                                                  {` {{Value: ${list?.event_parameter_value?.element_value}}}`}
                                                                </span>
                                                              ) : (
                                                                list.event_parameter_value
                                                              )}
                                                            </p>
                                                          )
                                                        },
                                                      )
                                                    ) : (
                                                      <p className="text-secondary text-14 my-1">
                                                        Not set
                                                      </p>
                                                    )}
                                                  </div>
                                                </div>

                                                <div className="row">
                                                  <div className="col-4">
                                                    <p className="my-1 text-12 text-secondary">
                                                      User Property Name
                                                    </p>
                                                    {eventGa4?.user_events
                                                      ?.length > 0 ? (
                                                      eventGa4.user_events.map(
                                                        (list, index) => {
                                                          return (
                                                            <p
                                                              key={index}
                                                              className="text-14 my-1"
                                                            >
                                                              {list?.event_parameter_value_type ===
                                                              'dynamic'
                                                                ? '-'
                                                                : list.user_property}
                                                            </p>
                                                          )
                                                        },
                                                      )
                                                    ) : (
                                                      <p className="text-secondary text-14 my-1">
                                                        Not set
                                                      </p>
                                                    )}
                                                  </div>
                                                  <div className="col-4">
                                                    <p className="my-1 text-12 text-secondary">
                                                      User Property Value
                                                    </p>
                                                    {eventGa4?.user_events
                                                      ?.length > 0 ? (
                                                      eventGa4.user_events.map(
                                                        (list, index) => {
                                                          return (
                                                            <p
                                                              key={index}
                                                              className="text-14 my-1"
                                                            >
                                                              {list?.event_parameter_value_type ===
                                                              'dynamic'
                                                                ? '-'
                                                                : list?.user_property_value ||
                                                                  '-'}
                                                            </p>
                                                          )
                                                        },
                                                      )
                                                    ) : (
                                                      <p className="text-secondary text-14 my-1">
                                                        Not set
                                                      </p>
                                                    )}
                                                  </div>
                                                </div>
                                              </Fragment>
                                            </div>
                                          </div>
                                        </Fragment>
                                      </div>
                                    )}
                                    {listChannels.channel === 'google_ads' && (
                                      <div
                                        className="p-3"
                                        style={{
                                          display:
                                            menuActive === 'google_ads'
                                              ? 'block'
                                              : 'none',
                                        }}
                                      >
                                        <p className="my-1 text-12 text-secondary">
                                          Conversion Label
                                        </p>
                                        <p className="text-14 ">
                                          {listChannels?.conversion_label
                                            ? listChannels.conversion_label
                                            : '-'}{' '}
                                        </p>
                                      </div>
                                    )}
                                    {listChannels.channel === 'meta_pixel' && (
                                      <div
                                        style={{
                                          display:
                                            menuActive === 'meta_pixel'
                                              ? 'block'
                                              : 'none',
                                        }}
                                      >
                                        <Fragment>
                                          <div className="collapse-box">
                                            <div className="collapse-header p-3 border border-bottom">
                                              <div className="d-flex justify-content-between">
                                                <div>
                                                  <span className="text-12 ">
                                                    <MdSource className="text-gray text-14" />
                                                    <span className="text-secondary mx-2">
                                                      Event Name :
                                                    </span>{' '}
                                                    <b className="text-14">
                                                      {' '}
                                                      {tagDetails.channels.filter(
                                                        (item) =>
                                                          item.channel ===
                                                          'meta_pixel',
                                                      ).length > 0
                                                        ? MappingEventNameLabel(
                                                            tagDetails.channels.filter(
                                                              (item) =>
                                                                item.channel ===
                                                                'meta_pixel',
                                                            )[0]?.event_list[0]
                                                              ?.event_name,
                                                          )
                                                        : '-'}
                                                    </b>{' '}
                                                  </span>
                                                </div>
                                                <div
                                                  className="-cursor mx-2 text-primary text-12"
                                                  onClick={() =>
                                                    setcollapseMetaAds(
                                                      !collapseMetaAds,
                                                    )
                                                  }
                                                >
                                                  {collapseMetaAds ? (
                                                    <Fragment>
                                                      <span className="mx-2 text-semibold">
                                                        Hide
                                                      </span>
                                                      <IoMdArrowDropupCircle className="text-14" />
                                                    </Fragment>
                                                  ) : (
                                                    <Fragment>
                                                      <span className="mx-2 text-semibold">
                                                        Show
                                                      </span>
                                                      <IoMdArrowDropdownCircle className="text-14" />
                                                    </Fragment>
                                                  )}{' '}
                                                </div>
                                              </div>
                                            </div>
                                            <div
                                              className={`collapse-content p-3 ${
                                                collapseMetaAds
                                                  ? 'd-block'
                                                  : 'd-none'
                                              }`}
                                            >
                                              <Fragment>
                                                <div className="d-flex mb-2">
                                                  <div className="firstBox">
                                                    <span className="my-1 text-12 text-secondary">
                                                      Object Property Key
                                                    </span>
                                                  </div>
                                                  <div className="secondBox">
                                                    <span className="my-1 text-12 text-secondary mx-2">
                                                      Object Property Value
                                                    </span>
                                                  </div>
                                                </div>

                                                {eventMetaAds.length > 0 ? (
                                                  eventMetaAds.map(
                                                    (eventList, i) => {
                                                      return (
                                                        <div
                                                          className="d-flex mb-2"
                                                          key={i}
                                                        >
                                                          <div className="firstBox">
                                                            <span className="my-1 text-14">
                                                              {
                                                                eventList.event_parameter_name
                                                              }
                                                            </span>
                                                          </div>

                                                          <div className="secondBox">
                                                            {Array.isArray(
                                                              eventList.event_parameter_value,
                                                            ) &&
                                                            eventList
                                                              .event_parameter_value
                                                              .length > 0 ? (
                                                              eventList.event_parameter_value.map(
                                                                (
                                                                  eventParamValue,
                                                                ) =>
                                                                  eventParamValue?.id ? (
                                                                    <p className="text-14 my-1 d-flex">
                                                                      <label className="text-secondary mx-2">
                                                                        Content
                                                                        ID :
                                                                      </label>
                                                                      <label className="w-200">
                                                                        {
                                                                          eventParamValue.id
                                                                        }
                                                                      </label>

                                                                      <label className="text-secondary mx-2">
                                                                        Quantity
                                                                        :
                                                                      </label>
                                                                      <label className="w-200">
                                                                        {
                                                                          eventParamValue.quantity
                                                                        }
                                                                      </label>
                                                                    </p>
                                                                  ) : (
                                                                    <p className="text-14 my-1 mx-2">
                                                                      {
                                                                        eventParamValue
                                                                      }
                                                                    </p>
                                                                  ),
                                                              )
                                                            ) : (
                                                              <p className="text-14 my-1 mx-2">
                                                                <label className="text-capitalize">
                                                                  {
                                                                    eventList
                                                                      .event_parameter_value
                                                                      .ref
                                                                  }{' '}
                                                                </label>

                                                                {eventList
                                                                  ?.event_parameter_value
                                                                  ?.ref
                                                                  ? ` : {{${
                                                                      eventList
                                                                        .event_parameter_value
                                                                        .ref ===
                                                                      'text'
                                                                        ? 'Value : '
                                                                        : ''
                                                                    } ${
                                                                      eventList
                                                                        .event_parameter_value
                                                                        .element_value
                                                                    } }}`
                                                                  : eventList?.event_parameter_value.toString()}
                                                              </p>
                                                            )}
                                                          </div>
                                                        </div>
                                                      )
                                                    },
                                                  )
                                                ) : (
                                                  <div className="d-flex mb-2">
                                                    <div className="firstBox">
                                                      <span className="my-1 text-12 text-secondary">
                                                        Not Set
                                                      </span>
                                                    </div>
                                                    <div className="secondBox">
                                                      <span className="my-1 text-12 text-secondary mx-2">
                                                        Not Set
                                                      </span>
                                                    </div>
                                                  </div>
                                                )}
                                              </Fragment>
                                            </div>
                                          </div>
                                        </Fragment>
                                      </div>
                                    )}
                                    {listChannels.channel === 'tiktok' && (
                                      <div
                                        style={{
                                          display:
                                            menuActive === 'tiktok'
                                              ? 'block'
                                              : 'none',
                                        }}
                                      >
                                        <Fragment>
                                          <div className="collapse-box">
                                            <div className="collapse-header p-3 border border-bottom">
                                              <div className="d-flex justify-content-between">
                                                <div>
                                                  <span className="text-12 ">
                                                    <MdSource className="text-gray text-14" />
                                                    <span className="text-secondary mx-2">
                                                      Event Name :
                                                    </span>{' '}
                                                    <b className="text-14">
                                                      {' '}
                                                      {tagDetails.channels.filter(
                                                        (item) =>
                                                          item.channel ===
                                                          'tiktok',
                                                      ).length > 0
                                                        ? MappingEventNameLabel(
                                                            tagDetails.channels.filter(
                                                              (item) =>
                                                                item.channel ===
                                                                'tiktok',
                                                            )[0]?.event_list[0]
                                                              ?.event_name,
                                                          )
                                                        : '-'}
                                                    </b>{' '}
                                                  </span>
                                                </div>
                                                <div
                                                  className="-cursor mx-2 text-primary text-12"
                                                  onClick={() =>
                                                    setcollapseTiktokAds(
                                                      !collapseTiktokAds,
                                                    )
                                                  }
                                                >
                                                  {collapseTiktokAds ? (
                                                    <Fragment>
                                                      <span className="mx-2 text-semibold">
                                                        Hide
                                                      </span>
                                                      <IoMdArrowDropupCircle className="text-14" />
                                                    </Fragment>
                                                  ) : (
                                                    <Fragment>
                                                      <span className="mx-2 text-semibold">
                                                        Show
                                                      </span>
                                                      <IoMdArrowDropdownCircle className="text-14" />
                                                    </Fragment>
                                                  )}{' '}
                                                </div>
                                              </div>
                                            </div>
                                            <div
                                              className={`collapse-content p-3 ${
                                                collapseTiktokAds
                                                  ? 'd-block'
                                                  : 'd-none'
                                              }`}
                                            >
                                              <Fragment>
                                                <div className="d-flex mb-2">
                                                  <div className="firstBox">
                                                    <span className="my-1 text-12 text-secondary">
                                                      Event Parameter Name
                                                    </span>
                                                  </div>
                                                  <div className="secondBox">
                                                    <span className="my-1 text-12 text-secondary mx-2">
                                                      Event Parameter Value
                                                    </span>
                                                  </div>
                                                </div>
                                                {eventTiktokAds.length > 0 ? (
                                                  eventTiktokAds.map(
                                                    (eventList, i) => {
                                                      return (
                                                        <div
                                                          key={i}
                                                          className="d-flex mb-2"
                                                        >
                                                          <div className="firstBox">
                                                            <span className="my-1 text-14">
                                                              {
                                                                eventList.event_parameter_name
                                                              }
                                                            </span>
                                                          </div>
                                                          {eventList.event_parameter_name ===
                                                          'contents' ? (
                                                            <div className="secondBox">
                                                              {eventContentsTiktokAds.length >
                                                              0 ? (
                                                                eventContentsTiktokAds.map(
                                                                  (
                                                                    contentsList,
                                                                    i,
                                                                  ) => {
                                                                    return (
                                                                      <div
                                                                        key={i}
                                                                        className="w-100"
                                                                      >
                                                                        <div className="d-flex mb-2 text-14">
                                                                          <div className="mx-2 my-1 w-148 text-grey-500">
                                                                            Content
                                                                            ID :
                                                                          </div>
                                                                          <div className="my-1">
                                                                            {
                                                                              contentsList.content_id
                                                                            }
                                                                          </div>
                                                                        </div>
                                                                        <div className="d-flex mb-2 text-14">
                                                                          <div className="mx-2 my-1 w-148 text-grey-500">
                                                                            Content
                                                                            Name
                                                                            :
                                                                          </div>
                                                                          <div className="my-1">
                                                                            {
                                                                              contentsList.content_name
                                                                            }
                                                                          </div>
                                                                        </div>
                                                                        <div className="d-flex mb-2 text-14">
                                                                          <div className="mx-2 my-1 w-148 text-grey-500">
                                                                            Content
                                                                            Category
                                                                            :
                                                                          </div>
                                                                          <div className="my-1">
                                                                            {
                                                                              contentsList.content_category
                                                                            }
                                                                          </div>
                                                                        </div>
                                                                        <div className="d-flex mb-2 text-14">
                                                                          <div className="mx-2 my-1 w-148 text-grey-500">
                                                                            Price
                                                                            :
                                                                          </div>
                                                                          <div className="my-1">
                                                                            {
                                                                              contentsList.price
                                                                            }
                                                                          </div>
                                                                        </div>
                                                                        <div className="d-flex mb-2 text-14">
                                                                          <div className="mx-2 my-1 w-148 text-grey-500">
                                                                            Quantity
                                                                            :
                                                                          </div>
                                                                          <div className="my-1">
                                                                            {
                                                                              contentsList.quantity
                                                                            }
                                                                          </div>
                                                                        </div>
                                                                        <hr />
                                                                      </div>
                                                                    )
                                                                  },
                                                                )
                                                              ) : (
                                                                <Fragment />
                                                              )}
                                                            </div>
                                                          ) : eventList.event_parameter_value_type ===
                                                            'dynamic' ? (
                                                            <div className="secondBox">
                                                              <div className="mx-2 my-1 text-14">
                                                                {
                                                                  eventList
                                                                    ?.event_parameter_value
                                                                    ?.ref
                                                                }{' '}
                                                                {
                                                                  ' : {{ Value : '
                                                                }{' '}
                                                                {
                                                                  eventList
                                                                    ?.event_parameter_value
                                                                    ?.element_value
                                                                }
                                                                {'}}'}
                                                              </div>
                                                            </div>
                                                          ) : (
                                                            <div className="secondBox">
                                                              <div className="mx-2 my-1 text-14">
                                                                {
                                                                  eventList?.event_parameter_value
                                                                }
                                                              </div>
                                                            </div>
                                                          )}
                                                        </div>
                                                      )
                                                    },
                                                  )
                                                ) : (
                                                  <p className="text-secondary text-14 my-1">
                                                    Not set
                                                  </p>
                                                )}
                                              </Fragment>
                                            </div>
                                          </div>
                                        </Fragment>
                                      </div>
                                    )}
                                  </Fragment>
                                )
                              })
                            ) : (
                              <div className="text-14 zero-tab">
                                <span className="text-semibold mb-2">
                                  No tracking channel found
                                </span>
                                <span>
                                  Add tracking channel by clicking on “Edit
                                  Tag”.
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </ModalTagBox>
                  </div>
                </div>
                <div className="modal-footer justify-content-center"></div>
              </div>
            </div>
          </Modal>
          <Modal
            shouldCloseOnOverlayClick={false}
            closeTimeoutMS={200}
            ariaHideApp={false}
            isOpen={openPopupConfirmDeleteByID}
            contentLabel="modal"
            style={customStylesSM}
            onRequestClose={() => setOpenPopupConfirmDeleteByID(false)}
            className={'modal-Publish-box animate-5 fadeIn two'}
          >
            <ModalBox>
              <div className="modal-dialog modal-md ">
                <div className="modal-content">
                  <div className="modal-header bg-danger">
                    <div className="p-1 text-white"></div>
                  </div>
                  <div className="modal-body p-4 mt-3">
                    <div className="text-center">
                      <img className="mb-2" src="/assets/img/warning.png" />
                    </div>
                    <p className="text-18 text-semibold text-danger text-center w-table">
                      Do you want to delete {tagName}?
                    </p>
                    <p className="text-14 text-center w-table">
                      {tagName} will be deleted from '
                      {pathName === 'global' ? 'every page' : pathName}' page
                    </p>
                  </div>
                  <div className="modal-footer justify-content-center px-4 pb-3">
                    <div
                      className="text-danger mx-3 cursor"
                      onClick={() => setOpenPopupConfirmDeleteByID(false)}
                    >
                      Cancel
                    </div>
                    <div
                      className="btn btn-danger"
                      onClick={() => onRemoveTagByID(tagID)}
                    >
                      Delete
                    </div>
                  </div>
                </div>
              </div>
            </ModalBox>
          </Modal>
          <Modal
            shouldCloseOnOverlayClick={false}
            closeTimeoutMS={200}
            ariaHideApp={false}
            isOpen={openPopupConfirmDeleteAll}
            contentLabel="modal"
            style={customStylesSM}
            onRequestClose={() => setOpenPopupConfirmDeleteAll(false)}
            className={'modal-Publish-box animate-5 fadeIn two'}
          >
            <div className="modal-dialog modal-md ">
              <div className="modal-content">
                <div className="modal-header bg-danger">
                  <div className="p-1 text-white"></div>
                </div>
                <div className="modal-body p-4 mt-3">
                  <div className="text-center">
                    <img className="mb-2" src="/assets/img/warning.png" />
                  </div>
                  <p className="text-18 text-semibold text-danger text-center">
                    Do you want to Delete all tags ?
                  </p>
                  <p className="text-14 text-center ">
                    These tags will be deleted from ' {pageName} ' page
                  </p>
                </div>
                <div className="modal-footer justify-content-center px-4 pb-3">
                  <div
                    className="text-danger mx-3 cursor"
                    onClick={() => setOpenPopupConfirmDeleteAll(false)}
                  >
                    Cancel
                  </div>
                  <div
                    className="btn btn-danger"
                    onClick={() => removeAllTag(pageName)}
                  >
                    Delete
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            shouldCloseOnOverlayClick={false}
            closeTimeoutMS={200}
            ariaHideApp={false}
            isOpen={openPopupTrackingCode}
            contentLabel="modal"
            style={customStylesTrackingCode}
            onRequestClose={() => setOpenPopupTrackingCode(false)}
            className={'modal-Publish-box animate-5 fadeIn two'}
          >
            <div className="modal-dialog modal-md ">
              <div className="modal-content">
                <div className="modal-header bg-primary">
                  <div className="p-1 text-white"></div>
                </div>
                <div className="d-flex justify-content-end px-2">
                  <div>
                    <MdClose
                      className="cursor text-secondary"
                      onClick={() => closePopupCopy()}
                    />
                  </div>
                </div>

                <div className="modal-body p-4 ">
                  <div className="container-md  ">
                    <p className="text-14 mb-1">
                      <h4 className="m-0 mb-3 text-20 text-semibold">
                        Tracking Code
                      </h4>
                      Copy the code below and paste it between the{' '}
                      {' <head> </head> '}tags on every page of your website.
                    </p>
                    {showAlertCopies && (
                      <TrackingBox>
                        <div className="tracking-alert">
                          <div role="alert">
                            <span className="badge border-5 px-3 badge-copy text-12">
                              <MdDoneAll className="text-semibold text-14" />{' '}
                              Copied
                            </span>
                          </div>
                        </div>
                      </TrackingBox>
                    )}
                    <textarea
                      className="tracking-font form-control mt-4 line-height-25"
                      type="text"
                      aria-label="With textarea"
                      id="textScriptTrackingCode"
                      rows="4"
                      value={scriptTrackingCode}
                      disabled
                    ></textarea>
                    <p
                      className="text-gray text-14 mt-3"
                      style={{ color: '#667085' }}
                    >
                      Find your code in <b>'Tracking Code' </b>menu when you're
                      ready to apply it.
                    </p>

                    <div className="pt-4 text-right">
                      <button
                        type="button"
                        className="btn btn-primary px-5"
                        onClick={() => coppyScript()}
                      >
                        Copy
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </TagBox>
  )
}

export default TagComponent
