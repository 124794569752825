import styled from 'styled-components'

const TopbarSettingsBox = styled.div`
  .badge {
    min-width: 55px;
    text-align: center;
    border-radius: 5px !important;
    font-weight: 600;
    font-size: 12px;
  }
  .d-flex {
    display: flex;
    align-items: center;
  }
  .img-logo-box {
    width: 235px;
    border-right: 1px solid #d0d5dd;
  }
  .dropdown-toggle::after {
    display: none !important;
  }
  .dropdown-p-xs {
    padding: 10px 15px !important;
  }
  .dropdown-item-setting {
  }
  .w-logo {
    width: 218px;
  }
  .boxSetting {
    right: 1% !important;
    width: 110px !important;
    text-align: left;
    border: 1px solid #0000000f;
    box-shadow: 6px 7px 20px 0 rgb(0 0 0 / 11%);
    top: 73px;
    z-index: 9;
  }
  .dropdown-menu-top {
    top: 64px;
    z-index: 9;
    left: 224px;
    position: absolute;
  }
  .account-hover:hover {
    background-color: #eaf0ff;
  }
  .account-active {
    background-color: #eaf0ff;
  }
  .body-drop-menu {
    min-height: 400px;
    height: 400px;
    overflow-y: auto;
  }
  .w-275 {
    width: 275px;
  }
  .w-365 {
    width: 365px;
  }
  .h-40 {
    height: 40px;
  }
  .h-53 {
    height: 53px;
  }

  .topbar-box {
    left: 0;
    top: 0;
    width: 100%;
    text-align: center;
    padding: 20px;
    background: #fff;
    border: 1px solid #eaecf0;
    height: 60px;
    .item-center {
      margin-top: 0px;
    }
  }

  .table {
    svg {
      color: #98a2b3;
      cursor: pointer;
    }
  }
`
const FooterBox = styled.div`
  .footer-box {
    // position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: transparent;
    text-align: center;
    padding: 0px 20px;
  }
  .footer-text {
    color: #455a64;
    font-size: 10px;
  }
  .footer-text > a {
    color: #455a64;
    font-size: 10px;
    text-decoration: none;
    margin-left: 5px;
  }
`
const TopbarBox = styled.div`
  .badge {
    min-width: 55px;
    text-align: center;
    border-radius: 5px !important;
    font-weight: 600;
    font-size: 12px;
  }
  .badge-primary {
    background-color: #eaf0ff;
    color: #0047ff;
  }
  .d-flex {
    display: flex;
    align-items: center;
  }
  .img-logo-box {
    width: 235px;
    border-right: 1px solid #d0d5dd;
  }
  .img-logo-box-home {
    width: 235px;
  }
  .dropdown-toggle::after {
    display: none !important;
  }
  .dropdown-item-setting {
    padding: 0px 0px !important;
  }
  .dropdown-p-xs {
    padding: 10px 15px !important;
  }
  .w-logo {
    width: 218px;
  }
  .boxSetting {
    right: 1% !important;
    width: 110px !important;
    text-align: left;
    border: 1px solid #0000000f;
    box-shadow: 6px 7px 20px 0 rgb(0 0 0 / 11%);
    top: 73px;
    z-index: 9;
  }
  .dropdown-menu-top {
    top: 64px;
    z-index: 9;
    left: 224px;
    position: absolute;
  }
  .account-hover:hover {
    background-color: #eaf0ff;
  }
  .account-active {
    background-color: #eaf0ff;
  }
  .body-drop-menu {
    min-height: 400px;
    height: 400px;
    overflow-y: auto;
  }
  .topbar-box {
    left: 0;
    top: 0;
    width: 100%;
    text-align: center;
    padding: 20px;
    background: #fff;
    border: 1px solid #eaecf0;
    height: 60px;
    .item-center {
      margin-top: 0px;
    }
  }
  .table {
    svg {
      color: #98a2b3;
      cursor: pointer;
    }
  }
  .w-275 {
    width: 275px;
  }
  .w-365 {
    width: 365px;
  }
  .h-40 {
    height: 40px;
  }
  .h-53 {
    height: 53px;
  }
`
const MenuBox = styled.div`
  .menu-bt-11 {
    padding-top: 11vh;
  }
  .card-bg-light {
    background-color: #f9fafb;
  }
  .sidenav-free {
    height: calc(100% - 108px) !important;
  }
  .sidenav {
    height: 93vh;
    width: 256px;
    position: fixed;
    left: 0;
    background-color: #fff;
    overflow-x: hidden;
    padding: 10px;
    border: 1px solid #eaecf0;
    display: grid;
    height: calc(100% - 60px);
  }

  .sidenav a {
    padding: 10px 20px;
    text-decoration: none;
    font-size: 14px;
    color: #667085;
    display: block;
    font-weight: 600;
    margin: 1px;
  }

  .sidenav-disable {
    color: #dddd !important;
    cursor: no-drop;
    padding: 10px 25px;
    font-size: 14px;
    font-weight: 600;
    margin: 1px;
  }

  .sidenav a:hover,
  .sidenav_active {
    color: #0047ff;
    background: #eaf0ff;
    border-radius: 10px;
  }

  .main {
    margin-left: 160px; /* Same as the width of the sidenav */
    font-size: 28px; /* Increased text to enable scrolling */
    padding: 0px 10px;
  }

  @media screen and (max-height: 450px) {
    .sidenav {
      padding-top: 15px;
    }
    .sidenav a {
      font-size: 18px;
    }
  }
  .menu-bt {
    padding-top: 12vh;
  }
`
const AlertBox = styled.div`
  .close:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit;
  }
  button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
  }
  .close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
  }
  .alert-success {
    background: #ecfdf3;
    border: 1px solid #12b76a;
    color: #12b76a;
    font-size: 14px;
  }
  .alert-error {
    background: #fecdca;
    border: 1px solid #d92d20;
    color: #d92d20;
    font-size: 14px;
  }
  .alert-warning {
    background: #fedf89;
    border: 1px solid #f79009;
    color: #f79009;
    font-size: 14px;
  }
  .alert-info {
    background: #eaf0ff;
    border: 1px solid #0047ff;
    color: #0047ff;
    font-size: 14px;
  }

  .footer-box {
    // position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: transparent;
    text-align: center;
    padding: 0px 20px;
  }
  .footer-text {
    color: #455a64;
    font-size: 12px;
  }
  .footer-text > a {
    color: #455a64;
    font-size: 12px;
    text-decoration: none;
    margin-left: 20px;
  }
`
const TopbarFreeNotiBox = styled.div`
  background: #0047ff;
  height: 48px;
  padding: 8px 27px;
  color: #fff;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  .btn-right {
    right: 43px;
    position: absolute;
  }
  .text-span {
    display: flex;
    align-items: center;
  }
  .btn-white {
    background: white;
    color: #0047ff;
    font-weight: 600;
    font-size: 12px;
  }
  .badge {
    min-width: 55px;
    text-align: center;
    border-radius: 5px !important;
    font-weight: 600;
    font-size: 12px;
  }
`
const TopbarFreeNotiRedBox = styled.div`
  background: #d92d20;
  height: 48px;
  padding: 8px 27px;
  color: #fff;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  .btn-right {
    right: 43px;
    position: absolute;
  }
  .badge {
    min-width: 55px;
    text-align: center;
    border-radius: 5px !important;
    font-weight: 600;
    font-size: 12px;
  }
  .text-span {
    display: flex;
    align-items: center;
  }
  .btn-white {
    background: transparent;
    color: rgb(255 255 255);
    font-weight: 600;
    font-size: 12px;
    border: 1px solid;
  }
`
const TopbarNotiBox = styled.div`
  background: #d92d20;
  height: 48px;
  padding: 13px 27px;
  color: #fff;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  .btn-right {
    right: 43px;
    position: absolute;
  }
`
const LoadingBox = styled.div`
  .loadingbox {
    text-align: center;
    height: 100vh;
    display: grid;
    align-content: center;
    justify-content: space-evenly;
    justify-items: center;
    align-items: center;
  }
`
const LoadingSMBox = styled.div`
  .loadingbox {
    text-align: center;
    height: auto;
    display: grid;
    align-content: center;
    justify-content: space-evenly;
    justify-items: center;
    align-items: center;
    min-height: 80px;
  }
`

export {
  FooterBox,
  TopbarBox,
  MenuBox,
  AlertBox,
  TopbarSettingsBox,
  TopbarNotiBox,
  LoadingBox,
  TopbarFreeNotiBox,
  LoadingSMBox,
  TopbarFreeNotiRedBox,
}
