import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { CreateWorkspaceBox, ModalBox } from './style'
import FooterComponent from '../layout/footer'
import TopbarComponent from '../layout/topbar'
import { accountById, createWorkspace, industryList } from '../../apis/home/api'
import TopbarNotiComponent from '../layout/topbarNotification'
import Select from 'react-select'
import Modal from 'react-modal'
import introJs from 'intro.js'

const CreateWorkspaceComponent = (props) => {
  const { accountId } = useParams()
  const [accountName, setAccountName] = useState('')
  const [workspaceName, setWorkspaceName] = useState('')
  const [url, setUrl] = useState('')
  const [accessToken, setAccessToken] = useState('')
  const [createWorkspaceId, setCreateWorkspaceId] = useState('')
  const [industyList, setIndustyList] = useState([])
  const [industy, setIndusty] = useState('')
  const [openPopupWorkspaceLimit, setOpenPopupWorkspaceLimit] = useState(false)

  const [isDisable, setisDisable] = useState(false)

  const [validateWorkspaceName, setValidateWorkspaceName] = useState({
    msg: 'updated successfully.',
    status: true,
  })
  const [validateDomainName, setValidateDomainName] = useState({
    msg: 'updated successfully.',
    status: true,
  })
  const [validateIndusty, setValidateIndusty] = useState({
    msg: 'updated successfully.',
    status: true,
  })
  const get_user_tour = localStorage.getItem('user_tour')
  const obj_get_user_tour = JSON.parse(get_user_tour)

  const customStylesSM = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '396px',
    },
  }
  useEffect(() => {
    document.title = 'Create Workspace | Tag Turbo'
    const accessToken = localStorage.getItem('token')
    setAccessToken(accessToken)
    getIndustryList(accessToken)
    if (props?.isCreate) {
      getAccountById(accessToken, accountId)
    }
  }, [])

  useEffect(() => {
    if (obj_get_user_tour) {
      if (obj_get_user_tour?.status !== 'Done') {
        let filterPage = obj_get_user_tour?.ut_page?.filter(
          (item) => item.page === document.title,
        )
        if (filterPage.length > 0) {
          if (filterPage[0].step !== '1') {
            //เคยเข้าหน้านี้แต่stepยังไม่ครบ
            renderUserTour()
          }
        } else {
          //ยังไม่เคยเข้าหน้านี้
          renderUserTour()
        }
      }
    } else {
      //เข้าแอพครั้งแรก
      renderUserTour()
    }
  }, [])

  const renderUserTour = () => {
    setTimeout(() => {
      introJs()
        .setOptions({
          disableOverlay: false,
          hidePrev: true,
          showStepNumbers: false,
          doneLabel: 'Done',
          exitOnOverlayClick: false,
          showButtons: true,
          showBullets: false,
          hideNext: false,
          highlightClass: 'custom-highlight-class',
        })
        .onbeforechange(function () {
          setTimeout(() => {
            var doneButton = document.querySelector('.introjs-donebutton')
            var skipButton = document.querySelector('.introjs-skipbutton')

            if (doneButton) {
              doneButton.addEventListener('click', function () {
                if (obj_get_user_tour) {
                  let temp = {
                    page: document.title,
                    step: '1',
                  }
                  obj_get_user_tour.ut_page.push(temp)
                  localStorage.setItem(
                    'user_tour',
                    JSON.stringify(obj_get_user_tour),
                  )
                } else {
                  let obj_tour = {
                    ut_page: [
                      {
                        page: document.title,
                        step: '1',
                      },
                    ],
                    status: '',
                  }
                  localStorage.setItem('user_tour', JSON.stringify(obj_tour))
                }
              })
            }
            if (skipButton) {
              skipButton.addEventListener('click', function () {
                let obj_tour = {
                  ut_page: [],
                  status: 'Done',
                }
                localStorage.setItem('user_tour', JSON.stringify(obj_tour))
              })
            }
          }, 500)
        })
        .start()
    }, 1000)
  }
  const getAccountById = async (token) => {
    const { data, status } = await accountById(token, accountId)
    if (status === 200) {
      setAccountName(data?.data.name)
    }
  }
  const getIndustryList = async (token) => {
    const { data, status } = await industryList(token)
    if (status === 200) {
      let industry = data.industry
      industry.forEach(function (obj) {
        obj.value = obj.id
        obj.label = obj.name
      })

      setIndustyList(industry)
    }
  }

  const onChangeIndusty = (e) => {
    setIndusty(e.value)
    handleValidateIndusty('', true)
  }

  const onCreateWorkspace = async () => {
    setisDisable(true)
    let isValidWorkspace = false
    let isValidDomain = false
    let isValidIndustry = false
    if (!workspaceName) {
      handleValidateWorkspaceName('Workspace Name is required.', false)
      setisDisable(false)
    } else {
      if (checkSpecialsChar(workspaceName)) {
        isValidWorkspace = true
      } else {
        handleValidateWorkspaceName('Workspace Name is Invalid.', false)
        setisDisable(false)
      }
    }
    if (!url) {
      handleValidateDomainName('Domain Name is required.', false)
      setisDisable(false)
    } else {
      if (checkSpecialsDomain(url)) {
        handleValidateDomainName('', true)
        isValidDomain = true
      } else {
        handleValidateDomainName('Domain Name is Invalid.', false)
        setisDisable(false)
        isValidDomain = false
      }
    }

    if (!industy) {
      handleValidateIndusty('Industy is required.', false)
      setisDisable(false)
    } else {
      handleValidateIndusty('', true)
      isValidIndustry = true
    }

    if (isValidWorkspace && isValidDomain && isValidIndustry) {
      let token = accessToken
      let obj = {
        workspace_name: workspaceName,
        url: url,
        industry_id: industy,
      }
      if (workspaceName && url && industy) {
        const { data, status } = await createWorkspace(token, accountId, obj)
        if (data && status === 200) {
          window.location.href = `/tag/${accountId}/${data.workspace_detail?.id}`
          localStorage.setItem('workspace_created', 'created')
        } else if (status === 400) {
          if (data?.response?.data?.error?.code === 405) {
            setOpenPopupWorkspaceLimit(true)
          }
        } else {
          console.log('error')
        }
        setisDisable(false)
      } else {
        setisDisable(false)
        handleValidateWorkspaceName('Workspace Name is Invalid.', false)
        handleValidateDomainName('Domain Name is Invalid.', false)
      }
    }
  }
  const upgradePlan = () => {
    if (accountId) {
      window.location.href = `/package/${accountId}`
    } else {
      console.log('Upgrade Plan is false')
    }
  }
  const regxUrl = (url) => {
    let regx = /^http[s]?:\/\/(www\.)?(.*)?\/?(.)*/
    if (url.match(regx)) {
      return url
    }
  }

  const removeTrailingSlash = (url) => {
    let lastChar = url.substr(url.length - 1)
    if (lastChar === '/') {
      url = url.slice(0, -1)
    }
    return url
  }

  const checkSpecialsChar = (str) => {
    let res
    const specials = /^[\u0E00-\u0E7Fa-zA-Z0-9\.\s\-_,]+$/gi
    if (str.match(specials)) {
      setWorkspaceName(str)
      handleValidateWorkspaceName('', true)

      res = true
    } else {
      res = false
    }
    return res
  }
  const checkSpecialsDomain = (str) => {
    let res

    const specialsDomain = /^http[s]?:\/\/(www\.)?(.*)?\/?(.)*/
    if (str.match(specialsDomain)) {
      setUrl(str)
      handleValidateDomainName('', true)
      res = true
    } else {
      handleValidateDomainName('Domain is Invalid.', false)
      res = false
    }
    return res
  }
  const handleValidateWorkspaceName = (msg, status) => {
    setValidateWorkspaceName({ msg, status })
  }
  const handleValidateDomainName = (msg, status) => {
    setValidateDomainName({ msg, status })
  }
  const handleValidateIndusty = (msg, status) => {
    setValidateIndusty({ msg, status })
  }

  return (
    <CreateWorkspaceBox>
      <div className="page pull-page-bg-secondary">
        <div className="content-box">
          <TopbarComponent isPublish={false} />
          <TopbarNotiComponent />

          <div className="container-md content-scroll animate fadeIn one">
            <div className="card p-2 border box-shadow-center bg-white mt-2 ">
              <div className="card-body">
                <div className="d-flex justify-content-between mb-4 item-center">
                  <div>
                    <h6>
                      <b>Account</b>
                    </h6>{' '}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-3">
                    <label className="form-label">Account Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name_account"
                      value={accountName}
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card p-2 border box-shadow-center bg-white mt-3 ">
              <div className="card-body">
                <div className="d-flex justify-content-between mb-4 item-center">
                  <div>
                    <h6>
                      <b>Create Workspace</b>
                    </h6>{' '}
                  </div>
                </div>

                <div className="row">
                  <div
                    className="col-7"
                    data-step="1"
                    data-intro="<div><p class='text-semibold text-14 mb-0'>Workspace Name</p>
                              <p class='text-12'>Name your workspace to easily identify and manage your projects. </p>
                              <p class=' text-semibold text-14 mb-0'>Workspace URL</p>
                              <p class='text-12'>Paste your website's main address here, starting with https://. - just the home page part!</p></div>
                              <p class='text-semibold text-14 mb-0'>Business Industry</p>
                              <p class='text-12'>Tell us your website's industry to receive personalized tracking suggestions tailored to your business. </p>"
                    data-position="right"
                    data-highlight-class="overlayUT"
                  >
                    <div className="col-lg-5">
                      <label className="form-label">Workspace Name</label>
                      <input
                        type="text"
                        maxLength={30}
                        className={`form-control ${
                          !validateWorkspaceName.status ? 'invalidate-box' : ''
                        }`}
                        id="workspace_name"
                        placeholder="e.g. My Website"
                        value={workspaceName}
                        onChange={(e) =>
                          e.target.value.length === 0
                            ? setWorkspaceName('')
                            : checkSpecialsChar(e.target.value)
                        }
                      />

                      {!validateWorkspaceName.status && (
                        <label className="form-label invalidate-text text-14">
                          {validateWorkspaceName.msg}
                        </label>
                      )}
                    </div>
                    <div className="row mt-4">
                      <div className="col-lg-5 pr-0">
                        <label className="form-label">Website URL</label>
                        <input
                          type="text"
                          className={`form-control ${
                            !validateDomainName.status ? 'invalidate-box' : ''
                          }`}
                          id="url_name"
                          placeholder="e.g. https://mysite.com"
                          value={url}
                          onChange={(e) => (
                            setUrl(regxUrl(e.target.value)),
                            handleValidateDomainName('', true)
                          )}
                          onBlur={(e) =>
                            setUrl(removeTrailingSlash(e.target.value))
                          }
                        />

                        {!validateDomainName.status && (
                          <label className="form-label invalidate-text text-14">
                            {validateDomainName.msg}
                          </label>
                        )}
                      </div>
                      <label className="form-label col-lg-12 text-12 mx-1">
                        Input your site's main URL (including https:// or
                        http://) and you can create tags all subdomains and
                        subdirectories.
                      </label>
                    </div>

                    <div className="row mt-2">
                      <div className="col-lg-5 ">
                        <label className="form-label">
                          Business Industry *
                        </label>
                        <Select
                          options={industyList}
                          className={`mb-1 ${
                            !validateIndusty.status
                              ? 'invalidate-box -br-5'
                              : ''
                          }`}
                          onChange={onChangeIndusty}
                        />
                      </div>
                      {!validateIndusty.status && (
                        <label className="form-label invalidate-text text-14">
                          {validateIndusty.msg}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="pt-4">
              <button
                type="button"
                onClick={() => (window.location.href = '/home')}
                className="btn btn-outline-secondary"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={() => onCreateWorkspace()}
                className="btn btn-primary"
                disabled={isDisable}
              >
                Create
              </button>
            </div>
          </div>
          <FooterComponent />
        </div>
      </div>
      <Modal
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={200}
        ariaHideApp={false}
        isOpen={openPopupWorkspaceLimit}
        contentLabel="modal"
        style={customStylesSM}
        onRequestClose={() => setOpenPopupWorkspaceLimit(false)}
        className={'modal-Publish-box animate-5 fadeIn two'}
      >
        <ModalBox>
          <div className="modal-dialog modal-md ">
            <div className="modal-content">
              <div className="modal-header bg-danger">
                <div className="p-1 text-white"></div>
              </div>
              <div className="modal-body p-3 mt-3">
                <div className="text-center">
                  <img className="mb-2" src="/assets/img/warning.png" />
                </div>
                <p className="text-18 text-semibold text-danger text-center">
                  Your Account Requires an Upgrade
                </p>

                <p className="text-14 text-center mb-2">
                  You have reached the total number of account users <br />{' '}
                  allowed for your plan. To continue to invite more workspace{' '}
                  <br /> users, your account will need to be upgraded to a new
                  plan.
                </p>
              </div>
              <div className="modal-footer justify-content-center px-4 pb-3">
                <div
                  className="text-danger mx-3 cursor text-14"
                  onClick={() => setOpenPopupWorkspaceLimit(false)}
                >
                  Cancel
                </div>
                <div
                  className="btn btn-danger text-14"
                  onClick={() => upgradePlan()}
                >
                  Upgrade Plan
                </div>
              </div>
            </div>
          </div>
        </ModalBox>
      </Modal>
    </CreateWorkspaceBox>
  )
}

export default CreateWorkspaceComponent
